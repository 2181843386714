import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from "react-router-dom";

import UserLayout from "../layouts/UserLayout";

import { ReactComponent as GoogleIcon } from '../assets/GoogleIcon.svg';
import { ReactComponent as DiscordIcon } from '../assets/DiscordIcon.svg';
import environment from '../helpers/envProvider';

function Integrations() {
  const user = useSelector(state => state.userSlice.user);
  const [searchParams] = useSearchParams();

  const connectedClasses = `pointer-events-none`;
  const defaultClasses = 'flex items-center justify-center gap-[10px] bg-app-search-gray w-[450px] h-[50px] mb-[25px] rounded-[20px] text-white font-semibold';

  useEffect(() => {
    if(searchParams.get('canceled')) {
      toast('Connection canceled.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }

    if(searchParams.get('discordConnected')) {
      toast('Discord connected successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }

    if(searchParams.get('googleConnected')) {
      toast('Google connected successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }

    if(searchParams.get('error')) {
			const err = searchParams.get('error')
			if (err === 'noDiscordEmail') {
        toast("Your discord email is not verified, please verify your email in discord and try again", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
			} else {
				toast('Something went wrong during connection, please try again.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					theme: 'dark',
					autoClose: 2000
				});
			}
    }
  }, []);
  
  return (
    <UserLayout>
      <div className="px-12 py-6">
        <a
          href={`https://discord.com/api/oauth2/authorize?client_id=${environment('REACT_APP_DISCORD_CLIENT_ID')}&redirect_uri=${environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com'}%2Fauth%2Fconnect-discord&response_type=code&scope=identify%20email`}
          className={`${defaultClasses} ${user?.discordId ? connectedClasses : ''}`}
        >
          <DiscordIcon /> {user?.discordId ? 'Discord connected' : 'Connect with Discord' }
        </a>

        <a
          href={`${environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com'}/auth/google-redirect?action=connect`}
          className={`${defaultClasses} ${user?.googleEmails?.length > 0 ? connectedClasses : ''}`}>
          <GoogleIcon alt="Google Icon" /> {user?.googleEmails?.length > 0 ? 'Google connected' : 'Connect with Google' }
        </a>
      </div>
    </UserLayout>
  );
}

export default Integrations;
