import { useNotify, useGetOne, RecordContextProvider, ReferenceField, TextField, useRecordContext } from 'react-admin';
import authAxiosInstance from '../helpers/auth-axios';
import React, { useState, useCallback } from 'react';
import AdminGenerationCard from './AdminGenerationCard';
import MultiSwitchToggle from './base/MultiSwitchToggle';
import { useGetList, Loading, Pagination } from 'react-admin';
import { Grid, Select, Modal } from '@material-ui/core';
import { CONFIG_PROPS } from '../components/input/style-config/constants';

import { ReactComponent as CloseIcon } from '../assets/CloseIcon.svg';
import { ReactComponent as ChevronDown } from '../assets/ChevronDownLightGray.svg';

function timestampToDate(timestamp) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  let hour = date.getHours();
  const minute = ('0' + date.getMinutes()).slice(-2);

  const amOrPm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12 || 12;

  const formattedDate = `${year}-${month}-${day} ${hour}:${minute} ${amOrPm}`;

  return formattedDate;
}

export const OldGenerationModal = (props) => {
	const { handleNext, handlePrev, handleClose } = props;
	const selectedItem = useRecordContext();
	return (
		<div className='w-full  h-full flex items-center justify-center overflow-auto'>
			<div className='relative w-full h-auto max-w-[650px] flex flex-col items-center justify-center bg-white rounded-[20px] p-4 gap-4'>
				<img
					className='w-full h-auto aspect-square object-cover rounded-[10px]'
					src={selectedItem.imageUrl}
					alt=''
				/>
				<button
					className='absolute top-6 right-6 p-[2px] bg-divider rounded-md'
					onClick={(e) => handleClose()}
				>
					<CloseIcon />
				</button>
				<button
					className='rounded-md bg-divider absolute top-[40%] left-6 p-[2px] -translate-y-1/2 z-20'
					onClick={handlePrev}
				>
					<ChevronDown className='rotate-90 w-6 h-6' />
				</button>
				<button
					className='rounded-md bg-divider absolute top-[40%] right-6 p-[2px] -translate-y-1/2 z-20'
					onClick={handleNext}
				>
					<ChevronDown className='-rotate-90 w-6 h-6' />
				</button>
				<div className='h-auto w-full flex items-center justify-center'>
					<div className='flex flex-col items-start justify-center gap-3 w-[60%] pr-4'>
						<div className='flex gap-[5px] flex-wrap'>
							{selectedItem.subscriptionProvider && (
								<p className='bg-[#979797] px-1 text-[10px] rounded-[7px]'>
									{selectedItem.subscriptionProvider === 'stripe'
										? 'Paid'
										: selectedItem.subscriptionProvider === 'free'
										? 'Free'
										: selectedItem.subscriptionProvider === 'appsumo'
										? 'Sumo'
										: null}
								</p>
							)}
							<ReferenceField source="userId" reference="users">
								<TextField source="email" className='text-[#1976D2] text-[12px] font-bold'/>
							</ReferenceField>
							<p className='text-[#BABABA] text-[10px]'>
								{timestampToDate(selectedItem.createdAt)}
							</p>
						</div>
						<p className='text-[14px] text-[#92929D]'>
							{selectedItem.prompt}
						</p>
						<p className='text-[#940000] text-[10px]'>
							{selectedItem.negativePrompt}
						</p>
						<p className='text-[14px] text-[#92929D]'>
							{selectedItem.internals?.injectedPrompt}
						</p>
						<p className='text-[14px] text-[#92929D]'>
							{selectedItem.internals?.injectedNegativePrompt}
						</p>
					</div>
					<div className='flex flex-col w-[40%] items-start justify-center '>
						<div className='flex w-full items-start justify-center gap-2'>
							{selectedItem.inputImage && (
								<img
									className='w-[70px] h-[70px] object-cover rounded-[5px] shadow-xl'
									src={selectedItem.inputImage}
									alt=''
								/>
							)}
							<div className='flex flex-col gap-2'>
								<p className='text-[14px] text-[#92929D]'>
									Redesign priority:
									<span className='text-[#636373] font-bold ml-1'>
										{selectedItem?.controlnetMode}
									</span>
								</p>
								<p className='text-[14px] text-[#92929D]'>
									IMG Influence:
									<span className='text-[#636373] font-bold ml-1'>
										{selectedItem?.imageWeight}
									</span>
								</p>
								<p className='text-[14px] text-[#92929D]'>
									Use Image Colors:
									<span className='text-[#636373] font-bold ml-1'>
										{selectedItem?.image_as_control_image
											? 'Yes'
											: 'No'}
									</span>
								</p>
							</div>
						</div>
						{selectedItem.styleImage && (
							<div className='flex w-full items-start justify-center gap-2'>
								<img
									className='w-[70px] h-[70px] object-cover rounded-[5px] shadow-xl'
									src={selectedItem.styleImage}
									alt=''
								/>
								<div className='flex flex-col gap-2'>
									<p className='text-[14px] text-[#92929D]'>
										Background Separation:
										<span className='text-[#636373] font-bold ml-1'>
											{selectedItem?.useBackgroundSeparation ? selectedItem?.backgroundSeparation : 'Off'}
										</span>
									</p>
									<p className='text-[14px] text-[#92929D]'>
										Style Strength:
										<span className='text-[#636373] font-bold ml-1'>
											{selectedItem?.useStyleStrength ? selectedItem?.styleStrength : 'Off'}
										</span>
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='w-full h-[1px] bg-[#E3E3E3]'></div>
				<div className='flex w-full items-start justify-between'>
					<div className='flex flex-col gap-4 w-1/3'>
						<p className='text-[14px] text-[#92929D]'>
							Resolution:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.dimension}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Image quality:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.imageQuality}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Sampler:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.scheduler}
							</span>
						</p>
					</div>
					<div className='flex flex-col gap-4 w-1/3'>
						<p className='text-[14px] text-[#92929D]'>
							Seed:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.seed}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Prompt Rigidness:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.promptRigidness}
							</span>
						</p>
						<div>
							<p className='text-[14px] text-[#92929D]'>
								Styles:
								<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold ml-1 inline-flex'>
									{selectedItem?.contentStyle}
								</span>
							</p>
						</div>
					</div>
					<div className='flex flex-col items-start gap-4 w-1/3'>
						<p className='text-[14px] text-[#92929D]'>
							Style Setting:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem.styleConfig && Object.keys(selectedItem.styleConfig).map(
									(k, index) => {
										const config = selectedItem.styleConfig[k];
										if (!config) return <></>
										const configValues = CONFIG_PROPS[
											selectedItem.method
										][selectedItem.style || selectedItem.contentStyle]?.[k]?.values?.filter(
											(it) => it.id === config.value
										);

										if (configValues && configValues.length > 0) {
											return (
												<React.Fragment key={index}>
													{typeof configValues[0].name === 'object' &&
													React.isValidElement(configValues[0].name)
														? React.cloneElement(configValues[0].name, {
																className: `${configValues[0].name.props.className} inline`,
															})
														: configValues[0].name}
													{/* {configValues[0].name} */}
													{index !==
														Object.keys(selectedItem.styleConfig)
															.length -
															1 && ', '}
												</React.Fragment>
											);
										}
									}
								)}
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	) 
}
export const V4GenerationModal = (props) => {
	const { handleNext, handlePrev, handleClose } = props;
	const selectedItem = useRecordContext();
	return (
		<div className='w-full  h-full flex items-center justify-center overflow-auto'>
			<div className='relative w-full h-auto max-w-[650px] flex flex-col items-center justify-center bg-white rounded-[20px] p-4 gap-4'>
				<img
					className='w-full h-auto aspect-square object-cover rounded-[10px]'
					src={selectedItem.imageUrl}
					alt=''
				/>
				<button
					className='absolute top-6 right-6 p-[2px] bg-divider rounded-md'
					onClick={(e) => handleClose()}
				>
					<CloseIcon />
				</button>
				<button
					className='rounded-md bg-divider absolute top-[40%] left-6 p-[2px] -translate-y-1/2 z-20'
					onClick={handlePrev}
				>
					<ChevronDown className='rotate-90 w-6 h-6' />
				</button>
				<button
					className='rounded-md bg-divider absolute top-[40%] right-6 p-[2px] -translate-y-1/2 z-20'
					onClick={handleNext}
				>
					<ChevronDown className='-rotate-90 w-6 h-6' />
				</button>
				<div className='h-auto w-full flex items-center justify-center'>
					<div className='flex flex-col items-start justify-center gap-3 w-[60%] pr-4'>
						<div className='flex gap-[5px] flex-wrap'>
							{selectedItem.subscriptionProvider && (
								<p className='bg-[#979797] px-1 text-[10px] rounded-[7px]'>
									{selectedItem.subscriptionProvider === 'stripe'
										? 'Paid'
										: selectedItem.subscriptionProvider === 'free'
										? 'Free'
										: selectedItem.subscriptionProvider === 'appsumo'
										? 'Sumo'
										: selectedItem.subscriptionProvider === 'partner'
										? 'Partner'
										: null}
								</p>
							)}
							<ReferenceField source="userId" reference="users">
								<TextField source="email" className='text-[#1976D2] text-[12px] font-bold'/>
							</ReferenceField>
							<p className='text-[#BABABA] text-[10px]'>
								{timestampToDate(selectedItem.createdAt)}
							</p>
						</div>
						<p className='text-[14px] text-[#92929D]'>
							{selectedItem.prompt}
						</p>
						<p className='text-[#940000] text-[10px]'>
							{selectedItem.negativePrompt}
						</p>
						<p className='text-[14px] text-[#92929D]'>
							{selectedItem.internals?.injectedPrompt}
						</p>
						<p className='text-[14px] text-[#92929D]'>
							{selectedItem.internals?.injectedNegativePrompt}
						</p>
					</div>
					<div className='flex flex-col w-[40%] items-start justify-center '>
						{selectedItem.inputImage && (
							<div className='flex w-full items-start justify-center gap-2'>
								<img
									className='w-[70px] h-[70px] object-cover rounded-[5px] shadow-xl'
									src={selectedItem.inputImage}
									alt=''
								/>
								<div className='flex flex-col gap-2'>
									<p className='text-[14px] text-[#92929D]'>
										Strength:
										<span className='text-[#636373] font-bold ml-1 space-x-2'>
											{selectedItem?.method === 'v4/xl' && (
												<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
													{selectedItem?.cnSwitch ? 'On' : 'Off'}
												</span>
											)}
										{(
											selectedItem?.method === 'v4/bolt' ||
											selectedItem?.method === 'v4/style-transfer' 
										) && (
												<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
													{selectedItem?.multiCnSwitch ? 'On' : 'Off'}
												</span>
											)}
											<span>
												{selectedItem?.imageStrength}
											</span>
										</span>
									</p>
									{(
										selectedItem?.method === 'v4/bolt' ||
										selectedItem?.method === 'v4/style-transfer' 
									) && (
										<>
											<p className='text-[14px] text-[#92929D]'>
												Depth:
												<span className='text-[#636373] font-bold ml-1 space-x-2'>
													<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
														{selectedItem?.depthSwitch ? 'On' : 'Off'}
													</span>
												</span>
											</p>
											<p className='text-[14px] text-[#92929D]'>
												Details:
												<span className='text-[#636373] font-bold ml-1 space-x-2'>
													<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
														{selectedItem?.cannySwitch ? 'On' : 'Off'}
													</span>
												</span>
											</p>
										</>
									)}
									<p className='text-[14px] text-[#92929D]'>
										Image Colors:
										<span className='text-[#636373] font-bold ml-1 space-x-2'>
											<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
												{selectedItem?.useImageColors ? 'On' : 'Off'}
											</span>
											<span>
												{selectedItem?.main_sampler_denoise_value}
											</span>
										</span>
									</p>
									<p className='text-[14px] text-[#92929D]'>
										Use Image Colors:
										<span className='text-[#636373] font-bold ml-1'>
											{selectedItem?.image_as_control_image
												? 'Yes'
												: 'No'}
										</span>
									</p>
								</div>
							</div>
						)}
						{selectedItem.styleImage && (
							<div className='flex w-full items-start justify-center gap-2'>
								<img
									className='w-[70px] h-[70px] object-cover rounded-[5px] shadow-xl'
									src={selectedItem.styleImage}
									alt=''
								/>
								<div className='flex flex-col gap-2'>
									<p className='text-[14px] text-[#92929D]'>
										Background Separation:
										<span className='text-[#636373] font-bold ml-1'>
											{selectedItem?.useBackgroundSeparation ? selectedItem?.backgroundSeparation : 'Off'}
										</span>
									</p>
									<p className='text-[14px] text-[#92929D]'>
										Style Strength:
										<span className='text-[#636373] font-bold ml-1'>
											{selectedItem?.useStyleStrength ? selectedItem?.styleStrength : 'Off'}
										</span>
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='w-full h-[1px] bg-[#E3E3E3]'></div>
				<div className='flex w-full items-start justify-between'>
					<div className='flex flex-col gap-4 w-1/3'>
						<p className='text-[14px] text-[#92929D]'>
							Resolution:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.dimension}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Image quality:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.imageQuality}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Sampler:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.scheduler}
							</span>
						</p>
					</div>
					<div className='flex flex-col gap-4 w-1/3'>
						<p className='text-[14px] text-[#92929D]'>
							Seed:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.seed}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Prompt Rigidness:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem?.promptRigidness}
							</span>
						</p>
						<div>
							<p className='text-[14px] text-[#92929D]'>
								Method:
								<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold ml-1 inline-flex'>
									{selectedItem?.method}
								</span>
							</p>
						</div>
					</div>
					<div className='flex flex-col items-start gap-4 w-1/3'>
						<p className='text-[14px] text-[#92929D]'>
							Styles:
							<span className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold ml-1 inline-flex'>
								{selectedItem?.style || selectedItem?.contentStyle}
							</span>
						</p>
						<p className='text-[14px] text-[#92929D]'>
							Style Setting:
							<span className='text-[#636373] font-bold ml-1'>
								{selectedItem.styleConfig && Object.keys(selectedItem.styleConfig).map(
									(k, index) => {
										const config = selectedItem.styleConfig[k];
										if (!config) return <></>
										const configValues = CONFIG_PROPS[
											selectedItem.method
										][selectedItem.style || selectedItem.contentStyle]?.[k]?.values?.filter(
											(it) => it.id === config.value
										);

										if (configValues && configValues.length > 0) {
											return (
												<React.Fragment key={index}>
													{typeof configValues[0].name === 'object' &&
													React.isValidElement(configValues[0].name)
														? React.cloneElement(configValues[0].name, {
																className: `${configValues[0].name.props.className} inline`,
															})
														: configValues[0].name}
													{/* {configValues[0].name} */}
													{index !==
														Object.keys(selectedItem.styleConfig)
															.length -
															1 && ', '}
												</React.Fragment>
											);
										}
									}
								)}
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	) 
}

export const GenerationListNew = () => {
  const notify = useNotify();

  const [filter, setFilter] = useState('All generations');

  const handleFilter = useCallback((filter) => {
    setFilter(filter);
  }, []);

  const subscriptions = {
    'All generations': {},
    Paid: { subscriptionProvider: 'stripe' },
    Free: { subscriptionProvider: 'free' },
    Sumolings: { subscriptionProvider: 'appsumo' },
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedGenerationIds, setSelectedGenerationIds] = useState([]);

  const handleOpen = (item, index) => {
    setSelectedIndex(index);
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setModalOpen(false);
  };

  const handleNext = () => {
    if (selectedIndex < data.length - 1) {
      setSelectedIndex(selectedIndex + 1);
      setSelectedItem(data[selectedIndex + 1]);
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
      setSelectedItem(data[selectedIndex - 1]);
    }
  };

  const onClickCheckBox = (id, isChecked) => {
    if (isChecked) {
      const _selectedIds = [...selectedGenerationIds, id];
      setSelectedGenerationIds(_selectedIds);
    } else {
      const _selectedIds = selectedGenerationIds.filter((it) => it !== id);
      setSelectedGenerationIds(_selectedIds);
    }
  };

  const addToGallery = async () => {
    try {
      const resp = await authAxiosInstance.post('/api/gallery', {
        generationIds: selectedGenerationIds,
      });

      setSelectedGenerationIds([]);

      notify('Generations added to gallery', { type: 'success' });
    } catch (e) {
      notify('Failed to add to gallery', { type: 'error' });
    }
  };
  const addToGalleryAsDraft = async () => {
    const { draft } = { draft: true };
    try {
      const resp = await authAxiosInstance.post('/api/gallery', {
        generationIds: selectedGenerationIds,
        draft,
      });

      setSelectedGenerationIds([]);

      notify('Generations added to gallery', { type: 'success' });
    } catch (e) {
      notify('Failed to add to gallery', { type: 'error' });
    }
  };

  const { data, total, isLoading, error } = useGetList('generation', {
    pagination: { page, perPage },
    sort: { field: 'published_at', order: 'DESC' },
    filter: subscriptions[filter],
  });

  function handlePerPageChange(event) {
    setPage(1);
    setPerPage(event.target.value);
  }
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <>
      <div className='py-6'>
        <div className='flex items-center justify-between'>
          <MultiSwitchToggle selected={filter} handleSelect={handleFilter} />
          <div className='flex items-center justify-between gap-3'>
            <button
              className='rounded-xl bg-picker-button focus:bg-ld-purple disabled:bg-select-label-disabled focus:outline-none px-2 py-1 text-white text-[11px] font-semibold'
              onClick={addToGallery}
              disabled={!selectedGenerationIds.length}
            >
              Add ({selectedGenerationIds.length}) designs to gallery
            </button>
            <button
              className='rounded-xl bg-picker-button focus:bg-ld-purple disabled:bg-select-label-disabled focus:outline-none px-2 py-1 text-white text-[11px] font-semibold'
              onClick={addToGalleryAsDraft}
              disabled={!selectedGenerationIds.length}
            >
              Add ({selectedGenerationIds.length}) designs to gallery as draft
            </button>
          </div>
        </div>
        <Grid container className='gap-[19px] py-4'>
          {data.map((item, index) => (
						<RecordContextProvider value={item} key={item._id}>
							<AdminGenerationCard
								_id={item._id}
								userId={item.userId}
								imageUrl={item.imageUrl}
								method={item.method}
								inputImage={item.inputImage}
								styleImage={item.styleImage}
								subscriptionProvider={item.subscriptionProvider}
								createdAt={timestampToDate(item.createdAt)}
								promptText={item.prompt}
								negativePromptText={item.negativePrompt}
								injectedPrompt={item.internals?.injectedPrompt}
								injectedNegativePrompt={item.internals?.injectedNegativePrompt}
								contentStyle={item.style || item.contentStyle}
								dimension={item.dimension}
								imageQuality={item.imageQuality}
								onClick={() => handleOpen(item, index)}
								onClickCheckBox={onClickCheckBox}
								isChecked={selectedGenerationIds.includes(item.id)}
							/>
						</RecordContextProvider>
          ))}
        </Grid>
        <div className='flex items-center justify-end'>
          <p className='text-[14px] mr-1'>Rows per page:</p>
          <Select
            disableUnderline
            value={perPage}
            onChange={handlePerPageChange}
          >
            {[10, 25, 50, 75, 100].map((value) => (
              <option className='text-center' key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            total={total}
            rowsPerPageOptions={[]}
          />
        </div>
        {selectedItem && (
					<RecordContextProvider value={selectedItem} key={selectedItem._id}>
						<Modal open={modalOpen} onClose={handleClose}>

							{selectedItem?.method?.startsWith('v4') ? 
								<V4GenerationModal handleClose={handleClose} handleNext={handleNext} handlePrev={handlePrev} /> :
								<OldGenerationModal handleClose={handleClose} handleNext={handleNext} handlePrev={handlePrev} />
							}
						</Modal>
					</RecordContextProvider>
        )}
      </div>
    </>
  );
};

export default GenerationListNew;
