import { useState, useEffect, useMemo } from 'react';
import { getWindowDimensions } from '../helpers/dimen';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GENERATION_STATUS, resetLastGenerationId } from '../reducers/formReducer';

const useHasGenerationOccured = () => {

  const location = useLocation();

  const generationStatus = useSelector((state) => state.form.generationStatus);
  const partialGenerationCounter = useSelector((state) => state.form.partialGenerationCounter);

	const [hasGenerationOccured, setHasGenerationOccured] = useState(false)

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(resetLastGenerationId())
	}, [location])

	useEffect(() => {
		setHasGenerationOccured(false)
	}, [location]);


	useEffect(() => {
		if (generationStatus == GENERATION_STATUS.SUCCEDED) {
			setHasGenerationOccured(true)
		}
	}, [generationStatus])


  return hasGenerationOccured;
}

export default useHasGenerationOccured;
