import React, {
  useCallback,
  createContext,
  useMemo,
  useRef,
  createRef,
} from "react";
import { useDispatch } from "react-redux";
import { resizeBase64 } from "../components/editor/resizers";
import { EDITOR_SIZE, IMAGE_EXPORT_SIZE } from "../components/editor/settings";
import { updateInputImage } from "../reducers/formReducer";

export const EditorContext = createContext({
  setEditor: () => undefined,
  saveEditorImage: async () => undefined,
});

export const editorRef = createRef();



export const EditorProvider = ({ children }) => {
  const dispatch = useDispatch();

  const setEditor = useCallback((ref) => {
    editorRef.current = ref
  }, []);

  const saveEditorImage = useCallback(async () => {
    if (editorRef?.current?.getInstance()) {
      await editorRef.current.getInstance().zoom({zoomLevel: 1, x: EDITOR_SIZE / 2, y: EDITOR_SIZE / 2})
      const originalImage = editorRef.current.getInstance().toDataURL({multiplier: 4});
      const { image } = await resizeBase64(originalImage, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE)
      dispatch(updateInputImage(image));
    }
  }, [dispatch])

  const value = useMemo(
    () => ({
      setEditor,
			editor: editorRef,
      saveEditorImage,
    }),
    [setEditor, saveEditorImage]
  );

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};
