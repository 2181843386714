import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as QueueIcon } from '../../assets/queue/queue-icon.svg';
import { GENERATION_STATUS, updateOutOfCreditText, updateShowOutOfCreditModal } from '../../reducers/formReducer';

export const useQueueVisibility = () => {
  const generationStatus = useSelector((state) => state.form.generationStatus);
  const showQueueForRequest = useSelector((state) => state.form.showQueueForRequest);
	const isInQueue = generationStatus === GENERATION_STATUS.IN_QUEUE;
	const isGenerating = generationStatus === GENERATION_STATUS.GENERATING;
	const show = (isInQueue || isGenerating) && showQueueForRequest;

	return {
		show,
		isGenerating,
		isInQueue,
		generationStatus,
		showQueueForRequest,
	}
}

const QueueStatus = (props) => {
	const {useParentWidth = false} = props;
	const queueSize = useSelector((state) => state.form.queueStatus.total)
	const currentPosition = useSelector((state) => state.form.queueStatus.position)
	const estimatedTime = useSelector((state) => state.form.queueStatus.estimated_time)
	const {
		show,
		isGenerating,
		isInQueue,
		generationStatus,
		showQueueForRequest,
	} = useQueueVisibility();

	const dispatch = useDispatch()

	const handleUpgrade = () => {
		dispatch(updateShowOutOfCreditModal(true));
		dispatch(updateOutOfCreditText({
			title: <>Upgrade for more credits<br />and features</>,
			subtitle: null,
		}));
	}

	return (
		<div className={`grid transition-all duration-1000 ${show ? ' block grid-rows-[1fr]' : 'hidden grid-rows-[0fr]'}`}>
			<div className={`relative transition-all duration-1000 ${
				show ? 'opacity-100  pt-5 pb-8 ' : 'opacity-0 height-0'
			} ${useParentWidth ? 'w-full' : 'w-[600px]'} mx-auto flex flex-col overflow-hidden border-2 border-dashed border-queue-border items-center px-4 gap-1 bg-app-bg-gray text-queue-text rounded-2xl`}>
				<QueueIcon className="w-9 -mb-3" />

				<div className="text-center font-roboto font-bold text-lg">
					{isInQueue ? (
						<>
							<span className="text-app-green">{currentPosition}</span>
							{' / '}
							{queueSize}
							<br />
							{!!estimatedTime && 0 < estimatedTime && (
								<span>
									Estimated wait time: {estimatedTime}s
								</span>
							)}
						</>
					) : (
						<span className="text-app-green">Generating...</span>
					)}
				</div>


				<div className="font-roboto text-sm text-center">
					{isInQueue ? (
						<>
							Your position is{' '}
							<span className="font-bold">{currentPosition}</span>
							{' '}in the image generation queue, we will start generating
							<br />
							your request soon. 
						</>
					) : (
						"Your images are being generated."
					)}
					{' '}
					<button type="button" className="underline font-bold text-button-purple" onClick={handleUpgrade}>
						Upgrade
					</button>
					{' '}
					to a paid tier and skip the queue
				</div>

			</div>
		</div>
	)
}

export default QueueStatus;
