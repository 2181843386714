import { Dialog, Switch, Transition } from "@headlessui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as LowCreditIcon } from "../../assets/profile/low-credits-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    getSumolingLicense,
	setShowAppsumo1024LimitDialog,
} from "../../reducers/userReducer";
import plans from "../../data/plans";
import SumolingPlans from "../../data/sumoling-plans";
import { toast } from "react-toastify";
import { ReactComponent as ChevronDown } from "../../assets/ChevronDownLightGray.svg";
import useGenerationEndpoint from "../input/useGenerationEndpoint";
import { Link } from "react-router-dom";
import calculateNextBillingPeriod from "../../helpers/calculateNextBillingPeriod";

const Appsumo1024LimitDialog = (props) => {
  const open = useSelector((state) => state.userSlice.showAppsumo1024LimitDialog);

  const sumolingLicense = useSelector((state) => state.userSlice.sumolingLicense);
  const user = useSelector((state) => state.userSlice.user);

  const [sumolingLicenseLoaded, setSumolingLicenseLoaded] = useState(false);

  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setShowAppsumo1024LimitDialog(false));
  };

	const userPlan = useMemo(() => {
    if (user?.isSumoling) 
				return user?.appsumoLicense;

		return user?.plan
	}, [user, sumolingLicense])

  const currentPlan = useMemo(() => {
    if (user?.isSumoling) {

      return SumolingPlans.find(
        (val) =>
          user?.appsumoLicense?.productId === val.plan_id ||
          sumolingLicense?.productId === val.plan_id
      );
    }
    return plans.find(
      (val) =>
        user?.plan?.productId === val.monthlyPriceId ||
        user?.plan?.productId === val.yearlyPriceId
    );
  }, [user, sumolingLicense]);
	
  const getSumolingData = async () => {
    try {
      await dispatch(getSumolingLicense()).unwrap();
      setSumolingLicenseLoaded(true)
    } catch(e) {
      console.log(e);
      
      toast(e.error?.message || e.error || "Something went wrong while activating Sumoling's license", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }
  }

  useEffect(() => {
    if (user?.isSumoling && !sumolingLicenseLoaded) {
      getSumolingData();
    }
  }, [user, sumolingLicenseLoaded]);

  const { method, mode, setMode } =
    useGenerationEndpoint();

	console.log("Plan", currentPlan)

  return (
    <Transition appear show={open || false} as={React.Fragment}>
      <Dialog open={open || false} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto custom-scroll">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-[550px] max-w-11/12 flex flex-col gap-8 justify-between overflow-hidden relative bg-app-black rounded-md "
                onClick={(e) => e.stopPropagation()}
              >
								<div className="bg-black py-12 flex items-center justify-center">
									<LowCreditIcon className="no-credit-icon-animation"/>
								</div>
								<div className="flex flex-col gap-6 px-12 pb-8">
									<h2 className="text-white font-montserrat text-2xl font-bold">
										You’ve used up all of your 1024px image credits this month
									</h2>
									<div className="font-inter font-light text-modal-description">
											Your plan comes with 
											<span className="font-bold">
												{' '}{currentPlan?.paidFeatureUsage?.['high-res']}{' '}
											</span>
											monthly images in 1024px resolution, 
											your 1024px image credits will be refilled on 
											{userPlan?.renewsOn && (
												<span className="font-bold">
													{' '}{calculateNextBillingPeriod(userPlan?.renewsOn).format("MMMM Do")} 
												</span>
											)}
											, You’ll be switched to Logo Diffusion v1.0 now 
											to use your remainder
											<span className="font-bold">
												{' '}{user?.remainingCredits}{' '} credits 
											</span>
											, or you can add extra 1024px image credits.
										</div>
										<div className="flex items-center justify-center gap-8">
											<Link onClick={() => onClose()} to="/profile?showBuyCredits=1" className="text-sm font-bold font-montserrat bg-button-purple h-12 px-3 rounded-md transition-all duration-300 text-white hover:text-white/70 flex items-center justify-center">
												By More Credits
											</Link>
											<button
												type="submit"
												className="h-12 py-4 pl-3 pr-1 border border-solid border-carousel-button-border bg-app-bg-gray rounded-md group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed"
												// disabled={values[currentQuestion.key] === undefined}
												onClick={() => {
													onClose();
													setMode('v1.1')
												}}
											>
												<span className="text-white font-montserrat font-light text-sm">
													Continue using V1.0
												</span>

												<ChevronDown
													className={`-rotate-90 [&>path]:stroke-white group-hover:[&>path]:stroke-app-green relative ml-0 mr-1 group-hover:ml-1 group-hover:mr-0 transition-all duration-300`}
												/>
											</button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		);
	};

export default Appsumo1024LimitDialog;
