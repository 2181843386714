import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import environment from "./helpers/envProvider";
import { registerDevice } from "./reducers/userReducer";
import { loadGenerationById } from "./reducers/formReducer";

/*
const firebaseConfig = {
};

export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
*/
export const registerUserToken = async (dispatch) => {
	try {
		/*
		const permission = await Notification.requestPermission()

		if (permission === 'granted') {
			const token = await getToken(messaging, { vapidKey: environment('REACT_APP_FIREBASE_VAPID_KEY') })

			console.log("Firebase", token, environment('REACT_APP_FIREBASE_VAPID_KEY'))

			onMessage(messaging, async (payload) => {
				console.log("New notification", payload);
				if (payload.data?.type === 'new-generation') {

					console.log("Loading new generations")
					await dispatch(loadGenerationById({
						generationId: payload.data?.generationId
					})).unwrap()
				}
			})

			await dispatch(registerDevice({ deviceId: token })).unwrap()

		}
		*/

	} catch(e) {
		console.log(e);
	}
}
