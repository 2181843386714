import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partialUpdateStatePayload, updateImageAsControlImage, } from "../../reducers/formReducer";

const useImageColorValues = (props={}) => {
  // const { mode = 'sdxl' } = props
  const imageAsControlImage = useSelector((state) => state.form.payload.image_as_control_image);
  const denoisingStrength = useSelector(
    (state) => state.form.payload.denoisingStrength
  );

  const dispatch = useDispatch();

	// const imageColorInfluence = useMemo(() => imageAsControlImage ? denoisingStrength : 0, [imageAsControlImage, denoisingStrength])
  const imageColorInfluence = useMemo(() => denoisingStrength, [imageAsControlImage, denoisingStrength])

  const setImageColorInfluence = (value) => {
    dispatch(partialUpdateStatePayload({ denoisingStrength: value }));
    if (value == 0) {
      dispatch(updateImageAsControlImage(false));
      return ;
    }
		dispatch(updateImageAsControlImage(true));
  }


  return {
    imageColorInfluence,
    setImageColorInfluence
  }
}

export default useImageColorValues;
