import { Dialog, Transition } from '@headlessui/react'
import React, { useCallback, useMemo, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectionPicker } from '../../../reducers/imageEditorReducer';
import { FontSection } from '../text/FontSection';
import { ColorPanel } from '../color/ColorPanel';

const SelectionColorPicker = (props) => {
  const { editor } = props;

  const selectedElement = useSelector(
    (state) => state.imageEditor.selectedElement
  );

  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.imageEditor.selection.pickerOpen);
  const pickerLocation = useSelector((state) => state.imageEditor.selection.pickerLocation);

  const onClose = useCallback(() => {
    dispatch(updateSelectionPicker(false))
  }, [dispatch])

  const renderContent = useMemo(() => {
    if (selectedElement?.id && selectedElement.type === "i-text") {
      return (
        <FontSection editor={editor} />
      )
    }
    return (
      <div className='rounded-lg bg-[#1c1c25ff] shadow-2xl p-3 z-50'>
        <ColorPanel editor={editor} />
      </div>
    )
  }, [editor, selectedElement])

  return (
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      show={isOpen || false}
    >
      <Dialog 
        as='div'
        onClose={onClose}
        open={isOpen || false}
        className="fixed z-50 px-4 sm:px-0 mb-2"
        // ref={dialogRef}
        style={{
          top: pickerLocation.top + 6,
          left: pickerLocation.left + 6
        }}
      >
        <Dialog.Panel
					onMouseDown={(e) => { 
						e.stopPropagation() 
					}}
        >
          {renderContent}
        </Dialog.Panel>
      </Dialog>
    </Transition>
  )
}

export default SelectionColorPicker
