import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as TutorialPlayIcon } from '../assets/TutorialPlayIcon.svg';
import { ReactComponent as CloseIcon } from '../assets/CloseIcon.svg';

import { setIsTutorialModalOpen } from '../reducers/appReducer';
import useTutorials from '../hooks/useTutorials';


const TutorialList = (props) => {
  const dispatch = useDispatch();
	const tutorials = useTutorials();

  return (
    <div>
      <h1 className="font-poppins font-semibold text-[24px] sm:text-[28px] xl:text-[36px] text-white mb-[12px] text-center">
        Getting started with Logo Diffusion
      </h1>

      <p className="text-[16px] text-[#92929D] mb-[38px] text-center">
        Here are a few short tutorials to help you get the most out of Logo Diffusion.
      </p>

      <div className="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-[5px] sm:gap-[10px] xl:gap-[28px] z-20 mb-[40px]">
        {tutorials.map(tutorial => (
          <article
            key={`tutorial-${tutorial.name}`}
            className='px-[5px] sm:px-[10px] xl:px-[16px] pt-[5px] sm:pt-[10px] xl:pt-[17px] pb-[5px] sm:ptb[10px] xl:pb-[22px] bg-app-bg-gray border border-[#1A1E2D] rounded-[15px] relative cursor-pointer'
            onClick={e => props.setPlayingVideo(tutorial)}
          >
            <span className='absolute top-[13px] right-[12px] bg-[#3DD598] text-[#08432B] font-poppins font-bold text-[12px] px-[7px] py-[5px] z-10 rounded-[5px]'>
              {tutorial.duration}
            </span>

            <div className='relative mb-[12px]'>
              <TutorialPlayIcon className='absolute top-[50%] translate-x-[-50%] left-[50%] translate-y-[-50%]'/>

              <img src={tutorial.thumbnail} alt={tutorial.name} className='rounded-[5px]'/>
            </div>

            <h1 className='font-poppins font-semibold text-[13px] sm:text-[15px] xl:text-[18px] text-[#FAFAFB] mb-[5px]'>{tutorial.name}</h1>
            <p className='text-[14px] text-[#92929D] leading-[22px] hidden sm:block'>{tutorial.text}</p>
          </article>
        ))}
      </div>

      {
        props.isModal
        ? (
        <div className="flex items-center justify-center">
          <button
            className="bg-ld-purple text-white font-poppins font-bold text-[18px] leading-[1.3em] px-[20px] h-[55px] rounded-[10px]"
            onClick={e => dispatch(setIsTutorialModalOpen(false))}
          >
            Start using Logo Diffusion
          </button>
        </div>
        )
        : ''
      }
    </div>
  )
}

const VideoPlayer = (props) => {
	const tutorials = useTutorials();
  return (
    <div>
      <button
        className='bg-[#1C1C24] border border-[#292932] py-[3px] px-[7px] text-[#909090] rounded-[5px] flex items-center text-[14px] mb-[20px]'
        onClick={e => props.setPlayingVideo(null)}
      >
        <span className='text-app-green'>&lt;</span>&nbsp; Back
      </button>

      <iframe
        className="w-full h-full aspect-video rounded-[15px] mb-[30px]"
        src={props.playingVideo.ytUrl}
        title={props.playingVideo.name}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen>
      </iframe>

      <h1 className='font-poppins font-semibold text-white text-center mb-[30px]'>{props.playingVideo.name}</h1>

      <div className='flex items-center justify-center gap-[10px]'>
        <button
          disabled={props.videoIndex === 0}
          className={`${props.videoIndex === 0 ? 'text-[#909090]' : 'text-app-green'} bg-[#1C1C24] border border-[#292932] py-[3px] px-[7px] rounded-[5px] flex items-center justify-center text-[14px] w-[100px] text-center`}
          onClick={e => props.handlePrevious()}
        >
          Previous
        </button>

        <span className='text-white text-[14px]'>{props.videoIndex + 1} / {tutorials.length}</span>

        <button
          disabled={(props.videoIndex + 1) === tutorials.length}
          className={`${(props.videoIndex + 1) === tutorials.length ? 'text-[#909090]' : 'text-app-green'} bg-[#1C1C24] border border-[#292932] py-[3px] px-[7px] rounded-[5px] flex items-center justify-center text-[12px] w-[100px] text-center`}
          onClick={e => props.handleNext()}
        >
          Next
        </button>
      </div>
    </div>
  )
}

function TutorialModal(props) {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [videoIndex, setVideoIndex] = useState(null);
	const tutorials = useTutorials();

  const dispatch = useDispatch();

  const handlePlayVideo = (video) => {
    const index = tutorials.findIndex(tut => tut.name === video.name);
    setVideoIndex(index);
    setPlayingVideo(video);
  }

  const handlePrevious = () => {
    setVideoIndex(videoIndex - 1);
    setPlayingVideo(tutorials[videoIndex - 1]);
  }

  const handleNext = () => {
    setVideoIndex(videoIndex + 1);
    setPlayingVideo(tutorials[videoIndex + 1]);
  }

  const modalClasses = `bg-[#13131A] rounded-[20px] w-[1249px] border border-[#1A1E2D] max-w-[90%] max-h-[90%] overflow-auto custom-scroll relative`;
  const containerClasses = `${props.isModal ? modalClasses : ''} pt-[40px] pb-[75px] px-[15px] xl:px-[55px]`;

  return (
    <div className={containerClasses}>
      {
        props.isModal ? (
        <div className='h-[28px] w-[28px] rounded-[4px] flex items-center justify-center bg-divider absolute top-[21px] right-[26px] cursor-pointer' onClick={e => dispatch(setIsTutorialModalOpen(false))}>
          <CloseIcon />
        </div>
      ) : ''}

      {
        playingVideo
        ? <VideoPlayer
            playingVideo={playingVideo}
            videoIndex={videoIndex}
            setPlayingVideo={setPlayingVideo}
            handlePlayVideo={handlePlayVideo}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        : <TutorialList setPlayingVideo={handlePlayVideo} isModal={props.isModal} /> 
      }
    </div>
  )
}

export default TutorialModal;
