import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as SlideIcon } from '../assets/slider-icon.svg';

import { ReactComponent as Download } from '../assets/DownloadIcon.svg';
import { ReactComponent as RefreshIcon } from '../assets/refresh.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';

import Background from '../assets/BGRmoveBackground.svg';

const ImageComparison = (props) => {
  const {
    beforeImage,
    afterImage,
    handleDownload,
    handleRefresh,
    handleBack,
    handleImageToImage,
  } = props;
  const [zoomSliderValue, setZoomSliderValue] = useState(8);
  const minSliderVale = 4;
  const maxSliderValue = 44;
  const [scale, setScale] = useState(1);
  const minScale = 0.4;
  const maxScale = 4.4;
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const containerRef = useRef(null);

  const handleZoomIn = () => {
    const newScale = scale + 0.4;
    const newSliderValue = Number(zoomSliderValue) + 4;
    if (newScale <= maxScale && newSliderValue <= maxSliderValue) {
      setScale(newScale);
      setZoomSliderValue(newSliderValue);
    }
  };

  const handleZoomOut = () => {
    const newScale = scale - 0.4;
    const newSliderValue = zoomSliderValue - 4;
    if (newScale >= minScale && newSliderValue >= minSliderVale) {
      setScale(newScale);
      setZoomSliderValue(newSliderValue);
    }
  };

  useEffect(() => {
    const image1 = imageRef1.current;
    const image2 = imageRef2.current;
    const container = containerRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };

      const containerRect = containerRef.current?.getBoundingClientRect();
      const maxX = containerRect.width / 3;

      setPosition((position) => ({
        x: Math.max(-maxX, Math.min(maxX, position.x + deltaX)),
        y: Math.max(-maxX, Math.min(maxX, position.y + deltaY)),
      }));
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    image1?.addEventListener('mousedown', handleMouseDown);
    image1?.addEventListener('mousemove', handleMouseMove);
    image1?.addEventListener('mouseup', handleMouseUp);
    container?.addEventListener('mouseleave', handleMouseUp);

    image2?.addEventListener('mousedown', handleMouseDown);
    image2?.addEventListener('mousemove', handleMouseMove);
    image2?.addEventListener('mouseup', handleMouseUp);

    return () => {
      /* image1?.removeEventListener('mousedown', handleMouseDown); */
      image1?.removeEventListener('mousemove', handleMouseMove);
      image1?.removeEventListener('mouseup', handleMouseUp);
      image2?.removeEventListener('mousedown', handleMouseDown);
      image2?.removeEventListener('mousemove', handleMouseMove);
      image2?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [imageRef1, imageRef2, scale]);

  const handleZoomChange = (e) => {
    const newSliderValue = e.target.value;
    const newScale = newSliderValue / 10;
    if (newScale >= minScale && newSliderValue >= minSliderVale) {
      setScale(newScale);
      setZoomSliderValue(newSliderValue);
    }
  };

  const [sliderValue, setSliderValue] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const handleSliderChange = (event) => {
    if (!isDragging) return;
    const rect = event.currentTarget.getBoundingClientRect();
    const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
    const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));
    setSliderValue(percent);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className='flex flex-col w-full h-full' onMouseUp={handleMouseUp}>
      <div
        className='flex flex-row h-full self-center aspect-square relative rounded-[20px] overflow-hidden'
        ref={containerRef}
        onMouseMove={handleSliderChange}
      >
        <div className='absolute z-20 left-5 top-5 flex flex-row gap-3'>
          {handleDownload && (
            <button
              className='bg-gray-200 shadow-sm rounded-md p-2'
              onClick={handleDownload}
            >
              <Download className='w-4 h-4 [&>path]:fill-[#84808D]' />
            </button>
          )}
          {handleRefresh && (
            <button
              className='bg-gray-200 shadow-sm rounded-md p-2'
              onClick={handleRefresh}
            >
              <RefreshIcon className='w-4 h-4' />
            </button>
          )}
        </div>
        <div className='bg-gray-200 shadow-sm px-[9px] py-[3px] rounded-[6px] absolute left-[20px] top-1/2 -translate-y-1/2 text-gray-500 text-[14px] font-bold z-20 select-none'>
          Before
        </div>
        <div className=' bg-gray-200 shadow-sm px-[9px] py-[3px] rounded-[6px] absolute right-[20px] top-1/2 -translate-y-1/2 text-gray-500 text-[14px] font-bold z-20 select-none'>
          After
        </div>
        <img
          src={Background}
          alt='background'
          className='absolute w-full h-full object-cover '
        />

        <img
          ref={imageRef1}
          src={afterImage}
          alt='After'
          className='object-contain cursor-grabbing w-full h-full '
          draggable={false}
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          }}
        />

        <div
          className='absolute top-0 left-0 right-0 bottom-0 w-full h-full select-none'
          style={{ clipPath: `inset(0 ${100 - sliderValue}% 0 0)` }}
        >
          <img
            src={beforeImage}
            alt='before'
            className='object-contain cursor-grabbing w-full h-full select-none'
            ref={imageRef2}
            draggable={false}
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            }}
          />
        </div>
        <div
          className='absolute top-0 bottom-0 w-[2.6px] bg-white z-0 select-none'
          style={{ left: `calc(${sliderValue}% - 1px)` }}
        >
          {/* <div className='bg-[#30303E] absolute rounded-full h-[38px] w-[30px] -left-[13.5px] top-[calc(50%-5px)]'></div> */}
          <SlideIcon
            className='absolute h-[38px] w-[30px] -left-[13.5px] top-[calc(50%-5px)] cursor-grabbing hover:fill-blue-200 active:fill-blue-200'
            onMouseDown={handleMouseDown}
          />
        </div>
      </div>
      <div className='flex justify-evenly gap-4 px-4 bg-app-bg-gray rounded-xl mx-auto py-4 mt-12'>
        <button
          className='flex justify-center items-center px-[12px] gap-1 text-[14px] font-semibold text-[#ACACAC] bg-[#30303E] rounded-[12px]'
          onClick={handleBack}
        >
          <ArrowLeft />
          Back
        </button>

        <button
          className='flex gap-2 shrink-0 whitespace-nowrap justify-center items-center p-3 font-semibold text-white bg-button-purple rounded-xl '
          onClick={handleDownload}
        >
          <Download className='w-4 h-4 [&>path]:fill-[#3F1AAA]' />
          Download
        </button>

        {handleImageToImage && (
          <button
            className='flex justify-center items-center px-[12px] gap-1 text-[14px] font-semibold text-[#ACACAC] bg-[#30303E] rounded-[12px]'
            onClick={handleImageToImage}
          >
            Send to Image to image
          </button>
        )}

        <button
          className={`flex justify-center items-center px-[12px] gap-1 text-[14px] ${
            scale === 1 ? 'font-semibold' : 'font-normal'
          } text-[#ACACAC] bg-[#30303E] rounded-[12px]`}
          onClick={() => {
            setScale(1);
            setPosition({ x: 0, y: 0 });
          }}
        >
          View full image
        </button>

        <button
          className={`flex justify-center items-center px-[12px] gap-1 text-[14px] ${
            scale !== 1 ? 'font-semibold' : 'font-normal'
          } text-[#ACACAC] bg-[#30303E] rounded-[12px]`}
          onClick={() =>
            setScale(
              imageRef1.current.naturalWidth /
                containerRef.current.getBoundingClientRect().width
            )
          }
        >
          Zoom at actual size
        </button>
      </div>
    </div>
  );
};

export default ImageComparison;
