import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authAxiosInstance from '../helpers/auth-axios';

function ResetPasswordForm(props) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!email) return toast('Email is required', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark',
      autoClose: 2000
    })

    try {
      setIsLoading(true);
      await authAxiosInstance.post('/auth/reset-password', { email });
      toast('Password request sent, please check your email.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
      props.handleClose();
    } catch (error) {
      toast('Something went wrong, please try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

      setIsLoading(false);
    }
  }

  return (
      <div className='max-w-[360px] w-[90%] mx-auto rounded-2xl flex flex-col justify-center min-h-screen'>
        <div className='bg-app-bg-gray text-white text-center rounded-2xl'>
          <div className='p-4'>
            <h1 className='font-bold text-white text-lg mb-6'>Request password reset</h1>

            <form onSubmit={handleResetPassword} className='flex flex-col'>
              <input
                type="email"
                placeholder='Enter your email'
                className='bg-app-search-gray mb-3 p-2 rounded-md'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <button
                className='bg-blue-600 text-white font-bold w-full rounded-md p-2 mb-2 text-sm'
                type="submit"
              >
                {isLoading ? 'Submitting' : 'Submit' }
              </button>
            </form>
          </div>

          <div className='bg-app-search-gray rounded-b-2xl text-sm p-4'>
            Changed your mind? <span className="text-blue-600 cursor-pointer" onClick={e => props.handleClose()}>Back to app</span>
          </div>
        </div>
      </div>
  )
}

export default ResetPasswordForm;
