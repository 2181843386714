import { createListenerMiddleware } from '@reduxjs/toolkit'
import { partialUpdateStatePayload, DEFAULT_STYLE_MAPPINGS, resetStyleConfig, updateShouldInjectColros } from './formReducer';

export const v3listener = createListenerMiddleware()

const RESET_SETTINGS = {
	denoising_start: null,
	denoising_end: null,
	schedulerRefiner: 'Euler a',
	imageQuality: 24,
	loras: [],
}

const STYLE_CONFIG = {
	'v3/2d': {
		default: {
			...RESET_SETTINGS,
			loras: [],
			promptRigidness: 5,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		badge: {
			...RESET_SETTINGS,
			loras: [
				['logobadge_AC-vU3B-50.safetensors', 0.5, 0.4],
			],
			useRefiner: true,
			refinerInfluence: 0.1,
			promptRigidness: 4,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		pictorial: {
			...RESET_SETTINGS,
			loras: [
				['logotpictorial-AC-vU3B-50.safetensors', 0.4, 0.3],
			],
			useRefiner: true,
			refinerInfluence: 0.1,
			promptRigidness: 5.5,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		cartoon: {
			...RESET_SETTINGS,
			loras: [
				['logocartoon-Exact-vU3B-50.safetensors', 0.35, 0.3],
			],
			useRefiner: true,
			refinerInfluence: 0.3,
			promptRigidness: 5.5,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		adcartoon: {
			...RESET_SETTINGS,
			loras: [
				['adcartoon-AC-vU3B-100.safetensors', 0.4, 0.3],
			],
			useRefiner: true,
			refinerInfluence: 0.25,
			promptRigidness: 5.5,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		mascot: {
			...RESET_SETTINGS,
			loras: [
				['logomascots-AC-vU3B-50.safetensors', 0.45, 0.35],
			],
			useRefiner: true,
			refinerInfluence: 0.15,
			promptRigidness: 5,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		abstract: {
			...RESET_SETTINGS,
			loras: [
				['logoabstract-AC-vU3B-100.safetensors', 0.55, 0.45],
			],
			useRefiner: true,
			refinerInfluence: 0.1,
			promptRigidness: 7,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		icon: {
			...RESET_SETTINGS,
			loras: [
				['logoflat-Exact-vU3Bmini-100.safetensors', 0.4, 0.35],
			],
			useRefiner: true,
			refinerInfluence: 0.15,
			promptRigidness: 6,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		'line-art': {
			...RESET_SETTINGS,
			loras: [
				['1logolineart-AC-vU3B-50.safetensors', 0.35, 0.25],
			],
			useRefiner: true,
			refinerInfluence: 0.3,
			promptRigidness: 5,
			scheduler: 'Euler a',
		},
		monogram: {
			...RESET_SETTINGS,
			loras: [
				['logomonogram-Exact-vU3B-50.safetensors', 0.85, 0.85],
			],
			useRefiner: true,
			refinerInfluence: 0.1,
			promptRigidness: 6,
			scheduler: 'Euler a',
			imageQuality: 24,
		},
		'logo-type': {
			...RESET_SETTINGS,
			loras: [
				['logotype-Exact-vU3B50.safetensors', 0.95, 0.95],
			],
			useRefiner: true,
			refinerInfluence: 0.1,
			promptRigidness: 6,
			scheduler: 'Euler a',
			imageQuality: 24,
		},

		// 2D Art
		'stylized-cartoon': {
			...RESET_SETTINGS,
			loras: [
				['adcartoon-AC-vU3B-100.safetensors', 0.4, 0.3],
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			refinerInfluence: 0.25,
			imageQuality: 24,
			promptRigidness: 5.5,
			controlnetMode: 'depth',
			imageWeight: 0.65,
		},
		'pixel-art': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			refinerInfluence: 0.1,
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'flat-graphics': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			refinerInfluence: 0.1,
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'coloring-book': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			refinerInfluence: 0.1,
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		cyberpunk: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		sticker: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'marker-drawing': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'sketch-note': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'color-sketch-note': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'digital-art': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'fantasy-art': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'pop-art': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'comic-art': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		manga: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		kawaii: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'punk-collage': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'dark-arts': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'charcoal-sketch': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		halftone: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},

		// 3D Art
		'stylized-3d': {
			...RESET_SETTINGS,
			loras: [
				['lodi3d-vCC1-030.safetensors', 0.5, 0.4],
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'3d': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			controlnetMode: 'depth',
			imageWeight: 0.65,
			imageQuality: 24,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 8,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'3d-text': {
			...RESET_SETTINGS,
			loras: [
				['text-logo-50.safetensors', 0.7, 0.7],
				['logotype-Exact-vU3B50.safetensors', 0.7, 0.7],
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			controlnetMode: 'depth',
			imageWeight: 0.65,
			imageQuality: 24,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 7,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		inflated: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		embroidered: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		isometric: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'low-poly': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			refinerInfluence: 0.1,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},

		// Realistic
		cinematic: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 9,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		hyperrealism: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 9,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'craft-clay': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 9,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		futuristic: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		origami: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		'paper-craft': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		

		// Fine art
		'oil-painting': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7.5,
			},
			_image2image: {
				promptRigidness: 9,
			},
		},
		'fantasy-illustration': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'DDIM',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
			},
			_image2image: {
				promptRigidness: 8,
			},
		},
		watercolor: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7,
				denoising_start: 0.85,
				denoising_end: 0.9,
			},
			_image2image: {
				promptRigidness: 9,
				denoising_start: 0.96,
				denoising_end: 0.95,
			},
		},
		'art-deco': {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 7.5,
			},
			_image2image: {
				promptRigidness: 8.5,
			},
		},
		bauhaus: {
			...RESET_SETTINGS,
			loras: [
			],
			useRefiner: true,
			schedulerRefiner: 'Euler a',
			imageQuality: 24,
			refinerInfluence: 0.1,
			controlnetMode: 'depth',
			imageWeight: 0.65,
			_text2image: {
				promptRigidness: 8,
			},
			_image2image: {
				promptRigidness: 9,
			},
		},

	},
}

v3listener.startListening({
	predicate: (action, currentState, previousState) => {
		if (currentState.form.method.startsWith('v3/') && !currentState.form.__dev_mode) {
			if (
				currentState.form.method !== previousState.form.method ||
				currentState.form.payload.contentStyle !== previousState.form.payload.contentStyle || 
				currentState.form.payload.inputImage !== previousState.form.payload.inputImage
			) {
				return true;
			}
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		const style = listenerApi.getState().form.payload.contentStyle
		const method = listenerApi.getState().form.method
		const inputImage = listenerApi.getState().form.payload.inputImage
		let updateValues = {
		}
		if (STYLE_CONFIG[method][style]) {
			const { _text2image, _image2image, ...inj } = STYLE_CONFIG[method][style]
			updateValues = {
				...updateValues, 
				...inj
			}
			if (inputImage && _image2image) {
				updateValues = {
					...updateValues, 
					..._image2image,
				}
			} else if (!inputImage && _text2image) {
				updateValues = {
					...updateValues, 
					..._text2image,
				}
			}

		} else if (STYLE_CONFIG[method]?.default) {
			updateValues = {
				...updateValues, 
				...STYLE_CONFIG[method].default
			}
		}
		listenerApi.dispatch(partialUpdateStatePayload(updateValues));
  },
})

