import createAuthRefreshInterceptor from "axios-auth-refresh";
import authAxiosInstance from "./auth-axios";
import axiosInstance from "./axios";
import { useCallback, useEffect } from "react";
import { useStore } from "react-redux";
import { logoutUser } from "../reducers/userReducer";
import { registerUserToken } from "../firebase";


export const setupIneterceptors = (refresh) => {

  createAuthRefreshInterceptor(authAxiosInstance, refresh, { statusCodes: [403] })
  createAuthRefreshInterceptor(axiosInstance, refresh, { statusCodes: [403] })
}

const InterceptorHelperComponent = () => {
  const store = useStore();
  const refresh = useCallback(async (failedRequest) => {
    // const { id, refreshToken, ...userData } = JSON.parse(localStorage.getItem('userInfo'));
    // localStorage.setItem('userInfo', JSON.stringify({ ...userData, id, refreshToken: data.refreshToken }));
    try {
      const { data } = await authAxiosInstance.post(
        '/auth/refresh-token', 
        { 
          token: store.getState().userSlice?.refreshToken, 
          userId: store.getState().userSlice?.user._id 
        }
      )

      const bearer = `Bearer ${data.accessToken}`;

			localStorage.setItem('access-token', data.accessToken)

      authAxiosInstance.defaults.headers.common.Authorization = bearer;
      axiosInstance.defaults.headers.common.Authorization = bearer;

      failedRequest.response.config.headers.Authorization = bearer;
    } catch(e) {
      store.dispatch(logoutUser())
    }
  }, [store]);

  useEffect(() => {
    setupIneterceptors(refresh);
  }, [refresh]);

	useEffect(() => {
		if (refresh)
			registerUserToken(store.dispatch);
	}, [refresh])

  return <></>
}

export default InterceptorHelperComponent
