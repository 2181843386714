export const imageWeightAcceptedValues = {
  'v1.1': [
    {
      name: '1',
      value: 0.1
    },
    {
      name: '2',
      value: 0.6
    },
    {
      name: '3',
      value: 1
    },
    {
      name: '4',
      value: 1.6
    },
    {
      name: '5',
      value: 2
    },
  ],
  'v2.0': {
		details: {
			[false]: {
				'sdxl-3d': [
					{
						name: 'Low',
						value: 0.65,
					},
					{
						name: '2',
						value: 0.75,
					},
					{
						name: '3',
						value: 1.2,
					},
					{
						name: '4',
						value: 1.25,
					},
					{
						name: 'High',
						value: 2,
					},
				],
				sdxl: [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
			},
			[true]: {
				'sdxl-3d': [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.6,
					},
					{
						name: '3',
						value: 0.79,
					},
					{
						name: '4',
						value: 1,
					},
					{
						name: 'High',
						value: 1.1,
					},
				],
				sdxl: [
					{
						name: 'Low',
						value: 0.25,
					},
					{
						name: '2',
						value: 0.45,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.78,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
			},
		},
		depth: {
			[false]: {
				'sdxl-3d': [
					{
						name: 'Low',
						value: 0.4,
					},
					{
						name: '2',
						value: 0.6,
					},
					{
						name: '3',
						value: 0.8,
					},
					{
						name: '4',
						value: 0.9,
					},
					{
						name: 'High',
						value: 0.91,
					},
				],
				sdxl: [
					{
						name: 'Low',
						value: 0.3,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.8,
					},
				],
			},
			[true]: {
				'sdxl-3d': [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.2,
					},
					{
						name: '3',
						value: 0.4,
					},
					{
						name: '4',
						value: 0.5,
					},
					{
						name: 'High',
						value: 0.6,
					},
				],
				sdxl: [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.15,
					},
					{
						name: '3',
						value: 0.3,
					},
					{
						name: '4',
						value: 0.45,
					},
					{
						name: 'High',
						value: 0.45,
					},
				],
			},
		}
	},
  'v3.0': {
		details: {
			[false]: {
				'v3/2d': [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
				'v3/lightning': [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
				'v3/cascade': [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
			},
			[true]: {
				'v3/2d': [
					{
						name: 'Low',
						value: 0.25,
					},
					{
						name: '2',
						value: 0.45,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.78,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
				'v3/lightning': [
					{
						name: 'Low',
						value: 0.25,
					},
					{
						name: '2',
						value: 0.45,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.78,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
				'v3/cascade': [
					{
						name: 'Low',
						value: 0.25,
					},
					{
						name: '2',
						value: 0.45,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.78,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
			},
		},
		depth: {
			[false]: {
				'v3/2d': [
					{
						name: 'Low',
						value: 0.3,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.8,
					},
				],
				'v3/lightning': [
					{
						name: 'Low',
						value: 0.3,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.8,
					},
				],
				'v3/cascade': [
					{
						name: 'Low',
						value: 0.3,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.8,
					},
				],
			},
			[true]: {
				'v3/lightning': [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.15,
					},
					{
						name: '3',
						value: 0.3,
					},
					{
						name: '4',
						value: 0.45,
					},
					{
						name: 'High',
						value: 0.55,
					},
				],
				'v3/cascade': [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.15,
					},
					{
						name: '3',
						value: 0.3,
					},
					{
						name: '4',
						value: 0.45,
					},
					{
						name: 'High',
						value: 0.55,
					},
				],
				'v3/2d': [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.15,
					},
					{
						name: '3',
						value: 0.3,
					},
					{
						name: '4',
						value: 0.45,
					},
					{
						name: 'High',
						value: 0.55,
					},
				],
			},
		}
	},
  'v4.0': {
		details: {
			[false]: {
				'v4/3d': [
					{
						name: 'Low',
						value: 0.65,
					},
					{
						name: '2',
						value: 0.75,
					},
					{
						name: '3',
						value: 1.2,
					},
					{
						name: '4',
						value: 1.25,
					},
					{
						name: 'High',
						value: 2,
					},
				],
				'v4/2d': [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
			},
			[true]: {
				'v4/3d': [
					{
						name: 'Low',
						value: 0.45,
					},
					{
						name: '2',
						value: 0.6,
					},
					{
						name: '3',
						value: 0.79,
					},
					{
						name: '4',
						value: 1,
					},
					{
						name: 'High',
						value: 1.1,
					},
				],
				'v4/2d': [
					{
						name: 'Low',
						value: 0.25,
					},
					{
						name: '2',
						value: 0.45,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.78,
					},
					{
						name: 'High',
						value: 0.9,
					},
				],
			},
		},
		depth: {
			[false]: {
				'v4/3d': [
					{
						name: 'Low',
						value: 0.4,
					},
					{
						name: '2',
						value: 0.6,
					},
					{
						name: '3',
						value: 0.8,
					},
					{
						name: '4',
						value: 0.9,
					},
					{
						name: 'High',
						value: 0.91,
					},
				],
				'v4/2d': [
					{
						name: 'Low',
						value: 0.3,
					},
					{
						name: '2',
						value: 0.5,
					},
					{
						name: '3',
						value: 0.65,
					},
					{
						name: '4',
						value: 0.75,
					},
					{
						name: 'High',
						value: 0.8,
					},
				],
			},
			[true]: {
				'v4/3d': [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.2,
					},
					{
						name: '3',
						value: 0.4,
					},
					{
						name: '4',
						value: 0.5,
					},
					{
						name: 'High',
						value: 0.6,
					},
				],
				'v4/2d': [
					{
						name: 'Low',
						value: 0,
					},
					{
						name: '2',
						value: 0.15,
					},
					{
						name: '3',
						value: 0.3,
					},
					{
						name: '4',
						value: 0.45,
					},
					{
						name: 'High',
						value: 0.45,
					},
				],
			},
		}
	}
}

export const ControlnetModes = [
  {
    name: 'Details',
    value: 'details',
  },
  {
    name: 'Depth',
    value: 'depth',
  },
]

export const GuidenceAcceptedValues = [
  {
    name: '1',
    value: 0.3
  },
  {
    name: '2',
    value: 0.45
  },
  {
    name: '3',
    value: 0.6
  },
  {
    name: '4',
    value: 0.8
  },
  {
    name: '5',
    value: 1
  },
]
export const DenoisingAcceptedValues = [
  {
    name: '1',
    value: 0.55
  },
  {
    name: '2',
    value: 0.65
  },
  {
    name: '3',
    value: 0.75
  },
  {
    name: '4',
    value: 0.86
  },
  {
    name: '5',
    value: 0.98
  },
]

export const SDXLDenoisingValues = {
	'v4/3d': [
		{
			name: 'Low',
			value: 0.99
		},
		{
			name: 'M',
			value: 0.97
		},
		{
			name: 'High',
			value: 0.96
		},
	],
	'v4/2d': [
		{
			name: 'Low',
			value: 0.99
		},
		{
			name: 'M',
			value: 0.96
		},
		{
			name: 'High',
			value: 0.93
		},
	],
	'v3/3d': [
		{
			name: 'Low',
			value: 0.99
		},
		{
			name: 'M',
			value: 0.97
		},
		{
			name: 'High',
			value: 0.96
		},
	],
	'v3/2d': [
		{
			name: 'Low',
			value: 0.99
		},
		{
			name: 'M',
			value: 0.96
		},
		{
			name: 'High',
			value: 0.93
		},
	],
	'sdxl-3d': [
		{
			name: 'Low',
			value: 0.99
		},
		{
			name: 'M',
			value: 0.97
		},
		{
			name: 'High',
			value: 0.96
		},
	],
	sdxl: [
		{
			name: 'Low',
			value: 0.99
		},
		{
			name: 'M',
			value: 0.96
		},
		{
			name: 'High',
			value: 0.93
		},
	],
}

export const ThresholdAcceptedValues = [
  {
    name: '1',
    value: 4
  },
  {
    name: '2',
    value: 16
  },
  {
    name: '3',
    value: 32
  },
  {
    name: '4',
    value: 46
  },
  {
    name: '5',
    value: 62
  },
]

export const PromptRigidnessAcceptedValues = [
  {
    name: '1',
    value: 3.5
  },
  {
    name: '2',
    value: 7
  },
  {
    name: '3',
    value: 10
  },
  {
    name: '4',
    value: 14
  },
  {
    name: '5',
    value: 28
  },
]

export const IterationsAcceptedValeus = {
  'v1.1': [
    {
      name: '1',
      value: 25,
      _cost: 1,
    },
    {
      name: '2',
      value: 35,
      _cost: 1,
    },
    {
      name: '3',
      value: 45,
      _cost: 1.5,
    },
    {
      name: '4',
      value: 55,
      _cost: 2,
    },
    {
      name: '5',
      value: 65,
      _cost: 2.5,
    },
  ],
  'v2.0': [
    {
      name: '1',
      value: 20,
      _cost: 1,
    },
    {
      name: '2',
      value: 24,
      _cost: 1,
    },
    {
      name: '3',
      value: 30,
      _cost: 1,
    },
    {
      name: '4',
      value: 35,
      _cost: 1,
    },
    {
      name: '5',
      value: 40,
      _cost: 1.5,
    },
  ],
  'v3.0': [
    {
      name: '1',
      value: 20,
      _cost: 1,
    },
    {
      name: '2',
      value: 24,
      _cost: 1,
    },
    {
      name: '3',
      value: 30,
      _cost: 1,
    },
    {
      name: '4',
      value: 35,
      _cost: 1,
    },
    {
      name: '5',
      value: 40,
      _cost: 1.5,
    },
  ],
  'v4.0': [
    {
      name: '1',
      value: 20,
      _cost: 1,
    },
    {
      name: '2',
      value: 24,
      _cost: 1,
    },
    {
      name: '3',
      value: 30,
      _cost: 1,
    },
    {
      name: '4',
      value: 35,
      _cost: 1,
    },
    {
      name: '5',
      value: 40,
      _cost: 1.5,
    },
  ],
}

export const ImagesToGenerateValues = [
  {
    name: '1',
    value: 1
  },
  {
    name: '2',
    value: 2
  },
  {
    name: '3',
    value: 3
  },
  {
    name: '4',
    value: 4
  },

]

export const UseAsControlImageValues = [
  {
    name: "Yes",
    value: true
  },
  {
    name: "No",
    value: false
  },
]

export const detailLevelValues = [
  {
    name: -3,
    value: -2
  },
  {
    name: -2,
    value: -1
  },
  {
    name: -1,
    value: -.5
  },
  {
    name: 0,
    value: 0
  },
  {
    name: 1,
    value: .5
  },
  {
    name: 2,
    value: 1
  },
  {
    name: 3,
    value: 2
  },
]

export const SDColors = [
	{
		name: 'Red',
		id: 'Red',
		hex: '#EB3434',
		gradient: 'linear-gradient(102deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #EB3434',
	},
	{
		name: 'Pink',
		id: 'Pink',
		hex: '#FF007F',
		gradient: 'linear-gradient(101deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 101.22%), #FF007F',
	},
	{
		name: 'Magenta',
		id: 'Magenta',
		hex: '#EB38B9',
		gradient: 'linear-gradient(94deg, rgba(0, 0, 0, 0.35) 0.45%, rgba(0, 0, 0, 0.00) 100%), #EB38B9',
	},
	{
		name: 'Violet',
		id: 'Violet',
		hex: '#A42AEF',
		gradient: 'linear-gradient(97deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #A42AEF',
	},
	{
		name: 'Blue',
		id: 'Blue',
		hex: '#4848FF',
		gradient: 'linear-gradient(99deg, rgba(0, 0, 0, 0.35) 1.07%, rgba(0, 0, 0, 0.00) 99.99%), #4848FF',
	},
	{
		name: 'Azure',
		id: 'Azure',
		hex: '#29AFFA',
		gradient: 'linear-gradient(97deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #29AFFA',
	},
	{
		name: 'Cyan',
		id: 'Cyan',
		hex: '#19F2F2',
		gradient: 'linear-gradient(99deg, rgba(0, 0, 0, 0.20) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #19F2F2',
	},
	{
		name: 'Aquamarine',
		id: 'Aquamarine',
		hex: '#28F78F',
		gradient: 'linear-gradient(92deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #28F78F',
	},
	{
		name: 'Green',
		id: 'Green',
		hex: '#35D63C',
		gradient: 'linear-gradient(97deg, rgba(0, 0, 0, 0.20) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #35D63C',
	},
	{
		name: 'Lime',
		id: 'Lime',
		hex: '#AFFC32',
		gradient: 'linear-gradient(99deg, rgba(0, 0, 0, 0.20) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #AFFC32',
	},
	{
		name: 'Yellow',
		id: 'Yellow',
		hex: '#FDEE00',
		gradient: 'linear-gradient(96deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #FDEE00',
	},
	{
		name: 'Orange',
		id: 'Orange',
		hex: '#FB9620',
		gradient: 'linear-gradient(95deg, rgba(0, 0, 0, 0.35) 4.24%, rgba(0, 0, 0, 0.00) 100%), #FB9620',
	},
	{
		name: 'White',
		id: 'White',
		hex: '#FBFBFB',
		gradient: 'linear-gradient(97deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #FBFBFB',
	},
	{
		name: 'Off White',
		id: 'Off White',
		hex: '#DDDDDD',
		gradient: 'linear-gradient(93deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.00) 100%), #DDD',
	},
	{
		name: 'Beige',
		id: 'Beige',
		hex: '#F4E0C8',
		gradient: 'linear-gradient(97deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #F4E0C8',
	},
	{
		name: 'Grey',
		id: 'Grey',
		hex: '#999999',
		gradient: 'linear-gradient(100deg, rgba(0, 0, 0, 0.20) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #999',
	},
	{
		name: 'Black',
		id: 'Black',
		hex: '#000000',
		textHex: '#FFFFFF99',
		gradient: '#000',
	},
	{
		name: 'Gold',
		id: 'Gold',
		hex: '#F8B853',
		gradient: 'linear-gradient(100deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #F8B853',
	},
	{
		name: 'Bronze',
		id: 'Bronze',
		hex: '#CB6D51',
		gradient: 'linear-gradient(95deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.00) 100%), #CB6D51',
	},
	{
		name: 'Silver',
		id: 'Silver',
		hex: '#BBD7E3',
		gradient: 'linear-gradient(97deg, rgba(0, 0, 0, 0.35) -0.01%, rgba(0, 0, 0, 0.00) 99.99%), #BBD7E3',
	},
]
export const SCHEDULER_VALUES = [
	{
		name: 'Euler a',
		value: 'Euler a',
	},
	{
		name: 'Heun',
		value: 'Heun',
	},
	{
		name: 'DPM++ 2S a',
		value: 'DPM++ 2S a',
	},
	{
		name: 'DPM++ 2M SDE',
		value: 'DPM++ 2M SDE',
	},
	{
		name: 'DPM++ 2M SDE Karras',
		value: 'DPM++ 2M SDE Karras',
	},
	{
		name: 'LCM',
		value: 'LCM',
	},
	{
		name: 'DPM++ 2M Karras',
		value: 'DPM++ 2M Karras',
	},
	{
		name: 'DDIM',
		value: 'DDIM',
	},
	{
		name: 'DDPM',
		value: 'DDPM',
	},
	{
		name: 'PNDM',
		value: 'PNDM',
	},
	{
		name: 'LMS',
		value: 'LMS',
	},
]

export const LORAS = [
	{
		name: 'Disable',
		value: '',
	},
	{
		name: 'sdxl_lightning_8step_lora.safetensors',
		value: 'sdxl_lightning_8step_lora.safetensors',
	},
	{
		name: '1logolineart-AC-vU3B-50.safetensors',
		value: '1logolineart-AC-vU3B-50.safetensors',
	},
	{
		name: 'adcartoon-AC-vU3B-100.safetensors',
		value: 'adcartoon-AC-vU3B-100.safetensors',
	},
	{
		name: 'logoabstract-AC-vU3B-100.safetensors',
		value: 'logoabstract-AC-vU3B-100.safetensors',
	},
	{
		name: 'logobadge_AC-vU3B-50.safetensors',
		value: 'logobadge_AC-vU3B-50.safetensors',
	},
	{
		name: 'logocartoon-Exact-vU3B-50.safetensors',
		value: 'logocartoon-Exact-vU3B-50.safetensors',
	},
	{
		name: 'logoflat-Exact-vU3Bmini-100.safetensors',
		value: 'logoflat-Exact-vU3Bmini-100.safetensors',
	},
	{
		name: 'logolineart-Exact-vU3B-50.safetensors',
		value: 'logolineart-Exact-vU3B-50.safetensors',
	},
	{
		name: 'logomascots-AC-vU3B-50.safetensors',
		value: 'logomascots-AC-vU3B-50.safetensors',
	},
	{
		name: 'logomonogram-Exact-vU3B-50.safetensors',
		value: 'logomonogram-Exact-vU3B-50.safetensors',
	},
	{
		name: 'logotpictorial-AC-vU3B-50.safetensors',
		value: 'logotpictorial-AC-vU3B-50.safetensors',
	},
	{
		name: 'logotype-Exact-vU3B50.safetensors',
		value: 'logotype-Exact-vU3B50.safetensors',
	},
	{
		name: 'text-logo-50.safetensors',
		value: 'text-logo-50.safetensors',
	},
	{
		name: 'lodi3d-vCC1-030.safetensors',
		value: 'lodi3d-vCC1-030.safetensors',
	},
]
