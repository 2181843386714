import { Menu } from 'react-admin';

import UserIcon from '@mui/icons-material/People';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MapIcon from '@mui/icons-material/Map';
import CollectionsIcon from '@mui/icons-material/Collections';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function AdminDashboardMenu() {
  return (
    <Menu>
      <Menu.Item to="/admin/users" primaryText="Users" leftIcon={<UserIcon />}/>
      <Menu.Item to="/admin/generation" primaryText="Generations" leftIcon={<UserIcon />}/>
      <Menu.Item to="/admin/generationnew" primaryText="GenerationsNew" leftIcon={<UserIcon />}/>
      <Menu.Item to="/admin/gallery" primaryText="Gallery" leftIcon={<CollectionsIcon />}/>
      <Menu.Item to="/admin/style-transfer-gallery" primaryText="Style Transfer Gallery" leftIcon={<CollectionsIcon />}/>
      <Menu.Item to="/admin/userRoles" primaryText="User roles" leftIcon={<SupervisedUserCircleIcon />} />
      <Menu.Item to="/admin/whitelist" primaryText="Whitelist viewer" leftIcon={<SupervisedUserCircleIcon />} />
      <Menu.Item to="/admin/invites" primaryText="Invites" leftIcon={<FormatListBulletedIcon />} />
      <Menu.Item to="/admin/whitelist-creator" primaryText="Whitelist" leftIcon={<FormatListBulletedIcon />} />
      <Menu.Item to="/admin/sendgrid-whitelist" primaryText="Sendgrid Whitelist" leftIcon={<FormatListBulletedIcon />} />
      <Menu.Item to="/admin/roadmap" primaryText="Roadmap" leftIcon={<MapIcon />} />
      <Menu.Item to="/admin/questionnaire" primaryText="Questionnaire" leftIcon={<HelpOutlineIcon />} />
      <Menu.Item to="/admin/blocked-domains" primaryText="Blocked Domains" leftIcon={<HelpOutlineIcon />} />
    </Menu>
  )
}

export default AdminDashboardMenu;
