import React, { useEffect, useMemo, useState } from 'react';
import SuccessIcon from '../assets/icon_verify.png'
import FailureIcon from '../assets/verify_failed.png'
import ProtectedRoute from '../components/ProtectedRoute';
import AppLayout from '../layouts/AppLayout';
import { Helmet } from 'react-helmet';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Tap from '@tapfiliate/tapfiliate-js';
import { useSelector, useStore } from 'react-redux';
import authAxiosInstance from '../helpers/auth-axios';


const VALUES = {
	success: {
		title: "Payment Successful",
		header: "Payment successfully processed!",
		description: "Dive into Logo Diffusion to experience the future of logo design",
		icon: SuccessIcon,
	},
	failure: {
		title: "Payment Failed",
		header: "Payment was not processed!", 
		description: "Your card has not been debited. However, if you notice any charges, an automatic refund will be processed, it typically processes within 2-5 days. If this doesn't occur, we recommend contacting your bank to inquire about this transaction.",
		icon: FailureIcon,
	},
}

const Payment = (props) => {
  const params = useParams();

	const status = params.status;
	const sessionId = params.sessionId;

  const [searchParams] = useSearchParams();

	const store = useStore();

  const user = useSelector(state => state.userSlice.user);

	const [sessionData, setSessionData] = useState();

	const submit_to_tapfiliate = async () => {
		try {
			const resp = await authAxiosInstance.post('/api/payment/session-data/', {
				session_id: sessionId,
			})
			setSessionData(resp.data);
			Tap.conversion(sessionId, resp.data.amount_total / 100, { customer_id: store.getState().userSlice.user.stripeCustomerId, meta_data: { subscription: resp.data.subscription } })
		} catch(e) {
			console.log(e);
		}
	}

	useEffect(() => {
		if (status && status === 'success') {
			submit_to_tapfiliate();
		}
	}, [status, sessionId, searchParams, store]);

	const content = useMemo(() => VALUES[status], [status]);

	return (
    <AppLayout>
			<ProtectedRoute>
				<Helmet>
					<title>{content.title} | Logo Diffusion</title>
				</Helmet>
				<div
					className="pb-[100px] xl:pb-[200px] w-full h-full flex flex-col items-center justify-center text-center"
				>
					<img
						className="mb-[40px]"
						src={content.icon}
						alt="Verify icon"
					/>

					<h1
						className="font-montserrat font-black text-[36px] xl:text-[52px] leading-[1.4em] mb-[15px] text-white"
					>
						{content.header}
					</h1>

					<p
						className="font-poppins text-[20px] leading-[26px] text-[#9BADBD] mb-[55px] w-[900px] max-w-11/12"
					>
						{content.description}
					</p>

					<Link
						to="/"
						className="bg-ld-purple h-[50px] w-[159px] rounded-[10px] text-white gap-[11px] flex items-center justify-center font-poppins font-bold text-[14px] mb-[35px]"
					>
						Back to app
					</Link>
				</div>
			</ProtectedRoute>
		</AppLayout>
	);
};

export default Payment;
