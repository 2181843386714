import { Helmet } from 'react-helmet';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import UserLayout from '../layouts/UserLayout';
import Tracking from '../services/TrackingService';

import Modal from '../components/Modal';
import ImageViewer from '../components/ImageViewer';
import { ReactComponent as LoadingDots } from '../assets/LoadingDots.svg';

import { setUserTokens } from '../reducers/userReducer';
import environment from '../helpers/envProvider';
import { showImageModal } from '../reducers/formReducer';
import authAxiosInstance from '../helpers/auth-axios';
const TempVar_API = environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';

function Bookmarks() {
  const [bookmarks, setBookmarks] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [viewingImage, setViewingImage] = useState(null);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(50)
	const [totalPages, setTotalPages] = useState(1);
	const [totalBookmarks, setTotalBookmarks] = useState(0);
	const [viewingImageIndex, setViewingImageIndex] = useState(null);
	const [loading, setLoading] = useState(false)
	const loadingAnchor = useRef();

  const user = useSelector(state => state.userSlice.user);

//   const dispatch = useDispatch();

	useEffect(() => {
		async function fetchData() {
			if(user) {
				const res = await authAxiosInstance.get(`/api/generation/by-user?userId=${user._id}&range=[${(page -1) * perPage},${page * perPage}]&bookmarked`);

        const sortedBookmarks = res.data.docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

				setBookmarks(sortedBookmarks);
        setGeneratedImages(sortedBookmarks);
				setTotalBookmarks(res.data.totalDocs);
			}
		}
		
		fetchData();
	}, [user]);

	const dispatch = useDispatch();

  const handleViewImage = (image) => {
		dispatch(showImageModal(image.imageUrl))
    // setViewingImage(image);
    // setIsImageViewerOpen(true);
		// setViewingImageIndex(generatedImages.findIndex(gen => gen._id === image._id));
  }

	const loadPage = async (page) => {
		try {
			const res = await authAxiosInstance.get(`/api/generation/by-user?userId=${user._id}&range=[${(page - 1) * perPage},${page * perPage}]&bookmarked`);	
			const sortedBookmarks = res.data.docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

			setPage(page);
			setTotalPages(res.data.totalPages);
			setBookmarks([...bookmarks, ...sortedBookmarks]);
			setGeneratedImages([...generatedImages, ...sortedBookmarks]);
			setTotalBookmarks(res.data.totalDocs);
		} catch (error) {
			toast('Something went wrong, please try again', {
				position: toast.POSITION.BOTTOM_RIGHT,
				theme: 'dark',
				autoClose: 2000
			});
		}
	}

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting && page < totalPages && !loading) {
					loadPage(page + 1)
				}
			},
			{ threshold: 1 }
		);

		if (loadingAnchor.current) {
			observer.observe(loadingAnchor.current);
		}

		return () => {
			if (loadingAnchor.current) {
				observer.unobserve(loadingAnchor.current);
			}
		};
	}, [loadingAnchor, totalPages, page, loading]);


	const handleUpdateItem = (updatedItem) => {
		setGeneratedImages(generatedImages.map(item => item._id === updatedItem._id ? {...item, ...updatedItem} : item))
	}
	const handleDeleteItem = (deletedItemId) => {
		setGeneratedImages(generatedImages.filter(item => item._id !== deletedItemId))
	}

  return (
    <UserLayout>
      <Helmet>
        <title>Bookmarks | Logo Diffusion</title>
      </Helmet>

			<ImageViewer
				generatedImages={generatedImages}
				updateItemInList={handleUpdateItem}
				deleteItemInList={handleDeleteItem}
			/>

      <div className='px-12 py-6'>
        <h1 className='text-white text-xl font-bold mb-6'>
          Bookmarked Images
        </h1>

        <div className='bg-app-bg-gray rounded-lg p-6'>
          <h2 className='text-gray-300 font-bold mb-6'>
            Images Added to your Bookmarks will never be deleted
            <span className='text-gray-500 text-sm'> ({totalBookmarks}/1000)</span>
          </h2>

					<div className='flex gap-4 flex-wrap'>
						{bookmarks.map(image => (
							<img className="cursor-pointer rounded-[20px] aspect-square w-[70px] md:w-[100px] xl:w-[150px] xxl:w-[225px]" key={image.imageUrl} src={image.imageUrl} alt={image.prompt} onClick={e => handleViewImage(image)}/>
						))}
					</div>

					<div ref={loadingAnchor} className='p-2 px-3 flex items-center justify-center bg-w'>
						{/* {page >= totalPages ? '' : 'Loading...'} */}
						{page < totalPages && (
							<span
								className="flex items-center justify-center"
								id="button-loading"
							>
								<LoadingDots className='h-2'/>
							</span>
						)}
					</div>

        </div>
      </div>
    </UserLayout>
  );
}

export default Bookmarks;
