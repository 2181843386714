import { createContext, useContext, useState } from "react";

export const ContainerContext = createContext({
	width: 0,
	height: 0,
});

export const SquareContainerContextWrapper = (props) => {
	const { containerRef, heightLeftover = 0, widthLeftover = 0, children } = props;

	const [boxSize, setBoxSize] = useState(0);
	const [containerSize, setContainerSize] = useState({
		width: 0,
		height: 0,
	});

	useState(() => {
		setTimeout(() => {
			if (containerRef.current) {
				const boxSizing = containerRef.current.getBoundingClientRect();
				setBoxSize(Math.min(boxSizing.width - widthLeftover,
					Math.max(boxSizing.height * 0.8, boxSizing.height - heightLeftover)))
				setContainerSize({
					width: boxSizing.width,
					height: boxSizing.height,
				})
			}
		}, )
		const handleResize = (e) => {
			if (containerRef.current) {
				const boxSizing = containerRef.current.getBoundingClientRect();
				setBoxSize(Math.min(boxSizing.width - widthLeftover, 
					Math.max(boxSizing.height * 0.8, boxSizing.height - heightLeftover)))
				setContainerSize({
					width: boxSizing.width,
					height: boxSizing.height,
				})
			}
			console.log("Resize", )
		}

		window.addEventListener('resize', handleResize, true);

		return () => {
			window.removeEventListener('resize', handleResize);
		}

	}, [containerRef.current])


	return (
		<ContainerContext.Provider value={{
			width: boxSize,
			height: boxSize,
			containerSize,
		}}>
			{children}
		</ContainerContext.Provider>
	);
}

export const FixedSizeContainerWRapper = (props) => {
	const { size, children, maxHeight } = props;

	return (
		<ContainerContext.Provider value={{
			width: maxHeight ? Math.min(size, maxHeight) : size,
			height: maxHeight ? Math.min(size, maxHeight) : size,
		}}>
			{children}
		</ContainerContext.Provider>
	);
}
