import Tooltip from './Tooltip';

import { ReactComponent as RandomizeIcon } from '../assets/RandomizeIcon.svg';
import { ReactComponent as FreezeIcon } from '../assets/FreezeIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { freezeSeed, unfreezeSeed, updateSeed } from '../reducers/formReducer';

function SeedGeneratorV4(props) {
  const dispatch = useDispatch();

  const isGeneratingSeed = useSelector(
    (state) => state.form.__autogenerated_seed
  );
  const seed = useSelector((state) => state.form.payload.seed);

  const setSeed = (value) => {
    dispatch(updateSeed(value));
  };
  const handleFreezeSeed = () => {
    dispatch(freezeSeed());
  };
  const handleRandomizeSeed = () => {
    dispatch(unfreezeSeed());
  };

  return (
    <div className={`flex items-center justify-between gap-2 w-full`}>
      <input
        className={`p-2 text-sm text-left bg-app-bg-gray font-poppins border-none h-[40px] rounded-[4px] text-white w-full flex`}
        value={seed}
        onChange={(e) => setSeed(e.target.value)}
      />

      <Tooltip title='Freeze Seed: Keeps the current seed number fixed to reproduce identical results (good if you want to test different prompts or settings). '>
        <FreezeIcon
          className={`cursor-pointer ml-10 ${
            isGeneratingSeed ? '' : '[&_path]:fill-app-green'
          }`}
          onClick={(e) => handleFreezeSeed()}
        />
      </Tooltip>

      <Tooltip title='Refresh Seed: Generates a new random seed number for unique outputs each time you click generate.'>
        <RandomizeIcon
          className={`cursor-pointer ${
            isGeneratingSeed ? '[&_path]:fill-app-green' : ''
          }`}
          onClick={(e) => handleRandomizeSeed()}
        />
      </Tooltip>
    </div>
  );
}

export default SeedGeneratorV4;
