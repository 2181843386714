import { createSlice } from "@reduxjs/toolkit";
import { emptyJPEG } from "../components/editor/image_generator";

export const DEFAULT_COLORS = [
  "#34C759",
  "#FC0",
  "#FF3B30",
  "#FF8A00",
  "#FF4789",
  "#007AFF",
  "#AF52DE",
  "#5856D6",
]

export const INITIAL_STATE = {
  isOpen: false,
  backgroundImage: emptyJPEG,
  isEditImage: false,

  presetColors: DEFAULT_COLORS,
  activeColor: "#686868ff",
  canvasSize: 0,

  brushSize: 50,

	strokeSize: 1,

  fontFamily: "'Open Sans', sans-serif",
  fontWeight: 400,
  fontSize: 48,

  tool: null,

  selectedElement: null,

  zoom: 1,

  selectedShape: 'ROUND_RECT',

  crop: {
    cropZone: null,
    buttonLoc: {
      left: null,
      top: null,
    },
  },

  selection: {
    pickerLocation: {},
    pickerOpen: false,
		pickerType: 'fill',
  }
} 


export const imageEditorSlice = createSlice({
  name: "imageEditorSlice",
  initialState: INITIAL_STATE,
  reducers: {
    reset(state) {
      return INITIAL_STATE
    },

    setIsOpen(state, action) {
      if (action.payload === null || action.payload === undefined) {
        state.isOpen = !state.isOpen;
      } else {
        state.isOpen = action.payload;

        // Reset state
        state.zoom = 1;
        // state.tool = "FREE_DRAWING"
        state.tool = null
      }
    },

    selectColor(state, action) {
      state.activeColor = action.payload;
    },

    setPresetColor(state, action) {
      state.presetColors = action.payload;
    },
    addPresetColor(state, action) {
      state.presetColors = [...state.presetColors, action.payload];
    },

    setBrushSize(state, action) {
      state.brushSize = action.payload;
    },

    setStrokeSize(state, action) {
      state.strokeSize = action.payload;
    },

    setBackgroundImage(state, action) {
      state.backgroundImage = action.payload;
    },

    updateFontFamily(state, action) {
      state.fontFamily = action.payload;
    },
    updateFontWeight(state, action) {
      state.fontWeight = action.payload;
    },
    updateFontSize(state, action) {
      state.fontSize = action.payload;
    },

    updateTool(state, action) {
      state.tool = action.payload;
    },

    updateSelectedElement(state, action) {
      state.selectedElement = action.payload;
    },

    updateZoom(state, action) {
      state.zoom = Math.max(Math.min(action.payload, 5), 1);
    },

    updateSelectedShape(state, action) {
      state.selectedShape = action.payload
    },

    updateCropZone(state, action) {
      state.crop.cropZone = action.payload
    },
    updateCropButtonLocation(state, action) {
      state.crop.buttonLoc = action.payload
    },


    updateSelectionPickerLocation(state, action) {
      state.selection.pickerLocation = action.payload
    },
    updateSelectionPicker(state, action) {
      state.selection.pickerOpen = action.payload.isOpen
      state.selection.pickerType = action.payload.type
    },
    setIsEditImage(state, action) {
      state.isEditImage = action.payload
    },
    setCanvasSize(state, action) {
      state.canvasSize = action.payload
    }
  },
  extraReducers: {},
});

export const {
  reset,
  setIsOpen,
  selectColor,
  setPresetColor,
  addPresetColor,
  setBrushSize,
	setStrokeSize,
  setBackgroundImage,
  updateFontFamily,
  updateFontWeight,
  updateFontSize,
  updateTool,
  updateSelectedElement,
  updateZoom,
  updateSelectedShape,
  updateCropZone,
  updateCropButtonLocation,
  updateSelectionPicker,
  updateSelectionPickerLocation,
  setIsEditImage,
  setCanvasSize,
} = imageEditorSlice.actions;
