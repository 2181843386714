import { Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const PostTitle = () => {
  const record = useRecordContext();
  return <span>Roadmap item {record ? `${record.title}` : ''}</span>;
};

export const RoadmapEdit = () => (
  <Edit title={<PostTitle />}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="text" />
      <TextInput source="category" />
    </SimpleForm>
  </Edit>
);

export default RoadmapEdit;