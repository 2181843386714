import { Popover, RadioGroup, Transition } from '@headlessui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SettingsIcon } from '../../assets/styles/style-config/settings-icon.svg';
import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { ReactComponent as CarrotIcon } from '../../assets/CaretDownIcon.svg';
import {
  resetStyleConfig,
  updateConfigTutorial,
  updateContentStyle,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
} from '../../reducers/formReducer';
import STYLES, { NoneIcon, V3_GROUPS } from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';
import StyleSettings from './style-config/StyleSettings';
import { CONFIG_PROPS, STYLE_SETTINGS } from './style-config/constants';

const isSettingsDisabled = (method, style) => {
  if (STYLE_SETTINGS[method]?.[style] === undefined) return true;
  return (
    STYLE_SETTINGS[method]?.[style].filter((c) => c.section_id !== 'colors')
      .length === 0
  );
};

const V3StyleSelector = (props) => {
  const dispatch = useDispatch();

  const { method, mode, setMode, generationStyle, setGenerationStyle } =
    useGenerationEndpoint();

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  const [settingsAnchorEl, setSettingsAnchorEl] = useState();
  const [settingsEl, setSettingsEl] = useState();
  const [configTutorialEl, setConfigTutorialEl] = useState();
  const [configTutorialArrowEl, setConfigTutorialArrowEl] = useState();

  const [clickedIndex, setClickedIndex] = useState('logo-design');
  const [filteredStyle, setFilteredStyle] = useState(STYLES[method]);

  const handleButtonClick = (index) => {
    setClickedIndex(index);
  };

  useEffect(() => {
    if (clickedIndex) {
      const _filteredStyle = STYLES[method].filter(
        (it) => it.group === clickedIndex
      );
      setFilteredStyle(_filteredStyle);
    }
  }, [clickedIndex]);

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
  });
  let settingsPopper = usePopper(anchorEl, settingsEl, {
    placement: 'top',
    strategy: 'fixed',
  });

  let tutorialPopper = usePopper(popperEl, tutorialEl, {
    placement: 'left',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: tutorialArrowEl,
          padding: -100,
        },
      },
    ],
  });

  let configTutorialPopper = usePopper(settingsEl, configTutorialEl, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: configTutorialArrowEl,
          // padding: -100,
        },
      },
    ],
  });

  const style = useSelector((state) => state.form.payload.contentStyle);

  const isOpen = useSelector((state) => state.form.showStyleSelector);
  useEffect(() => {
    if (isOpen) {
      anchorEl?.click();
      dispatch(updateShowStyleSelector(false));
    }
  }, [isOpen]);

  const handleCloseTutorial = () => {
    dispatch(updateShowStyleSelectorTutorial(false));
  };

  const showTutorial = useSelector(
    (state) => state.form.showStyleSelectorTutorial
  );

  const configTutorial = useSelector((state) => state.form.configTutorial);
  const handleCloseConfigTutorial = () => {
    dispatch(updateConfigTutorial({ ...configTutorial, style: null }));
  };
  useEffect(() => {
    if (configTutorial.style) {
      settingsAnchorEl?.click();
    }
  }, [configTutorial.style, settingsAnchorEl, configTutorial.key]);

  const currentStyle = useMemo(
    () => STYLES[method].find((item) => item.id === style),
    [method, style]
  );
  const CurrentStyleIcon = useMemo(
    () => currentStyle?.icon_preview || currentStyle?.icon || NoneIcon,
    [currentStyle]
  );

  const style_config = useSelector((state) => state.form.payload.styleConfig);
  const areAllRandomized = useMemo(
    () =>
      Object.values(style_config).reduce(
        (prev, cur) => prev && cur.__randomized,
        true
      ),
    [style, style_config]
  );
  const randomizeAll = () => {
    dispatch(resetStyleConfig({ use_current: true }));
  };

  const settingsDisabled = isSettingsDisabled(method, style);

  const handleStyle = (value) => {
    dispatch(updateContentStyle(value));

    if (!!CONFIG_PROPS[method]?.[value] && !isSettingsDisabled(method, value)) {
      setTimeout(() => {
        settingsAnchorEl.click();
      });
    }
  };

  return (
    <div className='flex flex-row gap-[2px]'>
      <Popover>
        {({ open, close }) => (
          <>
            <Popover.Button
              className='relative flex justify-start items-center cursor-pointer focus:outline-none overflow-hidden rounded-l-lg bg-overlay-bg w-28 h-7 gap-1 pr-2 outline-none'
              ref={setAnchorEl}
            >
              <div className='w-7 h-7'>
                <CurrentStyleIcon
                  className={`w-7 h-7 ${currentStyle?.icon_preview_classes}`}
                />
              </div>
              <p className='truncate text-white font-roboto text-xs grow text-center'>
                {currentStyle?.name}
              </p>
            </Popover.Button>

            <Transition
              as={React.Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
              {...props}
            >
              <Popover.Panel
                className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray pb-3 mb-4 flex gap-3 w-112 h-[286px] relative'
                ref={setPopperEl}
                style={styles.popper}
                {...attributes.popper}
              >
                <div className='flex flex-col w-40 h-full pr-2 pl-6 pt-4'>
                  <h3 className='font-bold flex flex-row gap-1 items-center text-title-white text-base col-start-1 col-end-4'>
                    Styles
                  </h3>
                  {V3_GROUPS.map(({ icon: Icon, ...item }, index) => {
                    return (
                      <button
                        key={index}
                        className={`flex items-center justify-start max-w-[123px] p-[5px] gap-[3px] rounded-[8px] my-[2px] text-[13px] ${
                          item.id === clickedIndex ? 'bg-[#8058F340]' : ''
                        }`}
                        onClick={() => handleButtonClick(item.id)}
                      >
                        <Icon
                          className={`w-[24px] h-[24px] ${
                            item.id === clickedIndex
                              ? '[&_path]:fill-white'
                              : ''
                          }`}
                        />
                        <div
                          className={`${
                            item.id === clickedIndex ? 'text-white' : ''
                          }`}
                        >
                          {item.name}
                        </div>
                      </button>
                    );
                  })}
                </div>
                <div
                  className={`bg-app-bg-gray w-72 h-full transition-all duration-500 overflow-hidden pt-4 px-2 `}
                >
                  <RadioGroup
                    value={style}
                    onChange={handleStyle}
                    className='grid grid-cols-3 gap-3 [&>div]:text-style-text [&>div]:text-xs max-h-full overflow-x-hidden overflow-y-auto custom-scroll px-2'
                  >
                    {filteredStyle.map(({ icon: Icon, ...item }) => {
                      return (
                        <RadioGroup.Option
                          disabled={item.disabled}
                          key={item.id}
                          value={item.id}
                          className='flex flex-col items-center gap-1 relative cursor-pointer ui-disabled:cursor-not-allowed aspect-square rounded-lg bg-style-bg ui-disabled:bg-style-disabled border border-solid border-style-bg ui-checked:border-app-green overflow-hidden'
                        >
                          <Icon
                            className={`w-[67px] h-[67px] ${item.icon_classes}`}
                          />
                          <div className='bg-style-text-bg/70 px-1 text-[0.6rem] [line-height:0.8rem] absolute bottom-0 right-0 left-0 text-center text-style-text'>
                            {item.name}
                          </div>
                          {item.beta && (
                            <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                              Beta
                            </div>
                          )}
                          {item.new && (
                            <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                              New
                            </div>
                          )}
                          {item.soon && (
                            <div className='bg-white/10 rounded-[0.2rem] px-1 text-style-soon-badge text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                              Soon
                            </div>
                          )}
                        </RadioGroup.Option>
                      );
                    })}
                  </RadioGroup>
                </div>

                {showTutorial && (
                  <div
                    className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 mr-4 w-60 rounded-md'
                    ref={setTutorialEl}
                    style={tutorialPopper.styles.popper}
                    {...tutorialPopper.attributes.popper}
                  >
                    <div
                      ref={setTutorialArrowEl}
                      style={tutorialPopper.styles.arrow}
                      {...tutorialPopper.attributes.arrow}
                      className='h-3 w-3 inline-block left-full '
                    >
                      <div className='w-full h-full bg-style-method-enabled -translate-y-10 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r'></div>
                    </div>
                    <div className='flex items-center justify-start gap-2'>
                      <span>
                        <InfoIcon />
                      </span>
                      <span className='text-title-white text-sm font-roboto'>
                        Choose a style first.
                      </span>
                    </div>
                    <div className='font-roboto text-input-color text-xs '>
                      Before you can generate your first image, you need to pick
                      a logo design style.
                    </div>
                    <div className='flex items-center justify-end mt-1'>
                      <button
                        className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
                        onClick={handleCloseTutorial}
                      >
                        Got It
                      </button>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Popover>
        {({ open, close }) => (
          <>
            <Popover.Button
              disabled={settingsDisabled}
              className='group grow relative flex justify-center items-center cursor-pointer disabled:cursor-not-allowed focus:outline-none overflow-hidden rounded-r-lg'
              ref={setSettingsAnchorEl}
            >
              <SettingsIcon
                className={`w-7 h-7 ${
                  settingsDisabled
                    ? '[&_.text]:fill-config-disabled'
                    : 'group-hover:[&_.text]:fill-app-green [&_.text]:fill-icon-text-color'
                } [&_.bg]:fill-overlay-bg [&_.bg]:[rx:0]`}
              />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
              {...props}
            >
              <Popover.Panel
                className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray pb-3 mb-4 flex flex-col gap-3 w-72 h-[380px] relative'
                ref={setSettingsEl}
                style={settingsPopper.styles.popper}
                {...settingsPopper.attributes.popper}
              >
                <div className='flex gap-2 items-center bg-style-bg px-4 py-2 h-14 rounded-t-xl'>
                  <div
                    className={
                      'w-[40px] h-[40px] [&_img]:max-h-full [&_img]:max-w-full flex items-center justify-center'
                    }
                  >
                    <CurrentStyleIcon
                      className={`w-[40px] h-[40px] ${currentStyle?.icon_classes}`}
                    />
                  </div>
                  <button
                    disabled={settingsDisabled}
                    className={`grow flex gap-1 items-center ${
                      STYLE_SETTINGS[style] === undefined
                        ? 'cursor-default'
                        : ''
                    }`}
                    type='button'
                  >
                    <span className='font-roboto font-bold text-style-text text-base'>
                      {currentStyle?.name}
                    </span>
                  </button>
                  <button
                    role='checkbox'
                    aria-checked={areAllRandomized}
                    type='button'
                    onClick={() => randomizeAll()}
                    className='group'
                  >
                    <RandomizeIcon
                      className={` ${
                        areAllRandomized
                          ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70'
                          : 'group-hover:[&_path]:fill-app-green/30'
                      }`}
                    />
                  </button>
                </div>
                <div className='flex flex-col gap-2 px-4 grow overflow-y-auto custom-scroll w-full max-w-full'>
                  <StyleSettings />
                </div>

                {configTutorial.style === style && (
                  <div
                    className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 mr-4 w-60 rounded-md'
                    ref={setConfigTutorialEl}
                    style={configTutorialPopper.styles.popper}
                    {...configTutorialPopper.attributes.popper}
                  >
                    <div
                      ref={setConfigTutorialArrowEl}
                      style={configTutorialPopper.styles.arrow}
                      {...configTutorialPopper.attributes.arrow}
                      className='h-3 w-3 inline-block left-full '
                    >
                      <div className='w-full h-full bg-style-method-enabled -translate-y-10 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r' />
                    </div>
                    <div className='flex items-center justify-start gap-2'>
                      <span>
                        <InfoIcon />
                      </span>
                      <span className='text-title-white text-sm font-roboto'>
                        {configTutorial.title}
                      </span>
                    </div>
                    <div className='font-roboto text-input-color text-xs '>
                      {configTutorial.body}
                    </div>
                    <div className='flex items-center justify-end mt-1'>
                      <button
                        className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
                        onClick={handleCloseConfigTutorial}
                        type='button'
                      >
                        Got It
                      </button>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default V3StyleSelector;
