import { ReactComponent as UploadImageIcon } from '../assets/UploadImageIcon.svg';
import { ReactComponent as TrashIcon } from '../assets/TrashIconWhite.svg';
import { ReactComponent as PenIcon } from "../assets/Pen.svg";
import { ReactComponent as EditCanvasIcon } from "../assets/EditCanvas.svg";
import ImageCropper from './ImageCropper';
import { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_COLORS, setBackgroundImage, setIsEditImage, setIsOpen, setPresetColor } from '../reducers/imageEditorReducer';
import { IMAGE_EXPORT_SIZE } from './editor/settings';
import { emptyJPEG } from './editor/image_generator';

export function toDataURL(src, callback, outputFormat) {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function() {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var dataURL;
    canvas.height = IMAGE_EXPORT_SIZE;
    canvas.width = IMAGE_EXPORT_SIZE;
    ctx.drawImage(this, 0, 0, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = src;
  }
}

function FileUploadInput(props) {
  const uploaderRef = useRef();
  const dispatch = useDispatch();

  const openEmptyCanvas = () => {

    dispatch(setPresetColor(DEFAULT_COLORS));
    dispatch(setBackgroundImage(emptyJPEG));
    dispatch(setIsEditImage(false));
    dispatch(setIsOpen(true));
  }

  return (
    <>
      <div
        className='bg-app-black lg:bg-app-bg-gray flex flex-row justify-around items-center rounded-2xl gap-3 border border-gray-800 aspect-square p-4 md:max-w-[250px] py-6'
      >
        <button className='basis-[45%] flex-grow shrink-0 !outline-none flex flex-col justify-center items-center gap-4' onClick={(e) => uploaderRef.current.click()}>
          <UploadImageIcon className='w-14 h-14'/>
          <p className='text-editor-label text-center text-[8px] 4xl:text-[12px]'>
            <span className='text-blue-600'>Click here</span> to upload your image JPG/PNG up to 5MB
          </p>
        </button>
        
        <div className='h-full w-[2px] bg-white bg-opacity-10 shrink-0'></div>

        <button className='basis-[45%] flex-grow shrink-0 !outline-none flex flex-col justify-center items-center gap-4' onClick={openEmptyCanvas}>
          <EditCanvasIcon className='w-14 h-14'/>
          <p className='text-editor-label text-center text-[8px] 4xl:text-[12px]'>
            <span className='text-blue-600'>Click here</span> to open an empty editable canvas
          </p>
        </button>
      </div>

      <input
        ref={uploaderRef}
        type="file" id="imgUpload" name="img" accept="image/*"
        onChange={e => props.handleImageUpload(e.target.files)}
        className=' hidden absolute opacity-0 '
      />
    </>
  )
}

function ImagePreview(props) {
  const dispatch = useDispatch();
  return (
    <div className='relative group overflow-hidden rounded-2xl'>
      <div className='absolute top-0 right-0 bottom-0 left-0 flex justify-between items-center p-2 bg-gradient-to-b text-slate-500 from-[rgb(0_0_0/0.4)] via-neutral to-[rgb(0_0_0/0.85)] opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-400'>
        <button className='group/edit basis-[45%] flex-grow shrink-0 !outline-none flex flex-col justify-center items-center gap-1 mt-4' onClick={e => dispatch(setIsOpen(true)) }>
          <EditCanvasIcon className='w-10 h-10 [&_path]:fill-white group-hover/edit:[&_path]:fill-app-green'/>
          <p className='text-white group-hover/edit:text-app-green text-center text-base text-bold 4xl:text-lg'>
            Edit
          </p>
        </button>
        
        <div className='h-full w-[2px] bg-white bg-opacity-10 shrink-0'></div>

        <button className='group/delete basis-[45%] flex-grow shrink-0 !outline-none flex flex-col justify-center items-center gap-1 mt-4' onClick={e => props.onRemoveUploadedImage() }>
          <TrashIcon className='w-10 h-10 [&_path]:fill-white group-hover/delete:[&_path]:fill-app-green'/>
          <p className='text-white group-hover/delete:text-app-green text-center text-base text-bold 4xl:text-lg'>
            Remove
          </p>
        </button>
      </div>

      <img className='min-h-full min-w-full rounded-2xl' src={props.imageToUpload} alt="to upload" />
    </div>
  )
}

function ImageUpload(props) {
	const { renderInput } = props;

  const [isCropping, setIsCropping] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);

	const InputComponent = useMemo(() => renderInput || FileUploadInput, [renderInput]);

  const handleImageSelection = (files) => {
    const [file] = files;

    if (file) {
      const url = URL.createObjectURL(file);
      const image = new Image();

      image.onload = () => {
        const { width, height } = image;

        if (width !== height) {
          setImageToCrop(url)
          setIsCropping(true)
        } else {
          toDataURL(url, (base64) => {
            props.handleImageSelected(base64)
          });
        }
      };

      image.src = url;
    }
  }

	const handleImageCropped = (file, base64Image) => {
		const url = URL.createObjectURL(file);
		
		props.handleImageSelected(base64Image);
		// setImageToUpload(url);

		setImageToCrop(null);
    setIsCropping(false)
  }

  if(props.imageToUpload) return <ImagePreview
    imageToUpload={props.imageToUpload}
    onRemoveUploadedImage={props.onRemoveUploadedImage}
  />;

  
  return (<>
      <ImageCropper 
        isCropping={isCropping}
        imageToCrop={imageToCrop}
        onConfirm={handleImageCropped}
        onCancel={() => {
          setIsCropping(false)
          setImageToCrop(null)
        }}
      />
      <InputComponent handleImageUpload={handleImageSelection} />
    </>);

}

export default ImageUpload;
