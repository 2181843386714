import { DateInput, Edit, SimpleForm, ReferenceField, TextField, TextInput } from 'react-admin';
import Grid from '@mui/material/Grid';

export const AdminQuestionnaireEdit = () => (
    <Edit>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReferenceField source="user" reference="users">
                <TextField source="email" fullWidth />
              </ReferenceField>
            </Grid>

            <Grid item xs={6}>
              <DateInput source="createdAt" fullWidth />
            </Grid>

            <Grid item xs={6}>
              <TextInput source="discovery" fullWidth />
            </Grid>

            <Grid item xs={6}>
              <TextInput source="occupation" fullWidth />
            </Grid>

            <Grid item xs={6}>
              <TextInput source="usageExplanation" fullWidth />
            </Grid>

            <Grid item xs={6}>
              <TextInput source="futureRecommendations" fullWidth />
            </Grid>
          </Grid>
        </SimpleForm>
    </Edit>
);

export default AdminQuestionnaireEdit;
