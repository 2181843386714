import React from 'react';
import Slider from './Slider';
import { Tooltip } from "react-tippy";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { useSelector } from 'react-redux';

const ValuedSlider = (props) => {
	const {
		value,
		onChange,
		values,
		title,
		disabled,
		label,
		showValue,
		appliedClasses = {},
		fixedValues=false,
	} = props;

	const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

	if (isDevModeEnabled && !fixedValues) {
		return (
			<div className={`flex justify-between ${appliedClasses.main}`} disabled={disabled}>
				<label className={`text-gray-label font-poppins font-bold text-xs flex gap-1 ${disabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
					{label}
		
					<Tooltip title={title} >
						<InfoIcon className={`${appliedClasses.infoTooltip}`}/>
					</Tooltip>
				</label>
				<div className="flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]">
					<input 
						className={` p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-full ${appliedClasses.optionContainer}`}
						value={value}
						onChange={(e) => onChange(e.target.value)}
						disabled={disabled}
					/>
				</div>
			</div>
		);
	}

	return (
		<Slider
			{...props}
			rangeInputProps={{
				...(props.rangeInputProps ?? {}),
				min: 0,
				max: values.length - 1,
				step: 1,
			}}
			value={[Math.max(values.findIndex((item) => item.value === value), 0)]}
			displayValue={values.find((item) => item.value === value)?.name}
			onChange={(inds) => onChange(values[inds[0]]?.value)}
		/>
	)
}

export const V4StyledSlider = (props) => {
	const { wideLayout } = props;
	return (
		<ValuedSlider 
			{...props}
			appliedClasses={{
				...(props.appliedClasses ?? {}),
				// label: "!text-base",
				rangeinput: wideLayout ? 'w-1/3 !gap-4 pl-2' : 'w-full !gap-4 pl-2',
				main: wideLayout ? 'w-full !items-center justify-between' : 'w-full flex-col !items-stretch',
				value: 'px-2 py-1 !rounded-md bg-app-black',
			}}
			rangeInputProps={{
				...(props.rangeInputProps ?? {}),
				importedRailStyle: {
					height: '8px',
					borderRadius: '3px',
				},
					bgInactiveColor: wideLayout ? '#1C1C24' : '#13131A',
				importedThumbStyle: {
					height: '15px',
					width: '15px',
				},
			}}
		/>
	)
}

export default ValuedSlider
