import { Datagrid, List, TextField } from 'react-admin';

export const UserRoleList = () => (
  <List>
      <Datagrid>
          <TextField source="email" />
      </Datagrid>
  </List>
);

export default UserRoleList;
