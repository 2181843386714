import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import environment from '../helpers/envProvider';
import Tracking from '../services/TrackingService';
import Commerce from '../services/eComService';
import authAxiosInstance from '../helpers/auth-axios';
import { reset as resetImageEditor } from './imageEditorReducer';
import { reset as resetForm, updateImagesToGenerate } from './formReducer';
import axiosInstance from '../helpers/axios';
const apiHost = environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';

export const INITIAL_STATE =  {
  user: null,
  isLoading: false,
  isLoggedOut: false,
  showQuestionnaireModal: false,
  sumolingLicense: null,

	deviceId: null,

	showAppsumo1024LimitDialog: false,
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setUserTokens(state, action) {
      state.user.remainingCredits = action.payload.tokens;
    },

    setUserTotalGenerations(state, action) {
      state.user.totalGenerations = action.payload.totalGenerations;
    },

		setShowAppsumo1024LimitDialog(state, action) {
			state.showAppsumo1024LimitDialog = action.payload;
		},

    setUserName(state, action) {
      state.user.name = action.payload.name;
    },

    updateShowQuestionnaireModal(state, action) {
      state.showQuestionnaireModal = action.payload;
    },
    updateRefreshToken(state, action) {
      state.refreshToken = action.payload
    },
    updateUser(state, action) {
      state.user = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.isLoggedOut = true;
        state.refreshToken = null;
        state.accessToken = null;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.user = null;
        state.isLoggedOut = true;
        state.refreshToken = null;
        state.accessToken = null;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(relogUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(relogUser.fulfilled, (state, action) => {
        state.user = action.payload

        state.isLoading = false;
        state.isLoggedOut = false;
      })
      .addCase(relogUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        const { refreshToken, accessToken, user } = action.payload
        // state.user = user
        // state.refreshToken = refreshToken
        // state.isLoggedOut = false;

        state.isLoading = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isLoggedOut = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const { refreshToken, accessToken, user } = action.payload
        state.user = user
        state.refreshToken = refreshToken
        state.isLoggedOut = false;

        state.isLoading = false;
      })
      .addCase(loginUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        const { subscription } = action.payload

        state.user.plan = subscription
      });

		builder.addCase(registerDevice.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(registerDevice.fulfilled, (state, action) => {
			state.isLoading = false;

			state.deviceId = action.payload?.deviceId
		});
		builder.addCase(registerDevice.rejected, (state, action) => {
			state.isLoading = false;
		})

    builder.addCase(confirmUserEmail.pending, (state, action) => {
      state.isLoading = true
    });
    builder.addCase(confirmUserEmail.fulfilled, (state, action) => {
      state.user = action.payload.user
      state.refreshToken = action.payload.refreshToken
      state.isLoggedOut = false;

      state.isLoading = false
    });
    builder.addCase(confirmUserEmail.rejected, (state, action) => {
      state.isLoading = false
    });


    builder.addCase(loadQuestionnaireAnswers.pending, (state, action) => {
      state.isLoading = true
    });
    builder.addCase(loadQuestionnaireAnswers.fulfilled, (state, action) => {
      state.showQuestionnaireModal = action.payload?.totalDocs === 0 || action.payload?.docs?.length === 0;
      state.isLoading = false
    });
    builder.addCase(loadQuestionnaireAnswers.rejected, (state, action) => {
      state.isLoading = false
    });

    builder.addCase(submitQuestionnaireAnswers.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(submitQuestionnaireAnswers.fulfilled, (state, action) => {
      // state.showQuestionnaireModal = false
      state.isLoading = false
    });
    builder.addCase(submitQuestionnaireAnswers.rejected, (state, action) => {
      state.isLoading = false
    });


    // Appsumo
    builder.addCase(activateSumolingLicense.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(activateSumolingLicense.fulfilled, (state, action) => {
      state.sumolingLicense = action.payload.plan
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      state.isLoading = false
    });
    builder.addCase(activateSumolingLicense.rejected, (state, action) => {
      state.isLoading = false
    });

    builder.addCase(getSumolingLicense.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getSumolingLicense.fulfilled, (state, action) => {
      state.sumolingLicense = action.payload.plan
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      state.isLoading = false
    });
    builder.addCase(getSumolingLicense.rejected, (state, action) => {
      state.isLoading = false
    });


    builder.addCase(getStripeManagementUrl.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getStripeManagementUrl.fulfilled, (state, action) => {
      state.isLoading = false
    });
    builder.addCase(getStripeManagementUrl.rejected, (state, action) => {
      state.isLoading = false
    });

    builder.addCase(downgradeSubscription.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(downgradeSubscription.fulfilled, (state, action) => {
      state.isLoading = false
			state.user.plan = action.payload.plan
    });
    builder.addCase(downgradeSubscription.rejected, (state, action) => {
      state.isLoading = false
    });

  }
});

export const { setIsLoading, setUserTokens, setUserTotalGenerations, setUserName, updateShowQuestionnaireModal, updateRefreshToken, updateUser, setShowAppsumo1024LimitDialog } = userSlice.actions;

export const relogUser = createAsyncThunk('userSlice/relogUser', async (relogData, thunkApi) => {
  try {
    if (relogData?.refreshToken) thunkApi.dispatch(updateRefreshToken(relogData.refreshToken))
    if (relogData?.userId) thunkApi.dispatch(updateUser({ ...(thunkApi.getState().userSlice?.user || {}), _id: relogData.userId }));

    const { data: userData } = await authAxiosInstance.get(`/api/users/${thunkApi.getState().userSlice?.user?._id || relogData.userId}`);
    Tracking.send('authenticate', {
      user: userData
    })
    if (userData.subscriptionProvider === 'free') 
			thunkApi.dispatch(updateImagesToGenerate(Math.min(2, thunkApi.getState().form.payload.imagesToGenerate)))
    return {
      ...userData,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({
      response: {
        data: error.response?.data,
        status_code: error.response?.status_code,
      }
    });
  }
});

export const registerDevice = createAsyncThunk('userSlice/registerDevice', async (payload, thunkApi) => {
  try {
    // const resp = await authAxiosInstance.post('/api/device/register-device', { deviceId: payload.deviceId });

    Tracking.send('logout', { user: null })
    return {
			deviceId: payload.deviceId
		};
  } catch (error) {
    return error.response?.data?.message;
  }
});

export const logoutUser = createAsyncThunk('userSlice/logoutUser', async (refreshToken, thunkApi) => {
  try {
    thunkApi.dispatch(resetImageEditor())
    thunkApi.dispatch(resetForm())

    // await authAxiosInstance.post('/auth/logout', { deviceId: thunkApi.getState().userSlice?.deviceId });

    authAxiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.defaults.headers.common.Authorization = null;
		localStorage.removeItem('access-token');
    Tracking.send('logout', { user: null })
    return;
  } catch (error) {
    return error.response?.data?.message;
  }
});

export const deleteUser = createAsyncThunk('userSlice/deleteUser', async (body, thunkApi) => {
  try {
    thunkApi.dispatch(resetImageEditor())
    thunkApi.dispatch(resetForm())

    await authAxiosInstance.delete(`/api/users/${
			thunkApi.getState().userSlice.user?._id
		}`, {
			params: {
				body: JSON.stringify(body)
			}
		});

    authAxiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.defaults.headers.common.Authorization = null;
		localStorage.removeItem('access-token');
    Tracking.send('logout', { user: null })
    return;
  } catch (error) {
    return error.response?.data?.message;
  }
});

export const loginUser = createAsyncThunk('userSlice/loginUser', async (loginData, thunkApi) => {
  try {

    authAxiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.defaults.headers.common.Authorization = null;
    localStorage.removeItem('access-token');


    const { data } = await authAxiosInstance.post(`/auth/login`, loginData);

    Tracking.send('login', { user: data.user })
    Tracking.send('authenticate', { user: data.user })

		localStorage.setItem('access-token', `Bearer ${data.accessToken}`);
    return {
      user: data.user,
      refreshToken: data.refreshToken,
      accessToken: data.accessToken,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({
      response: {
        data: error.response?.data,
        status_code: error.response?.status_code,
      }
    });
  }
});

export const registerUser = createAsyncThunk('userSlice/registerUser', async (registerData, { rejectWithValue }) => {
  try {
    authAxiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.defaults.headers.common.Authorization = null;

    const { data } = await authAxiosInstance.post(`/auth/register`, registerData);
    Tracking.send('register', { user: data.user, token: data })
    return data
  } catch (error) {
    return rejectWithValue({ message: error.response?.data?.msg, response: {
      details: error.response?.data?.details,
      detail: error.response?.data?.detail,
			data: {
				...(error?.response?.data || {})
			},
    } });
  }

});

export const verifyUserToken = createAsyncThunk('userSlice/verify-token', async (_, { rejectWithValue }) => {
  try {
    const { data } = await authAxiosInstance.get(`/auth/verify-token`);
    return data
  } catch (error) {
    return rejectWithValue({ error: "Failed to validate token"});
  }

});

export const confirmUserEmail = createAsyncThunk('userSlice/confirmUserEmail', async (payload, thunkApi) => {
  try {
    authAxiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.defaults.headers.common.Authorization = null;

    const { data } = await authAxiosInstance.post(
      `/auth/confirm-email/`,
      {},
      {
				IGNORE_AUTH: true,
        headers: {
          Authorization: `Bearer ${payload.token}`,
        }
      }
    );
    return {
      ...data
    };
  } catch (error) {
    return thunkApi.rejectWithValue({
      response: {
        data: error.response?.data,
        status_code: error.response?.status_code,
      }
    });
  }
});

//////////////
// Stripe
//////////////
export const checkoutCredits = createAsyncThunk('userSlice/checkoutCredits', async (payload, thunkApi) => {
  try {
    const { data } = await authAxiosInstance.post(`/payment/checkout/`, payload);
    Tracking.update({ user: thunkApi.getState().userSlice.user }, { ...data })
    // Commerce.track('', {
    //   ...data
    // })
    return data;
  } catch(e) {
    return thunkApi.rejectWithValue({
      response: {
        data: e.response?.data,
        status_code: e.response?.status_code,
      },
    });
  }
});

export const checkoutSubscription = createAsyncThunk('userSlice/checkoutSubscription', async (payload, thunkApi) => {
  try {

    const { data } = await authAxiosInstance.post("/payment/buy-subscription/", payload);
    console.log("[checkoutSubscription] response:", { data })
    Tracking.update({ user: thunkApi.getState().userSlice.user }, { ...data })

    return data;
  } catch(e) {
    return thunkApi.rejectWithValue({
      response: {
        data: e.response?.data,
        status_code: e.response?.status_code
      }
    });
  }
});

export const downgradeSubscription = createAsyncThunk('userSlice/downgradeSubscription', async (payload, thunkApi) => {
  try {

    const { data } = await authAxiosInstance.post("/payment/downgrade-subscription/", payload);
    Tracking.update({ user: thunkApi.getState().userSlice.user }, { ...data })

    return data;
  } catch(e) {
    return thunkApi.rejectWithValue({
      response: {
        data: e.response?.data,
        status_code: e.response?.status_code
      }
    });
  }
});

export const upgradeSubscription = createAsyncThunk('userSlice/upgradeSubscription', async (payload, thunkApi) => {
  try {

    const { data } = await authAxiosInstance.post("/payment/upgrade-subscription/", payload);
    Tracking.update({ user: thunkApi.getState().userSlice.user }, { ...data })

    return data;
  } catch(e) {
    return thunkApi.rejectWithValue({
      response: {
        data: e.response?.data,
        status_code: e.response?.status_code
      }
    });
  }
});

export const cancelSubscription = createAsyncThunk('userSlice/cancelSubscription', async (payload, thunkApi) => {
  try {
    const { data } = await authAxiosInstance.delete(`${apiHost}/payment/cancel-subscription/`);

    return data;
  } catch(e) {
    return thunkApi.rejectWithValue({
      response: {
        data: e.response?.data,
        status_code: e.response?.status_code,
      },
    });
  }
});

export const getStripeManagementUrl = createAsyncThunk(
  'userSlice/get-stripe-management-portal',
  async (payload, thunkApi) => {
    try {
			const response = await authAxiosInstance.post("/api/payment/manage-subscription/", payload);

      return response.data
    } catch(e) {
      console.log(e)
      return thunkApi.rejectWithValue({ error: e.response?.data });
    }
  }
)

//////////////
// Questoinaire
//////////////
export const loadQuestionnaireAnswers = createAsyncThunk(
  'userSlice/load-questionnaire-answers',
  async (payload, thunkApi) => {
    try {
			const response = await authAxiosInstance.get(`/api/questionnaire?user=${thunkApi.getState().userSlice.user._id}`);

      return response.data
    } catch(e) {
      console.log(e)
      return thunkApi.rejectWithValue({ error: e.response?.data });
    }
  }
)

export const submitQuestionnaireAnswers = createAsyncThunk(
  'userSlice/submit-questionnaire-answers',
  async (payload, thunkApi) => {
    try {
			const response = await authAxiosInstance.post(`/api/questionnaire`, {
        ...payload,
        user: thunkApi.getState().userSlice.user?.id
      });

      return response.data
    } catch(e) {
      console.log(e)
      return thunkApi.rejectWithValue({ error: e.response?.data });
    }
  }
)

//////////////
// Sumoling
//////////////
export const activateSumolingLicense = createAsyncThunk(
  'userSlice/sumoling-activate',
  async (payload, thunkApi) => {
    try {
			const response = await authAxiosInstance.post(`/api/appsumo/activate/`, { });

      return response.data
    } catch(e) {
      console.log(e)
      return thunkApi.rejectWithValue({ error: e.response?.data });
    }
  }
)

export const getSumolingLicense = createAsyncThunk(
  'userSlice/sumoling-retrieve',
  async (payload, thunkApi) => {
    try {
			const response = await authAxiosInstance.get(`/api/appsumo/plan/`, );

      return response.data
    } catch(e) {
      console.log(e)
      return thunkApi.rejectWithValue({ error: e.response?.data });
    }
  }
)


export default userSlice.reducer;
