import { Helmet } from 'react-helmet';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

import UserLayout from '../layouts/UserLayout';
import AppLayout from '../layouts/AppLayout';
import CustomToogleSwitch from '../components/base/ToogleSwitch';
import { ReactComponent as TrashIconV4 } from '../assets/TrashIconWhiteV4.svg';
import { ReactComponent as UploadImageIconV4 } from '../assets/UploadImageIconV4.svg';
import ImageComparison from '../components/ImageComparison';
import SpinnerLoader from '../components/base/SpinnerLoader';
import { ReactComponent as CloudUploadImageIcon } from '../assets/CloudUploadImageIcon.svg';
import Tooltip from '../components/Tooltip';
import {
  upscaleImageTool,
  updateAfterUpscaleImage,
} from '../reducers/formReducer';

import { ReactComponent as UpscaleIcon } from '../assets/upscale-icon-white.svg';
import { ReactComponent as UpscaleUploadImageIcon } from '../assets/UpscaleUploadImageIcon.svg';
import { ReactComponent as UpscaleLoadingIcon } from '../assets/upscale-icon.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';
import { ReactComponent as Download } from '../assets/DownloadIcon.svg';
// import { ReactComponent as PenIcon } from '../assets/MagicPen.svg';
import { RadioGroup } from '@headlessui/react'
import Dropdown from '../components/base/Dropdown';
import { V4StyledSlider as ValuedSlider } from '../components/base/ValuedSlider';
import convertUrlToBase64, { convertImageToBase64 } from '../helpers/convertUrlToBase64';
import Accordion from '../components/base/Accordion'
import VectorizeResult from '../components/VectorizeResult';
import { useSearchParams } from 'react-router-dom';


const UPSCALE_MODEL = [
	{
		name: 'ultra Realistic',
		value: 'epicrealism_naturalSinRC1VAE.safetensors [84d76a0328]',
	},
	{
		name: 'Balanced',
		value: 'juggernaut_reborn.safetensors [338b85bc4f]',
	},
	{
		name: 'illustration and Anime',
		value: 'flat2DAnimerge_v45Sharp.safetensors',
	},
]
const CREATIVITY = [
	{ value: 0.2, name: '1' },
	{ value: 0.3, name: '2' },
	{ value: 0.35, name: '3' },
	{ value: 0.4, name: '4' },
	{ value: 0.45, name: '5' },
	{ value: 0.48, name: '6' },
	{ value: 0.52, name: '7' },
	{ value: 0.56, name: '8' },
	{ value: 0.6, name: '9' },
	{ value: 0.65, name: '10' },
]
const DYNAMIC = [
	{ value: 3, name: '1' },
	{ value: 4, name: '2' },
	{ value: 5, name: '3' },
	{ value: 6, name: '4' },
	{ value: 7, name: '5' },
	{ value: 8, name: '6' },
	{ value: 9, name: '7' },
]
const RESEMBLENCE = [
	{ value: 0.3, name: '1' },
	{ value: 0.6, name: '2' },
	{ value: 1.2, name: '3' },
	{ value: 2, name: '4' },
	{ value: 3, name: '5' },
]
const FRACTALITY = [
	{ value: 1, name: '1' },
	{ value: 2, name: '2' },
	{ value: 3, name: '3' },
	{ value: 4, name: '4' },
	{ value: 5, name: '5' },
	{ value: 6, name: '6' },
	{ value: 7, name: '7' },
]
const SHARPNESS = [
	{ value: 0, name: '1' },
	{ value: 0.5, name: '2' },
	{ value: 1, name: '3' },
	{ value: 1.4, name: '4' },
	{ value: 1.8, name: '5' },
]

const STYLES = [
	{ value: 'realistic', name: 'Realistic' },
	{ value: '2d-illustration', name: '2D Illustration' },
	{ value: '3d-art', name: '3D Art' },
	{ value: 'fine-art', name: 'Fine Art' },
	{ value: 'default', name: 'default' },
];

const Upscale = (props) => {
	const user = useSelector((state) => state.userSlice.user);
	const afterImage = useSelector((state) =>
		state.form.afterUpscaleImage
		? state.form.afterUpscaleImage.startsWith('https') ? 
		state.form.afterUpscaleImage
		: `data:image/png;base64,${state.form.afterUpscaleImage}` : null
	);

	const [generationId, setGenerationId] = useState();
  const isUpscalingTool = useSelector((state) => state.form.isUpscalingTool);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const dispatch = useDispatch();
  const uploadRef = useRef();

  const [beforeImage, setBeforeImage] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showUpscalerSpinner, setShowUpscalerSpinner] = useState(false);
  // const [isScaleChecked, setIsScaleChecked] = useState(false);
  const isImageUploaded = !!beforeImage;

  const [sliderValue, setSlideValue] = useState(2);

	const [state, setState] = useState({
		prompt: '',
		negativePrompt: '',

		creativity: 0.35,
		dynamic: 6,

		resemblence: 0.6,

		fractality: 3,

		sharpness: 1,

		scale_factor: 2,

		sd_model: 'juggernaut_reborn.safetensors [338b85bc4f]',
		style: 'default',
	})

	const [doneUpscale, setDoneUpscale] = useState(false)
  // const doneUpscale = useMemo(() => {
  //   return afterImage && beforeImage;
  // }, [afterImage, beforeImage]);
	//
	const handleFileUpload = (file) => {
		const url = URL.createObjectURL(file)

		const img = new Image();

		img.onload = function() {
			console.log(img.width, img.height)
			if (img.width > 1024 || img.height > 1024) {
				setState((s) => ({ ...s, downscaling: true }))
			} else {
				setState((s) => ({ ...s, downscaling: false }))
			}
			setFileName(file.name)

			var canvas = document.createElement('CANVAS');
			var ctx = canvas.getContext('2d');
			var dataURL;
			canvas.height = img.height;
			canvas.width = img.width;
			ctx.drawImage(this, 0, 0, img.width, img.height);
			dataURL = canvas.toDataURL('png');

			setBeforeImage(dataURL)
		}

		img.src = url
	}

  const handleImageUpload = (event) => {
		const file = event.target.files[0];

		if (file && file.type.startsWith('image/')) {
			handleFileUpload(file)
		}
	}

  const handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file && file.type.startsWith('image/')) {
			handleFileUpload(file)
		}
	}

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleRemoveImage = useCallback(() => {
    setBeforeImage(null);
    setFileName(null);
  }, [setBeforeImage, setFileName]);

  const handleUpscale = async (e) => {
		setDoneUpscale(false)
    e.stopPropagation();
    setShowUpscalerSpinner(true);
    try {
      await dispatch(
        upscaleImageTool({
					...state,
					endpoint: 'v4/upscale',
					inputImage: beforeImage,
        })
      ).unwrap();
			setDoneUpscale(true)
    } catch (e) {
      console.log(e);

      toast(e?.error?.detail || 'Failed to upscale image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowUpscalerSpinner(false);
  };

  const handleBack = useCallback(() => {
		setDoneUpscale(false)
    dispatch(updateAfterUpscaleImage());
    // setBeforeImage(null);
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateAfterUpscaleImage());
  }, []);

	const [isDownloading, setIsDownloading] = useState(false)
  const handleDownload = async () => {
		setIsDownloading(true)
    const link = document.createElement('a');
		if (afterImage.startsWith('http')) {
			link.href = await convertUrlToBase64(afterImage)
		} else {
			link.href = afterImage;
		}
    link.download = `LoDi-${fileName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
		setIsDownloading(false)
  };

  // const onChangeScaleToggleSwitch = () => {
  //   setIsScaleChecked(!isScaleChecked);
  // };

  const handleInputChange = (event, setInput) => {
    setInput(event.target.value);
  };

  const handleZoomChange = (e) => {
    setSlideValue(e.target.value);
  };
	
  const [searchParams] = useSearchParams();
	const [isLoadingImage, setIsLoadingImage] = useState(false)

	const loadUrlImage = async (url) => {
		setIsLoadingImage(true)
		try {
      const base64 = await convertImageToBase64(url, null, null);
			setBeforeImage(base64)
		
		} catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to vectorize image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
		} finally {
			setIsLoadingImage(false)
		}
	}

	useEffect(() => {
		if (searchParams.get('generationId')) {
			setGenerationId(searchParams.get('generationId'))
		}

		console.log("Search params", searchParams)

		if (searchParams.get('imageUrl')) {
			loadUrlImage(searchParams.get('imageUrl'))
		}

		if (searchParams.get('fileName')) {
			setFileName(searchParams.get('fileName'))
			setState((s) => ({ ...s, prompt: searchParams.get('fileName') }))
		}
	}, [searchParams])

  return (
    <AppLayout >
      <Helmet>
        <title>Upscale | Logo Diffusion</title>
      </Helmet>
		<div className="flex justify-between gap-4 grow max-h-full pt-6 pl-2">
			<input
				type='file'
				accept='image/*'
				className='hidden'
				ref={uploadRef}
				onChange={handleImageUpload}
			/>

			<div className='pr-2 bg-app-bg-gray rounded-[10px] 4xl:!h-[calc(100%-55px)] !h-[calc(100%-80px)]'>
				<div
					className={`bg-app-bg-gray h-full rounded-[10px] overflow-x-visible md:w-[370px] md:h-full custom-scroll txt2img-custom-scroll flex flex-col gap-4 p-2 pt-4 pl-6 overflow-y-auto`}
				>
						<button
							className={`flex shrink-0 whitespace-nowrap justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px] cursor-pointer ${
								!isImageUploaded ? 'pointer-events-none opacity-50' : ''
							}`}
							onClick={handleUpscale}
							disabled={!isImageUploaded}
						>
							Upscale{' '}
							<span className='text-[10px] text-[#B7A3F1]'>
								({state.scale_factor === 2 ? 5 : 20}) credits
							</span>
						</button>

						<Accordion title="Input Image" defaultOpen>
							<div className="grid grid-cols-2 gap-4 px-2">
								<div
									className={`flex flex-col aspect-square items-center justify-center ${
										isImageUploaded ? 'outline' : 'outline-dashed'
									} outline-2 outline-offset-2 outline-icon-text-color rounded-[8px]`}
								>
									{isImageUploaded ? (
										<div className='relative group overflow-hidden rounded-[8px]'>
											<>
												{' '}
												<button
													className='group/delete basis-[45%] flex-grow shrink-0 !outline-none absolute -top-3 right-[3px] gap-1 mt-4'
													onClick={(e) => setBeforeImage(null)}
												>
													<TrashIconV4 className='w-[20px] h-[20px] group-hover/delete:[&_path]:fill-app-green' />
												</button>
												<img
													className='min-h-full min-w-full rounded-[8px]'
													src={beforeImage}
													alt='to upload'
												/>
											</>
										</div>
									) : (
										<button
											className='flex w-full h-full items-center justify-center'
											onClick={(e) => uploadRef.current?.click()}
										>
											<UploadImageIconV4 className='w-auto' />
										</button>
									)}
								</div>
								<div className='flex flex-col gap-2'>
									<button
										className='flex justify-start gap-1 items-center bg-button-purple p-2 rounded-[4px]'
										onClick={(e) => uploadRef.current?.click()}
									>
										<CloudUploadImageIcon className='w-4 h-4' />
										<p className='text-white text-center text-[11px] font-semibold'>
											Upload image
										</p>
									</button>

									<Dropdown
										values={[2, 4].map((v) =>(
											{
												value: v,
												name: (<div className="inline-flex gap-2 items-center text-xs my-auto">
													<UpscaleIcon className='w-3 h-3 shrink-0' />
													Upscale by {v}x
												</div>)
											}
										))}
										appliedClasses={{
											root: 'w-full flex-col items-stretch gap-1',
											buttonCnt: '!max-w-[100%]',
											button:
												'!rounded-[4px] border-none bg-app-bg-gray p-2 text-icon-text-color text-sm',
											buttonText: 'inline-flex gap-1 text-xs items-center',
										}}
										value={state.scale_factor}
										onChange={(v) => {
											setState((s) => ({...s, scale_factor: v}));
										}}
										// label="Optimized For"
									/>
								</div>
							</div>
						</Accordion>


						<Accordion title="Upscale Settings" defaultOpen>
							<Dropdown
								values={STYLES}
								appliedClasses={{
									root: 'w-full flex-col items-stretch gap-1',
									buttonCnt: '!max-w-[100%]',
									button:
										'!rounded-[4px] border-none bg-app-bg-gray h-[40px] text-icon-text-color text-sm',
								}}
								value={state.style}
								onChange={(v) => setState((s) => ({ ...s, style: v }))}
								label="Optimized For"
							/>
							<ValuedSlider 
								label="Creativity"
								title='Enables the AI to "imagine" extra elements, enhancing the realism but at the risk of straying from the original image. However, higher settings may produce quite bizarre outcomes, default setting is (3).'
								values={CREATIVITY}
								value={state.creativity}
								onChange={(v) => setState((s) => ({ ...s, creativity: v }))}
								showValue
							/>
							<label className='flex flex-col gap-1 '>
								<span className="text-gray-label font-poppins font-bold text-xs">
									Upscale Prompt
								</span>

								<textarea
									rows={3}
									className='rounded-[4px] font-poppins text-icon-text-color text-sm bg-app-bg-gray p-2 border-none placeholder:text-icon-text-color placeholder:text-sm custom-scroll '
									type='text'
									placeholder='Describe your Image'
									value={state.prompt}
									onChange={(v) => setState((s) => ({ ...s, prompt: v.target.value }))}
								/>
							</label>
						</Accordion>

						<Accordion title="Advanced Settings">
							<label className='flex flex-col gap-1 '>
								<span className="text-gray-label font-poppins font-bold text-xs">
										Negative prompt
								</span>

								<input
									className='rounded-[4px] h-[40px] font-poppins text-icon-text-color text-sm bg-app-bg-gray p-2 border-none placeholder:text-icon-text-color placeholder:text-sm'
									type='text'
									placeholder='Describe What to Avoid'
									value={state.negativePrompt}
									onChange={(v) => setState((s) => ({ ...s, negativePrompt: v.target.value }))}
								/>
							</label>

							<Dropdown
								values={UPSCALE_MODEL}
								appliedClasses={{
									root: 'w-full flex-col items-stretch gap-1',
									buttonCnt: '!max-w-[100%]',
									button:
										'!rounded-[4px] border-none bg-app-bg-gray h-[40px] !text-icon-text-color',
								}}
								value={state.sd_model}
								onChange={(v) => setState((s) => ({ ...s, sd_model: v }))}
								label="Upscale model"
							/>

							<ValuedSlider 
								label="Resemblence"
								title='a higher number will enhance the similarity to the original image, but high settings may lead to blotches or a less clean appearance. On the other hand, reducing the value allows greater creative liberty for the A.I at the expense of deviating more from the original image. default setting is (2).'
								values={RESEMBLENCE}
								value={state.resemblence}
								onChange={(v) => setState((s) => ({ ...s, resemblence: v }))}
								showValue
							/>
							<ValuedSlider 
								label="Sharpness"
								title="Adds a final layer of sharpness to the upscaled image, No sharpness is applied at (1), and high sharpness is applied at 5, default setting is (3)."
								values={SHARPNESS}
								value={state.sharpness}
								onChange={(v) => setState((s) => ({ ...s, sharpness: v }))}
								showValue
							/>
							<ValuedSlider 
								label="HDR"
								title="Enhances clarity and detail, but very high settings may produce results that seem to have artifacts or blotches. default setting is (4)."
								values={DYNAMIC}
								value={state.dynamic}
								onChange={(v) => setState((s) => ({ ...s, dynamic: v }))}
								showValue
							/>
							<ValuedSlider 
								label="Fractality"
								title={
									`
										Adjust the intensity of your prompt and detail per square pixel:
										lower Fractality: simpler detail, generally leading to fewer errors.
										Higher Fractality: intensifies your prompt in progressively smaller sections of your entire image. For instance, if your image is a bird and you use "A photograph of a bird" as your prompt with a high Fractality setting, smaller bird-like features might appear within the primary bird . It's somewhat strange, yet occasionally beneficial for artistic reasons. default setting is (3).
									`
								}
								values={FRACTALITY}
								value={state.fractality}
								onChange={(v) => setState((s) => ({ ...s, fractality: v }))}
								showValue
							/>
						</Accordion>


				</div>
			</div>
      {!isUpscalingTool && !doneUpscale ? (
        <div className='flex flex-col gap-4 items-center w-full'>
          {!beforeImage && (
						<>
							<UpscaleIcon className='w-12 h-12' />
							<h1 className='text-white text-[52px] text-center font-bold uppercase'>
								Upscale images
							</h1>
							<p className='text-base text-app-green '>
								Upscale your images in seconds with logo Diffusion
							</p>
						</>
					)}
          {!beforeImage ? (
            <div className='flex items-center justify-center bg-app-bg-gray h-[240px] w-[80%] mt-4 rounded-[8px] p-4 relative'>
              <div
                className='w-full h-full border-2 border-[#30303E] border-dashed rounded flex items-center justify-center'
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <button
                  type='button'
                  className='w-full flex flex-col items-center'
                  onClick={() => uploadRef.current.click()}
                >
                  <UpscaleUploadImageIcon className='w-8 h-8 mb-4' />
                  <p className='text-white text-base'>
                    Click to upload your image or drag and drop it here
                  </p>
                </button>
              </div>

							<SpinnerLoader isLoading={isLoadingImage} />
            </div>
          ) : (
            <div className='flex items-center justify-center bg-app-bg-gray max-h-[calc(100%-150px)] max-w-[65%] mt-4 rounded-[36px] p-4'>
              <div className='h-full border-2 border-[#30303E] border-dashed rounded-[28px] flex items-center justify-center'>
                <div className='flex flex-col h-full w-full p-4 rounded-[20px]'>
                  <img
                    src={beforeImage}
                    alt='uploaded'
                    className='h-[calc(100%-48px)] rounded-t-[14px]'
                  />
                  <div className='flex items-center h-[48px] justify-between whitespace-nowrap w-full bg-[#E7E7E7] rounded-b-[14px] px-3 py-2'>
                    <p className='text-ellipsis overflow-hidden'>{fileName}</p>
                    <button
                      onClick={handleRemoveImage}
                      className='text-red-500 rounded text-2xl'
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
					<div className="flex flex-col items-center max-w-11/12 w-[540px] gap-6 pb-10">

							<button
								className={`flex shrink-0 whitespace-nowrap justify-center items-center h-[40px] px-8 text-[14px] font-semibold text-white bg-button-purple rounded-[12px] cursor-pointer ${
									!isImageUploaded ? 'pointer-events-none opacity-50' : ''
								}`}
								onClick={handleUpscale}
								disabled={!isImageUploaded}
							>
								Upscale{' '}
								<span className='text-[10px] text-[#B7A3F1]'>
									({state.scale_factor === 2 ? 5 : 20}) credits
								</span>
							</button>
					</div>
        </div>
      ) : (
        <div className='flex flex-col items-center px-5 py-2 w-full h-full'>
          {!doneUpscale ? (
            <div className='relative flex items-center justify-center bg-app-bg-gray h-auto max-h-[77vh] w-auto max-w-[90vw] mt-[21px] rounded-[20px] border-[4.3px] border-[#30303E] border-dashed'>
              <img
                src={beforeImage}
                alt='uploaded'
                className='w-full h-full object-cover rounded-[20px]'
              />
              <SpinnerLoader
                classNames={{
                  backdrop: 'z-30 !rounded-[20px]',
                }}
                isLoading={showUpscalerSpinner}
                icon={<UpscaleLoadingIcon />}
              />
            </div>
          ) : (
            <div className='relative h-full max-h-[85vh] w-auto max-w-[90vw]'>
              <ImageComparison
                beforeImage={beforeImage}
                afterImage={afterImage}
								handleDownload={handleDownload}
								handleRefresh={handleUpscale}
								handleBack={handleBack}
              />
						{/* <VectorizeResult beforeImage={beforeImage} afterImage={afterImage} /> */}
            </div>
          )}
						
        </div>

				)}
		</div>
    </AppLayout>
  );
};

export default Upscale;
