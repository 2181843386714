import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { flushSync } from 'react-dom';
import { convertToHtml, setImportance } from '../../helpers/importanceHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  removePromptHelper,
  updateShowStyleSelector,
} from '../../reducers/formReducer';
import allContentStyleHelpers from '../../data/promptHelpers';
import STYLES from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';

const TextAreaWithImportance = (props) => {
  const {
    value,
    placeholder,
    updateValue,
    innerHtml,
    textAreaRef,
    importanceRef,
  } = props;

  const dispatch = useDispatch();
  const contentStyleHelpers = useSelector(
    (state) => state.form.payload.promptHelpers
  );
  const handleContentStyleHelperRemoved = (value) => {
    dispatch(removePromptHelper(value));
  };

  const contentStyle = useSelector((state) => state.form.payload.contentStyle);
  const method = useSelector((state) => state.form.method);

  const { mode } = useGenerationEndpoint();

  const update = (e) => {
    const target = e.relatedTarget;
    // console.log("Update, checking target", target)
    if (importanceRef.current.contains(target)) {
      console.log('importance selection, skipping update');
      return;
    }

    const calculateTextImportance = (text) => {
      let result = [''];
      let parantheses_count = 0;
      text.split('').forEach((element) => {
        if (parantheses_count === 0 && /^\s$/g.test(element)) {
          result.push('');
          return;
        }

        if (element === '(') parantheses_count++;
        if (element === ')') parantheses_count--;

        result[result.length - 1] += element;
      });

      return result
        .filter((item) => !!item.replace(/[\s\n]/g, ''))
        .map(
          (item, ind) => item.trim()
          // setImportance(item.trim(), ind === 0 ? importance : 0)
        );
    };

    // flushSync(() => {
    updateValue(() => {
      const element = textAreaRef.current;
      let newList = [];
      let leftover_text = '';
      for (let val of element.childNodes) {
        let importance = 0;
        if (!(val instanceof Text) && !(val instanceof String))
          importance = Number(val.dataset?.importance);

        if (importance > 0) {
          newList = [
            ...newList,
            ...calculateTextImportance(leftover_text),
            ...[val.innerText ?? val.textContent]
              // .split(" ")
              .filter((item) => !!item.replace(/[\s\n]/g, ''))
              .map((item, ind) =>
                // item.trim()
                setImportance(item.trim(), ind === 0 ? importance : 0)
              ),
          ];
          leftover_text = '';
          continue;
        }
        leftover_text += val.innerText ?? val.textContent;
      }
      return [...newList, ...calculateTextImportance(leftover_text)].filter(
        (item) => !!item
      );
    });
    // })
    // updateValue(newList);
  };

  const handleRemove = (e) => {
    let target = e.target;

    if (target.tagName === 'PATH') target = target.parent;

    if (!target.dataset || target.dataset.action !== 'unimportant') return;

    flushSync(() => {
      update(e);
    });

    if (target.dataset && target.dataset.action === 'unimportant') {
      const ind = Number(target.dataset.pos);
      updateValue((val) => {
        return [
          ...val.slice(0, ind),
          setImportance(val[ind], 0),
          ...val.slice(ind + 1),
        ];
      });
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const raw = window.event.clipboardData.getData('text/plain');

    let result = [''];
    let parantheses_count = 0;
    raw.split('').forEach((element) => {
      if (parantheses_count === 0 && /^\s$/g.test(element)) {
        result.push('');
        return;
      }

      if (element === '(') parantheses_count++;
      if (element === ')') parantheses_count--;

      result[result.length - 1] += element;
    });

    document.execCommand('insertHTML', false, convertToHtml(result));
  };

  return (
    <>
      <div className='relative flex-grow group' onSelect={(e) => {}}>
        {value.filter((item) => !!item).length === 0 && (
          <div className='text-gray-600 group-focus-within:hidden leading-6'>
            {placeholder}
          </div>
        )}
        <div
          // innerRef={textAreaRef}
          ref={textAreaRef}
          className='focus:outline-0 overflow-y-auto z-[5] absolute inset-1 custom-scroll pb-4'
          onPaste={handlePaste}
          contentEditable='true'
          onBlur={(e) => flushSync(() => update(e))}
          onClick={handleRemove}
          // onInput={update}
          // html={innerHtml}
          // onChange={(e) => setInnerHtml(e.target.value)}
          onChange={update}
          // onInput={update}
          dangerouslySetInnerHTML={{ __html: innerHtml }}
        />

        <div className='absolute bottom-0 left-1 right-2 flex gap-2 z-10 overflow-x-auto custom-scroll pointer-events-none'>
          {contentStyleHelpers?.map((helper, i) => (
            <span
              className='flex justify-between items-center text-[0.6rem] 4xl:text-[13px] font-bold text-tag-text bg-app-green rounded-full pl-1 pr-1 relative mb-0 gap-1'
              key={`content-helper-${i}`}
            >
              #
              {allContentStyleHelpers.find((item) => item.slug === helper).name}
              <div
                className='flex font-bold text-[0.6rem] cursor-pointer items-center justify-center hover:text-white'
                onClick={(e) => handleContentStyleHelperRemoved(helper)}
              >
                x
              </div>
            </span>
          ))}
          {mode !== 'v3.0' &&
            mode !== 'v4.0' &&
            contentStyle &&
            contentStyle !== 'none' && (
              <button
                className='flex justify-between items-center text-[0.6rem] 4xl:text-[13px] font-bold text-tag-text bg-app-green rounded-full pl-1 pr-1 relative mb-0 gap-1 pointer-events-auto'
                type='button'
                onClick={() => dispatch(updateShowStyleSelector(true))}
              >
                {STYLES[method].find((item) => item.id === contentStyle)?.name}
              </button>
            )}
        </div>
      </div>
    </>
  );
};
export default TextAreaWithImportance;
