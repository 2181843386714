import { Dialog, Transition } from "@headlessui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Carousel from "../base/Carousel";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import { ReactComponent as ChevronDown } from "../../assets/ChevronDownLightGray.svg";
import { ReactComponent as OtherIcon } from "../../assets/questionnaire/other.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/CheckmarkIconGray.svg";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { QuestionnairePages } from "../../data/questionnaire";
import {
  loadQuestionnaireAnswers,
  submitQuestionnaireAnswers,
  updateShowQuestionnaireModal,
} from "../../reducers/userReducer";

const QuestionnaireModal = (props) => {
  const user = useSelector((state) => state.userSlice.user);
  const [loaded, setLoaded] = useState(false);

  const open = useSelector((state) => state.userSlice.showQuestionnaireModal);
  const [index, setIndex] = useState(0);
  const [values, setValues] = useState({});
  const [error, setError] = useState();
  const [hideModal, setHideModal] = useState(
    localStorage.getItem("hideQuestionnaireModal") === "true"
  );
  const inputRef = useRef();

  const currentQuestion = useMemo(() => QuestionnairePages[index], [index]);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(updateShowQuestionnaireModal(false));
    if (hideModal) localStorage.setItem("hideQuestionnaireModal", "true");
  };

  const loadPreviousAnswers = async () => {
    try {
      await dispatch(loadQuestionnaireAnswers()).unwrap();
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  const next = async () => {
    if (!values[currentQuestion.key]) {
      setError("Please select an option to continue.");
      return;
    }
    setError();
    let res = {
      ...values,
    };
    if (res[currentQuestion.key] === "-1")
      res[currentQuestion.key] = inputRef.current.value;
    if (index + 1 < QuestionnairePages.length) {
      setIndex((ind) => ind + 1);
      // setInputVal('');
      setValues(res);
      let nextQuestion = QuestionnairePages[index + 1];
      let nextAnswer = res[nextQuestion.key];
      if (
        nextAnswer &&
        nextQuestion.options.map((item) => item.title).indexOf(nextAnswer) < 0
      ) {
        setValues({
          ...res,
          [nextQuestion.key]: "-1",
        });

        inputRef.current.value = nextAnswer;
      } else {
        setValues(res);
        inputRef.current.value = "";
      }
    } else {
      try {
        await dispatch(
          submitQuestionnaireAnswers({
            ...res,
          })
        ).unwrap();
        onClose();
      } catch (e) {
        console.log(e);
        toast("Failed to submit the answers", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 2000,
        });
      }
    }
  };

  const prev = async () => {
    if (user) setError();
    let res = {
      ...values,
    };
    if (res[currentQuestion.key] === "-1")
      res[currentQuestion.key] = inputRef.current.value;
    if (index > 0) {
      setIndex((ind) => ind - 1);
      // setInputVal('');
      let previousQuestion = QuestionnairePages[index - 1];
      let previousAnswer = res[previousQuestion.key];
      if (
        previousAnswer &&
        previousQuestion.options
          .map((item) => item.title)
          .indexOf(previousAnswer) < 0
      ) {
        setValues({
          ...res,
          [previousQuestion.key]: "-1",
        });

        inputRef.current.value = previousAnswer;
      } else {
        setValues(res);
        inputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    if (
      user &&
      !loaded &&
      localStorage.getItem("hideQuestionnaireModal") !== "true"
    )
      loadPreviousAnswers();
  }, [user, loaded]);

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog open={open} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto custom-scroll">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-[700px] max-w-11/12 pt-8 flex flex-col gap-6 justify-around items-center overflow-hidden relative bg-app-black rounded-md"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center"
                  onClick={onClose}
                >
                  <CloseIcon className="w-6 h-6" />
                </button>

                <div className="flex flex-col items-center gap-1">
                  <h1 className="text-white text-2xl font-bold text-center">
                    {currentQuestion.title}
                  </h1>

                  <p className="text-modal-description text-sm text-center w-9/12 ">
                    Your feedback will help us in developing new features and
                    improving logo diffusion
                  </p>
                </div>

                <RadioGroup
                  className="w-[70%] grid grid-cols-2 grid-rows-2 gap-4 [&>div]:aspect-square"
                  value={values[currentQuestion.key] || ""}
                  onChange={(val) => {
                    if (val === "-1") {
                      inputRef.current.focus();
                    }
                    setValues((old) => ({
                      ...old,
                      [currentQuestion.key]: val,
                    }));
                    // console.log('u', inputRef.current, val, inputVal)
                  }}
                >
                  {currentQuestion.options.map((resp) => {
                    const Icon = resp.icon;
                    return (
                      <RadioGroup.Option
                        className={`p-4 flex flex-col items-center justify-center gap-4 rounded-md bg-app-bg-gray ui-checked:outline-[2px] ui-checked:[outline-style:solid] ui-checked:outline-app-green transition-all duration-300 cursor-pointer`}
                        key={resp.title}
                        value={resp.title}
                      >
                        <Icon className="" />
                        <span className="text-white text-base">
                          {resp.title}
                        </span>
                        <span className="w-11/12 text-sm text-modal-description">
                          {resp.subtitle}
                        </span>
                      </RadioGroup.Option>
                    );
                  })}
                  <RadioGroup.Option
                    className={`p-4 flex flex-col items-center justify-center gap-4 rounded-md bg-app-bg-gray ui-checked:outline-[2px] ui-checked:[outline-style:solid] ui-checked:outline-app-green transition-all duration-300 cursor-pointer`}
                    value={"-1"}
                    key="-1"
                  >
                    <OtherIcon className="" />
                    <span className="text-white text-base">Other</span>
                    <textarea
                      rows={3}
                      className="w-11/12 text-sm text-modal-description p-2 rounded-lg !outline-none bg-app-bg-gray placeholder:text-modal-description border-solid border border-text-field-border "
                      placeholder="Please specify."
                      ref={inputRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        setValues((old) => ({
                          ...old,
                          [currentQuestion.key]: "-1",
                        }));
                      }}
                      onFocus={(e) => e.stopPropagation()}
                    />
                  </RadioGroup.Option>
                </RadioGroup>

                <span className="text-red-600">{error}</span>
                <div className="flex flex-row gap-2">
                  <button
                    className="h-12 py-4 pl-3 pr-1 rounded-md group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed"
                    disabled={index <= 0}
                    onClick={prev}
                  >
                    <ChevronDown
                      className={`rotate-90 [&>path]:stroke-back-btn  w-6 h-6 ${
                        values[currentQuestion.key] === undefined
                          ? ""
                          : "group-hover:[&>path]:stroke-app-green"
                      } relative ml-0 mr-1 group-hover:ml-1 group-hover:mr-0 transition-all duration-300`}
                    />
                  </button>
                  <button
                    className="h-12 py-4 pl-3 pr-1 border border-solid border-carousel-button-border bg-app-bg-gray rounded-md group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300 group disabled:cursor-not-allowed"
                    // disabled={values[currentQuestion.key] === undefined}
                    onClick={next}
                  >
                    <span className="text-carousel-next-count mr-1">
                      {index + 1}/{QuestionnairePages.length}
                    </span>

                    <span className="text-white group-disabled:text-carousel-next-count">
                      Next
                    </span>

                    <ChevronDown
                      className={`-rotate-90 [&>path]:stroke-white ${
                        values[currentQuestion.key] === undefined
                          ? ""
                          : "group-hover:[&>path]:stroke-app-green"
                      } relative ml-0 mr-1 group-hover:ml-1 group-hover:mr-0 transition-all duration-300`}
                    />
                  </button>
                </div>

                <div className="bg-app-bg-gray w-full p-4 flex items-center justify-center ">
                  <div className="flex flex-row">
                    <input
                      type="checkbox"
                      id="dont-show"
                      checked={hideModal}
                      onChange={(e) => setHideModal(e.target.checked)}
                      className="appearance-none h-[17px] w-[17px]  rounded-[4px] border-[1.5px] border-solid border-checkmark-border transition-all duration-200 peer"
                    />
                    <CheckmarkIcon className="opacity-0 peer-checked:opacity-100 [&>path]:stroke-checkmark-check absolute rounded-full pointer-events-none my-1 mx-1 transition-all duration-200 w-[9px] h-[9px]" />
                    <label
                      htmlFor="dont-show"
                      className="flex flex-col justify-center px-2 select-none text-xs text-title-white"
                    >
                      Don't show this again
                    </label>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default QuestionnaireModal;
