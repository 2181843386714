
import { Edit, SimpleForm, TextInput, BooleanInput, } from 'react-admin';
import Grid from '@mui/material/Grid';

export const BlockedDomainEdit = () => (
    <Edit>
        <SimpleForm>
          <Grid container spacing={2}>
						<TextInput source="domain" fullWidth />
						<BooleanInput source="blockRegistration" fullWidth />

          </Grid>
        </SimpleForm>
    </Edit>
);

export default BlockedDomainEdit;
