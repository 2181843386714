/**
 * @module ImageHandler
 * @fileoverview This module provides the ImageHandler service with methods and logic for downloading raster or vector 
 * versions of images and vectorizing image files. The main entry points are the download and vectorize functions.
 * Additional utility functions, displayErrorToast and createFormattedImageName, are available for displaying error
 * messages and formatting image filenames.
 *
 * @exports {Object} ImageHandler - The primary interface for interacting with this module, containing two functions:
 *                                  1) download: For downloading images as raster (PNG) or vector (SVG) formats.
 *                                  2) vectorize: For vectorizing an image file and returning the result.
 *
 * @example
 * import ImageHandler from './ImageHandler';
 * //* Download raster image
 * ImageHandler.download('raster', image, opts);
 * //* Download vector image
 * ImageHandler.download('vector', image, opts);
 * //* Vectorize image
 * const vectorizedImage = await ImageHandler.vectorize(image, opts);
 */
import Tracking from "./TrackingService";
import apiAxios from "../helpers/axios";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

const displayErrorToast = () => {
  toast("Something went wrong while trying to vectorize, please try again", {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: "dark",
    autoClose: 2000,
  });
};

const createFormattedImageName = (baseName) => {
  const dateTimeString = moment().format("YYYY-MM-DD-HH:mm:ss");
  const uuid = `${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;

  return `${baseName}-${dateTimeString}-${uuid}`;
};

/**
 * Vectorize an image using provided options.
 *
 * @async
 * @function vectorizeImage
 * @param {Object} image - The image to vectorize.
 * @param {Object} [options={}] - The options to use while vectorizing the image.
 * @returns {Object} The vectorized image object.
 * @throws {Error} If there is an error in the request.
 */
async function vectorizeImage(image, options = {}) {
  try {
    const response = await apiAxios.post("/api/vectorize", {
      image: image,
      user: options.user?.id || image.userId,
    });

    Tracking.send("vectorize", { image, options });

    return {
      user: response.data.user,
      data: response.data,
    };
  } catch (error) {
    // displayErrorToast();
    console.log("Vectorize error", error);
    throw error;
  }
}

/**
 * Download an image with its given type (raster or vector) and specified options.
 *
 * @async
 * @function downloadImageWithType
 * @param {string} type - The image type (either "raster" or "vector").
 * @param {Object} image - The image to download.
 * @param {Object} [options={}] - The options to use while downloading the image.
 */
async function downloadImageWithType(type, image, options = {}) {
  const { imageName = "image", name, extensionOveride } = options;
  const imagename = name || createFormattedImageName(`LoDi-${imageName}`) || "image";

  const responseType = type === "raster" ? "blob" : undefined;
  const cacheControlHeader = type === "raster" ? { "Cache-Control": "no-cache" } : undefined;

  const response = await axios.get(image.imageUrl, { responseType, headers: cacheControlHeader });

  const imageDownloadURL = type === "raster" ? URL.createObjectURL(response.data) : image.imageUrl;
  const downloadName = `${imagename}.${extensionOveride || (type === "vector" ? "svg" : "png")}`;

  const link = document.createElement("a");
  link.href = imageDownloadURL;
  link.download = downloadName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  if (type === "raster") {
    URL.revokeObjectURL(imageDownloadURL);
  }
}

// The ImageHandler object provides methods to vectorize and download images.
const ImageHandler = { download: downloadImageWithType, vectorize: vectorizeImage };
export default ImageHandler;