import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useCurrentWorkspace from "../hooks/useCurrrentWorkspace";
import { GENERATION_STATUS } from "../reducers/formReducer";
import { ReactComponent as CloseIcon } from "../assets/CloseEditor.svg";

function GeneratedImagePlaceholder({
  isMain,
  image,
  onClick,
	appliedClasses = {}
}) {
	const { workspace } = useCurrentWorkspace();
	const workspace_classnames = {
		'image-workspace': {
			container: appliedClasses.container + (isMain ? ' aspect-square h-full' : ' w-full'),
			text_preview: appliedClasses.text_preview + (isMain ? ' w-1/2' : ' w-full'),
		},
		'text-workspace': {
			container: '',
			text_preview: '',
		},
	}
  const containerClasses = `${workspace_classnames[workspace].container} border border-dashed border-gray-600 bg-app-bg-gray flex items-center justify-center aspect-square rounded-2xl relative`

  const [timeElapsed, setTimeElapsed] = useState(0);
  const generationStatus = useSelector((state) => state.form.generationStatus);
  const isLoading = useMemo(() => {
		if (image?.status && 
			[GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(image.status) > -1) {
			return true
		}
		return [GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(generationStatus) > -1
	}, [generationStatus, image])
  const showImagePlaceholders = useSelector(state => state.form.showPlaceholders);

  const timer = useRef(null);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setTimeElapsed((t) => t + 1)
      }, 1000)

      timer.current = interval
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
      setTimeElapsed(0);
    }
  }, [isLoading])

  const calculateLoaderPercentage = (timeElapsed) => {
    if (timeElapsed < 1) return 100

    const calculateVal = (n) => [...Array(n)].reduce((a, b, ind) => a + 1 / (ind + 1), 0) 

    const rate = 1 - (calculateVal(timeElapsed + 5) - calculateVal(3)) / (calculateVal(65) - calculateVal(3))

    return rate * 100;
  }

	if ([GENERATION_STATUS.FAILED].indexOf(image?.status) > -1) {
		return (
			<div className="overflow-hidden  border border-dashed border-gray-600 bg-app-bg-gray flex items-center justify-center aspect-square rounded-2xl relative">
				<div className="flex flex-col items-center justify-center z-10 gap-4">
					<CloseIcon className="w-12 h-12"/>
					
					<span className="text-xs text-center text-[#8A8A92]">Failed!</span>
				</div>
			</div>
		)
	}

  if (!showImagePlaceholders && image && image.imageUrl) {
		if (workspace === 'image-workspace') {
			return (
				<div className={`${containerClasses} overflow-hidden relative`}>
					<div
						className="relative cursor-pointer"
						onClick={onClick}
					>
						<img
							src={image.imageUrl}
							alt={image.prompt}
							className="w-full aspect-square rounded-2xl"
						/>
					</div>
					
				</div>
			)
		}
    return (
			<div className="relative group rounded-2xl transition-2 bg-app-bg-gray">
				<div
					className="relative cursor-pointer"
					onClick={onClick}
				>
					<img
						src={image.imageUrl}
						alt={image.prompt}
						className="w-full aspect-square rounded-2xl"
					/>
				</div>
				
			</div>
    )
  }

  return (
    <div className={`${containerClasses} overflow-hidden`}>
      <div
        className="absolute top-0 bottom-0 right-20 left-0 bg-app-search-gray transition-all duration-300 rounded-l-2xl"
        style={{ right: `${calculateLoaderPercentage(timeElapsed)}%` }}
      >

      </div>
      {
        isLoading
          ? (
            <div className="flex flex-col items-center justify-center z-10">
              <div className="loader mb-3"></div>
              <span className="text-xs text-center text-[#8A8A92]">({timeElapsed}s)</span>
              </div>)
          : (
            <p className={`text-gray-600 ${workspace_classnames[workspace].text_preview} p-1 text-center text-[10px] xs:text-xs`}>
              Write your first prompt and click generate, to see results here.
            </p>
          )
      }
    </div>
  )
}

export default GeneratedImagePlaceholder;
