const contentStyleTextHelpers = [{
  slug: 'avoid-text',
  label: 'Avoid text'
}, {
  slug: 'allow-text',
  label: 'Allow text'
}, {
  slug: 'force-text',
  label: 'Force text'
}];

export default contentStyleTextHelpers;