import { Popover, RadioGroup, Transition } from "@headlessui/react";
import { ReactComponent as CaretUp } from "../../assets/caret-up.svg";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { ReactComponent as Method2DIcon } from "../../assets/2d-icon-new.svg";
import { ReactComponent as Method3DIcon } from "../../assets/3d-icon-new.svg";
import { ReactComponent as ModeV20 } from "../../assets/generation/mode-v2.svg";
import { ReactComponent as ModeV11 } from "../../assets/generation/mode-v1.svg";
import { ReactComponent as Method2DCarrotIcon } from "../../assets/generation/gen-style-2d.svg";
import { ReactComponent as Method3DCarrotIcon } from "../../assets/generation/gen-style-3d.svg";
import { ReactComponent as SettingsIcon } from "../../assets/styles/style-config/settings-icon.svg";
import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { ReactComponent as CarrotIcon } from '../../assets/CaretDownIcon.svg';
import { Tooltip } from "react-tippy";
import { useDispatch, useSelector } from "react-redux";
import {
    resetStyleConfig,
  updateContentStyle,
  updateMethod,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
} from "../../reducers/formReducer";
import STYLES, { NoneIcon } from "./content-styles";
import useGenerationEndpoint from "./useGenerationEndpoint";
import BaseTooltip from "../base/BaseTooltip";
import StyleSettings from "./style-config/StyleSettings";
import { STYLE_SETTINGS } from "./style-config/constants";

const StyleSelector = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: "top",
		strategy: 'fixed',
  });

  let tutorialPopper = usePopper(popperEl, tutorialEl, {
    placement: "left",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: tutorialArrowEl,
          padding: -100,
        },
      },
    ],
  });

  const { method, mode, setMode, generationStyle, setGenerationStyle } =
    useGenerationEndpoint();

	const [currentModal, setCurrentModal] = useState('styles');
	const toggleModalMode = () => {
		setCurrentModal((m) => {
			return m === 'styles' && STYLE_SETTINGS[method]?.[style] !== undefined ? 'settings' : 'styles'
		});
	}

	const switchToSettings = () => {
		setCurrentModal((m) => {
			return STYLE_SETTINGS[method]?.[style] !== undefined ? 'settings' : 'styles'
		});
	}
	const switchToStyles = () => {
		setCurrentModal('styles');
	}

  const style = useSelector((state) => state.form.payload.contentStyle);
  const handleStyle = (value) => {
    dispatch(updateContentStyle(value));
		if (STYLE_SETTINGS[method]?.[value] !== undefined) {
			setCurrentModal('settings');
		}
  };

	useEffect(() => {
		if (currentModal === 'settings' && STYLE_SETTINGS[method]?.[style] === undefined) {
			setCurrentModal('styles');
		}
	}, [currentModal, style, method]);

  const isOpen = useSelector((state) => state.form.showStyleSelector);
  useEffect(() => {
    if (isOpen) {
			anchorEl?.click();
			dispatch(updateShowStyleSelector(false));
		}
  }, [isOpen]);

  const handleCloseTutorial = () => {
    dispatch(updateShowStyleSelectorTutorial(false));
  };

  const showTutorial = useSelector(
    (state) => state.form.showStyleSelectorTutorial
  );

	const currentStyle = useMemo(() => STYLES[method].find(item => item.id === style), [method, style]);
	const CurrentStyleIcon = useMemo(() => currentStyle?.icon_preview || currentStyle?.icon || NoneIcon, [currentStyle]);

	const style_config = useSelector((state) => state.form.payload.styleConfig);
	const areAllRandomized = useMemo(() => Object.values(style_config).reduce(
		(prev, cur) => prev && cur.__randomized, true), [style, style_config])
	const randomizeAll = () => {
		dispatch(resetStyleConfig({ use_current: true }));
	}

  return (
    <div className="flex flex-col justify-between py-[0.125rem]">
			<Popover>
				{({ open, close }) => (
					<>
						<Popover.Button
							className="flex flex-row gap-[0.15rem] outline-none"
							ref={setAnchorEl}
						 // onClick={() => switchToSettings()} 
						>

							<button 
								className="group grow relative flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-l-lg bg-overlay-bg w-7 h-7 "
								onClick={() => {
									// dispatch(updateShowStyleSelector(true))
									switchToStyles();
									if (open) {
										setTimeout(() => anchorEl?.click(), 200);
									}
								}}
								type="button"
								ref={setAnchorEl}
							>
								<CurrentStyleIcon className={`w-7 h-7 ${currentStyle?.icon_preview_classes}`} />
							</button>
							<button 
								type="button" 
								className="group grow relative flex justify-center items-center cursor-pointer focus:outline-none overflow-hidden rounded-r-lg"
								onClick={() => {
									switchToSettings();
									if (open) {
										setTimeout(() => anchorEl?.click(), 200);
									}
								}}
							>
								<SettingsIcon className="w-7 h-7 group-hover:[&_.text]:fill-app-green [&_.text]:fill-icon-text-color [&_.bg]:fill-overlay-bg [&_.bg]:[rx:0]" />
							</button>
						</Popover.Button>

						<Transition
							as={React.Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
							{...props}
						>
							<Popover.Panel
								className="border border-solid border-app-search-gray rounded-xl bg-app-bg-gray pb-3 mb-4 flex flex-col gap-3 w-72 h-[500px] relative"
								ref={setPopperEl}
								style={styles.popper}
								{...attributes.popper}
							>

								<div className="flex gap-2 items-center bg-style-bg rounded-xl px-4 py-2 h-14">
									<div className={"w-[40px] h-[40px] [&_img]:max-h-full [&_img]:max-w-full flex items-center justify-center"} >
										<CurrentStyleIcon className={`w-[40px] h-[40px] ${currentStyle?.icon_classes}`} />
									</div>
									<button disabled={STYLE_SETTINGS[method]?.[style] === undefined } className={`grow flex gap-1 items-center ${STYLE_SETTINGS[style] === undefined ? 'cursor-default' : ''}`} type="button" onClick={toggleModalMode}>
										<span className="font-roboto font-bold text-style-text text-base">
											{currentStyle?.name}
										</span>
										<CarrotIcon className={`${STYLE_SETTINGS[method]?.[style] === undefined ? 'opacity-0' : 'opacity-100'} ${currentModal === 'styles' ? 'rotate-180' : 'rotate-0'} transition-all duration-300`}/>
									</button>
									<button 
										role="checkbox" aria-checked={areAllRandomized} type="button"
										onClick={() => randomizeAll()}
										className="group"
									>
										<RandomizeIcon className={` ${areAllRandomized ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/70' : 'group-hover:[&_path]:fill-app-green/30'}`}	/>
					
									</button>
								</div>
								<>
									<div className="flex flex-col gap-2 px-4 grow overflow-y-auto custom-scroll w-full max-w-full">
										<StyleSettings />
									</div>
								</>
								<>
									<div className={`bg-app-bg-gray absolute transition-all duration-500 left-0 top-14 right-0 ${currentModal === 'styles' ? 'bottom-0 ' : 'bottom-full '} overflow-hidden px-2 `}>
										<RadioGroup
											value={style}
											onChange={handleStyle}
											className="grid grid-cols-3 gap-3 [&>div]:text-style-text [&>div]:text-xs max-h-full overflow-x-hidden overflow-y-auto custom-scroll py-4 px-2"
										>
											{STYLES[method].map(({ icon: Icon, ...item }) => {
												if (item.type === 'separator') {
													return (
														<h3 className="font-bold flex flex-row gap-1 items-center text-title-white text-base col-start-1 col-end-4" key={item.id}>
															{item.title}
															{item.description && (<Tooltip title={item.description}>
																<InfoIcon />
															</Tooltip>)}
														</h3>
													);
												}
												return (
													<RadioGroup.Option
														disabled={item.disabled}
														key={item.id}
														value={item.id}
														className="flex flex-col items-center gap-1 relative cursor-pointer ui-disabled:cursor-not-allowed aspect-square rounded-lg bg-style-bg ui-disabled:bg-style-disabled border border-solid border-style-bg ui-checked:border-app-green overflow-hidden"
													>
														<Icon className={`w-[67px] h-[67px] ${item.icon_classes}`} />
														<div className="bg-style-text-bg/70 px-1 text-[0.6rem] [line-height:0.8rem] absolute bottom-0 right-0 left-0 text-center text-style-text">
															{item.name}
														</div>
														{item.beta && (
															<div className="bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]">
																Beta
															</div>
														)}
														{item.soon && (
															<div className="bg-white/10 rounded-[0.2rem] px-1 text-style-soon-badge text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]">
																Soon
															</div>
														)}
													</RadioGroup.Option>
												);
											})}
										</RadioGroup>
									</div>
								</>

								{showTutorial && (
									<div
										className="flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 mr-4 w-60 rounded-md"
										ref={setTutorialEl}
										style={tutorialPopper.styles.popper}
										{...tutorialPopper.attributes.popper}
									>
										<div
											ref={setTutorialArrowEl}
											style={tutorialPopper.styles.arrow}
											{...tutorialPopper.attributes.arrow}
											className="h-3 w-3 inline-block left-full "
										>
											<div className="w-full h-full bg-style-method-enabled -translate-y-10 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r"></div>
										</div>
										<div className="flex items-center justify-start gap-2">
											<span>
												<InfoIcon />
											</span>
											<span className="text-title-white text-sm font-roboto">
												Choose a style first.
											</span>
										</div>
										<div className="font-roboto text-input-color text-xs ">
											Before you can generate your first image, you need to pick a
											logo design style.
										</div>
										<div className="flex items-center justify-end mt-1">
											<button
												className="capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3"
												onClick={handleCloseTutorial}
											>
												Got It
											</button>
										</div>
									</div>
								)}
								
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>


		{/*	<button
						className="rounded-lg text-icon-text-color font-bold text-xs flex flex-row items-center justify-center flex-grow w-full !outline-none"
						ref={setStyleSelectorButtonRef}
					>*/}
						<RadioGroup
							value={generationStyle}
							onChange={(v) => {
								setGenerationStyle(v);
							}}
							onMouseDown={() => {
								switchToStyles();
								setTimeout(() => anchorEl.click(), 300);
							}}
							className="flex flex-row gap-[0.15rem]"
						>
							<RadioGroup.Option
								value={"2d"}
								className={({ active, checked }) =>
									`
									${
										checked
											? "[&_.bg]:fill-app-green  [&_.text]:fill-tag-text"
											: "[&_.bg]:fill-overlay-bg  [&_.text]:fill-icon-text-color "
									}
									grow relative flex cursor-pointer focus:outline-none overflow-hidden rounded-l-md [&_.bg]:[rx:0]`
								}
							>
								<Method2DCarrotIcon className="w-7 h-7" />
							</RadioGroup.Option>
							<RadioGroup.Option
								value={"3d"}
								className={({ active, checked }) =>
									`
									${
										checked
											? "[&_.bg]:fill-app-green  [&_.text]:fill-tag-text "
											: "[&_.bg]:fill-overlay-bg  [&_.text]:fill-icon-text-color "
									}
									grow relative flex cursor-pointer focus:outline-none overflow-hidden rounded-r-md [&_.bg]:[rx:0]`
								}
							>
								<Method3DCarrotIcon className="w-7 h-7" />
							</RadioGroup.Option>
						</RadioGroup>


			</div>
		);
	};

export default StyleSelector;
