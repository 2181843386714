import { useMemo } from "react";
import { useSelector } from "react-redux";
import { APPSUMO_DIMENSOIN_COSTS, DIMENSOIN_COSTS } from "../data/dimensions";
import { IterationsAcceptedValeus } from "../components/redesign/constants";
import useGenerationEndpoint from "../components/input/useGenerationEndpoint";

const useGenerationCost = () => {
  const iterations = useSelector((state) => state.form.payload.imageQuality);
  const dimension = useSelector((state) => state.form.payload.dimension);

  const { mode } = useGenerationEndpoint();
  const imagesToGenerate = useSelector((state) => state.form.payload.imagesToGenerate);

  const user = useSelector((state) => state.userSlice.user);

  const cost = useMemo(() => {
    const dimension_cost = user?.isSumoling ? APPSUMO_DIMENSOIN_COSTS[dimension] : DIMENSOIN_COSTS[dimension];
    const iteration_cost = mode === 'v4.0' ? 1 : IterationsAcceptedValeus[mode].find((item, ind, arr) => {
			if (ind >= arr.length - 1 || arr[ind + 1].value > Number(iterations))
				return true
			return false
		})._cost
    return Math.floor(imagesToGenerate * dimension_cost * iteration_cost)
  }, [imagesToGenerate, iterations, dimension, mode, user])

  return cost

}

export default useGenerationCost;
