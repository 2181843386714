import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as UserIcon } from '../assets/UserIcon.svg';
import { ReactComponent as HistoryIcon } from '../assets/HistoryIcon.svg';
import { ReactComponent as PlansIcon } from '../assets/PlansIcon.svg';
import { ReactComponent as BookmarkIcon } from '../assets/BookmarkIconLightGray.svg';
import { ReactComponent as DocumentationIcon } from '../assets/DocumentationIcon.svg';
import { ReactComponent as TextIcon } from '../assets/Text.svg';
import { ReactComponent as DashboardIcon } from '../assets/dashboard/dashboar-icon.svg';
import { ReactComponent as CanvasIcon } from '../assets/BrushIcon.svg';
import { useEffect } from "react";
import { setIsMobileNavOpen } from "../reducers/appReducer";

function MobileNav(props) {
  const isOpen = useSelector(state => state.appSlice.isMobileNavOpen);

  const generalLinkClasses = 'flex items-center gap-3 pl-4 py-4 border-l-4 text-lg';
  const activeLinkClasses = 'border-app-green text-app-green';
  const inactiveLinkClasses = 'border-transparent text-[#D2D2D3]'

	const location = useLocation();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setIsMobileNavOpen(false));
	}, [location, dispatch]);
  
  return (
    <>
      <div className="h-4 md:hidden shrink-0"/>
      <div className={`${isOpen ? 'right-0' : 'right-[-285px]' } fixed top-[55px] sm:top-[61px] w-[285px] bottom-0 bg-app-bg-gray min-w-[285px] border-l border-app-search-gray z-40 transition-all duration-200 ease-in-out lg:hidden`}>

				<NavLink to="/" className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}>
					<DashboardIcon />
					Dashboard
				</NavLink>

				<NavLink to="/gen/text-workspace" className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}>
					<TextIcon />
					Text to image
				</NavLink>

				<NavLink to="/gen/image-workspace" className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}>
					<CanvasIcon />
					Image to image
				</NavLink>

        <NavLink
          className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}
          to="/profile"
        >
          <UserIcon />
          User profile
        </NavLink>

        {/* <NavLink
          className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}
          to="/profile"
        >
          <ModelIcon />
          Models Gallery
        </NavLink> */}

        <NavLink
          className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}
          to="/history"
        >
          <HistoryIcon />
          History
        </NavLink>

        <NavLink
          className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}
          to="/plans"
        >
          <PlansIcon />
          Plans
        </NavLink>

        <NavLink
          className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}
          to="/bookmarks"
        >
          <BookmarkIcon />
          Bookmarks
        </NavLink>

        <NavLink
          className={(navData) => `${navData.isActive ? activeLinkClasses : inactiveLinkClasses} ${generalLinkClasses}`}
          to="/documentation"
        >
          <DocumentationIcon />
          Documentation
        </NavLink>
      </div>
    </>
  )
}

export default MobileNav;
