import { createListenerMiddleware } from '@reduxjs/toolkit'
import { partialUpdateStatePayload, DEFAULT_STYLE_MAPPINGS, resetStyleConfig, updateShouldInjectColros, updatePrompt, updatePromptForceUpdate } from './formReducer';
import { getValueAndImportance } from '../helpers/importanceHelpers';

const formListener = createListenerMiddleware()

const DenoisingValues = {
	'sdxl-3d': {
		0.99: {
			imageWeight: 0.79,
			controlGuidanceEnd: 0.99,
		},
		0.97: {
			imageWeight: 1,
			controlGuidanceEnd: 0.65,
		},
		0.96: {
			imageWeight: 1.5,
			controlGuidanceEnd: 0.9,
		},
	},
	sdxl: {
		0.99: {
			imageWeight: 0.45,
			controlGuidanceEnd: 0.5,
		},
		0.96: {
			imageWeight: 0.65,
			controlGuidanceEnd: 0.6,
		},
		0.93: {
			imageWeight: 0.9,
			controlGuidanceEnd: 0.9,
		},
	},
}
const WeightValues = {
	details: {
		[false]: {
			'sdxl-3d': {
				0.65: {
					controlGuidanceEnd: 1,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.75: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1.2: {
					controlGuidanceEnd: 0.9,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1.25: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				2: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
			sdxl: {
				0.45: {
					controlGuidanceEnd: 0.5,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.5: {
					controlGuidanceEnd: 0.55,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.65: {
					controlGuidanceEnd: 0.6,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.75: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.9: {
					controlGuidanceEnd: 0.9,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
			'v3/3d': {
				0.65: {
					controlGuidanceEnd: 1,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.75: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1.2: {
					controlGuidanceEnd: 0.9,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1.25: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				2: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
			'v3/2d': {
				0.45: {
					controlGuidanceEnd: 0.5,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.5: {
					controlGuidanceEnd: 0.55,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.65: {
					controlGuidanceEnd: 0.6,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.75: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.9: {
					controlGuidanceEnd: 0.9,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
		},
		[true]: {
			'sdxl-3d': {
				0.45: {
					controlGuidanceEnd: 0.5,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.6: {
					controlGuidanceEnd: 0.8,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.79: {
					controlGuidanceEnd: 0.99,
					denoisingStrength: 0.97,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1: {
					controlGuidanceEnd: 0.65,
					denoisingStrength: 0.97,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1.1: {
					controlGuidanceEnd: 0.99,
					denoisingStrength: 0.94,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
			sdxl: {
				0.25: {
					controlGuidanceEnd: 0.35,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.45: {
					controlGuidanceEnd: 0.5,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.65: {
					controlGuidanceEnd: 0.6,
					denoisingStrength: 0.96,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.78: {
					controlGuidanceEnd: 0.75,
					denoisingStrength: 0.94,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.9: {
					controlGuidanceEnd: 0.9,
					denoisingStrength: 0.93,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
			'v3/3d': {
				0.45: {
					controlGuidanceEnd: 0.5,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.6: {
					controlGuidanceEnd: 0.8,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.79: {
					controlGuidanceEnd: 0.99,
					denoisingStrength: 0.97,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1: {
					controlGuidanceEnd: 0.65,
					denoisingStrength: 0.97,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				1.1: {
					controlGuidanceEnd: 0.99,
					denoisingStrength: 0.94,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
			'v3/2d': {
				0.25: {
					controlGuidanceEnd: 0.35,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.45: {
					controlGuidanceEnd: 0.5,
					denoisingStrength: 0.99,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.65: {
					controlGuidanceEnd: 0.6,
					denoisingStrength: 0.96,
					__default: true,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.78: {
					controlGuidanceEnd: 0.75,
					denoisingStrength: 0.94,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
				0.9: {
					controlGuidanceEnd: 0.9,
					denoisingStrength: 0.93,
					depthWeight: 0,
					depthWeightGuidanceEnd: 0,
				},
			},
		},
	},
	depth: {
		[false]: {
			'sdxl-3d': {
				0.4: {
					controlGuidanceEnd: 0.4,
					depthWeight: 0.35,
					depthWeightGuidanceEnd: 0.4,
				},
				0.6: {
					controlGuidanceEnd: 0.6,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.5,
				},
				0.8: {
					controlGuidanceEnd: 0.7,
					depthWeight: 0.5,
					depthWeightGuidanceEnd: 0.6,
					__default: true,
				},
				0.9: {
					controlGuidanceEnd: 0.9,
					depthWeight: 0.7,
					depthWeightGuidanceEnd: 0.8,
				},
				0.91: {
					controlGuidanceEnd: 0.99,
					depthWeight: 0.9,
					depthWeightGuidanceEnd: 0.94,
				},
			},
			sdxl: {
				0.3: {
					controlGuidanceEnd: 0.35,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.35,
				},
				0.5: {
					controlGuidanceEnd: 0.45,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.45,
				},
				0.65: {
					controlGuidanceEnd: 0.6,
					depthWeight: 0.5,
					depthWeightGuidanceEnd: 0.55,
					__default: true,
				},
				0.75: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0.6,
					depthWeightGuidanceEnd: 0.6,
				},
				0.8: {
					controlGuidanceEnd: 0.7,
					depthWeight: 0.7,
					depthWeightGuidanceEnd: 0.7,
				},
			},
			'v3/3d': {
				0.4: {
					controlGuidanceEnd: 0.4,
					depthWeight: 0.35,
					depthWeightGuidanceEnd: 0.4,
				},
				0.6: {
					controlGuidanceEnd: 0.6,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.5,
				},
				0.8: {
					controlGuidanceEnd: 0.7,
					depthWeight: 0.5,
					depthWeightGuidanceEnd: 0.6,
					__default: true,
				},
				0.9: {
					controlGuidanceEnd: 0.9,
					depthWeight: 0.7,
					depthWeightGuidanceEnd: 0.8,
				},
				0.91: {
					controlGuidanceEnd: 0.99,
					depthWeight: 0.9,
					depthWeightGuidanceEnd: 0.94,
				},
			},
			'v3/2d': {
				0.3: {
					controlGuidanceEnd: 0.35,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.35,
				},
				0.5: {
					controlGuidanceEnd: 0.45,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.45,
				},
				0.65: {
					controlGuidanceEnd: 0.6,
					depthWeight: 0.5,
					depthWeightGuidanceEnd: 0.55,
					__default: true,
				},
				0.75: {
					controlGuidanceEnd: 0.65,
					depthWeight: 0.6,
					depthWeightGuidanceEnd: 0.6,
				},
				0.8: {
					controlGuidanceEnd: 0.7,
					depthWeight: 0.7,
					depthWeightGuidanceEnd: 0.7,
				},
			},
		},
		[true]: {
			'sdxl-3d': {
				0: {
					controlGuidanceEnd: 0,
					depthWeight: 0.2,
					depthWeightGuidanceEnd: 0.2,
					denoisingStrength: 0.99,
				},
				0.2: {
					controlGuidanceEnd: 0.2,
					depthWeight: 0.2,
					depthWeightGuidanceEnd: 0.2,
					denoisingStrength: 0.99,
				},
				0.4: {
					controlGuidanceEnd: 0.4,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.3,
					denoisingStrength: 0.97,
					__default: true,
				},
				0.5: {
					controlGuidanceEnd: 0.5,
					depthWeight: 0.4,
					depthWeightGuidanceEnd: 0.4,
					denoisingStrength: 0.94,
				},
				0.6: {
					controlGuidanceEnd: 0.6,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.5,
					denoisingStrength: 0.9,
				},
			},
			sdxl: {
				0: {
					controlGuidanceEnd: 0,
					depthWeight: 0.15,
					depthWeightGuidanceEnd: 0.15,
					denoisingStrength: 0.99,
				},
				0.15: {
					controlGuidanceEnd: 0.15,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.3,
					denoisingStrength: 0.99,
				},
				0.3: {
					controlGuidanceEnd: 0.3,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.3,
					denoisingStrength: 0.97,
					__default: true,
				},
				0.45: {
					controlGuidanceEnd: 0.45,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.45,
					denoisingStrength: 0.94,
				},
				0.55: {
					controlGuidanceEnd: 0.55,
					depthWeight: 0.5,
					depthWeightGuidanceEnd: 0.5,
					denoisingStrength: 0.86,
				},
			},
			'v3/3d': {
				0: {
					controlGuidanceEnd: 0,
					depthWeight: 0.2,
					depthWeightGuidanceEnd: 0.2,
					denoisingStrength: 0.99,
				},
				0.2: {
					controlGuidanceEnd: 0.2,
					depthWeight: 0.2,
					depthWeightGuidanceEnd: 0.2,
					denoisingStrength: 0.99,
				},
				0.4: {
					controlGuidanceEnd: 0.4,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.3,
					denoisingStrength: 0.97,
					__default: true,
				},
				0.5: {
					controlGuidanceEnd: 0.5,
					depthWeight: 0.4,
					depthWeightGuidanceEnd: 0.4,
					denoisingStrength: 0.94,
				},
				0.6: {
					controlGuidanceEnd: 0.6,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.5,
					denoisingStrength: 0.9,
				},
			},
			'v3/2d': {
				0: {
					controlGuidanceEnd: 0,
					depthWeight: 0.15,
					depthWeightGuidanceEnd: 0.15,
					denoisingStrength: 0.99,
				},
				0.15: {
					controlGuidanceEnd: 0.15,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.3,
					denoisingStrength: 0.99,
				},
				0.3: {
					controlGuidanceEnd: 0.3,
					depthWeight: 0.3,
					depthWeightGuidanceEnd: 0.3,
					denoisingStrength: 0.97,
					__default: true,
				},
				0.45: {
					controlGuidanceEnd: 0.45,
					depthWeight: 0.45,
					depthWeightGuidanceEnd: 0.45,
					denoisingStrength: 0.94,
				},
				0.55: {
					controlGuidanceEnd: 0.55,
					depthWeight: 0.5,
					depthWeightGuidanceEnd: 0.5,
					denoisingStrength: 0.86,
				},
			},
		},
	},

}

const refinerInfluenceValues = {
	sdxl: {
		txt2img: 0.4,
		img2img: 0.15,
	},
	'sdxl-3d': {
		txt2img: 0.056,
		img2img: 0,
	},
}

const getDefaultWeight = (state) => {
	return Object.entries(WeightValues[state.form.payload.controlnetMode][state.form.payload.image_as_control_image][state.form.method])
		.filter(([_, item]) => item.__default).map(([val, item]) => val)?.[0];
}

// Refiner influence
formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if ((currentState.form.method.includes('sdxl')) && 
			Object.keys(refinerInfluenceValues).indexOf(currentState.form.method) > -1) {
			if (
				currentState.form.payload.inputImage !== previousState.form.payload.inputImage ||
				currentState.form.method !== previousState.form.method
			) {
				return true;
			}
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		const pipeline = listenerApi.getState().form.payload.inputImage ? 'img2img' : 'txt2img'
		const value = refinerInfluenceValues[listenerApi.getState().form.method][pipeline]
		if (value === 0) {
			listenerApi.dispatch(partialUpdateStatePayload({
				useRefiner: false,
				refinerInfluence: value,
			}));
		} else {
			listenerApi.dispatch(partialUpdateStatePayload({
				useRefiner: true,
				refinerInfluence: value,
			}));
		}
	},
});

formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if ((currentState.form.method.includes('sdxl') || currentState.form.method.includes('v3/')) && !currentState.form.__dev_mode) {
			if (
				currentState.form.payload.image_as_control_image !== previousState.form.payload.image_as_control_image &&
				currentState.form.payload.inputImage && 
				!currentState.form.__dev_mode
			) {
				return true;
			}
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		let updateValues = {
			controlGuidanceEnd: 0.8,
		};

		if (listenerApi.getState().form.payload.inputImage) {
			const previousKeys = Object.keys(WeightValues[listenerApi.getState().form.payload.controlnetMode][!listenerApi.getState().form.payload.image_as_control_image][listenerApi.getState().form.method]).map(i => Number(i)).sort();
			const currentKeys = Object.keys(WeightValues[listenerApi.getState().form.payload.controlnetMode][listenerApi.getState().form.payload.image_as_control_image][listenerApi.getState().form.method]).map(i => Number(i)).sort();
			const val = currentKeys[previousKeys.findIndex(item => item == listenerApi.getState().form.payload.imageWeight)];

			updateValues = {
				...updateValues,
				...WeightValues[
					listenerApi.getState().form.payload.controlnetMode
				][
					listenerApi.getState().form.payload.image_as_control_image
				][
					listenerApi.getState().form.method
				][val],
				__default: undefined,
				imageWeight: Number(val),
			}
		}


		listenerApi.dispatch(partialUpdateStatePayload(updateValues));
  },
})

// Method changes: ImageWeight
formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if ((currentState.form.method.includes('sdxl') || currentState.form.method.includes('v3/')) && !currentState.form.__dev_mode) {
			if (
				currentState.form.method !== previousState.form.method
			) {
				return true;
			}
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		let updateValues = {
			controlGuidanceEnd: 0.8,
		};

		if (listenerApi.getState().form.__dev_mode) {
			
			if (
				!/^[0-9.-]*\d+$/.test(listenerApi.getState().form.payload.denoisingStrength) ||
				!/^[0-9.-]*\d+$/.test(listenerApi.getState().form.payload.imageWeight)
			)
				return;
		}


		if (listenerApi.getState().form.payload.inputImage) {
			updateValues = {
				...updateValues,
				// refinerInfluence: 0.0125,
			}
			if (listenerApi.getState().form.__dev_mode) {
				return;
			}
		}
		const val = getDefaultWeight(listenerApi.getState());
		
		updateValues = {
			...updateValues,
			...WeightValues[
				listenerApi.getState().form.payload.controlnetMode
			][
				listenerApi.getState().form.payload.image_as_control_image
			][
				listenerApi.getState().form.method
			][val],
			__default: undefined,
			imageWeight: Number(val),
		}

		listenerApi.dispatch(partialUpdateStatePayload(updateValues));
  },
})

formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if ((currentState.form.method.includes('sdxl') || currentState.form.method.includes('v3/')) && !currentState.form.__dev_mode) {
			if (
				currentState.form.payload.inputImage !== previousState.form.payload.inputImage ||
				currentState.form.payload.imageWeight !== previousState.form.payload.imageWeight ||
				currentState.form.payload.controlnetMode !== previousState.form.payload.controlnetMode
				// currentState.form.payload.denoisingStrength !== previousState.form.payload.denoisingStrength
			) {
				return true;
			}
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		let updateValues = {
			controlGuidanceEnd: 0.8,
		};

		if (listenerApi.getState().form.__dev_mode) {
			
			if (
				!/^[0-9.-]*\d+$/.test(listenerApi.getState().form.payload.denoisingStrength) ||
				!/^[0-9.-]*\d+$/.test(listenerApi.getState().form.payload.imageWeight)
			)
				return;
		}


		if (listenerApi.getState().form.payload.inputImage) {
			updateValues = {
				...updateValues,
				// refinerInfluence: 0.0125,
			}
			let val = Object.keys(
				WeightValues[
					listenerApi.getState().form.payload.controlnetMode
				][
					listenerApi.getState().form.payload.image_as_control_image
				][
					listenerApi.getState().form.method
				]).find(
					(item) => item == listenerApi.getState().form.payload.imageWeight)
			if (val === undefined) {
				if (listenerApi.getState().form.__dev_mode) {
					return;
				}
				val = getDefaultWeight(listenerApi.getState());
			}
			
			updateValues = {
				...updateValues,
				...WeightValues[
					listenerApi.getState().form.payload.controlnetMode
				][
					listenerApi.getState().form.payload.image_as_control_image
				][
					listenerApi.getState().form.method
				][val],
				__default: undefined,
				imageWeight: Number(val),
			}
		}

		listenerApi.dispatch(partialUpdateStatePayload(updateValues));
  },
})

// PromptRigidness
const PromptRigidnessValuesByMethodImg2Img = {
	sdxl: {
		_default: 8.5,
		badge: 6,
		mascot: 5,
		'line-art': 7,
		icon: 6,
		cartoon: 5,
		pictorial: 7.5,
		abstract: 5,
		'logo-type': 7,
		monogram: 5,
		img2img: {
			badge: 5,
			mascot: 5,
			'line-art': 6,
			icon: 5,
			cartoon: 5,
			pictorial: 6,
			abstract: 5,
			'logo-type': 6,
			monogram: 5,
		},
	},
	'sdxl-3d': {
		_default: 7.5,
	},
	_default: 7.5,
}

formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if (currentState.form.method.includes('sdxl') && !currentState.form.__dev_mode) {
			if (
				currentState.form.payload.contentStyle !== previousState.form.payload.contentStyle ||
				currentState.form.method !== previousState.form.method
			) {
				return true;
			}
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		const updateValues = {
			promptRigidness: 7.5,
		}
		const sec = PromptRigidnessValuesByMethodImg2Img[listenerApi.getState().form.method]
		const pipeline = listenerApi.getState().form.payload.inputImage ? 'img2img' : 'txt2img'
		updateValues.promptRigidness = (
			sec[pipeline]?.[listenerApi.getState().form.payload.contentStyle]
			|| sec[listenerApi.getState().form.payload.contentStyle]
			|| sec._default
			|| PromptRigidnessValuesByMethodImg2Img._default
		)

		listenerApi.dispatch(partialUpdateStatePayload(updateValues));
	},
});

// Style Config
formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if (
			action.type !== 'form/load/fulfilled' &&
			(
				currentState.form.payload.contentStyle !== previousState.form.payload.contentStyle ||
				currentState.form.payload.style !== previousState.form.payload.style
			)
		) {
			return true;
		}
		if (
			action.type !== 'form/load/fulfilled' &&
			currentState.form.method !== previousState.form.method
		) {
			if (currentState.form.method.startsWith('v4') && previousState.form.method.startsWith('v4'))
				return false;
			return true;
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		listenerApi.dispatch(resetStyleConfig());
	},
})

// Disable wieghting for waterfall
formListener.startListening({
	predicate: (action, currentState, previousState) => {
		if (
			currentState.form.method.startsWith('v4') &&
			currentState.form.method !== previousState.form.method
		) {
			return true;
		}
		return false;
	},
  effect: async (action, listenerApi) => {
		console.log("Removing prompt weights")
		let prompt = listenerApi.getState().form.payload.prompt
		let result = [''];
		let parantheses_count = 0;
		prompt.split('').forEach((element) => {
			if (parantheses_count === 0 && /^\s$/g.test(element)) {
				result.push('');
				return;
			}

			if (element === '(') parantheses_count++;
			if (element === ')') parantheses_count--;

			result[result.length - 1] += element;
		});
		console.log("Removing prompt weights", result)

		const newPrompt = result.map(x => getValueAndImportance(x)[0]).join(' ')

		listenerApi.dispatch(updatePrompt(newPrompt))
		listenerApi.dispatch(updatePromptForceUpdate(newPrompt))

	},
})

export default formListener
