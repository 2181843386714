
export const resizeInputImage = (image, target_size=425) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");

    img.alt = "input"

    img.onload = function() {
      const ratio = Math.min(target_size / Math.max(img.width, img.height), 1)
      c.width = img.width * ratio;
      c.height = img.height * ratio;

      ctx.drawImage(this, 0, 0, img.width * ratio, img.height * ratio);


      c.toBlob(function(blob) {
        resolve({
          url: URL.createObjectURL(blob),
          width: img.width * ratio,
          height: img.height * ratio,
        })
        document.removeChild(c)
        document.removeChild(img)

      }, "image/png", 1);
    };

    img.src = URL.createObjectURL(image);

  });
}

export const resizeBase64 = (image, newWidth=512, newHeight=512) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");

    img.alt = "input"

    img.onload = function() {
      c.width = newWidth;
      c.height = newHeight;

      ctx.drawImage(this, 0, 0, newWidth, newHeight);

      resolve({
        image: c.toDataURL("image/png", 1),
      })
    };

    img.src = image;

  });
}
