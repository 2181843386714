import UserLayout from '../layouts/UserLayout';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <UserLayout>
      <Helmet>
        <title>Plans | Logo Diffusion</title>
      </Helmet>

      <h1 className='text-white p-4'>Hi from plans</h1>
    </UserLayout>
  );
}

export default Home;
