import React, { useMemo } from 'react';
import { STYLE_SETTINGS } from './constants';
import Config from './Config';
import { useDispatch, useSelector } from 'react-redux';
import { setStyleConfig } from '../../../reducers/formReducer';

const StyleSettings = (props) => {
  const style = useSelector((state) => state.form.payload.contentStyle);
  const method = useSelector((state) => state.form.method);

	const config = useMemo(() => STYLE_SETTINGS[method]?.[style] || [], [style, method]);

	const style_config = useSelector((state) => state.form.payload.styleConfig);

	const dispatch = useDispatch();

	const handleConfigChange = (config_id, value) => {
		dispatch(setStyleConfig({
			config_id,
			value,
		}));
	}
	
	if (config === undefined) 
		return (
			<div className="flex flex-col gap-2 text-center font-bold font-poppins text-select-label-disabled w-full max-w-full">
				No customizations available for this style!
			</div>
		);

	return (
		<div className="flex flex-col gap-4 w-full max-w-full">
			{config.filter(config => config.section_id !== 'colors').map((config_item) => (
				<React.Fragment key={config_item.section_id}>
					<h3 className="font-roboto font-bold text-sm text-gray-label">
						{config_item.name}
					</h3>
					{config_item.elements.map((el) => (el.component ? 
						(
							<el.component  config_metadata={el} value={style_config[el.id]} onChange={(val) => handleConfigChange(el.id, val)} key={el.id}/>
						) : (
							<Config config_metadata={el} value={style_config[el.id]} onChange={(val) => handleConfigChange(el.id, val)} key={el.id}/>
						)
					))}
				</React.Fragment>
			))}
		</div>
	);
}

export default StyleSettings;
