import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PromptInput from '../../components/input/PromptInput';
import Generations from '../../components/generation/Generations';

import { ReactComponent as EditCanvasIcon } from '../../assets/EditorIcon.svg';
import { ReactComponent as UploadImageIcon } from '../../assets/UploadImageIcon.svg';
import { ReactComponent as ArrowBack } from '../../assets/arrow-back.svg';
import GraphicEditor from '../../components/editor/GraphicEditor';
import GenerationHistory from '../../components/generation/GenerationHistory';
import { useWindowDimensions } from '../../hooks/useDimenssion';
import { breakpoints } from '../../constant/device';
import AdvancedSettings from '../../components/redesign/AndvancedSettings';
import Redesign from '../../components/redesign/Redesign';
import ImageUpload from '../../components/ImageUpload';
import {
  DEFAULT_COLORS,
  setBackgroundImage,
  setIsEditImage,
  setPresetColor,
} from '../../reducers/imageEditorReducer';
import { emptyJPEG } from '../../components/editor/image_generator';
import { updateInputImage } from '../../reducers/formReducer';
import QueueStatus, {
  useQueueVisibility,
} from '../../components/queue/QueueStatus';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import GenerationsV4 from '../../components/generation/GenerationsV4';
import V4GenerationHistory from '../generation/V4GenerationHistory';
import RedesignV4 from '../redesign/RedesignV4';

const V3ImagePipeline = (props) => {
  const [mode, setMode] = useState('EDIT'); // EDIT/PREVIEW
  const windowDimens = useWindowDimensions();
  const isMobile = useMemo(
    () => windowDimens.width < breakpoints.LAPTOP,
    [windowDimens.width]
  );
  const generationRef = useRef();

  const { show: showQueue } = useQueueVisibility();

  const isGenerating = useSelector((state) => state.form.isLoading);

  const inputImage = useSelector((state) => state.form.payload.inputImage);

  const uploadRef = useRef();

  const dispatch = useDispatch();

  const switchToEditMode = () => {
    if (isGenerating) return;
    setMode('EDIT');
  };

  const openEmptyCanvas = () => {
    dispatch(setPresetColor(DEFAULT_COLORS));
    dispatch(setBackgroundImage(emptyJPEG));
    dispatch(setIsEditImage(false));
    dispatch(updateInputImage(emptyJPEG));
  };

  const handleInputImage = (value) => {
    dispatch(updateInputImage(value));
    dispatch(setBackgroundImage(value));
    dispatch(setIsEditImage(true));
  };

  const { mode: version } = useGenerationEndpoint();

  return (
          <div className='flex flex-col grow shrink-0 overflow-hidden max-h-[90%] relative'>
            <div
              className={` p-4 fixed -translate-x-1/2 z-50 ${
                showQueue ? 'max-h-none' : 'max-h-0'
              }`}
              style={{
                top: generationRef.current?.getBoundingClientRect().top,
                left:
                  (generationRef.current?.getBoundingClientRect().left +
                    generationRef.current?.getBoundingClientRect().right) /
                  2,
                width: generationRef.current?.getBoundingClientRect().width,
              }}
            >
              <QueueStatus useParentWidth />
            </div>
            <div
              className={` max-h-[calc(100%-250px] md:max-h-[calc(100%-100px)] grow shrink w-full flex flex-row justify-between relative flex-wrap lg:flex-nowrap gap-2 3xl:gap-4 pt-0 lg:pt-4 custom-scroll px-4`}
            >
              {version === 'v4.0' ? (
                <div className='pr-2 bg-app-bg-gray rounded-[10px] 4xl:!h-[calc(100%-55px)] h-full'>
                  <div className='w-[270px] 3xl:w-[321px] max-w-[321px] h-full bg-app-bg-gray py-3 pl-3 pr-0 rounded-[10px] overflow-auto custom-scroll txt2img-custom-scroll'>
                    <RedesignV4 />
                  </div>
                </div>
              ) : (
                <div
                  className={`h-full basis-full lg:h-[492px] max-h-[100%] lg:basis-[270px] pt-4  shrink-0 `}
                >
                  <AdvancedSettings />
                </div>
              )}
              <div
                className={`flex flex-wrap lg:flex-nowrap gap-9 aspect-[6/4] shrink-1 ${
                  version === 'v4.0' ? 'justify-center' : ''
                } `}
              >
                {mode !== 'EDIT' && isMobile ? null : (
                  <div
                    className={`${
                      version === 'v4.0'
                        ? 'aspect-[2.9/4] 3xl:aspect-[6/7]'
                        : 'aspect-[3/4]'
                    } flex flex-col`}
                  >
                    {inputImage ? (
                      <GraphicEditor showToolbar={true} />
                    ) : (
                      <ImageUpload
                        handleImageSelected={handleInputImage}
                        renderInput={(props) => (
                          <>
                            <input
                              type='file'
                              className='hidden'
                              ref={uploadRef}
                              onChange={(e) =>
                                props.handleImageUpload(e.target.files)
                              }
                            />
                            <div className='flex flex-col gap-6 aspect-square'>
                              <button
                                type='button'
                                className='basis-1/2 grow shrink border border-app-search-gray bg-app-bg-gray rounded-2xl flex flex-col gap-2 items-center justify-center'
                                onClick={() => uploadRef.current.click()}
                              >
                                <UploadImageIcon className='w-14 h-14' />
                                <span className='text-editor-label text-center font-roboto text-xs'>
                                  <span className='text-button-blue'>
                                    Click here{' '}
                                  </span>
                                  to upload your
                                  <br />
                                  image JPG/PNG up to 5MB
                                </span>
                              </button>
                              <button
                                type='button'
                                className='basis-1/2 grow shrink border border-app-search-gray bg-app-bg-gray rounded-2xl flex flex-col gap-2 items-center justify-center'
                                onClick={() => openEmptyCanvas()}
                              >
                                <EditCanvasIcon className='w-14 h-14' />
                                <span className='text-editor-label text-center font-roboto text-xs'>
                                  <span className='text-button-blue'>
                                    Click here{' '}
                                  </span>
                                  to open the editor
                                  <br />
                                  and start sketching ideas
                                </span>
                              </button>
                            </div>
                          </>
                        )}
                      />
                    )}
                    {version === 'v4.0' ? <></> : <Redesign />}
                  </div>
                )}
                {mode !== 'PREVIEW' && isMobile ? null : version === 'v4.0' ? (
                  <div
                    className={`aspect-[2.9/4] 3xl:aspect-[6/7] ${
                      inputImage ? '-mr-[45px] 3xl:mr-0' : 'mr-0'
                    }`}
                  >
                    <GenerationsV4 ref={generationRef} />
                  </div>
                ) : (
                  <Generations ref={generationRef} />
                )}
                {mode !== 'PREVIEW' && isMobile ? null : (
                  <div className={`flex justify-center lg:hidden`}>
                    <button
                      onClick={switchToEditMode}
                      className='flex flex-row items-center text-icon-text-color gap-1 text-lg font-bold bg-app-bg-gray px-3 py-2 rounded-xl'
                    >
                      <ArrowBack />
                      Back to editing
                    </button>
                  </div>
                )}
              </div>

              {version === 'v4.0' ? (
                <div className={`max-w-[115px] 4xl:h-[calc(100%-65px)] h-full`}>
                  <V4GenerationHistory />
                </div>
              ) : (
                <div
                  className={`h-full basis-full lg:h-[600px] max-h-[100%] lg:basis-[230px] pt-4 shrink-0 `}
                >
                  <GenerationHistory />
                </div>
              )}
              <PromptInput setMode={setMode} />
            </div>
            <div className='basis-[250px] md:basis-[150px] shrink-0 grow-0' />
          </div>
  );
};

export default V3ImagePipeline;
