import { Datagrid, List, TextField } from 'react-admin';

export const RoadmapViewer = () => (
  <List>
      <Datagrid rowClick="edit">
          <TextField source="title" />
          <TextField source="text" />
          <TextField source="category" />
      </Datagrid>
  </List>
);

export default RoadmapViewer;
