const samplerOptions = [
  {
    id: 1,
    slug: 'Euler a',
    label: 'Euler a'
  },
  {
    id: 2,
    slug: 'Heun',
    label: 'Heun'
  },
  {
    id: 3,
    slug: 'DPM2 a',
    label: 'DPM2 a'
  },
  // {
  //   id: 4,
  //   slug: 'DPM++ 2S a',
  //   label: 'DPM++ 2S a'
  // },
  {
    id: 5,
    slug: 'DPM++ 2M',
    label: 'DPM++ 2M'
  },
  // {
  //   id: 6,
  //   slug: 'DPM++ SDE',
  //   label: 'DPM++ SDE'
  // },
  // {
  //   id: 7,
  //   slug: 'DPM++ 2M Karras',
  //   label: 'DPM++ 2M Karras'
  // },
  // {
  //   id: 8,
  //   slug: 'DPM++ 2S a Karras',
  //   label: 'DPM++ 2S a Karras'
  // },
  {
    id: 9,
    slug: 'DDIM',
    label: 'DDIM'
  }
];
// Slug should be the same as A1 api
// change label if needs to be shorter aesthetically
export default samplerOptions;
