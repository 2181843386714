import { useState, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../assets/CloseIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { setUserName } from '../reducers/userReducer';
import authAxiosInstance from '../helpers/auth-axios';

function ProfileInfoForm(props) {
  const [name, setName] = useState();
  const [buttonText, setButtonText] = useState('Update')

  const dispatch = useDispatch();
  const user = useSelector(state => state.userSlice.user);

  useEffect(() => {
    setName(user?.name);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!name) return toast('Name cannot be empty.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark',
      autoClose: 2000
    });

    setButtonText('Updating...');

    try {
      const body = {
        name
      };

      await authAxiosInstance.put(`/api/users/${user?._id}`, body);
      dispatch(setUserName({ name }));
      setButtonText('Update');
      props.handleClose();
    } catch (error) {
      toast('Something went wrong. Please try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
      setButtonText('Update');
    }
  };

  return (
    <div className='bg-[#13131A] pt-[35px] pb-[35px] px-[55px] rounded-[20px] w-full max-w-[710px] border border-[#1A1E2D] max-h-[90%] overflow-auto custom-scroll relative'>
       <div className='h-[28px] w-[28px] rounded-[4px] flex items-center justify-center bg-divider absolute top-[21px] right-[26px] cursor-pointer z-30' onClick={e => props.handleClose()}>
        <CloseIcon />
      </div>

      <form
        className='flex flex-col gap-2 max-w-[490px] mx-auto'
        onSubmit={handleSubmit}
      >
        <label htmlFor="name" className="font-poppins font-medium text-[#B8B8C6] text-[16px] mb-[19px]">Name</label>
        <input
          name="name"
          type="text"
          placeholder="Name"
          className='bg-app-bg-gray rounded-2xl border border-app-search-gray mb-[42px] p-[15px] text-[#92929D] text-[14px]'
          value={name}
          onChange={e => setName(e.target.value)}
        >
        </input>

        <button
          className='bg-blue-600 relative text-white font-bold rounded-[10px] text-sm flex w-full items-center justify-center gap-2 h-[50px] mb-[50px]'
          type='submit'
        >
          {buttonText}
        </button>
      </form>
    </div>
  )
}

export default ProfileInfoForm;
