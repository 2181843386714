import Minimalist from '../assets/MinimalistHelper.jpg';
import Detailed from '../assets/DetailedHelper.jpg';
import Geometric from '../assets/GeometricHelper.jpg';
import Badges from '../assets/BadgeHelper.jpg';
import Mascot from '../assets/MascotHelper.jpg';
import Emblem from '../assets/EmblemHelper.jpg';
import Feminine from '../assets/FeminineHelper.jpg';
import Masculine from '../assets/MasculineHelper.jpg';

const promptHelpers = [
  {
    name: 'Minimalist',
    thumbnail: Minimalist,
    slug: 'minimalist'
  },
  {
    name: 'Detailed',
    thumbnail: Detailed,
    slug: 'detailed'
  },
  {
    name: 'Geometric',
    thumbnail: Geometric,
    slug: 'geometric'
  },
  {
    name: 'Badge',
    thumbnail: Badges,
    slug: 'badge'
  },
  {
    name: 'Mascot',
    thumbnail: Mascot,
    slug: 'mascot'
  },
  {
    name: 'Emblem',
    thumbnail: Emblem,
    slug: 'emblem_crest'
  },
  {
    name: 'Feminine',
    thumbnail: Feminine,
    slug: 'feminine'
  },
  {
    name: 'Masculine',
    thumbnail: Masculine,
    slug: 'masculine'
  }
];

export default promptHelpers;