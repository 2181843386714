import Tooltip from './Tooltip'

import { ReactComponent as RandomizeIcon } from '../assets/RandomizeIcon.svg';
import { ReactComponent as RandomizeIconGreen } from '../assets/RandomizeIconGreen.svg';
import { ReactComponent as FreezeIcon } from '../assets/FreezeIcon.svg';
import { ReactComponent as FreezeIconGreen } from '../assets/FreezeIconGreen.svg';
import { ReactComponent as InfoIcon } from '../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { freezeSeed, unfreezeSeed, updateSeed } from '../reducers/formReducer';
import useCurrentWorkspace from '../hooks/useCurrrentWorkspace';


function SeedGenerator(props) {
  const dispatch = useDispatch();

  const isGeneratingSeed = useSelector((state) => state.form.__autogenerated_seed);
  const seed = useSelector((state) => state.form.payload.seed);

  const setSeed = (value) => {
    dispatch(updateSeed(value))
  }
  const handleFreezeSeed = () => {
    dispatch(freezeSeed())
  }
  const handleRandomizeSeed = () => {
    dispatch(unfreezeSeed())
  }

	const { workspace } = useCurrentWorkspace();


  return (
    <div className={`flex items-center justify-between gap-2 ${workspace === 'image-workspace' ? 'max-w-[250px]' : ''}`}>
      <span className='text-gray-label font-poppins font-bold text-xs flex gap-1'>
        Seed
        <Tooltip title="Different numbers result in new variations of your image, randomize picks a random number every time you click generate">
          <InfoIcon />
        </Tooltip>
      </span>

      <div className="flex justify-end grow items-center gap-2 pr-1 max-w-[60%]">
        <input
          className={`p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl max-w-[80%] flex`}
          value={seed}
          onChange={e => setSeed(e.target.value)}
        />

        <Tooltip title="Freeze this seed value for new image generations">
          <FreezeIcon className={`cursor-pointer ${isGeneratingSeed ? '' : '[&_path]:fill-app-green'}`} onClick={e => handleFreezeSeed()} />
        </Tooltip>


        <Tooltip title="Use a new seed  with every new image generations">
          <RandomizeIcon className={`cursor-pointer ${isGeneratingSeed ? '[&_path]:fill-app-green' : ''}`} onClick={e => handleRandomizeSeed()} />
        </Tooltip>
      </div>
    </div>
  )
}

export default SeedGenerator;
