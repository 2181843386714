import { useDispatch, useSelector } from "react-redux"
import { partialUpdateStatePayload } from "../../../../reducers/formReducer"
import DevInput from "./DevInput";

const MulagoSettings = () => {
	const dispatch = useDispatch()

	const cnStrength = useSelector((state) => state.form.payload.cnStrength)
	const cnEndPercent = useSelector((state) => state.form.payload.cnEndPercent)
	const mainSamplerDenoise = useSelector((state) => state.form.payload.mainSamplerDenoise)

	const handleFieldUpdate = (key) => (val) => {
		dispatch(partialUpdateStatePayload({
			[key]: val
		}))
	}

	return (
		<>
			<div className="text-gray-label font-poppins font-bold">
				<span className="text-sm mr-2">Model:{" "}</span>
				<span className="font-bold text-base">Mulago{" "}</span>
				<span className="text-xs text-app-green">(/v4/mulago)</span>
			</div>
			<DevInput
				value={mainSamplerDenoise}
				onChange={handleFieldUpdate('mainSamplerDenoise')}
				label={'main_sampler_denoise'}
				title='main_sampler_denoise'
			/>
			<DevInput
				value={cnStrength}
				onChange={handleFieldUpdate('cnStrength')}
				label={'cn_strength'}
				title='cn_strength'
			/>
			<DevInput
				value={cnEndPercent}
				onChange={handleFieldUpdate('cnEndPercent')}
				label={'cn_end_percent'}
				title='cn_end_percent'
			/>
		</>
	)
}

export default MulagoSettings;
