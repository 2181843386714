import { Dialog } from "@headlessui/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  selectColor,
  setBrushSize,
  setIsOpen,
  setPresetColor,
  updateCropButtonLocation,
  updateFontFamily,
  updateFontWeight,
  updateSelectedElement,
  updateSelectionPicker,
  updateSelectionPickerLocation,
  updateTool,
} from "../../reducers/imageEditorReducer";

import "tui-image-editor/dist/tui-image-editor.css";
import ToastUIImageEditor from "@toast-ui/react-image-editor";
import Vibrant from "node-vibrant";
import Zoom from "./Zoom";
import BrushSizePreview from "./brush/BrushSizePreview";
import { SHAPES } from "./shapes/shapes";
import { CURSOR_STYLES, SELECTION_DISABLED_TOOLS } from "./tool_metadata";
import CropConfirmation from "./crop/CropConfirmation";

import { fabric } from 'fabric';
import SelectionColorPicker from "./selection/SelectionColorPicker";
import { RenderedColorPickerIcon, RenderedDeleteIcon, RenderedResizeIcon, RenderedRotateIcon } from "./selection/selection_icons";
import Toolbar from "./toolbar";
import RedoUndo from "./RedoUndo";
import ActionBar from "./actionbar";

import "./ImageEditor.css"
import { useWindowDimensions } from "../../hooks/useDimenssion";
import { resizeBase64 } from "./resizers";
import { EDITOR_SIZE, IMAGE_EXPORT_SIZE } from "./settings";
import DownloadAction from "./actionbar/DownloadAction";
import V4Toolbar from "./toolbar/V4Toolbar";
import V4Zoom from "./V4Zoom";
import { updateInputImage } from "../../reducers/formReducer";

const deleteObjectHandler = (eventData, transform) => {
  var target = transform.target;
  var canvas = target.canvas;
  canvas.remove(target);
  canvas.requestRenderAll();
}

const ImageEditor = (props) => {
	const store = useStore();
  const isOpen = useSelector((state) => state.imageEditor.isOpen);
  const backgroundImage = useSelector(
    (state) => state.imageEditor.backgroundImage
  );

  const currentFont = useSelector((state) => state.imageEditor.fontFamily);
  const currentFontWeight = useSelector(
    (state) => state.imageEditor.fontWeight
  );
  const currentFontSize = useSelector((state) => state.imageEditor.fontSize);

  const activeColor = useSelector((state) => state.imageEditor.activeColor);
  const brushSize = useSelector((state) => state.imageEditor.brushSize);

  const currentTool = useSelector((state) => state.imageEditor.tool);

  const dispatch = useDispatch();

  // const [editor, setEditor] = useState();
  const editorContainer = useRef();
  const editor = useRef();
  const selectionEventRef = useRef();
  const toolRef = useRef();
  const zoomCenterRef = useRef({
    zoomLevel: 1,
    x: 256,
    y: 256,
  });

  const windowDimensions = useWindowDimensions();
  // const isMobile = useMemo(() => windowDimensions.width < breakpoints.TABLET, [windowDimensions.width]);

  const editorSize = useMemo(() => {
    const { width, height } = windowDimensions;
    if (width > height) { // landscape
      return 'max-h-[1024px] h-[80vh]'
    } else {
      return 'max-w-[1024px] w-[95vw]'
    }
  }, [windowDimensions])

  const padding = useMemo(() => {
    const { width, height } = windowDimensions;
    const baseSize = Math.min(width, height);
    const isPortrait = height > width;
    let p = 'p-2'
    if (isPortrait) {
      if (baseSize * 0.95 >= 1024) p = 'p-5';
      if (baseSize * 0.95 >= 768) p = 'p-3';
      if (baseSize * 0.95 >= 512) p = 'p-2.5';
    } else {
      if (baseSize * 0.8 >= 1024) p = 'p-5';
      if (baseSize * 0.8 >= 768) p = 'p-3';
      if (baseSize * 0.8 >= 512) p = 'p-2.5';
    }
    return p;
  }, [windowDimensions])

  const onClose = async (e) => {
    dispatch(setIsOpen(false));

    // Reset
    zoomCenterRef.current = {
      zoomLevel: 1,
      x: 256,
      y: 256,
    }
    await editor?.current.getInstance().zoom(zoomCenterRef.current)

    toolRef.current = null
    await editor.current?.getInstance().stopDrawingMode();

		setTimeout(async() => {
			const originalImage = editor?.current.getInstance().toDataURL({multiplier: 4});
			const { image } = await resizeBase64(originalImage, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE)
			dispatch(updateInputImage(image));
		})
  };

  const loadBaseImage = useCallback(
    async (editor, size) => {
      const { image } = await resizeBase64(backgroundImage, size, size);
      const resp = await fetch(image);
      const url = URL.createObjectURL(await resp.blob());

      editor.current?.getInstance().loadImageFromURL(url, "Inspiration");

      /* Load image colors */
      try {
        const vibrant = new Vibrant(url);
        const palette = await vibrant.getPalette();

        const paletteAvailable =
          palette &&
          palette.Vibrant &&
          Object.values(palette)
            .map((color) => color.hex)
            .filter((item) => !!item).length > 0;

        if (paletteAvailable) {
          dispatch(
            setPresetColor(
              Object.values(palette)
                .map((color) => color.hex)
                .filter((item) => !!item)
            )
          );
          dispatch(
            selectColor(Object.values(palette).map((color) => color.hex)[0])
          );
          dispatch(updateTool());
        }

        if (editor) {
          await editor.current?.getInstance().stopDrawingMode();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [backgroundImage, dispatch]
  );

  const loadIcons = async () => {
    await editor.current?.getInstance().registerIcons(SHAPES)
  }

  const editorLoader = useCallback(
    async (node) => {
      if (node) node.getInstance()._graphics._canvas.preserveObjectStacking = true // Don't bring objects forward when clicked
      if (node) {
        node.getInstance()._graphics._canvas.on("mouse:dblclick", async () => { // Bring objects forward on double click 
					/* console.log("Double click", 
						selectionEventRef.current?.id == editor.current?.getInstance()._graphics.getObjectId(editor.current?.getInstance()._graphics._canvas._activeObject), 
						selectionEventRef.current?.id, editor.current?.getInstance()._graphics.getObjectId(editor.current?.getInstance()._graphics._canvas._activeObject),
						editor.current?.getInstance()._graphics._canvas._activeObject
					)*/
          if (selectionEventRef.current?.id == editor.current?.getInstance()._graphics.getObjectId(editor.current?.getInstance()._graphics._canvas._activeObject)) {
            // console.log("Bringing object to front")
            await editor.current?.getInstance()._graphics._canvas.bringToFront(editor.current?.getInstance()._graphics._canvas._activeObject);
          }
        })

				node.getInstance()._graphics._canvas.width = EDITOR_SIZE;
				node.getInstance()._graphics._canvas.height = EDITOR_SIZE;

      }

      editor.current = node;

      if (node) {
        await loadIcons();
      }

      if (node && backgroundImage && editorContainer?.current) {
        setTimeout(async () => {
          await loadBaseImage(editor, 1024);
        }, 10)
      }
    },
    [backgroundImage, loadBaseImage]
  );

  useEffect(() => {
    if (editor?.current && backgroundImage && editorContainer?.current) {
      setTimeout(() => {
        loadBaseImage(editor, 1024);
      }, 10)
    }
  }, [backgroundImage, editor, loadBaseImage]);

  const handleMouseDown = async (event, originPointer) => {
    try {
      if (editor.current?.getInstance().getDrawingMode() === "TEXT") {
        await editor.current?.getInstance().discardSelection();
        await editor.current?.getInstance().addText("Double click to edit", {
          styles: {
            fill: "#000",
            fontSize: currentFontSize,
            fontFamily: currentFont,
            fontWeight: currentFontWeight,
          },
          position: {
            x: originPointer.x - 200,
            y: originPointer.y,
          },
          autofocus: true,
        });

        await editor.current?.getInstance().stopDrawingMode();
        dispatch(updateTool());
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleObjectActivated = async (event) => {
    if (toolRef.current === "ERASER") {
      await editor?.current.getInstance().removeActiveObject();
      return ;
    } 

    if (event.type === 'cropzone') {
      dispatch(updateCropButtonLocation({
        left: event.left + event.width,
        top: event.top + event.height,
      }))
    }

    if (event.type === 'i-text') {
      dispatch(updateFontFamily(event.fontFamily));
      dispatch(updateFontWeight(event.fontWeight));
    }

    selectionEventRef.current = event;

    dispatch(updateSelectedElement(event));
  };

  const handleSelectionCleared = async (event) => {
    dispatch(updateSelectedElement());
  }

  const setCurrentTool = async (val) => {

    if (editor.current?.getInstance()._graphics.getZoomMode() === 'hand' && val !== 'PAN')
    await editor.current?.getInstance()._graphics.endHandMode();

    await editor.current?.getInstance().changeSelectableAll(!SELECTION_DISABLED_TOOLS.includes(val));
    await editor.current?.getInstance().changeCursor(CURSOR_STYLES[val] || 'default');
    dispatch(updateTool(val));
    toolRef.current = val
  };

  const updateDrawingMode = async (mode) => {
    await editor.current?.getInstance().startDrawingMode(
      mode,
      ["LINE_DRAWING", "FREE_DRAWING"].indexOf(mode) > -1 && {
        width: brushSize,
        color: activeColor,
      }
    );
    setCurrentTool(mode);
  };

  useEffect(() => {
    const updateLocation = (event) => {
      const rect = editor.current?.getInstance().getCropzoneRect();
      dispatch(updateCropButtonLocation({
        left: rect.left + rect.width,
        top: rect.top + rect.height,
      }))
    }

    const elem = editorContainer.current

    if (currentTool === 'CROPPER') {
      elem?.addEventListener('mousemove', updateLocation)
    }

    return () => {
      if (currentTool === 'CROPPER') {
        elem?.removeEventListener('mousemove', updateLocation)
      }
    }
  }, [currentTool, dispatch, editor])


  const handleSelectionColorPicker = useCallback(async (event, transform) => {
    dispatch(updateSelectionPicker({ isOpen: true, type: 'fill' }))
    dispatch(updateSelectionPickerLocation({
      top: event.clientY,
      left: event.clientX,
    }))
  }, [dispatch])

  const handleStrokeSelectionColorPicker = useCallback(async (event, transform) => {
    dispatch(updateSelectionPicker({ isOpen: true, type: 'stroke' }))
    dispatch(updateSelectionPickerLocation({
      top: event.clientY,
      left: event.clientX,
    }))
  }, [dispatch])

  // if (!isOpen) return null;
	
	const selectionStyle = {
    cornerSize: 10,
    transparentCorners: false,
		borderColor: '#858585',
		cornerColor: '#000000',
		cornerStrokeColor: '#858585',
		controls: {
			...fabric.Object.prototype.controls,
			mtr: new fabric.Control({
				visible: false,
			}),
			rotate: new fabric.Control({
				x: 0.5,
				y: -0.5,
				offsetY: -20,
				offsetX: 20,
				cursorStyle: 'crosshair',
				actionHandler: fabric.controlsUtils.rotationWithSnapping,

				render: RenderedRotateIcon,
				cornerSize: 32
			}),
			delete: new fabric.Control({
				x: -0.5,
				y: -0.5,
				offsetY: -20,
				offsetX: -20,
				cursorStyle: 'pointer',
				mouseDownHandler: deleteObjectHandler,

				render: RenderedDeleteIcon,
				cornerSize: 38,
			}),
			resize: new fabric.Control({
				x: 0.5,
				y: 0.5,
				offsetY: 20,
				offsetX: 20,
				cursorStyle: 'crosshair',
				actionHandler: fabric.controlsUtils.scalingEqually,
				cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,

				render: RenderedResizeIcon,
				cornerSize: 56
			}),
			colorPicker: new fabric.Control({
				x: -0.5,
				y: 0.5,
				offsetY: 20,
				offsetX: -20,
				cursorStyle: 'pointer',
				mouseDownHandler: handleSelectionColorPicker,

				render: RenderedColorPickerIcon,
				cornerSize: 32
			}),
		},

	};

	const rootRef = useRef();
  const isPickerOpen = useSelector((state) => state.imageEditor.selection.pickerOpen);
	useEffect(() => {
		const clickListener = (e) => {
			if (!rootRef.current.contains(e.target) && !isPickerOpen) {
				console.log("Removing selection", e.target);
				editor.current.getInstance().discardSelection();
			}
		}
		document.addEventListener('mousedown', clickListener, { capture: false });
		return () => {
			document.removeEventListener('mousedown', clickListener);
		}
	}, [editor, rootRef, isPickerOpen]);

	useEffect(() => {
		if (editor.current) {
			const selectBrush = async () => {
				if (store.getState().imageEditor.tool !== 'FREE_DRAWING') updateDrawingMode('FREE_DRAWING');

				await editor.current?.getInstance().setBrush({
					width: store.getState().imageEditor.brushSize,
					color: store.getState().imageEditor.activeColor,
				});
			}
			selectBrush()
		}
	}, [editor, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50" unmount={false}>
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 overflow-y-auto custom-scroll">
        <div className="flex min-h-[100vh] items-center justify-center p-4 text-center ">
          <Dialog.Panel
            className={`${padding} bg-app-black rounded-2xl flex flex-col lg:flex-row gap-6 border border-[#1A1E2D] relative`}
            onClick={(e) => e.stopPropagation()}
						ref={rootRef}
          >
            <div className="w-full h-full group/editor relative overflow-hidden">
              <div
                className={`${editorSize} ${
									"![&_.tui-image-editor-canvas-container_canvas]:max-w-full ![&_.tui-image-editor-canvas-container_canvas]:max-h-full " +
									"![&_.tui-image-editor-canvas-container]:max-w-full ![&_.tui-image-editor-canvas-container]:max-h-full [&_.tui-image-editor-canvas-container]:bg-red"
								} [&>div]:h-full [&>div]:w-full [&>div]:flex [&>div]:items-center [&>div]:justify-center relative aspect-square`
								}
                ref={editorContainer}
              >
                <ToastUIImageEditor
                  // ref={editor}
                  ref={editorLoader}
                  usageStatistics={false}
                  onMousedown={handleMouseDown}
                  onObjectActivated={handleObjectActivated}
                  onSelectionCleared={handleSelectionCleared}

									cssMaxHeight={1024}
									cssMaxWidth={1024}

                  selectionStyle={selectionStyle}
                />

								{/* <Zoom editor={editor} zoomCenterRef={zoomCenterRef} /> */}
								<V4Zoom editor={editor} zoomCenterRef={zoomCenterRef} />

                <BrushSizePreview editorContainer={editorContainer} editor={editor} />

                <CropConfirmation editor={editor} updateDrawingMode={updateDrawingMode} editorContainer={editorContainer} />

                <SelectionColorPicker editor={editor} />
								{false && (
									<Toolbar
										editor={editor}
										onSave={onClose}
										toolRef={toolRef}
										setCurrentTool={setCurrentTool}
										updateDrawingMode={updateDrawingMode}
										zoomCenterRef={zoomCenterRef}
									/>
								)}
                <RedoUndo editor={editor} toolRef={toolRef} zoomCenterRef={zoomCenterRef} onSave={onClose} />

              </div>
							<V4Toolbar
								selectionStyle={selectionStyle}
								editor={editor}
								onSave={onClose}
								toolRef={toolRef}
								setCurrentTool={setCurrentTool}
								updateDrawingMode={updateDrawingMode}
								zoomCenterRef={zoomCenterRef}
								isOpen={isOpen}
								isModal
								handleStrokeSelectionColorPicker={
									handleStrokeSelectionColorPicker
								}
								handleSelectionColorPicker={handleSelectionColorPicker}
							/>
							{false && (
								<div className={`${padding} !pb-0 !pl-0 !pr-0`}>
									<ActionBar
										selectionStyle={selectionStyle}
										editor={editor}
										onSave={onClose}
										toolRef={toolRef}
										setCurrentTool={setCurrentTool}
										updateDrawingMode={updateDrawingMode}
										zoomCenterRef={zoomCenterRef}
										isModal

										handleStrokeSelectionColorPicker={handleStrokeSelectionColorPicker}
										handleSelectionColorPicker={handleSelectionColorPicker}
									/>
								</div>
							)}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default ImageEditor;
