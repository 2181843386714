import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { relogUser } from "../reducers/userReducer";

function ProtectedRoute(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(state => state.userSlice.user);
	const isLoggedOut = useSelector(state => state.userSlice.isLoggedOut);

	const adminRouteId = '643a1cd15a31f90010a4ad6a';

  useEffect(() => {
		if(window.location.pathname === '/admin') return;

		if(isLoggedOut) return navigate('/login');

		if(user && user?.role !== adminRouteId) return navigate('/');

		if(!user && !isLoggedOut) {
			if(localStorage.getItem('userInfo')) {
				const { id, refreshToken } = JSON.parse(localStorage.getItem('userInfo'));

				dispatch(relogUser({ userId: id, refreshToken })).unwrap().then((res) => {
					if(res === 'Invalid token') {
						navigate('/login')
					} else {
						localStorage.setItem('userInfo', JSON.stringify({ id, refreshToken: res.refreshToken }));
						if(res?.role !== adminRouteId) return navigate('/');
					}
				})
			} else {
				navigate('/login');
			}
		}
	}, [user, isLoggedOut, dispatch, navigate]);

  return props.children;
}

export default ProtectedRoute;
