import V3StyleSelector from './V3StyleSelector';
import NegativePrompt from './NegativePrompt';
import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../assets/styles/style-config/settings-icon.svg';
import { usePopper } from 'react-popper';
import StyleSettings from './style-config/StyleSettings';

import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { STYLE_SETTINGS } from './style-config/constants';
import ColorSelector from './ColorSelector';
import useGenerationEndpoint from './useGenerationEndpoint';
import V4SuggestionSelector from './V4SuggestionSelector';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import V4ColorSelector from './V4ColorSelector';

const V3Controls = (props) => {
  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
  });

  const { mode } = useGenerationEndpoint();

  const { workspace } = useCurrentWorkspace();
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row gap-2'>
        {mode === 'v4.0' && <V4ColorSelector />}
        {/* {mode === 'v4.0' && <ColorSelector />} */}
        {mode === 'v4.0' ? <V4SuggestionSelector /> : <V3StyleSelector />}
      </div>
      <div className='flex flex-row gap-2'>
        <NegativePrompt />

        {mode === 'v3.0' && <ColorSelector />}
      </div>
    </div>
  );
};

export default V3Controls;
