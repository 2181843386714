export const Color = (props) => {
  const { activeColor, color, onClick } = props;

  return <div className='rounded-full aspect-square h-6 p-[0.125rem]' style={{
    backgroundColor: color,
  }} onClick={onClick}>
    <div className={`rounded-full h-full w-full bg-transparent trnasition-all duration-150 border-solid border-app-black ${activeColor === color ? 'border-[3px]' : 'border-0' }`}></div>

  </div>

}