import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_COLORS,
  selectColor,
  setBackgroundImage,
  setCanvasSize,
  setIsEditImage,
  setIsOpen,
  setPresetColor,
  updateCropButtonLocation,
  updateFontFamily,
  updateFontWeight,
  updateSelectedElement,
  updateSelectionPicker,
  updateSelectionPickerLocation,
  updateTool,
} from '../../reducers/imageEditorReducer';

import 'tui-image-editor/dist/tui-image-editor.css';
import ToastUIImageEditor from '@toast-ui/react-image-editor';
import Vibrant from 'node-vibrant';
import Zoom from './Zoom';
import BrushSizePreview from './brush/BrushSizePreview';
import { SHAPES } from './shapes/shapes';
import { CURSOR_STYLES, SELECTION_DISABLED_TOOLS } from './tool_metadata';
import CropConfirmation from './crop/CropConfirmation';

import { fabric } from 'fabric';
import SelectionColorPicker from './selection/SelectionColorPicker';
import {
  RenderedColorPickerIcon,
  RenderedDeleteIcon,
  RenderedResizeIcon,
  RenderedRotateIcon,
} from './selection/selection_icons';
import { ReactComponent as ExpandIcon } from '../../assets/Expand.svg';
import Toolbar from './toolbar';
import RedoUndo from './RedoUndo';
import { dataURLtoFile } from '../../helpers/convertUrlToBase64';

import './GraphicEditor.css';
import { useWindowDimensions } from '../../hooks/useDimenssion';
import Redesign from '../redesign/Redesign';
import { WhiteBGBas64 } from '../../data/WhiteBGImage';
import { resizeBase64, resizeInputImage } from './resizers';
import { useEditor } from '../../hooks';
import ActionBar from './actionbar';
import { EDITOR_SIZE } from './settings';
import { useLocation } from 'react-router-dom';
import V4Toolbar from './toolbar/V4Toolbar';
import DownloadAction from './actionbar/DownloadAction';
import V4Zoom from './V4Zoom';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import { ContainerContext } from '../image-to-image/V4ContainerContext';

const deleteObjectHandler = (eventData, transform) => {
  var target = transform.target;
  var canvas = target.canvas;
  canvas.remove(target);
  canvas.requestRenderAll();
};

const V4GraphicEditor = (props) => {
  const { setEditor } = useEditor();
  const backgroundImage = useSelector(
    (state) => state.imageEditor.backgroundImage
  );

  const isOpen = useSelector((state) => state.imageEditor.isOpen);

  const currentFont = useSelector((state) => state.imageEditor.fontFamily);
  const currentFontWeight = useSelector(
    (state) => state.imageEditor.fontWeight
  );
  const currentFontSize = useSelector((state) => state.imageEditor.fontSize);

  const activeColor = useSelector((state) => state.imageEditor.activeColor);
  const brushSize = useSelector((state) => state.imageEditor.brushSize);
  const initialImage = useSelector((state) => state.form?.payload?.inputImage);

  const currentTool = useSelector((state) => state.imageEditor.tool);

  const dispatch = useDispatch();

  const editorContainer = useRef();
  const editor = useRef();
  const selectionEventRef = useRef();
  const toolRef = useRef();
  const zoomCenterRef = useRef({
    zoomLevel: 1,
    x: 256,
    y: 256,
  });

  const windowDimensions = useWindowDimensions();

  const editorSize = useMemo(() => {
    const { width, height } = windowDimensions;
    if (!isOpen) return '';
    if (width > height) {
      // landscape
      return 'max-h-[1024px] h-[80vh]';
    } else {
      return 'max-w-[1024px] w-[95vw]';
    }
  }, [windowDimensions, isOpen]);

  const padding = useMemo(() => {
    const { width, height } = windowDimensions;
    const baseSize = Math.min(width, height);
    const isPortrait = height > width;
    let p = 'p-2';
    if (isPortrait) {
      if (baseSize * 0.95 >= 1024) p = 'p-5';
      if (baseSize * 0.95 >= 768) p = 'p-3';
      if (baseSize * 0.95 >= 512) p = 'p-2.5';
    } else {
      if (baseSize * 0.8 >= 1024) p = 'p-5';
      if (baseSize * 0.8 >= 768) p = 'p-3';
      if (baseSize * 0.8 >= 512) p = 'p-2.5';
    }
    return p;
  }, [windowDimensions]);

  const onClose = async (e) => {
    dispatch(setIsOpen(false));

    // Reset
    zoomCenterRef.current = {
      zoomLevel: 1,
      x: 256,
      y: 256,
    };
    await editor?.current.getInstance().zoom(zoomCenterRef.current);

    toolRef.current = null;
    await editor.current?.getInstance().stopDrawingMode();
  };

  const loadBaseImage = useCallback(
    async (editor, size) => {
      const { image } = await resizeBase64(backgroundImage, size, size);
      const resp = await fetch(image);
      const url = URL.createObjectURL(await resp.blob());

      editor.current?.getInstance().loadImageFromURL(url, 'Inspiration');

      /* Load image colors */
      try {
        const vibrant = new Vibrant(url);
        const palette = await vibrant.getPalette();

        const paletteAvailable =
          palette &&
          palette.Vibrant &&
          Object.values(palette)
            .map((color) => color.hex)
            .filter((item) => !!item).length > 0;

        if (paletteAvailable) {
          dispatch(
            setPresetColor(
              Object.values(palette)
                .map((color) => color.hex)
                .filter((item) => !!item)
            )
          );
          dispatch(
            selectColor(Object.values(palette).map((color) => color.hex)[0])
          );
          dispatch(updateTool());
        }

        if (editor) {
          await editor.current?.getInstance().stopDrawingMode();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [backgroundImage, dispatch]
  );

  const loadIcons = async () => {
    await editor.current?.getInstance().registerIcons(SHAPES);
  };

  const editorLoader = useCallback(
    async (node) => {
      if (node)
        node.getInstance()._graphics._canvas.preserveObjectStacking = true; // Don't bring objects forward when clicked
      if (node) {
        node.getInstance()._graphics._canvas.on('mouse:dblclick', async () => {
          // Bring objects forward on double click
          // console.log("Double click", selectionEventRef.current?.id == editor.current?.getInstance()._graphics.getObjectId(editor.current?.getInstance()._graphics._canvas._activeObject), selectionEventRef.current?.id, editor.current?.getInstance()._graphics.getObjectId(editor.current?.getInstance()._graphics._canvas._activeObject), editor.current?.getInstance()._graphics._canvas._activeObject)
          if (
            selectionEventRef.current?.id ===
            editor.current
              ?.getInstance()
              ._graphics.getObjectId(
                editor.current?.getInstance()._graphics._canvas._activeObject
              )
          ) {
            // console.log("Bringing object to front")
            await editor.current
              ?.getInstance()
              ._graphics._canvas.bringToFront(
                editor.current?.getInstance()._graphics._canvas._activeObject
              );
          }
        });

        // await node.getInstance().resizeCanvasDimension({ maxWidth: EDITOR_SIZE, maxHeight: EDITOR_SIZE });
        node.getInstance()._graphics._canvas.width = 1024;
        node.getInstance()._graphics._canvas.height = 1024;
      }

      editor.current = node;

      if (node) {
        await loadIcons();
        setEditor(node);
      }

      if (node && backgroundImage && editorContainer?.current) {
        setTimeout(async () => {
          await loadBaseImage(editor, 1024);
          await node.getInstance().resizeCanvasDimension({
            maxWidth: EDITOR_SIZE,
            maxHeight: EDITOR_SIZE,
            width: EDITOR_SIZE,
            height: EDITOR_SIZE,
          });
        }, 10);
      }
    },
    [backgroundImage, loadBaseImage, setEditor]
  );

  useEffect(() => {
    if (editorContainer?.current) {
      dispatch(
        setCanvasSize(Math.min(1024, editorContainer?.current.clientWidth))
      );
    }

    // load bg-removed image

    const loadImage = async () => {
      const file = dataURLtoFile(initialImage, 'bg-removed');

      const currentURL = new URL(window.location.href);

      const params = new URLSearchParams(currentURL.search);
      const fromBGRemover = params.get('fromBGRemover');

      if (Boolean(fromBGRemover) && initialImage) {
        const { url, width, height } = await resizeInputImage(
          file,
          Math.floor(
            editor?.current.getInstance()._graphics._canvas.width * 0.8
          )
        );
        const imageObj = await editor.current
          ?.getInstance()
          .addImageObject(url);
        await editor.current
          ?.getInstance()
          .setObjectPropertiesQuietly(imageObj.id, {
            left: 256,
            top: 256,
            width,
            height,
          });
      }
      currentURL.searchParams.delete('fromBGRemover');
      window.history.replaceState({}, document.title, currentURL.toString());
    };
    loadImage();
  }, []);

  useEffect(() => {
    // dispatch(setPresetColor(DEFAULT_COLORS));
    // dispatch(setBackgroundImage(WhiteBGBas64));
    dispatch(setIsEditImage(false));
  }, [dispatch]);

  useEffect(() => {
    if (editor?.current && backgroundImage && editorContainer?.current) {
      setTimeout(() => {
        loadBaseImage(editor, 1024);
      }, 10);
    }
  }, [backgroundImage, editor, loadBaseImage]);

  const handleMouseDown = async (event, originPointer) => {
    try {
      if (editor.current?.getInstance().getDrawingMode() === 'TEXT') {
        await editor.current?.getInstance().discardSelection();
        await editor.current?.getInstance().addText('Double click to edit', {
          styles: {
            fill: '#000',
            fontSize: currentFontSize,
            fontFamily: currentFont,
            fontWeight: currentFontWeight,
          },
          position: {
            x: originPointer.x - 200,
            y: originPointer.y,
          },
          autofocus: true,
        });

        await editor.current?.getInstance().stopDrawingMode();
        dispatch(updateTool());
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleObjectActivated = async (event) => {
    if (toolRef.current === 'ERASER') {
      await editor?.current.getInstance().removeActiveObject();
      return;
    }

    if (event.type === 'cropzone') {
      dispatch(
        updateCropButtonLocation({
          left: event.left + event.width,
          top: event.top + event.height,
        })
      );
    }

    if (event.type === 'i-text') {
      dispatch(updateFontFamily(event.fontFamily));
      dispatch(updateFontWeight(event.fontWeight));
    }

    selectionEventRef.current = event;

    dispatch(updateSelectedElement(event));
  };

  const handleSelectionCleared = async (event) => {
    dispatch(updateSelectedElement());
  };

  const setCurrentTool = async (val) => {
    if (
      editor.current?.getInstance()._graphics.getZoomMode() === 'hand' &&
      val !== 'PAN'
    )
      await editor.current?.getInstance()._graphics.endHandMode();

    await editor.current
      ?.getInstance()
      .changeSelectableAll(!SELECTION_DISABLED_TOOLS.includes(val));
    await editor.current
      ?.getInstance()
      .changeCursor(CURSOR_STYLES[val] || 'default');
    dispatch(updateTool(val));
    toolRef.current = val;
  };

  const updateDrawingMode = async (mode) => {
    await editor.current?.getInstance().startDrawingMode(
      mode,
      ['LINE_DRAWING', 'FREE_DRAWING'].indexOf(mode) > -1 && {
        width: brushSize,
        color: activeColor,
      }
    );
    setCurrentTool(mode);
  };

  useEffect(() => {
    const updateLocation = (event) => {
      const rect = editor.current?.getInstance().getCropzoneRect();
      dispatch(
        updateCropButtonLocation({
          left: rect.left + rect.width,
          top: rect.top + rect.height,
        })
      );
    };

    const elem = editorContainer.current;

    if (currentTool === 'CROPPER') {
      elem?.addEventListener('mousemove', updateLocation);
    }

    return () => {
      if (currentTool === 'CROPPER') {
        elem?.removeEventListener('mousemove', updateLocation);
      }
    };
  }, [currentTool, dispatch, editor]);

  const handleSelectionColorPicker = useCallback(
    async (event, transform) => {
      dispatch(updateSelectionPicker({ isOpen: true, type: 'fill' }));
      dispatch(
        updateSelectionPickerLocation({
          top: event.clientY,
          left: event.clientX,
        })
      );
    },
    [dispatch]
  );

  const toggleEditor = useCallback(
    (_open = true) => {
      dispatch(setIsOpen(_open));
    },
    [dispatch]
  );

  const selectionStyle = {
    cornerSize: 10,
    transparentCorners: false,
    borderColor: '#858585',
    cornerColor: '#000000',
    cornerStrokeColor: '#858585',
    controls: {
      ...fabric.Object.prototype.controls,
      mtr: new fabric.Control({
        visible: false,
      }),
      rotate: new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -20,
        offsetX: 20,
        cursorStyle: 'crosshair',
        actionHandler: fabric.controlsUtils.rotationWithSnapping,

        render: RenderedRotateIcon,
        cornerSize: 32,
      }),
      delete: new fabric.Control({
        x: -0.5,
        y: -0.5,
        offsetY: -20,
        offsetX: -20,
        cursorStyle: 'pointer',
        mouseDownHandler: deleteObjectHandler,

        render: RenderedDeleteIcon,
        cornerSize: 38,
      }),
      resize: new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetY: 20,
        offsetX: 20,
        cursorStyle: 'crosshair',
        actionHandler: fabric.controlsUtils.scalingEqually,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,

        render: RenderedResizeIcon,
        cornerSize: 56,
      }),
      colorPicker: new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetY: 20,
        offsetX: -20,
        cursorStyle: 'pointer',
        mouseDownHandler: handleSelectionColorPicker,

        render: RenderedColorPickerIcon,
        cornerSize: 32,
      }),
    },
  };

  const handleStrokeSelectionColorPicker = useCallback(
    async (event, transform) => {
      dispatch(updateSelectionPicker({ isOpen: true, type: 'stroke' }));
      dispatch(
        updateSelectionPickerLocation({
          top: event.clientY,
          left: event.clientX,
        })
      );
    },
    [dispatch]
  );

  const rootRef = useRef();
  const isPickerOpen = useSelector(
    (state) => state.imageEditor.selection.pickerOpen
  );
  useEffect(() => {
    const clickListener = (e) => {
      if (!rootRef.current.contains(e.target) && !isPickerOpen) {
        editor.current.getInstance().discardSelection();
      }
    };
    document.addEventListener('mousedown', clickListener, { capture: false });
    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [editor, rootRef, isPickerOpen]);

  const { mode } = useGenerationEndpoint();

	const { width, height } = useContext(ContainerContext)
	const containerStyle = width && height ? {
		width: `${width}px`,
		height: `${height}px`,
	} : {};

  return (
    <div className='relative flex flex-col items-center overflow-hidden group/editor' ref={rootRef} style={containerStyle}>
      {isOpen && (
        <div
          className='backdrop'
          id='graphics-backdrop'
          onClick={() => toggleEditor(false)}
        />
      )}
      <div
        className={`flex flex-col items-center editor-container ${
          isOpen
            ? 'full-editor bg-app-black rounded-2xl p-4 border border-[#1A1E2D]'
            : 'w-full h-full'
        }`}
      >
        <div
          className={`${editorSize} ${isOpen ? '' : 'w-full'}
          [&>div]:h-full [&>div]:w-full [&>div]:flex [&>div]:items-center [&>div]:justify-center relative aspect-square`}
          ref={editorContainer}
        >
          <ToastUIImageEditor
            // ref={editor}
            ref={editorLoader}
            usageStatistics={false}
            onMousedown={handleMouseDown}
            onObjectActivated={handleObjectActivated}
            onSelectionCleared={handleSelectionCleared}
            cssMaxHeight={1024}
            cssMaxWidth={1024}
            selectionStyle={selectionStyle}
          />

					<V4Zoom editor={editor} zoomCenterRef={zoomCenterRef} />

          <BrushSizePreview editorContainer={editorContainer} editor={editor} />

          <CropConfirmation
            editor={editor}
            updateDrawingMode={updateDrawingMode}
            editorContainer={editorContainer}
          />

          <SelectionColorPicker editor={editor} />
          {false &&mode !== 'v4.0' && (
            <Toolbar
              editor={editor}
              onSave={onClose}
              toolRef={toolRef}
              setCurrentTool={setCurrentTool}
              updateDrawingMode={updateDrawingMode}
              zoomCenterRef={zoomCenterRef}
            />
          )}
          {!isOpen && (
            <ExpandIcon
              className='absolute flex h-fit w-fit right-0 top-0 cursor-pointer'
              width={40}
              height={40}
              onClick={toggleEditor}
            />
          )}
					{false && <DownloadAction editor={editor} zoomCenterRef={zoomCenterRef} />}
          <RedoUndo editor={editor} />
        </div>
				<V4Toolbar
					selectionStyle={selectionStyle}
					editor={editor}
					onSave={onClose}
					toolRef={toolRef}
					setCurrentTool={setCurrentTool}
					updateDrawingMode={updateDrawingMode}
					zoomCenterRef={zoomCenterRef}
					isOpen={isOpen}
					isModal
					handleStrokeSelectionColorPicker={
						handleStrokeSelectionColorPicker
					}
					handleSelectionColorPicker={handleSelectionColorPicker}
				/>
      </div>
    </div>
  );
};

export default V4GraphicEditor;
