import React from 'react';
import Modal from './Modal';
import { useState } from 'react';
import { ReactComponent as DangerIcon } from '../assets/Danger.svg'
import { ReactComponent as UncheckIcon } from "../assets/radio-check.svg";
import { ReactComponent as CheckIcon } from "../assets/radio-uncheck.svg";
import { ReactComponent as CheckmarkIcon } from "../assets/green_checkmark.svg";
import { ReactComponent as CloseIcon } from "../assets/CloseIcon.svg";
import { RadioGroup } from "@headlessui/react";
import { deleteUser } from '../reducers/userReducer';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const REASONS = [
	'It’s Too expensive.',
	'I’m not getting the results I want.',
	"I used it for a project and I don't need it anymore.",
	'I was just testing it.',
	'I encountered technical issues.',
	'Other'
];

const DeleteAccountModal = (props) => {
	const { stage, setStage } = props;

	const dispatch = useDispatch();

	const [state, setState] = useState({
		reason: '',
		description: '',
	});

	const onClose = () => {
		setStage(0)
	}

	const deleteAccount = async () => {
		try {
			await dispatch(deleteUser(state)).unwrap();

			setStage(0);
      toast("Your account has been deleted", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
		} catch(e) {
			console.log(e);
      toast(e.response?.data?.details || `An error occured.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
		}
	}

	return (
		<>
			<Modal isOpen={stage === 1} onClose={onClose}>
				<div className='text-white bg-app-bg-gray rounded-lg p-4 px-4 w-[400px] text-center flex flex-col items-center gap-6' onClick={e => e.stopPropagation()}>
					<div className="space-y-2">
						<DangerIcon className="w-8 h-8 mx-auto" />
						<h3 className="text-lg font-bold text-center">
							You're about to delete your account
						</h3>
					</div>
					
					<p className="text-center text-sm">
						All account data, and generated logos will be <br/>
						<span className="font-bold text-danger">DELETED</span>
						{" "} from the app immediately. <br/>
						This step cannot be undone.
					</p>

					<button 
						className="self-stretch text-white font-bold bg-button-purple rounded-lg p-2 "
						onClick={onClose}
					>
						Keep my Account
					</button>
					<button 
						className="self-stretch border-danger-border border-solid border text-fade-button font-bold rounded-lg p-2"
						onClick={() => setStage(2)}
					>
						Delete my account
					</button>
					
				</div>
			</Modal>
			<Modal isOpen={stage === 2} onClose={onClose}>
				<div 
					className={`w-[550px] max-w-11/12 flex flex-col gap-6 justify-between items-center overflow-hidden relative bg-app-black rounded-md pb-8 pt-10`}
					onClick={(e) => e.stopPropagation()}
				>
					<button
						className="w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center"
						onClick={onClose}
					>
						<CloseIcon className="w-6 h-6" />
					</button>

					<div className={`flex flex-col self-stretch items-center gap-4`}>
						<h2 className="text-white text-center text-2xl font-bold font-montserrat">
							Why are you deleting your account?
						</h2>
						<h3 className="text-modal-title text-center text-sm font-light font-montserrat">
							We're sorry to see you had to delete your Logo Diffusion subscription. 
							<br />
							Could you share the reason with us? 
						</h3>
					</div>
					<RadioGroup
						value={state.reason}
						onChange={(v) => setState(s => ({ ...s, reason: v }))}
						className="w-10/12"
					>
						<div className="space-y-2 flex flex-col mx-auto ">
							{REASONS.map((reason) => (
								<RadioGroup.Option
									key={reason}
									value={reason}
									className={({ active, checked }) => `
										${
											checked
												? "bg-app-bg-gray bg-opacity-75   border-app-green border-[2px]"
												: "bg-transparent border border-radio-border"
										}
										relative flex items-center cursor-pointer rounded-2xl px-3 py-3 shadow-md focus:outline-none  border-solid text-white`}
								>
									{({ active, checked }) => (
										<>
											{!checked ? (
												<CheckIcon className="w-6 h-6 mr-2" />
											) : (
												<UncheckIcon className="w-6 h-6 mr-2" />
											)}
											<RadioGroup.Label
												as="p"
												className={`text-start text-white flex-grow`}
											>
												{reason}
											</RadioGroup.Label>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>

					<label className="text-white font-semibold flex flex-col gap-2 w-10/12">
						Tell us more
						<textarea 
							className="rounded-lg border border-solid border-radio-border bg-white/10" rows={2}
							value={state.description}
							onChange={e => setState(s => ({ ...s, description: e.target.value }))}
						>
						</textarea>
					</label>
					
					<div className="flex flex-row items-center justify-around w-10/12">
						<button
							className="text-sm text-danger"
							onClick={deleteAccount}
						>
							Delete my account
						</button>
						<button 
							className="self-stretch text-sm text-white font-semibold bg-button-purple rounded-md py-3 px-6"
							onClick={onClose}
						>
							I'll give it another chance
						</button>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default DeleteAccountModal
