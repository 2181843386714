import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { relogUser } from "../reducers/userReducer";

function ProtectedRoute(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
	const location = useLocation();

  const user = useSelector(state => state.userSlice.user);
	const isLoggedOut = useSelector(state => state.userSlice.isLoggedOut);
	const isLoading = useSelector(state => state.userSlice.isLoading);

	const [userLoaded, setUserLoaded] = useState(false) ;

  useEffect(() => {
		// if (isLoading) return;
		if(isLoggedOut) {
			navigate(`/login${location.search}`);
			return ;
		}

		if (userLoaded) return;

		// if(!user) {
			dispatch(relogUser()).unwrap().then((res) => {
				setUserLoaded(true)
			}).catch(e => navigate(`/login${location.search}`));
		// }
	}, [isLoggedOut, navigate, dispatch, location.search, userLoaded]);

  return props.children;
}

export default ProtectedRoute;
