import PromptToLogo from '../assets/tutorials/01-prompt-to-logo.jpg'
import Redesign2D from '../assets/tutorials/02-redesign-2d.jpg'
import Sketch2D from '../assets/tutorials/03-sketch-2d.jpg'
import Redesign3D from '../assets/tutorials/04-redesign-3d.jpg'
import PhotoToIllustration from '../assets/tutorials/05-to-illustration.jpg'
import Illustration2D from '../assets/tutorials/06-illustration-2d.jpg'
import Sketch3D from '../assets/tutorials/07-sketch-3d.jpg'
import Sketch3DColor from '../assets/tutorials/08-sketch-3d-color.jpg'
import IntroV2Txt from '../assets/tutorials/intro-v2-txt2img.jpg'
import TextEffects from '../assets/tutorials/text-effects.jpg'
import BrandAssets from '../assets/tutorials/brand-assets.jpg'
import Turning2D3D from '../assets/tutorials/2d-3d.png'
import ImageToLogo from '../assets/tutorials/image-to-logo.jpg'
import SketchToLogo from '../assets/tutorials/sketch-to-logo.jpg'
import V3Tutorial from '../assets/tutorials/V3-thumb.jpg'

import V4Tutorial01 from '../assets/tutorials/v4/01.jpg'
import V4Tutorial02 from '../assets/tutorials/v4/02.jpg'
import V4Tutorial03 from '../assets/tutorials/v4/03.jpg'

const tutorials = [
	// V4
  {
    name: "Logo Diffusion V.4 is out, what's new?",
    ytUrl: "https://youtube.com/embed/hJLtSnI5CTQ",
    duration: "21:32",
    thumbnail: V4Tutorial01,
		v: 'v4.0',
  },
  {
    name: "Sketch to A.I logo design, with logo Diffusion V.4",
    ytUrl: "https://youtube.com/embed/-CyEqAgl_RA",
    duration: "11:24",
    thumbnail: V4Tutorial02,
		v: 'v4.0',
  },
  {
    name: "Transfer any style to your logo",
    ytUrl: "https://youtube.com/embed/Bbn5aG5IrMk",
    duration: "05:13",
    thumbnail: V4Tutorial03,
		v: 'v4.0',
  },
	// V3
  {
    name: "Logo Diffusion V.3 is out, what's new? | A.I logo design",
    ytUrl: "https://youtube.com/embed/6zsahu7LmwA",
    duration: "10:36",
    thumbnail: V3Tutorial,
		v: 'v3.0',
  },
	{
    name: "Turn a Sketch to logo with Logo Diffusion V2.0",
    ytUrl: "https://youtube.com/embed/NO2lPu-yVrQ",
    duration: "06:14",
    thumbnail: SketchToLogo,
		v: 'v3.0',
  },
  {
    name: "Turn an image to a logo or illustration with Logo Diffusion V2.0",
    ytUrl: "https://youtube.com/embed/ohQDMWohO2g",
    duration: "06:03",
    thumbnail: ImageToLogo,
		v: 'v3.0',
  },
  {
    name: "Text effects with logo Diffusion V2.0",
    ytUrl: "https://youtube.com/embed/vfQRpvtQnzs",
    duration: "07:05",
    thumbnail: TextEffects,
		v: 'v3.0',
  },
  {
    name: "Creating Brand assets using A.I | Logo Diffusion v3.0",
    ytUrl: "https://youtube.com/embed/Z3FNhU_zX2w",
    duration: "08:28",
    thumbnail: BrandAssets,
		v: 'v3.0',
  },
  {
    name: "Turning a 2D logo into a 3D logo using A.I.",
    ytUrl: "https://youtube.com/embed/k4q-mnaesm8",
    duration: "04:59",
    thumbnail: Turning2D3D,
		v: 'v3.0',
  },
	// V2
  {
    name: "Getting Started With Logo Diffusion V2.0, Text to image.",
    ytUrl: "https://youtube.com/embed/8fJlatKdovw",
    duration: "08:53",
    thumbnail: IntroV2Txt,
		v: 'v2.0',
  },
	{
    name: "Turn a Sketch to logo with Logo Diffusion V2.0",
    ytUrl: "https://youtube.com/embed/NO2lPu-yVrQ",
    duration: "06:14",
    thumbnail: SketchToLogo,
		v: 'v2.0',
  },
  {
    name: "Turn an image to a logo or illustration with Logo Diffusion V2.0",
    ytUrl: "https://youtube.com/embed/ohQDMWohO2g",
    duration: "06:03",
    thumbnail: ImageToLogo,
		v: 'v2.0',
  },
  {
    name: "Text effects with logo Diffusion V2.0",
    ytUrl: "https://youtube.com/embed/vfQRpvtQnzs",
    duration: "07:05",
    thumbnail: TextEffects,
		v: 'v2.0',
  },
  {
    name: "Creating Brand assets using A.I | Logo Diffusion v2.0",
    ytUrl: "https://youtube.com/embed/Z3FNhU_zX2w",
    duration: "08:28",
    thumbnail: BrandAssets,
		v: 'v2.0',
  },
  {
    name: "Turning a 2D logo into a 3D logo using A.I.",
    ytUrl: "https://youtube.com/embed/k4q-mnaesm8",
    duration: "04:59",
    thumbnail: Turning2D3D,
		v: 'v2.0',
  },

  {
    name: "Text prompt to logo & editing logo outputs",
    ytUrl: "https://youtube.com/embed/zD2u3XXSACo",
    duration: "04:44",
    thumbnail: PromptToLogo,
		v: 'v1.1',
  },
  {
    name: "How to create a 2D logo from a basic sketch",
    ytUrl: "https://youtube.com/embed/XSYn3wYyyXE",
    duration: "02:09",
    thumbnail: Sketch2D,
		v: 'v1.1',
  },
  {
    name: "How to redesign a 2D logo into new concepts",
    ytUrl: "https://youtube.com/embed/p-5pbbqgXdo",
    duration: "02:31",
    thumbnail: Redesign2D,
		v: 'v1.1',
  },
  {
    name: "How to turn any 2D logo into a 3D logo.",
    ytUrl: "https://youtube.com/embed/4zBnKjoAaf8",
    duration: "03:51",
    thumbnail: Redesign3D,
		v: 'v1.1',
  },
  {
    name: "How to turn any photo into a 2d/3d illustration",
    ytUrl: "https://youtube.com/embed/SsK50bZkCY4",
    duration: "03:38",
    thumbnail: PhotoToIllustration,
		v: 'v1.1',
  },
  {
    name: "How to turn any photo into a 2d illustration",
    ytUrl: "https://youtube.com/embed/FRsK7oKBa4M",
    duration: "00:59",
    thumbnail: Illustration2D,
		v: 'v1.1',
  },
  {
    name: "How to create a 3D Icon from a basic sketch",
    ytUrl: "https://youtube.com/embed/uBK7nbseXOk",
    duration: "03:05",
    thumbnail: Sketch3D,
		v: 'v1.1',
  },
  {
    name: "3D illustration from a color sketch with text",
    ytUrl: "https://youtube.com/embed/dHi1tlYOpBU",
    duration: "04:01",
    thumbnail: Sketch3DColor,
		v: 'v1.1',
  },
];

export default tutorials;
