import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partialUpdateStatePayload } from "../reducers/formReducer";

export const useLoraValue = ({ loraIndex }) => {
  const loras = useSelector((state) => state.form.payload.loras);

	const lora = useMemo(() => loras?.[loraIndex] || ['', 0, 0], [loras, loraIndex])


	const dispatch = useDispatch();

	const updateValue = (ind) => (val) => {
		const newVal = val === '-' || val.endsWith('.') ? val : Number(val)

		if (loras.length <= loraIndex) return;

		if (ind === 1) {
			dispatch(partialUpdateStatePayload({ loras: loras.map((l, i) => {
				if (i === loraIndex)
					return [l[0], newVal, l[2]]
				else return l
			})}));
		} else if (ind === 2) {
			dispatch(partialUpdateStatePayload({ loras: loras.map((l, i) => {
				if (i === loraIndex)
					return [l[0], l[1], newVal]
				else return l
			})}));
		}

	}

	const updateLora = (val) => {
		if (val) {
			let newLoras = [...loras]
			if (loras.length <= loraIndex) {
				newLoras = [...loras, ...Array(loraIndex + 1 - loras.length).fill(["", 0, 0])] 
			}
			dispatch(partialUpdateStatePayload({ loras: newLoras.map((l, i) => {
				if (i === loraIndex)
					return [val, l[1], l[2]]
				else return l
			})}));
		} else {
			dispatch(partialUpdateStatePayload({ loras: loras.filter((_, i) => i !== loraIndex) }));
		}
	}


	return { lora, updateValue, updateLora,  }
}
