import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import {Buffer} from 'buffer';
import { EDITOR_SIZE, IMAGE_EXPORT_SIZE } from './editor/settings';

function ImageCropper(props) {
  const initialCrop = {
    unit: 'px',
    x: 25,
    y: 25,
    width: 250,
    height: 250
  };;

  const [crop, setCrop] = useState(initialCrop);
  const [completeCrop, setCompleteCrop] = useState(null);

  const imageRef = useRef(null);

  const handleSetCrop = (data) => {
    setCrop(data);
  }

  const handleCancel = () => {
    setCrop(initialCrop);
    props.onCancel();
  }

  const onSubmitCrop = () => {
    // create a canvas element to draw the cropped image
    const canvas = document.createElement("canvas");

    // get the image element
    const imageUrl = props.imageToCrop;
    const image = new Image();

    const cropToUse = crop;

    image.onload = () => {
      // draw the image on the canvas
      if (image) {
        const scaleX = image.naturalWidth / imageRef.current.clientWidth;
        const scaleY = image.naturalHeight / imageRef.current.clientHeight;
        const ctx = canvas.getContext("2d");
        // const pixelRatio = window.devicePixelRatio;

        canvas.width = IMAGE_EXPORT_SIZE
        canvas.height = IMAGE_EXPORT_SIZE

        // ctx.drawImage(
        //   image,
        //   0,
        //   0,
        //   512,
        //   512,
        // )

        if (ctx) {
          // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = "high";

          ctx.drawImage(
            image,
            cropToUse.x * scaleX,
            cropToUse.y * scaleY,
            cropToUse.width * scaleX,
            cropToUse.height * scaleY,
            0,
            0,
            IMAGE_EXPORT_SIZE,
            IMAGE_EXPORT_SIZE
            // cropToUse.width * scaleX,
            // cropToUse.height * scaleY
          );
        }

        const base64Image = canvas.toDataURL("image/png"); // can be changed to jpeg/jpg etc

        if (base64Image) {
          const fileType = base64Image.split(";")[0].split(":")[1];

          const buffer = Buffer.from(
            base64Image.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );

          const file = new File([buffer], 'image', { type: fileType });
          setCrop(initialCrop);
          props.onConfirm(file, base64Image);
        }
      }
    }

    image.src = imageUrl;
  }

  return (
    <div className={`${!props.isCropping && 'hidden'} fixed top-0 right-0 bottom-0 left-0 bg-app-black bg-opacity-75 z-30 flex flex-col items-center justify-center`}>
      <div className='p-4 bg-app-bg-gray flex flex-col gap-4 border border-gray-700 rounded-lg'>
        <ReactCrop
          crop={crop}
          onChange={c => handleSetCrop(c)}
          aspect={1}
          style={{ backgroundColor: 'rgb(28,28, 36)' }}
        >
          <img className="max-h-96" src={props.imageToCrop} alt="to crop" ref={imageRef}/>
        </ReactCrop>

        <div className='flex items-center justify-between'>
          <h1 className='text-white font-bold text-sm'>Crop image</h1>

          <div className='flex gap-2 items-center'>
            <button
              className='text-xs font-bold text-app-green border border-app-green rounded-md p-1'
              onClick={onSubmitCrop}
            >
              Confirm
            </button>

            <button
              className='text-xs font-bold text-red-400 border border-red-400 rounded-md p-1'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageCropper;
