import { EDITOR_SIZE } from "../settings";


export const SVG_RESIZE_MULTIPLIER = Math.round(EDITOR_SIZE / 128);

const scale = (shape, scale_ratio=SVG_RESIZE_MULTIPLIER) => {
	let ret = "";
	let seg = "";

	const process_segment = (seg) => {
		return parseFloat(seg) * scale_ratio;
	};

	Array.from(shape).forEach((c, i) => {
		if (/^[-.0-9]$/g.test(c)) {
			seg += c;
		} else {
			if (seg.length > 0) {
				ret += process_segment(seg);
				seg = "";
			}
			ret += c;
		}
	});
	if (seg.length > 0) ret += process_segment(seg);

	return ret;
}

export const SHAPES = Object.entries({
	// Autoscale shapes
  // Rectangle round corners
  ROUND_RECT: 'M2.15858 28.2169C0.0114552 26.2432 0.00345478 23.5526 0.00145467 20.765C-0.00121215 16.6689 -0.000212219 12.5729 0.00445472 8.47678C0.00545478 7.11797 0.0894595 5.51521 0.503483 4.31138C1.19952 2.29167 2.92262 0.76689 5.02675 0.302956C5.8888 0.112984 6.95719 0.0159974 8.23193 0.011998C12.6635 -2.71162e-07 17.0951 -0.00299947 21.5267 0.00299966C22.6694 0.0043328 23.7425 0.0893209 24.7459 0.257963C27.013 0.638908 28.9311 2.36566 29.5802 4.55034C29.9782 5.89015 29.9982 7.49592 29.9992 8.93571C30.0025 13.1158 29.9955 17.2955 29.9782 21.4749C29.9722 23.0507 29.9542 24.6265 29.3732 26.0233C28.5941 27.895 26.947 29.2768 24.9519 29.7027C24.0258 29.9007 22.8924 29.9997 21.5517 29.9997C17.2268 30.0017 12.9015 29.9943 8.57595 29.9777C6.27082 29.9697 3.97869 29.8897 2.15858 28.2169Z',
  ROUND_TRIANGLE: 'M1.05766 19.613L11.9166 3.02951C12.5326 2.0982 13.3698 1.33403 14.3534 0.805367C15.3369 0.2767 16.4361 0 17.5527 0C18.6693 0 19.7685 0.2767 20.752 0.805367C21.7355 1.33403 22.5727 2.0982 23.1887 3.02951L34.0477 19.5245C34.7187 20.5428 35.1017 21.7236 35.1557 22.9419C35.21 24.1601 34.9338 25.3703 34.356 26.4442C33.7783 27.5181 32.9205 28.4157 31.8741 29.0416C30.8275 29.6674 29.6312 29.9988 28.4116 30H6.6937C5.48809 29.9885 4.30744 29.6548 3.27428 29.0333C2.24111 28.4119 1.39305 27.5255 0.818022 26.4656C0.242997 25.4059 -0.0380084 24.2116 0.00412913 23.0066C0.0462667 21.8017 0.410013 20.63 1.05766 19.613Z',
  VERY_ROUND_RECT: 'M5.88045 28.627C1.63049 26.943 0 23.0138 0 14.8881C0 2.80657 4.22323 0 14.8882 0C25.5532 0 29.9368 2.80657 29.9368 14.8881C29.9368 26.5153 25.7136 29.9634 14.9417 29.9634C11.8619 30.1385 8.77871 29.6838 5.88045 28.627Z',
  HEXAGON: 'M29.575 12.4251L23.6715 2.18042C23.3921 1.69383 22.9892 1.28959 22.5035 1.00853C22.0178 0.727463 21.4666 0.579533 20.9055 0.579675H9.09849C8.5411 0.575606 7.99238 0.717721 7.50704 0.99185C7.0217 1.26598 6.6167 1.66254 6.33242 2.14201L0.428912 12.3867C0.147927 12.8734 0 13.4254 0 13.9874C0 14.5494 0.147927 15.1015 0.428912 15.5881L6.33242 25.8328C6.61447 26.3162 7.01829 26.7172 7.50363 26.9958C7.98897 27.2744 8.53886 27.4209 9.09849 27.4207H20.9055C21.4614 27.4248 22.0087 27.2841 22.4938 27.0124C22.9788 26.7407 23.3847 26.3474 23.6715 25.8712L29.575 15.6266C29.8535 15.139 30 14.5873 30 14.0258C30 13.4644 29.8535 12.9126 29.575 12.4251Z',
  OCTA_SNOWFLAKE: 'M18 0H12V7.75736L6.51473 2.27208L2.27208 6.51471L7.75737 12H0V18H7.75736L2.27208 23.4852L6.51473 27.728L12 22.2426V30H18V22.2426L23.4854 27.728L27.728 23.4854L22.2426 18H30V12H22.2426L27.728 6.51471L23.4854 2.27207L18 7.75736V0Z',
  SHIELD: 'M23.8051 1.67059C20.3634 0.556981 16.7676 -0.00678914 13.1502 6.16925e-05C9.45931 0.00257467 5.78985 0.560304 2.26494 1.65453C1.60707 1.85851 1.03202 2.2682 0.624328 2.82335C0.216639 3.3785 -0.00216439 4.04982 9.06966e-05 4.73858V14.0014C-0.00602686 15.6339 0.297384 17.2527 0.894252 18.7721C2.25423 22.1828 5.2312 26.8463 11.6831 29.7216C12.0993 29.9068 12.55 30.0017 13.0056 30C13.4648 29.9982 13.9187 29.9016 14.3388 29.7162C23.6284 25.572 25.6416 17.5888 25.9522 16.0307C25.9911 15.8296 26.0108 15.6252 26.0111 15.4203V4.73858C26.0147 4.05971 25.8032 3.39713 25.4069 2.84593C25.0106 2.29474 24.4498 1.88327 23.8051 1.67059Z',
  HEART: 'M31.045 3.5765C29.2995 1.68331 26.9043 0.640625 24.3003 0.640625C22.3539 0.640625 20.5713 1.25602 19.0019 2.46954C18.2101 3.08203 17.4926 3.83151 16.86 4.70612C16.2276 3.83171 15.5099 3.08203 14.7178 2.46954C13.1487 1.25602 11.3661 0.640625 9.41967 0.640625C6.81565 0.640625 4.42028 1.68331 2.67475 3.5765C0.950087 5.44757 0 8.00377 0 10.7745C0 13.6262 1.06277 16.2367 3.3444 18.9899C5.38552 21.4527 8.31909 23.9528 11.7163 26.8478C12.8763 27.8365 14.1912 28.9571 15.5564 30.1508C15.9172 30.4667 16.3805 30.6408 16.86 30.6406C17.3393 30.6408 17.8023 30.4669 18.163 30.1513C19.5283 28.9573 20.844 27.8362 22.0045 26.847C25.4012 23.9525 28.3347 21.4527 30.3759 18.9896C32.6575 16.2368 33.72 13.6263 33.72 10.7742C33.72 8.00377 32.7699 5.44757 31.045 3.5765Z',
  RECT: 'M 0 0 H 30 V 30 H 0 L 0 0',
  TRIANGLE: 'M33.9973 26.7772L18.9975 1.06312C18.1703 -0.354372 16.1218 -0.354372 15.2946 1.06312L0.294774 26.7772C-0.537719 28.2064 0.491915 30 2.14619 30H32.1459C33.8002 30 34.8298 28.2064 33.9973 26.7772Z',
  QUAD_STAR: 'M14.9864 30C15.9638 30 16.7648 29.2806 16.9005 28.2624C18.2443 19.1946 19.4796 17.9321 28.1947 16.9412C29.1991 16.819 29.9729 15.9775 29.9729 15.0001C29.9729 14.009 29.2127 13.1946 28.2082 13.0453C19.5475 11.8371 18.4752 10.7783 16.9005 1.72406C16.724 0.719516 15.9502 0 14.9864 0C13.9955 0 13.2081 0.719515 13.0453 1.73756C11.7286 10.7919 10.4933 12.0544 1.79184 13.0453C0.760158 13.1811 0 13.9955 0 15.0001C0 15.9775 0.733018 16.7919 1.7647 16.9412C10.4389 18.1765 11.4977 19.2217 13.0453 28.2761C13.2489 29.2941 14.0362 30 14.9864 30Z',
  OCTA_STAR: 'M27.5604 11.7364C26.9922 11.3897 26.4957 10.9372 26.0979 10.4036C25.9831 9.71296 26.0093 9.00626 26.175 8.32607C26.3796 6.95464 26.6121 5.39893 25.6061 4.39393C24.6 3.38893 23.0443 3.61929 21.6739 3.825C20.9938 3.99122 20.287 4.01746 19.5964 3.90214C19.0628 3.50428 18.6103 3.00782 18.2636 2.43964C17.4268 1.29643 16.4775 0 15 0C13.5225 0 12.5732 1.29643 11.7364 2.43964C11.3897 3.00782 10.9372 3.50428 10.4036 3.90214C9.713 4.01746 9.00619 3.99122 8.32607 3.825C6.95464 3.62036 5.4 3.38679 4.39286 4.39286C3.38571 5.39893 3.62036 6.95357 3.825 8.32607C3.99068 9.00626 4.01693 9.71296 3.90214 10.4036C3.50428 10.9372 3.00782 11.3897 2.43964 11.7364C1.29643 12.5732 0 13.5225 0 15C0 16.4775 1.29643 17.4268 2.43964 18.2636C3.00782 18.6103 3.50428 19.0628 3.90214 19.5964C4.01693 20.287 3.99068 20.9937 3.825 21.6739C3.62036 23.0454 3.38786 24.6011 4.39393 25.6061C5.4 26.6111 6.95464 26.3796 8.32607 26.175C9.00627 26.0093 9.71296 25.9831 10.4036 26.0979C10.9375 26.4954 11.39 26.992 11.7364 27.5604C12.5732 28.7036 13.5225 30 15 30C16.4775 30 17.4268 28.7036 18.2636 27.5604C18.61 26.992 19.0625 26.4954 19.5964 26.0979C20.287 25.9831 20.9937 26.0093 21.6739 26.175C23.0454 26.3796 24.6 26.6132 25.6061 25.6061C26.6121 24.5989 26.3796 23.0464 26.175 21.6739C26.0093 20.9937 25.9831 20.287 26.0979 19.5964C26.4957 19.0628 26.9922 18.6103 27.5604 18.2636C28.7036 17.4268 30 16.4775 30 15C30 13.5225 28.7036 12.5732 27.5604 11.7364Z',
  SHIELD_2: 'M22.8917 3.20392C22.8917 7.33636 22.8917 17.4091 22.8917 17.4091C22.8917 20.572 20.8771 23.082 18.7084 25.0867C16.1968 27.4113 13.2947 29.0715 11.4475 30C9.59698 29.0715 6.69492 27.4113 4.1833 25.0867C2.01455 23.082 0 20.572 0 17.4091V3.20392C3.32531 3.31219 5.75491 2.88893 7.70056 2.14742C9.1278 1.60277 10.3401 0.885875 11.4458 0C12.5515 0.885875 13.7639 1.60277 15.1911 2.14742C17.1368 2.88893 19.5664 3.31219 22.8917 3.20392Z',
  STAR: 'M29.1521 13.1198L22.3615 18.0535L24.9552 26.0361C25.2347 26.8993 24.9418 27.8016 24.2071 28.3339C23.8412 28.6001 23.4199 28.7337 22.9996 28.7337C22.5793 28.7337 22.159 28.6001 21.7911 28.3339L15.0015 23.4002L8.21197 28.3339C7.47721 28.8672 6.52871 28.8672 5.796 28.3339C5.06124 27.8006 4.76837 26.8993 5.04789 26.0361L7.64163 18.0524L0.851031 13.1208C0.117301 12.5865 -0.175575 11.6852 0.104969 10.822C0.384486 9.95982 1.15213 9.40284 2.0585 9.40284H10.4532L13.046 1.42019C13.3265 0.556978 14.0941 0 15.0005 0C15.9069 0 16.6745 0.556977 16.9551 1.41916L19.5478 9.40284H27.9415C28.8489 9.40284 29.6155 9.95982 29.895 10.822C30.1756 11.6842 29.8827 12.5865 29.149 13.1198H29.1521Z',
}).reduce((shapes, [name, shape_params]) => ({
	[name]: scale(shape_params),
	...shapes,
}), {
	// Override shapes
	CIRCLE: 'M 0, 120 a 120,120 0 1,1 240,0 a 120,120 0 1,1 -240,0',
});

export const SHAPE_METADATA = Object.entries({
  ROUND_TRIANGLE: {
    canvasWidth: 35.6,
    canvasHeight: 30,
  },
  HEART: {
    canvasWidth: 33.7,
    canvasHeight: 30,
  },
  TRIANGLE: {
    canvasWidth: 34.29,
    canvasHeight: 30,
  },
  
}).reduce((metadataArr, [name, metadata]) => ({
	[name]: Object.entries(metadata).reduce((rest, [propName, propValue]) => ({
		...rest,
		[propName]: propValue * SVG_RESIZE_MULTIPLIER,
	}), {}),
	...metadataArr,
}), {})
