import React, { useMemo } from 'react';
import ImageUpload from '../ImageUpload';
import Radio from '../base/Radio';
import RedesignMethods from './RedesignMethods';
import { useDispatch, useSelector } from 'react-redux';
import {
  partialUpdateStatePayload,
  updateDetailLevel,
  updateEdgeThreshold,
  updateGuidenceStregth,
  updateImageAsControlImage,
  updateImageWeight,
  updateInputImage,
  updateMethod,
} from '../../reducers/formReducer';
import {
  DenoisingAcceptedValues,
  GuidenceAcceptedValues,
  SDXLDenoisingValues,
  ThresholdAcceptedValues,
  UseAsControlImageValues,
  detailLevelValues,
  imageWeightAcceptedValues,
} from './constants';
import AndvancedSettings from './AndvancedSettings';
import {
  setBackgroundImage,
  setIsEditImage,
} from '../../reducers/imageEditorReducer';
import Slider from '../base/Slider';
import { Tooltip } from 'react-tippy';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import useImageColorValues from './useImageColorValues';
import ColorInjection from './ColorInjection';
import SDDenoising from './SDDenoising';
import FormSlider from '../base/FormSlider';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import ImageUploadV4 from '../ImageUploadV4';
import V4StyleSelector from '../input/V4StyleSelector';
import StyleSettingsV4 from '../input/style-config/StyleSettingsV4';
import { STYLE_SETTINGS } from '../input/style-config/constants';
import RedesignV4 from './RedesignV4';

const Redesign = () => {
  const dispatch = useDispatch();

  const imageWeight = useSelector((state) => state.form.payload.imageWeight);
  const denoisingStrength = useSelector(
    (state) => state.form.payload.denoisingStrength
  );
  const edgeThreshold = useSelector(
    (state) => state.form.payload.edgeThreshold
  );
  const inputImage = useSelector((state) => state.form.payload.inputImage);
  const imageAsControlImage = useSelector(
    (state) => state.form.payload.image_as_control_image
  );
  const controlnetMode = useSelector(
    (state) => state.form.payload.controlnetMode
  );

  const detailLevel = useSelector((state) => state.form.payload.detailLevel);
  const detailLevelSlider = useMemo(
    () => [detailLevelValues.find((item) => item.value === detailLevel)?.name],
    [detailLevel]
  );

  const method = useSelector((state) => state.form.method);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const { mode } = useGenerationEndpoint();
  const { imageColorInfluence, setImageColorInfluence } = useImageColorValues();

  const { workspace } = useCurrentWorkspace();

  const handleImageWeight = (value) => {
    dispatch(updateImageWeight(value));
  };

  const hanldeDenoisingStrength = (value) => {
    dispatch(partialUpdateStatePayload({ denoisingStrength: value }));
    // dispatch(partialUpdateState(value))
  };
  const hanldeEdgeThreshold = (value) => {
    dispatch(updateEdgeThreshold(value));
    // dispatch(partialUpdateState(value))
  };

  const handleInputImage = (value) => {
    dispatch(updateInputImage(value));
    dispatch(setBackgroundImage(value));
    dispatch(setIsEditImage(true));
  };

  const handleRemoveImage = () => {
    dispatch(updateInputImage(null));
    dispatch(setBackgroundImage(null));
    if (method === 'ddd') dispatch(updateMethod('redesign'));
  };

  const handleImageAsControlImage = (value) => {
    dispatch(updateImageAsControlImage(value));
  };

  const handleDetailLevel = (values) => {
    dispatch(
      updateDetailLevel(
        detailLevelValues.find((item) => item.name === values[0]).value
      )
    );
  };

	if (mode === 'v4.0') {
		return <RedesignV4 />
	}

  return (
    <div
      className={`flex flex-col gap-4  ${
        workspace === 'image-workspace'
          ? '[&_.field-label]:font-bold [&_.field-label]:text-base overflow-auto custom-scroll py-4'
          : 'py-4 px-2 sm:px-3 sm:py-6 '
      }`}
    >
      {workspace === 'text-workspace' &&
        (
          <ImageUpload
            imageToUpload={inputImage}
            onRemoveUploadedImage={handleRemoveImage}
            handleImageSelected={handleInputImage}
          />
        )}
      {(mode === 'v2.0' || mode === 'v3.0') && (
        <>
          <FormSlider
            values={
              imageWeightAcceptedValues[mode][controlnetMode][
                imageAsControlImage
              ][method]
            }
            value={imageWeight}
            onChange={handleImageWeight}
            label={'Image Influence'}
            title='Defines the influence of your image, (Low) follows your image roughly, (High) follows your image more precisely.'
            appliedClasses={{
              optionContainer: 'max-w-[150px]',
            }}
          />
          <SDDenoising />
          <ColorInjection
            appliedClasses={{
              optionContainer: 'max-w-[150px]',
            }}
          />
        </>
      )}
      {mode === 'v1.1' && (
        <>
          <Radio
            values={imageWeightAcceptedValues[mode]}
            value={imageWeight}
            onChange={handleImageWeight}
            label={'Use Image Shape'}
            title='Defines the influence of the redesign method.'
            appliedClasses={{
              optionContainer: 'max-w-[150px]',
            }}
          />
          <Radio
            values={UseAsControlImageValues}
            value={imageAsControlImage}
            onChange={handleImageAsControlImage}
            label={'Use Image Colors'}
            title='Whether to preserve the image colors in the generation'
            appliedClasses={{
              option: 'w-[4.5rem] md:w-[2.85rem] justify-center',
            }}
            fixedValues
          />
          {imageAsControlImage ? (
            <Radio
              values={DenoisingAcceptedValues}
              value={denoisingStrength}
              onChange={hanldeDenoisingStrength}
              label={'Creative Freedom'}
              title='Higher values result in more creative outcomes, while lower values create closer variations to the input image.'
              disabled={!imageAsControlImage}
              appliedClasses={{
                optionContainer: 'max-w-[150px]',
              }}
            />
          ) : (
            <Tooltip
              title={'Only works when using image colors'}
              onShow={() => imageAsControlImage}
            >
              <Radio
                values={DenoisingAcceptedValues}
                value={denoisingStrength}
                onChange={hanldeDenoisingStrength}
                label={'Creative Freedom'}
                title='Higher values result in more creative outcomes, while lower values create closer variations to the input image.'
                disabled={!imageAsControlImage}
                appliedClasses={{
                  optionContainer: 'max-w-[150px]',
                }}
              />
            </Tooltip>
          )}
        </>
      )}
      {(mode === 'v1.1' || isDevModeEnabled) && (
        <>
          {mode !== 'v3.0' && <RedesignMethods />}
          <Radio
            values={ThresholdAcceptedValues}
            value={edgeThreshold}
            onChange={hanldeEdgeThreshold}
            label={'Edge Detection'}
            title='Higher values will make the edges less detailed'
            appliedClasses={{
              optionContainer: 'max-w-[150px]',
            }}
          />
          <Slider
            value={detailLevelSlider}
            onChange={handleDetailLevel}
            label={'Logo Details'}
            title='Default value is 0, higher values will add details, and lower values will remove details.'
            rangeInputProps={{
              min: -3,
              max: 3,
              step: 1,
            }}
            appliedClasses={{
              optionContainer: 'max-w-[150px]',
            }}
            showValue
          />
        </>
      )}
      {workspace === 'text-workspace' && <AndvancedSettings />}
    </div>
  );
};

export default Redesign;
