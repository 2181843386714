import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { ReactComponent as Header } from '../../assets/dashboard/header.svg';
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import { ReactComponent as BiggerIcon } from '../../assets/dashboard/bigger-icon.svg';
import { useDispatch } from 'react-redux';
import { setIsTutorialModalOpen } from '../../reducers/appReducer';
import V4Banner from '../../assets/tutorials/v4/v4-thumb.jpg'

const VersionAnnounceModal = (props) => {
  const [isV4AnnounceModal, setIsV4AnnounceModal] = useState(false);
	const dispatch = useDispatch()

  const handleDontShowAgain = useCallback(() => {
    setIsV4AnnounceModal(false);
    localStorage.setItem('hideV4AnnounceModal', 'true');
  }, []);

  useEffect(() => {
    const hideV3Announce = localStorage.getItem('hideV3AnnounceModal');
    if (!hideV3Announce) {
      setIsV4AnnounceModal(true);
    }
		// localStorage.removeItem('hideV3AnnounceModal');
  }, []);

  return (
    <Transition appear show={isV4AnnounceModal} as={React.Fragment}>
      <Dialog
        open={isV4AnnounceModal}
        onClose={() => setIsV4AnnounceModal(false)}
        className='relative z-40'
      >
        <Transition.Child
          as={React.Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/50' aria-hidden='true' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto custom-scroll'>
          <div className='flex min-h-full items-center justify-center p-4 text-center '>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className='w-auto max-w-[620px] flex flex-col justify-between items-center overflow-hidden relative bg-app-black rounded-md'
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center"
                  onClick={() => setIsV4AnnounceModal(false)}
                >
                  <CloseIcon className="w-6 h-6" />
                </button>
                <img src={V4Banner} alt="V4 is out now!" />
                <p className='text-[24px] text-white mt-[35px] w-[80%]'>
                  Logo Diffusion V.4 is out! See what's new?{' '}
                </p>
                <p className='text-[16px] text-modal-description mt-[23px] w-[80%]'>
									Watch our {' '}
									<button className="text-modal-link font-bold underline" onClick={() => {
										setIsV4AnnounceModal(false)
										dispatch(setIsTutorialModalOpen(true))
									}}>Video Preview </button>
									{' '}or read our{' '}
									<a className="text-modal-link font-bold underline" href="https://medium.com/@logodiffusion/logo-diffusion-v-4-release-notes-better-models-style-transfer-and-creative-upscaling-22bc1a092e61">
									Release Notes</a>
									detailing all 
									the new features and improvements We’ve introduced 
									into logo Diffusion V.4!
                </p>
                <div className='flex items-center justify-center gap-[23px] mt-[27px] mb-[40px]'>
                  <a
                    href='https://logodiffusion.medium.com/logo-diffusion-v-3-release-notes-better-results-more-features-and-text-based-logos-3aa0d1a87707'
                    target='_blank'
                    rel='noreferrer'
                    className='focus:outline-none'
                  >
                    <button className='flex justify-center items-center h-[50px] px-[12px] gap-1 text-[14px] font-semibold text-white bg-button-purple rounded-[12px] focus:outline-none'>
                      Watch the video
                      <BiggerIcon />
                    </button>
                  </a>
                  <button
                    className='flex justify-center items-center h-[50px] px-[12px] text-[14px] font-normal text-white bg-app-bg-gray rounded-[12px]'
                    onClick={handleDontShowAgain}
                  >
                    Don't show again
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default VersionAnnounceModal;
