export const BRUSH_SIZES = [
  { 
    size: 10,
    borderRadius: 5,
   },
   { 
    size: 18,
    borderRadius: 8,
   },
   { 
    size: 27,
    borderRadius: 10,
   },
   { 
    size: 36,
    borderRadius: 11,
   },
   { 
    size: 48,
    borderRadius: 18,
   },
]
