import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ConfirmIcon } from "../../../assets/green_checkmark.svg";
import { EDITOR_SIZE } from '../settings';

const CropConfirmation = (props) => {
  const { updateDrawingMode, editor, editorContainer } = props

  const buttonLoc = useSelector((state) => state.imageEditor.crop.buttonLoc);
  const currentTool = useSelector((state) => state.imageEditor.tool);

  const dispatch = useDispatch();

  const confirmCropZone = async (event) => {
    await editor.current?.getInstance().crop(editor.current?.getInstance().getCropzoneRect());
    await editor.current?.getInstance().resize({ width: EDITOR_SIZE, height: EDITOR_SIZE});
    await editor.current?.getInstance().stopDrawingMode();

    updateDrawingMode("FREE_DRAWING")
  }

  if (currentTool !== 'CROPPER') return <></>

  return (
    <button className='transition-all duration-150 absolute aspect-square group rounded-full p-2 bg-black flex items-center justify-center' onClick={confirmCropZone} style={{
      left: buttonLoc.left * Math.max(1, editorContainer.current.getBoundingClientRect().width / editor?.current.getInstance().getCanvasSize().width),
      top: buttonLoc.top * Math.max(1, editorContainer.current.getBoundingClientRect().height / editor?.current.getInstance().getCanvasSize().height),
    }}>
      <ConfirmIcon
        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[14px] lg:h-[14px] [&_path]:fill-white group-hover:[&_path]:fill-app-green `}
      />
    </button>
  )
}

export default CropConfirmation