export const V3_FILTER = [
  {
    id: 'all',
    name: 'All Styles',
    subItems: [],
    checked: true,
  },
  {
    id: 'logo-design',
    name: 'Logo Design',
    subItems: [
      {
        id: 'pictorial',
        name: 'Pictorial',
      },
      {
        id: 'mascot',
        name: 'Mascot',
      },
      {
        id: 'badge',
        name: 'Badge/Crest',
      },
      {
        id: 'cartoon',
        name: 'Cartoon',
      },
      /*
			{
				id: 'adcartoon',
				name: 'AdCartoon',
				icon: CartoonStyle,
			},
			*/
      {
        id: 'icon',
        name: 'Icon/Emoji',
      },
      {
        id: 'abstract',
        name: 'Abstract',
      },
      {
        id: 'line-art',
        name: 'Line Art',
      },
      {
        id: 'logo-type',
        name: 'Logo Type',
      },
      {
        id: 'monogram',
        name: 'Monogram',
      },
    ],
  },
  {
    id: '2d-art',
    name: '2D Art',
    subItems: [
      {
        id: 'stylized-cartoon',
        name: 'Stylized Cartoon',
      },
      {
        id: 'pixel-art',
        name: 'Pixel Art',
      },
      {
        id: 'flat-graphics',
        name: 'Flat Graphics',
      },
      {
        id: 'coloring-book',
        name: 'Coloring book',
      },
      {
        id: 'cyberpunk',
        name: 'CyberPunk',
      },
      {
        id: 'sticker',
        name: 'Sticker',
      },
      {
        id: 'marker-drawing',
        name: 'Marker Drawing',
      },
      {
        id: 'sketch-note',
        name: 'Sketch Note',
      },
      {
        id: 'color-sketch-note',
        name: 'Color Sketch Note',
      },
      {
        id: 'digital-art',
        name: 'Digital art',
      },
      {
        id: 'fantasy-art',
        name: 'Fantasy art',
      },
      {
        id: 'pop-art',
        name: 'Pop Art',
      },
      {
        id: 'comic-art',
        name: 'Comic Art',
      },
      {
        id: 'manga',
        name: 'Manga',
      },
      {
        id: 'kawaii',
        name: 'Kawaii',
      },
      {
        id: 'punk-collage',
        name: 'Punk Collage',
      },
      {
        id: 'dark-arts',
        name: 'Dark Arts',
      },
      {
        id: 'charcoal-sketch',
        name: 'Charcoal Sketch',
      },
      {
        id: 'halftone',
        name: 'Halftone',
      },
    ],
  },
  {
    id: '3d-art',
    name: '3D Art',
    subItems: [
      {
        id: 'stylized-3d',
        name: 'Stylized 3D',
      },
      {
        id: '3d',
        name: 'Realistic 3D',
      },
      {
        id: '3d-text',
        name: '3D Text',
        beta: true,
      },
      {
        id: 'inflated',
        name: 'Inflated',
      },
      {
        id: 'embroidered',
        name: 'Embroidered',
      },
      {
        id: 'isometric',
        name: 'Isometric',
      },
      {
        id: 'low-poly',
        name: 'Low Poly',
      },
    ],
  },
  {
    id: 'realistic',
    name: 'Realistic',
    subItems: [
      {
        id: 'cinematic',
        name: 'Cinematic',
      },
      {
        id: 'hyperrealism',
        name: 'Hyperrealism',
      },
      {
        id: 'craft-clay',
        name: 'Craft Clay',
      },
      {
        id: 'futuristic',
        name: 'Futuristic',
      },
      {
        id: 'origami',
        name: 'Origami',
      },
      {
        id: 'paper-craft',
        name: 'Papercraft',
      },
    ],
  },
  {
    id: 'fine-art',
    name: 'Fine Art',
    subItems: [
      {
        id: 'oil-painting',
        name: 'Oil Painting',
      },
      {
        id: 'fantasy-illustration',
        name: 'Fantasy Illustration',
      },
      {
        id: 'watercolor',
        name: 'Water Color',
      },
      {
        id: 'art-deco',
        name: 'Art Deco',
      },
      {
        id: 'bauhaus',
        name: 'Bauhaus',
      },
    ],
  },
];
