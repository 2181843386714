export const breakpoints = {
  MOBILE_XL: 680,
  MOBILE_MAX: 767,
  TABLET: 768,
  TABLET_L: 1023,
  LAPTOP: 1024,
  LAPTOP_SM: 1280,
  LAPTOP_M: 1440,
  LAPTOP_L: 1536,
  DESKTOP_S: 1784,
  DESKTOP_M: 2320,
  DESKTOP_L: 2820,
  DESKTOP: 3330,
};