import UserLayout from '../layouts/UserLayout';
import { Helmet } from 'react-helmet';

import TutorialModal from '../components/TutorialModal';

function Home() {
  return (
    <UserLayout>
      <Helmet>
        <title>Documentation | Logo Diffusion</title>
      </Helmet>

      <TutorialModal />
    </UserLayout>
  );
}

export default Home;
