import React, { useRef, useState } from 'react';
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { Tooltip } from "react-tippy";
import { toDataURL } from '../ImageUpload';

const InlineImageSelector = (props) => {
	const {
		value, label, title, onChange,
		disabled, appliedClasses={}, preview,
	} = props;

	const [fileName, setFileName] = useState();
	const fileRef = useRef(null);

	return (
		<label className={`flex flex-col gap-2 ${appliedClasses.container}`}>
			<input type="file" className="hidden" ref={fileRef} onChange={e => {
				if (e.target.files.length === 0) {
					return ;
				}
				const file = e.target.files[0];
				const url = URL.createObjectURL(file)
				setFileName(file.name)
				toDataURL(url, (base64) => onChange(base64), 'png');
			}} />
			<div className={`flex flex-row justify-between ${appliedClasses.main}`}>
				<div className={`text-gray-label font-poppins font-bold text-xs flex gap-1 items-center ${disabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
					{label}

					<Tooltip title={title} >
						<InfoIcon className={`${appliedClasses.infoTooltip}`}/>
					</Tooltip>
				</div>

					<div className="flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]">
						<input 
							className={` p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-full ${appliedClasses.optionContainer}`}
							value={fileName}
							disabled={disabled}
							onClick={() => fileRef.current?.click()}
						/>
					</div>
			</div>
			{preview && value && (
				<img src={value} className="max-h-[200px] rounded-lg" alt="IP Adapter image"/>
			)}
		</label>
	)
}

export default InlineImageSelector;
