import { Datagrid, List, TextField } from 'react-admin';

export const UserRoleList = () => (
  <List>
      <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="permissions" />
      </Datagrid>
  </List>
);

export default UserRoleList;
