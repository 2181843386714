import { DateInput, Edit, SimpleForm, ReferenceField, TextField, TextInput, Image, ImageField, BooleanInput, SelectInput, } from 'react-admin';
import Grid from '@mui/material/Grid';
import { STYLE_TRANSFER_STYLES } from '../StyleImage';

export const StyleTransferGalleryEdit = () => (
    <Edit>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
							
							<ImageField source="imageUrl" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }}/>
							<ImageField source="optimizedImageUrl" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }}/>
            </Grid>

            <Grid item xs={6}>
              <TextInput source="order" fullWidth />
              <TextInput source="name" fullWidth />
              <SelectInput source="style" fullWidth choices={STYLE_TRANSFER_STYLES.map(x => ({
								id: x.value,
								name: x.name
							}))}/>
              <BooleanInput source="draft" fullWidth />
            </Grid>

          </Grid>
        </SimpleForm>
    </Edit>
);

export default StyleTransferGalleryEdit;
