import { Listbox, Transition } from '@headlessui/react';
import React, { useRef } from 'react';
import { Tooltip } from 'react-tippy';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/green_checkmark.svg';
import { ReactComponent as CaretDownIcon } from '../../assets/caret-up.svg';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up-v4.svg';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import { twMerge } from 'tailwind-merge'

const shouldOpenAbove = (anchor) => {
  if (!anchor.current) return false;
  const anchorRect = anchor.current.getBoundingClientRect();

  return anchorRect.top + anchorRect.height + 180 > window.innerHeight;
};

const Dropdown = (props) => {
  const {
    values,
    value,
    valueFallback,
    onChange,
    label,
    title,
    appliedClasses = {},
		disabled,
		labelAppend,
  } = props;

  const anchor = useRef();

  const { mode } = useGenerationEndpoint();

  return (
    <Listbox
      as='div'
      className={twMerge(`flex justify-between relative gap-4 items-center ui-disabled:cursor-not-allowed`, appliedClasses.root)}
      value={value}
      onChange={onChange}
			disabled={disabled}
    >
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className={twMerge(`text-gray-label font-poppins font-bold text-xs flex gap-1`, appliedClasses.label)}>
              {label}

              {title && (
                <Tooltip title={title}>
                  <InfoIcon />
                </Tooltip>
              )}
							{labelAppend}
            </Listbox.Label>
          )}
          <div
            className={twMerge(`relative flex-grow max-w-[50%] flex`, appliedClasses.buttonCnt)}
            ref={anchor}
          >
            <Listbox.Button
              className={twMerge(`p-1 relative font-poppins text-xs text-white flex items-center justify-between border border-solid border-select-border rounded-xl px-2 grow max-w-full ui-disabled:cursor-not-allowed ui-disabled:text-white/60 ui-disabled:bg-dropdown-disabled`, appliedClasses.button)}
            >
              <span className={twMerge('block truncate', appliedClasses.buttonText)}>
                {value
                  ? (values.find((item) => item.value === value)
                      ?.selectedName ??
                      values.find((item) => item.value === value)?.name) ||
                    valueFallback
                  : label}
              </span>
              {mode === 'v4.0' ? (
                <CaretDownIconV4 className='transition-all duration-300 -rotate-90 [&_path]:fill-chevron-icon ' />
              ) : (
                <CaretDownIcon className='transition-all duration-300 ui-not-open:rotate-180 [&_path]:fill-chevron-icon ' />
              )}
            </Listbox.Button>
            <Transition
              show={open}
              as={React.Fragment}
              leave='transition ease-in duration-100 '
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                style={{
                  ...(anchor.current &&
                    (shouldOpenAbove(anchor)
                      ? {
                          bottom: '100%',
                        }
                      : {
                          top: `${
                            anchor.current.getBoundingClientRect().height
                          }px`,
                        })),
                }}
                className={`absolute origin-bottom my-1 max-h-60 min-w-full overflow-auto rounded-md ${
                  mode === 'v4.0'
                    ? 'bg-[#474752] border-none'
                    : 'bg-app-bg-gray'
                } border border-solid border-select-border text-gray-label font-poppins shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-xs z-50 custom-scroll v4-custom-scroll `}
              >
                {values.map((item) => (
                  <Listbox.Option
                    key={item.value}
                    value={item.value}
                    className={`${
                      mode === 'v4.0' ? 'h-[40px] flex items-center' : ''
                    } relative cursor-default select-none 
											ui-active:bg-list-hover ui-active:text-white 
											py-1 px-2 pl-7  ui-selected:text-white ui-disabled:text-app-search-gray`}
                    disabled={item.disabled}
                  >
                    {({ selected }) => (
                      <>
                        <span className='truncate'>
                          {selected
                            ? item.selectedName ?? item.name
                            : item.name}
                        </span>
                        {selected && (
                          <span className='absolute inset-y-0 left-0 pl-2 flex items-center'>
                            <CheckmarkIcon className='w-3 h-3' />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Dropdown;
