import axios from 'axios';
import environment from './envProvider';


export function getAccessToken() {
    return localStorage.getItem('access-token');
}

const authAxiosInstance = axios.create({
  // baseURL: 'http://logodiffusion.com:1111',
  baseURL: environment('REACT_APP_AUTH_URL') || "https://auth.logodiffusion.com",
});

authAxiosInstance.interceptors.request.use((request) => {
	if (getAccessToken() && !request.IGNORE_AUTH) {
		request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
	}
	return request;
});

export default authAxiosInstance;
