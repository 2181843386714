import { useState, useEffect, useMemo } from 'react';
import { getWindowDimensions } from '../helpers/dimen';
import { useLocation } from 'react-router-dom';

const useCurrentWorkspace = () => {

  const location = useLocation();

	const workspace = useMemo(() => {
		if (location.pathname.includes('image-workspace')) return 'image-workspace';
		else if (location.pathname.includes('text-workspace')) return 'text-workspace';
		else return 'none';
	}, [location]);


  return { workspace };
}

export default useCurrentWorkspace;
