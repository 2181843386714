import { BooleanField, BulkDeleteButton, Button, Datagrid, FilterList, FilterListItem, ImageField, List, ReferenceField, TextField, useListContext, useNotify } from 'react-admin';
import authAxiosInstance from '../../helpers/auth-axios';


export const UpdateDraftAction = (props) => {
	const { draft } = props;
	const { selectedIds } = useListContext();

	const notify = useNotify();

	const save = async (e, b) => {
		try {
			const resp = await authAxiosInstance.put('/api/gallery', {
				ids: selectedIds,
				draft: !!draft,
			});

			notify("Generations added to gallery", { type: 'success' })
		} catch(e) {
			notify("Failed to add to gallery", { type: 'error' })
		}
	}

	return (
		<Button onClick={save} label={`${draft ? 'Mark as draft' : 'Mark as published'}`} />
	)
}

export const GalleryList = () => (
    <List filters={[
			<FilterList label="Is draft" >
				<FilterListItem label="True" value={{ draft: true }} />
				<FilterListItem label="False" value={{ draft: false }} />
			</FilterList>
		]}>
        <Datagrid
					rowClick="edit"
					bulkActionButtons={
						<>
							<UpdateDraftAction />
							<UpdateDraftAction draft />
							<BulkDeleteButton />
						</>
					}
				>
            <ImageField source="generation.imageUrl" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }} />

            <BooleanField source="draft" />

            <ReferenceField source="generation.userId" reference="users">
              <TextField source="email" />
            </ReferenceField>
            <ImageField source="generation.inputImage" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }} />

            <TextField source="generation.dimension" />

            <TextField source="generation.prompt" />
            <TextField source="generation.negativePrompt" />
            <TextField source="generation.contentStyle" label="Style" />
            <TextField source="generation.detailLevel" />
            <TextField source="generation.imageQuality" />
            <TextField source="generaion.seed" />

            <TextField source="generation.method" />

            <TextField source="order" />
        </Datagrid>
    </List>
);

export default GalleryList;
