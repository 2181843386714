import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { CONFIG_PROPS } from './style-config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, RadioGroup, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { SDColors } from '../redesign/constants';
import { ReactComponent as EyeDropperIconV4 } from '../../assets/Eyedropper.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/CheckmarkIconNew.svg';
import { ReactComponent as GradientIcon } from '../../assets/GradientIcon.svg';
import { ReactComponent as GradientNormalIcon } from '../../assets/GradientNormalIcon.svg';
import { ReactComponent as ThickIcon } from '../../assets/ThickIcon.svg';
import { ReactComponent as ThinIcon } from '../../assets/ThinIcon.svg';
import { ReactComponent as GearIcon } from '../../assets/GearIcon.svg';
import { ReactComponent as NoneIconV4 } from '../../assets/NoneIconV4.svg';
import { setStyleConfig } from '../../reducers/formReducer';
import { ReactComponent as BackIcon } from '../../assets/arrow-left.svg';
import useGenerationEndpoint from './useGenerationEndpoint';
import useStyle from '../../hooks/useStyle';
import { Saturation, Hue } from 'react-color/lib/components/common';
import tinycolor from 'tinycolor2';
import { cloneDeep } from 'lodash';

const COLOR_KEYS = ['palette', 'outline_stroke', 'background'];

const standardColors = [
  { hex: '#AA0604', name: 'Red' },
  { hex: '#890D4F', name: 'Dark Pink' },
  { hex: '#311B92', name: 'Dark Violet' },
  { hex: '#0D47A1', name: 'Dark Blue' },
  { hex: '#004D3F', name: 'Dark Cyan' },
  { hex: '#33691D', name: 'Dark Green' },
  { hex: '#FBC02D', name: 'Dark Yellow' },
  { hex: '#BF360D', name: 'Dark Orange' },
  { hex: '#3E2723', name: 'Dark Brown' },
  { hex: '#094562', name: 'Dark Navy' },
  { hex: '#87774A', name: 'Olive' },
  { hex: '#CB6D51', name: 'Bronze' },
  { hex: '#3A3A3A', name: 'Dark Grey' },
  { hex: '#000000', name: 'Black' },
  { hex: '#F44336', name: 'Coral' },
  { hex: '#E91E63', name: 'Hot Pink' },
  { hex: '#512DA8', name: 'Purple' },
  { hex: '#2196F3', name: 'Blue' },
  { hex: '#009688', name: 'Aquamarine Blue' },
  { hex: '#679F37', name: 'Green' },
  { hex: '#FFEB3B', name: 'Yellow' },
  { hex: '#FF5722', name: 'Orange' },
  { hex: '#795448', name: 'Brown' },
  { hex: '#327DA1', name: 'Navy' },
  { hex: '#BAA879', name: 'Khaki' },
  { hex: '#F8B853', name: 'Gold' },
  { hex: '#969696', name: 'Grey' },
  { hex: '#F1F1F1', name: 'Off White' },
  { hex: '#DCDCDC', name: 'Light Grey' },
  { hex: '#F9A19A', name: 'Light Red' },
  { hex: '#F48EB1', name: 'Light Pink' },
  { hex: '#A896D3', name: 'Lavender' },
  { hex: '#90CAF9', name: 'Light Blue' },
  { hex: '#7FCAC3', name: 'Turquoise' },
  { hex: '#B3CF9B', name: 'Light Green' },
  { hex: '#FFF59D', name: 'Light Yellow' },
  { hex: '#FFAB90', name: 'Light Orange' },
  { hex: '#BCA9A3', name: 'Light Brown' },
  { hex: '#92BDD1', name: 'Light Navy' },
  { hex: '#DCD3BB', name: 'Beige' },
  { hex: '#BBD7E3', name: 'Silver' },
  { hex: '#FFFFFF', name: 'White' },
];
const presetColors = [
  [
    { color: '#F1F1F1', name: 'Off White' },
    { color: '#327DA1', name: 'Navy' },
    { color: '#DCDCDC', name: 'Light Grey' },
    { color: '#094562', name: 'Dark Navy' },
  ],
  [
    { color: '#FBC02D', name: 'Dark Yellow' },
    { color: '#094562', name: 'Dark Navy' },
    { color: '#DCDCDC', name: 'Light Grey' },
    { color: '#BCA9A3', name: 'Light Brown' },
  ],
  [
    { color: '#512DA8', name: 'Purple' },
    { color: '#F1F1F1', name: 'Off White' },
    { color: '#311B92', name: 'Dark Violet' },
    { color: '#DCD3BB', name: 'Beige' },
  ],
  [
    { color: '#AA0604', name: 'Red' },
    { color: '#FBC02D', name: 'Dark Yellow' },
    { color: '#FFFFFF', name: 'White' },
    { color: '#000000', name: 'Black' },
  ],
  [
    { color: '#009688', name: 'Aquamarine Blue' },
    { color: '#DCD3BB', name: 'Beige' },
    { color: '#DCDCDC', name: 'Light Grey' },
    { color: '#FFFFFF', name: 'White' },
  ],
  [
    { color: '#679F37', name: 'Green' },
    { color: '#2196F3', name: 'Blue' },
    { color: '#FFFFFF', name: 'White' },
    { color: '#DCDCDC', name: 'Light Grey' },
  ],
  [
    { color: '#795448', name: 'Brown' },
    { color: '#FBC02D', name: 'Dark Yellow' },
    { color: '#000000', name: 'Black' },
    { color: '#DCDCDC', name: 'Light Grey' },
  ],
  [
    { color: '#FF5722', name: 'Orange' },
    { color: '#512DA8', name: 'Purple' },
    { color: '#DCDCDC', name: 'Light Grey' },
    { color: '#FFFFFF', name: 'White' },
  ],
  [
    { color: '#0D47A1', name: 'Dark Blue' },
    { color: '#2196F3', name: 'Blue' },
    { color: '#DCDCDC', name: 'Light Grey' },
    { color: '#FFFFFF', name: 'White' },
  ],
  [
    { color: '#F8B853', name: 'Gold' },
    { color: '#F44336', name: 'Coral' },
    { color: '#3A3A3A', name: 'Dark Grey' },
    { color: '#DCDCDC', name: 'Light Grey' },
  ],
];

// Color data (subset for demonstration)
const colorData = [
  { hex: '#AA0604', name: 'Red' },
  { hex: '#890D4F', name: 'Dark Pink' },
  { hex: '#311B92', name: 'Dark Violet' },
  { hex: '#0D47A1', name: 'Dark Blue' },
  { hex: '#004D3F', name: 'Dark Cyan' },
  { hex: '#33691D', name: 'Dark Green' },
  { hex: '#FBC02D', name: 'Dark Yellow' },
  { hex: '#BF360D', name: 'Dark Orange' },
  { hex: '#3E2723', name: 'Dark Brown' },
  { hex: '#094562', name: 'Dark Navy' },
  { hex: '#87774A', name: 'Olive' },
  { hex: '#CB6D51', name: 'Bronze' },
  { hex: '#3A3A3A', name: 'Dark Grey' },
  { hex: '#000000', name: 'Black' },
  { hex: '#F44336', name: 'Coral' },
  { hex: '#E91E63', name: 'Hot Pink' },
  { hex: '#512DA8', name: 'Purple' },
  { hex: '#2196F3', name: 'Blue' },
  { hex: '#009688', name: 'Aquamarine Blue' },
  { hex: '#679F37', name: 'Green' },
  { hex: '#FFEB3B', name: 'Yellow' },
  { hex: '#FF5722', name: 'Orange' },
  { hex: '#795448', name: 'Brown' },
  { hex: '#327DA1', name: 'Navy' },
  { hex: '#BAA879', name: 'Khaki' },
  { hex: '#F8B853', name: 'Gold' },
  { hex: '#969696', name: 'Grey' },
  { hex: '#F1F1F1', name: 'Off White' },
  { hex: '#F9A19A', name: 'Light Red' },
  { hex: '#F48EB1', name: 'Light Pink' },
  { hex: '#A896D3', name: 'Lavender' },
  { hex: '#90CAF9', name: 'Light Blue' },
  { hex: '#7FCAC3', name: 'Turquoise' },
  { hex: '#B3CF9B', name: 'Light Green' },
  { hex: '#FFF59D', name: 'Light Yellow' },
  { hex: '#FFAB90', name: 'Light Orange' },
  { hex: '#BCA9A3', name: 'Light Brown' },
  { hex: '#92BDD1', name: 'Light Navy' },
  { hex: '#DCD3BB', name: 'Beige' },
  { hex: '#BBD7E3', name: 'Silver' },
  { hex: '#DCDCDC', name: 'Light Grey' },
  { hex: '#FFFFFF', name: 'White' },
  { hex: '#53f72c', name: 'Neon green' },
  { hex: '#a25e15', name: 'Aienna' },
  { hex: '#6fa460', name: 'Asparagus' },
  { hex: '#20b069', name: 'Tree green' },
  { hex: '#f9b311', name: 'Golden yellow' },
  { hex: '#8a5891', name: 'Medium lavender' },
  { hex: '#39a332', name: 'Primary green' },
  { hex: '#ffc287', name: 'Peach' },
  { hex: '#15f4bd', name: 'Light mint' },
  { hex: '#13ebef', name: 'Caribbean blue' },
  { hex: '#52ec9c', name: 'Mint green' },
  { hex: '#4cd4b9', name: 'Aqua' },
  { hex: '#565e11', name: 'Camouflage green' },
  { hex: '#390346', name: 'Dark purple' },
  { hex: '#953308', name: 'Umber' },
  { hex: '#86a51e', name: 'Avocado' },
  { hex: '#1235df', name: 'Cobalt blue' },
  { hex: '#2078c7', name: 'Bright blue' },
  { hex: '#94b5fc', name: 'Hydrangea' },
  { hex: '#8ddd79', name: 'Soft green' },
  { hex: '#fec9de', name: 'Pink' },
  { hex: '#b6a306', name: 'Chartreuse' },
  { hex: '#95a911', name: 'Pea green' },
  { hex: '#a4b90c', name: 'Warm olive' },
  { hex: '#cda7f7', name: 'Lilac' },
  { hex: '#b8950c', name: 'Mustard' },
  { hex: '#f5e667', name: 'Soft yellow' },
  { hex: '#fbf132', name: 'Gen z yellow' },
  { hex: '#ed52aa', name: 'Pink carnation' },
  { hex: '#a90bca', name: 'Magenta' },
  { hex: '#848b8d', name: 'Medium grey' },
  { hex: '#f7f2ad', name: 'Butter yellow' },
  { hex: '#980f60', name: 'Wine' },
  { hex: '#aba58b', name: 'Soft olive' },
  { hex: '#930e0d', name: 'Nrick red' },
  { hex: '#fc6504', name: 'Blood orange' },
  { hex: '#056563', name: 'Dark blue green' },
  { hex: '#5a82a8', name: 'Indigo blue' },
  { hex: '#8724cd', name: 'Primary purple' },
  { hex: '#5636c4', name: 'Bright purple' },
  { hex: '#18ad89', name: 'Bright teal' },
  { hex: '#64938d', name: 'Muted teal' },
  { hex: '#5b3cdd', name: 'Cobalt purple' },
  { hex: '#06400a', name: 'Hunter green' },
  { hex: '#c85005', name: 'Pumpkin spice' },
  { hex: '#ae62e3', name: 'Grape' },
  { hex: '#e458d3', name: 'Muted magenta' },
  { hex: '#03d2fe', name: 'Neon blue' },
  { hex: '#c1f529', name: 'Neon lime' },
  { hex: '#a47204', name: 'Yellow ochre' },
  { hex: '#6f7410', name: 'Swamp green' },
  { hex: '#c5700b', name: 'Dark mustard' },
  { hex: '#a27162', name: 'Brownish pink' },
  { hex: '#c7717f', name: 'Mauve' },
  { hex: '#62152f', name: 'Maroon' },
  { hex: '#8c588e', name: 'Muted purple' },
  { hex: '#fb5f87', name: 'Medium pink' },
  { hex: '#fdab30', name: 'Navel orange' },
  { hex: '#385f81', name: 'Dark petrol' },
  { hex: '#c9a55d', name: 'Light mustard' },
  { hex: '#aaf6b9', name: 'Fresh mint' },
  { hex: '#c0fc85', name: 'Lime yellow' },
  { hex: '#495459', name: 'Charcoal' },
  { hex: '#da0128', name: 'Primary red' },
  { hex: '#aa8556', name: 'Mocha' },
  { hex: '#7683ec', name: 'Cornflower' },
  { hex: '#a50145', name: 'Raspberry' },
  { hex: '#156588', name: 'Dark aqua' },
  { hex: '#c96364', name: 'Faded red' },
  { hex: '#179e95', name: 'Peacock blue' },
  { hex: '#659c40', name: 'Stem green' },
  { hex: '#856e8c', name: 'Purple grey' },
  { hex: '#9abf11', name: 'Lime green' },
  { hex: '#de7e5d', name: 'Dark peach' },
  { hex: '#b6455f', name: 'Dusty red' },
  { hex: '#759e6d', name: 'Sage green' },
  { hex: '#3da474', name: 'Aquatic green' },
  { hex: '#037e90', name: 'Ocean teal' },
  { hex: '#ca0154', name: 'Ruby slipper' },
  { hex: '#43669b', name: 'French blue' },
  { hex: '#b6fbf6', name: 'Light aqua' },
  { hex: '#e88940', name: 'Whiskey' },
  { hex: '#4e5286', name: 'Dusk purple' },
  { hex: '#a885aa', name: 'Dusty lavender' },
  { hex: '#c13c39', name: 'Washed red' },
  { hex: '#f6f489', name: 'Neon yellow' },
  { hex: '#d58998', name: 'Moody pink' },
  { hex: '#8c6f95', name: 'Faded grape' },
  { hex: '#fbc20a', name: 'Dandelion' },
  { hex: '#88a581', name: 'Celadon' },
  { hex: '#b8da8e', name: 'Aloe' },
  { hex: '#c08e96', name: 'Faded maroon' },
  { hex: '#aeff6e', name: 'Key lime' },
  { hex: '#8cfb7e', name: 'Neon lime sherbet' },
  { hex: '#f0c9fd', name: 'Warm lavender' },
  { hex: '#c292a1', name: 'Blush purple' },
  { hex: '#fec1cb', name: 'Coral sunset pink' },
  { hex: '#c1f354', name: 'Neon lemon lime' },
  { hex: '#fe7f4e', name: 'Melon' },
  { hex: '#bc13fe', name: 'Neon purple' },
  { hex: '#ff073a', name: 'Neon red' },
  { hex: '#d99b82', name: 'Peach blush' },
  { hex: '#f08504', name: 'Light pumpkin' },
  { hex: '#6488ea', name: 'Powder blue' },
  { hex: '#ca663f', name: 'Terra cotta' },
  { hex: '#f4320c', name: 'Vermilion' },
];

const getColorDistance = (hex1, hex2) => {
  const rgb1 = tinycolor(hex1).toRgb();
  const rgb2 = tinycolor(hex2).toRgb();

  return Math.sqrt(
    Math.pow(rgb1.r - rgb2.r, 2) +
      Math.pow(rgb1.g - rgb2.g, 2) +
      Math.pow(rgb1.b - rgb2.b, 2)
  );
};

export const getClosestColor = (hex) => {
  let closestColor = null;
  let closestDistance = Infinity;

  for (const color of colorData) {
    const distance = getColorDistance(hex, color.hex);
    if (distance < closestDistance) {
      closestDistance = distance;
      closestColor = color.name;
    }
  }

  return closestColor || 'Unknown';
};

/**
 *
 * @param {string} color HEX Color
 */
export const getColorName = (color, colorName) => {
  return (
    standardColors?.find(
      ({ hex, name, id }) =>
        hex.toLowerCase() === color.toLowerCase() ||
        (colorName &&
          (name?.toLowerCase() === colorName?.toLowerCase() ||
            id?.toLowerCase() === colorName?.toLowerCase()))
    )?.name ?? getClosestColor(color)
  );
};

const generateRandomColorsWithNames = () => {
  const allColors = [...standardColors, ...colorData];
  const shuffled = allColors.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, 4).map(({ hex, name }) => ({ color: hex, name }));
};

const ColorPicker = ({ onBack, color = '#00e1ff', onChange }) => {
  const activeColor = useMemo(() => tinycolor(color), [color]);
  const colorName = useMemo(() => getColorName(color), [color]);

  const handleSaturationChange = (hsv) => {
    const hexColor = `#${tinycolor(hsv).toHex()}`;
    handleChangeColor(hexColor, getColorName(hexColor));
  };

  const handleHueChange = (hue) => {
    const hexColor = `#${tinycolor(hue).toHex()}`;
    handleChangeColor(hexColor, getColorName(hexColor));
  };

  const handleChangeColor = (c, name) => {
    onChange({
      color: c,
      name: name,
    });
  };

  const pickColor = (color) => handleChangeColor(color, getColorName(color));

  const SaturationPointer = (props) => {
    const { hsl } = props;
    return (
      <div
        className='h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1 -mt-[0.2rem]'
        style={{ backgroundColor: `#${tinycolor(hsl).toHex8()}` }}
      />
    );
  };

  const AlphaPointer = (props) => {
    const { activeColor: currentColor } = props;

    return (
      <div
        className='h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1 mt-[0.2rem]'
        style={{ backgroundColor: `#${tinycolor(currentColor).toHex8()}` }}
      />
    );
  };

  return (
    <div className='relative flex flex-col w-full'>
      <button
        className='flex items-center justify-center p-2 gap-[5px] border border-config-clear-border border-solid rounded-[4px] h-[24px] w-[61px] text-config-clear-text text-[12px] font-bold font-montserrat mb-[5px]'
        type='reset'
        onClick={onBack}
      >
        <BackIcon />
        Back
      </button>
      <div className='flex gap-2'>
        <div className='flex flex-col w-1/2 h-full gap-[7px]'>
          <div
            className='relative flex w-full h-[82px] rounded-[10px] overflow-hidden'
            style={{
              backgroundColor: `${color}`,
            }}
          >
            <p className='absolute bottom-0 w-full h-[43px] text-[12px] text-black font-bold bg-white flex items-center justify-center'>
              <span id='colorName'>{colorName}</span>
            </p>
          </div>
          <input
            value={color}
            onChange={(e) => pickColor(e.target.value)}
            className='!outline-none text-center border-none bg-black rounded-[4px] w-[81px] h-[18px] text-xs text-[#4F4F5C] py-1 px-2'
          />
        </div>
        <div className='w-1/2 rounded-[10px] h-auto gap-[7px] flex flex-col items-center justify-center'>
          <div className='relative w-full h-[82px]'>
            <Saturation
              hsl={activeColor.toHsl()}
              hsv={activeColor.toHsv()}
              onChange={handleSaturationChange}
              pointer={SaturationPointer}
              width='100%'
              style={{
                color: { borderRadius: '0.25rem' },
                white: { borderRadius: '0.25rem' },
                black: { borderRadius: '0.25rem' },
              }}
            />
          </div>
          <div className='rounded-full relative bg-white bg-opacity-20 h-[18px] w-full [&_.hue-horizontal]:!rounded-full'>
            <Hue
              hsl={activeColor.toHsl()}
              onChange={handleHueChange}
              pointer={(props) => (
                <AlphaPointer {...props} activeColor={color} />
              )}
              styles={{
                default: {
                  hue: {
                    borderRadius: '1000px',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-wrap w-full items-start gap-x-[6px] gap-y-[4px] pt-[8px]'>
        {standardColors.map(({ hex, name }) => (
          <div
            key={hex}
            className={`relative flex items-center justify-center w-[20px] h-[20px] rounded-[4px] cursor-pointer`}
            onClick={() => handleChangeColor(hex, name)}
            style={{ backgroundColor: hex }}
          >
            <CheckmarkIcon
              className={`[&>*]:invert [&>*]:contrast-[9] [&>*]:grayscale fill-black ${
                color === hex ? '' : 'hidden'
              }`}
              style={{ fill: hex }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const PresetsPanel = ({ onBack, color = '#00e1ff', onChange }) => {
  const [selectedPresetsColorItem, setSelectedPresetsColorItem] =
    useState(null);
  const dispatch = useDispatch();

  const style_config = useSelector((state) => state.form.payload.styleConfig);

  const handlePresetsColorItemClick = (index, colorArray) => {
    const presetsColors = colorArray;
    setSelectedPresetsColorItem(index);
    let colors = [...(style_config?.[COLOR_KEYS[0]]?.colors ?? [])];
    colors[0] = presetsColors[0];
    colors[1] = presetsColors[1];

    dispatch(
      setStyleConfig({
        config_id: COLOR_KEYS[0],
        value: {
          ...style_config[COLOR_KEYS[0]],
          enabled: true,
          __randomized: true,
          colors: colors,
        },
      })
    );

    let outlineColor = [...(style_config?.[COLOR_KEYS[1]]?.colors ?? [])];
    outlineColor[0] = presetsColors[2];
    dispatch(
      setStyleConfig({
        config_id: COLOR_KEYS[1],
        value: {
          ...style_config[COLOR_KEYS[1]],
          enabled: true,
          __randomized: true,
          colors: outlineColor,
        },
      })
    );

    let backgroundColor = [...(style_config?.[COLOR_KEYS[2]]?.colors ?? [])];
    backgroundColor[0] = presetsColors[3];
    dispatch(
      setStyleConfig({
        config_id: COLOR_KEYS[2],
        value: {
          ...style_config[COLOR_KEYS[2]],
          enabled: true,
          __randomized: true,
          colors: backgroundColor,
        },
      })
    );
    onBack();
  };
  return (
    <div className='relative flex flex-col w-full gap-[9px]'>
      <div className='grid grid-row-5 grid-cols-2 gap-x-[24px] gap-y-[15px]'>
        {presetColors.map((colorArray, index) => (
          <div
            key={index}
            className={`flex overflow-hidden rounded-[8px] h-[25px] ${
              selectedPresetsColorItem === index
                ? 'outline outline-1 outline-offset-2 outline-[#545454]'
                : ''
            }`}
          >
            {colorArray.map((color, idx) => (
              <div
                key={idx}
                className={`w-[42px] h-[25px]`}
                style={{ backgroundColor: color.color }}
                title={color.name}
                onClick={() => handlePresetsColorItemClick(index, colorArray)}
              />
            ))}
          </div>
        ))}
      </div>
      <span className='flex items-center justify-start gap-[5px] text-[13px] font-medium font-inter text-[#4E4E58] mb-[7px]'>
        <BackIcon onClick={onBack} className='cursor-pointer' />
        Back to color palette
      </span>
    </div>
  );
};

const V4ColorSelector = (props) => {
  const { method } = useGenerationEndpoint();

  const style = useStyle();
  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [showColorsPanel, setShowColorsPanel] = useState(false);
  const [showPresetsPanel, setShowPresetsPanel] = useState(false);
  const [activeColorKey, setActiveColorKey] = useState({
    name: 'Primary Color',
    key: COLOR_KEYS[0],
    ind: 0,
  });
  const [isNone, setIsNone] = useState(false);
  const dispatch = useDispatch();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [10, 20] },
      },
    ],
  });

  const config = useMemo(
    () => CONFIG_PROPS[method]?.[style] || [],
    [style, method]
  );

  const style_config = useSelector((state) => state.form.payload.styleConfig);

  const currentColor = useMemo(() => {
    if (!activeColorKey) {
      return undefined;
    }
    return style_config?.[activeColorKey.key]?.colors?.[activeColorKey?.ind]
      ?.color;
  }, [style_config, activeColorKey]);

  const color_keys = useMemo(() => {
    return COLOR_KEYS.filter((key) => config[key] && config[key].colors > 0);
  }, [config]);

  const fields = useMemo(() => {
    const arr = [];
    for (const key of color_keys) {
      if (config[key]) {
        for (let i = 0; i < config[key].colors; i++) {
          arr.push({
            key,
            name: config[key].colorNames?.[i],
            ind: i,
          });
        }
      }
    }
    return arr;
  }, [config, color_keys]);

  const hasColorFields = useMemo(() => {
    for (const key of color_keys) {
      if (config[key]) return true;
    }
    return false;
  }, [config, color_keys]);

  const handleClear = () => {
    for (const key of color_keys) {
      if (config[key]) {
        dispatch(
          setStyleConfig({
            config_id: key,
            value: {
              value: config[key].default ?? style_config[key].value,
              enabled:
                config[key].default_enabled ??
                config[key].always_enabled ??
                true,
              colors: [],
              __randomized: true,
            },
          })
        );
      }
    }
  };

  const handleRandomize = () => {
    const randomColors = generateRandomColorsWithNames();
    let colors = [...(style_config?.[COLOR_KEYS[0]]?.colors ?? [])];
    colors[0] = randomColors[0];
    colors[1] = randomColors[1];
    if (config[COLOR_KEYS[0]]) {
      dispatch(
        setStyleConfig({
          config_id: COLOR_KEYS[0],
          value: {
            ...style_config[COLOR_KEYS[0]],
            enabled: true,
            __randomized: true,
            colors: colors.slice(0, config[COLOR_KEYS[0]].colors),
          },
        })
      );
    }

    let outlineColor = [...(style_config?.[COLOR_KEYS[1]]?.colors ?? [])];
    outlineColor[0] = randomColors[2];
    if (config[COLOR_KEYS[1]]) {
      dispatch(
        setStyleConfig({
          config_id: COLOR_KEYS[1],
          value: {
            ...style_config[COLOR_KEYS[1]],
            enabled: true,
            __randomized: true,
            colors: outlineColor.slice(0, config[COLOR_KEYS[1]]?.colors),
          },
        })
      );
    }

    let backgroundColor = [...(style_config?.[COLOR_KEYS[2]]?.colors ?? [])];
    backgroundColor[0] = randomColors[3];
    if (config[COLOR_KEYS[2]]) {
      dispatch(
        setStyleConfig({
          config_id: COLOR_KEYS[2],
          value: {
            ...style_config[COLOR_KEYS[2]],
            enabled: true,
            __randomized: true,
            colors: backgroundColor.slice(0, config[COLOR_KEYS[2]]?.colors),
          },
        })
      );
    }
  };
  const handlePresets = () => {
    setShowPresetsPanel(true);
  };
  const handleColors = (item) => {
    setActiveColorKey(item);
    setShowColorsPanel(true);
    setIsNone(false);
  };
  const handleSelectColorItem = (item) => {
    setActiveColorKey(item);
  };
  const handleBackColorToPalette = () => {
    setShowColorsPanel(false);
  };
  const handleBackPresetsToPalette = () => {
    setShowPresetsPanel(false);
  };
  const handleColorChange = (color) => {
    let colors = [...(style_config?.[activeColorKey?.key]?.colors ?? [])];
    colors[activeColorKey.ind] = color;

    dispatch(
      setStyleConfig({
        config_id: activeColorKey?.key,
        value: {
          ...(style_config?.[activeColorKey?.key] ?? {}),
          enabled: true,
          __randomized: false,
          colors: colors,
        },
      })
    );
  };

  const handleStandardColorChange = (item) => {
    setIsNone(false);
    let colors = [...(style_config?.[activeColorKey?.key]?.colors ?? [])];
    colors[activeColorKey.ind] = {
      ...(colors[activeColorKey.ind] || {}),
      ...item,
    };

    dispatch(
      setStyleConfig({
        config_id: activeColorKey?.key,
        value: {
          ...(style_config?.[activeColorKey?.key] ?? {}),
          enabled: true,
          __randomized: false,
          colors: colors,
        },
      })
    );
  };

  const handleClickNone = () => {
    let colors = [...(style_config?.[activeColorKey?.key]?.colors ?? [])];
    colors[activeColorKey.ind] = [];
    setIsNone(true);
    dispatch(
      setStyleConfig({
        config_id: activeColorKey.key,
        value: {
          ...(style_config?.[activeColorKey?.key] ?? {}),
          enabled: true,
          __randomized: false,
          colors: colors,
        },
      })
    );
  };

  const handleUpdateColorValue = () => {
    const key = activeColorKey?.key;
    const config = cloneDeep(style_config?.[key] ?? []);

    if (key === 'palette') {
      let color = config.colors[activeColorKey?.ind];
      handleColorChange({
        ...color,
        prefix: color?.prefix === 'gradient' ? null : 'gradient',
      });
      return;
    }

    let value = config.value;
    if (key === 'outline_stroke') {
      value = value !== 'thin' ? 'thin' : 'thick';
    } else {
      value = value !== 'gradient' ? 'gradient' : 'solid';
    }

    dispatch(
      setStyleConfig({
        config_id: key,
        value: {
          ...config,
          enabled: true,
          value: value,
          __randomized: false,
        },
      })
    );
  };

  useEffect(() => {
    setIsNone(false);
  }, [activeColorKey]);

  const widthPercentage = 100 / fields.length + '%';

  return (
    <>
      <Popover as={React.Fragment}>
        {({ open, close }) => (
          <>
            <Popover.Button
              className='relative flex justify-start items-center cursor-pointer focus:outline-none overflow-hidden rounded-lg bg-overlay-bg grow h-7 gap-1 pr-2 disabled:cursor-not-allowed disabled:text-config-disabled text-xs'
              ref={setAnchorEl}
              disabled={!hasColorFields}
            >
              <div className='flex flex-col w-3 h-full'>
                {color_keys.map((key, index) => {
                  return (
                    <React.Fragment key={index}>
                      {style_config[key]?.colors
                        ?.filter((c) => !!c)
                        .map((item, innerIndex) => (
                          <div
                            className='grow w-full'
                            key={innerIndex}
                            style={{
                              background: item.color,
                            }}
                          ></div>
                        ))}
                    </React.Fragment>
                  );
                })}
              </div>
              Colors
            </Popover.Button>

            <Transition
              as={React.Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
              {...props}
            >
              <Popover.Panel
                className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray flex max-w-[95%] w-[400px] h-[250px] overflow-hidden relative p-[19px]'
                ref={setPopperEl}
                style={styles.popper}
                {...attributes.popper}
              >
                {showColorsPanel ? (
                  <ColorPicker
                    onBack={handleBackColorToPalette}
                    color={currentColor}
                    onChange={handleColorChange}
                  />
                ) : showPresetsPanel ? (
                  <PresetsPanel onBack={handleBackPresetsToPalette} />
                ) : (
                  <div className='flex flex-col gap-[9px] w-full'>
                    <RadioGroup className='flex gap-3 w-full ' value={activeColorKey} onChange={handleSelectColorItem}>
                      {fields.map((item, ind) => {
                        return (
                          <RadioGroup.Option
                            className={`relative flex flex-col items-center justify-start border border-config-clear-border border-solid rounded-[10px] h-[96px] overflow-hidden ${
                              item?.name === activeColorKey?.name
                                ? 'outline outline-1 outline-offset-2 outline-[#648176]'
                                : ''
                            }`}
                            key={`${item.key}-${item.ind}`}
                            value={item}
                            style={{
                              width: widthPercentage,
                              backgroundColor:
                                style_config?.[item.key]?.colors?.[item.ind]
                                  ?.color ?? '',
                            }}
                          >
                            {style_config?.[item?.key]?.value === 'gradient' ||
                              (style_config?.[item.key]?.colors?.[item.ind]
                                ?.prefix === 'gradient' && (
                                <div
                                  className='absolute top-0 w-full h-[43px]'
                                  style={{
                                    backgroundImage:
                                      ' linear-gradient(282.48deg, rgba(255, 255, 255, 0.35) 3.43%, rgba(0, 0, 0, 0.35) 100%)',
                                    mixBlendMode: 'overlay',
                                    transform: 'rotate(-180deg)',
                                  }}
                                ></div>
                              ))}
                            <div className='relative w-full h-[39px] flex items-center justify-center'>
                              <EyeDropperIconV4
                                className='[&>*]:invert [&>*]:contrast-[9] [&>*]:grayscale fill-black w-[15px] h-[15px]'
                                style={{
                                  fill:
                                    style_config?.[item.key]?.colors?.[item.ind]
                                      ?.color ?? '',
                                }}
                              />
                              {(style_config?.[item?.key]?.value ===
                                'gradient' ||
                                style_config?.[item.key]?.colors?.[item.ind]
                                  ?.prefix === 'gradient') && (
                                <GradientIcon className='absolute w-[8px] h-[8px] bottom-[5px] left-[5px] [&_path]:fill-[#FAFAFA]' />
                              )}
                              {item.key === 'outline_stroke' &&
                                (style_config?.[item?.key]?.value === 'thin' ? (
                                  <ThinIcon className='absolute w-[11px] h-[11px] bottom-[3px] left-[5px] [&_path]:fill-[#FAFAFA]' />
                                ) : (
                                  <ThickIcon className='absolute w-[11px] h-[11px] bottom-[3px] left-[5px] [&_path]:fill-[#FAFAFA]' />
                                ))}

                              <button
                                className='absolute bottom-[2px] right-[2px] w-[15px] h-[15px] flex items-center justify-center'
                                onClick={() => handleColors(item)}
                              >
                                <GearIcon className='w-[8px] h-[8px]' />
                              </button>
                            </div>
                            <div
                              className={`absolute bottom-0 flex flex-col items-start justify-start gap-1 w-full h-[52px] cursor-pointer ${
                                style_config?.[item.key]?.colors?.[item.ind]
                                  ?.color
                                  ? 'bg-[#F4F4F4]'
                                  : 'bg-[#323239]'
                              }`}
                              onClick={() => handleSelectColorItem(item)}
                            >
                              <p
                                className={`${
                                  style_config?.[item.key]?.colors?.[item.ind]
                                    ?.color
                                    ? 'text-black'
                                    : 'text-[#808080]'
                                } text-[12px] font-semibold font-roboto ml-[5px] mt-[8px] leading-[12px]`}
                              >
                                {item.key !== 'outline_stroke'
                                  ? item.name.replace('Color', '')
                                  : style_config?.[item?.key]?.value === 'thin'
                                  ? `Thin ${item.name.replace('Color', '')}`
                                  : `Thick ${item.name.replace('Color', '')}`}
                              </p>
                              <p className='text-[#808080] text-[10px] font-normal font-roboto ml-[5px] leading-[13px]'>
                                {style_config?.[item.key]?.colors?.[item.ind]
                                  ?.name
                                  ? style_config?.[item?.key]?.value ===
                                      'gradient' ||
                                    style_config?.[item.key]?.colors?.[item.ind]
                                      ?.prefix === 'gradient'
                                    ? `Gradient ${
                                        style_config?.[item.key]?.colors?.[
                                          item.ind
                                        ]?.name
                                      }`
                                    : style_config?.[item.key]?.colors?.[
                                        item.ind
                                      ]?.name
                                  : 'Select Color'}
                              </p>
                            </div>
                          </RadioGroup.Option>
                        );
                      })}
                    </RadioGroup>
                    <div className='flex flex-wrap w-full items-start gap-x-[6px] gap-y-[4px]'>
                      <div
                        className={`relative flex items-center justify-center w-[20px] h-[20px] border border-[#2A2931] rounded-[4px] cursor-pointer ${
                          isNone ? 'border border-app-green' : ''
                        }`}
                        onClick={() => handleClickNone()}
                      >
                        <NoneIconV4 className='[&_path]:fill-[#43424B] w-[15px] h-[15px]' />
                      </div>
                      {standardColors
                        .filter((item) => item.name !== 'Off White')
                        .map((item, index) => (
                          <div
                            key={index}
                            className={`relative flex items-center justify-center w-[20px] h-[20px] rounded-[4px] cursor-pointer`}
                            onClick={() =>
                              handleStandardColorChange({
                                color: item.hex,
                                name: item.name,
                              })
                            }
                            style={{
                              backgroundColor: item.hex,
                            }}
                          >
                            {style_config?.[activeColorKey?.key]?.value ===
                              'gradient' ||
                              (style_config?.[activeColorKey?.key]?.colors?.[
                                activeColorKey?.ind
                              ]?.prefix === 'gradient' && (
                                <div
                                  className='absolute top-0 w-full h-full rounded-[4px]'
                                  style={{
                                    backgroundImage:
                                      ' linear-gradient(282.48deg, rgba(255, 255, 255, 0.35) 3.43%, rgba(0, 0, 0, 0.35) 100%)',
                                    mixBlendMode: 'overlay',
                                    transform: 'rotate(-180deg)',
                                  }}
                                ></div>
                              ))}

                            <CheckmarkIcon
                              className={`[&>*]:invert [&>*]:contrast-[9] [&>*]:grayscale fill-black ${
                                currentColor === item.hex ? '' : 'hidden'
                              }`}
                              style={{ fill: item.hex }}
                            />
                          </div>
                        ))}
                    </div>
                    <div className='flex items-center justify-between'>
                      <button
                        className='border border-config-clear-border border-solid rounded-[8px] h-[35px] w-[80px] text-config-clear-text text-[12px] font-bold font-montserrat '
                        type='reset'
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                      <button
                        className='border border-config-clear-border border-solid rounded-[8px] h-[35px] w-[109px] text-config-clear-text text-[12px] font-bold font-montserrat '
                        type='reset'
                        onClick={handleRandomize}
                      >
                        Randomize
                      </button>
                      <button
                        className='border border-config-clear-border border-solid rounded-[8px] h-[35px] w-[98px] text-config-clear-text text-[12px] font-bold font-montserrat '
                        type='reset'
                        onClick={handlePresets}
                      >
                        Presets
                      </button>
                      <button
                        className='flex items-center justify-center border border-config-clear-border border-solid rounded-[8px] h-[35px] w-[35px] text-config-clear-text text-[12px] font-bold font-montserrat '
                        type='reset'
                        onClick={handleUpdateColorValue}
                      >
                        {activeColorKey?.key === 'outline_stroke' ? (
                          style_config?.[activeColorKey?.key]?.value ===
                            'thick' ||
                          !style_config?.[activeColorKey?.key]?.value ? (
                            <ThickIcon />
                          ) : (
                            <ThinIcon />
                          )
                        ) : style_config?.[activeColorKey?.key]?.colors?.[
                            activeColorKey?.ind
                          ]?.prefix === 'gradient' ||
                          style_config?.[activeColorKey?.key]?.value ===
                            'gradient' ? (
                          <GradientIcon />
                        ) : (
                          <GradientNormalIcon />
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default V4ColorSelector;
