import { ReactComponent as CloudUploadImageIcon } from '../assets/CloudUploadImageIcon.svg';
import { ReactComponent as TrashIconV4 } from '../assets/TrashIconWhiteV4.svg';
import { ReactComponent as EditCanvasIcon } from '../assets/EditCanvas.svg';
import { ReactComponent as CaretDownIconV4 } from '../assets/caret-up-v4.svg';
import { ReactComponent as UploadImageIconV4 } from '../assets/UploadImageIconV4.svg';
import { ReactComponent as InfoIcon } from '../assets/InfoIconGray.svg';
import { ReactComponent as BrowseIcon } from '../assets/BrowseIcon.svg';
import { ReactComponent as BrowsePlaceholder } from '../assets/BrowsePlaceholder.svg';

import { Disclosure } from '@headlessui/react';
import ImageCropper from './ImageCropper';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_COLORS,
  setBackgroundImage,
  setIsEditImage,
  setIsOpen,
  setPresetColor,
} from '../reducers/imageEditorReducer';
import { partialUpdateStatePayload, updateDetailLevel, updateStyleImage, updateStyleImageUrl } from '../reducers/formReducer';
import { IMAGE_EXPORT_SIZE } from './editor/settings';
import { emptyJPEG } from './editor/image_generator';
import RedesignV4 from './redesign/RedesignV4';
import Tooltip from './Tooltip';
import { Switch } from '@headlessui/react';
import Slider from './base/Slider';
import { detailLevelValues } from '../components/redesign/constants';
import STYLES, { V4_GROUPS } from '../components/input/content-styles';
import useGenerationEndpoint from './input/useGenerationEndpoint';
import BrowseGallery from './BrowseGallery';
import { V4StyledSlider as ValuedSlider } from './base/ValuedSlider';

export function toDataURL(src, callback, outputFormat) {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var dataURL;
    canvas.height = IMAGE_EXPORT_SIZE;
    canvas.width = IMAGE_EXPORT_SIZE;
    ctx.drawImage(this, 0, 0, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    img.src = src;
  }
}

export const STYLE_TRANSFER_STYLES = [
	{ name: 'Realistic', value: 'realistic' },
	{ name: '2D Illustration', value: '2d-illustration' },
	{ name: '3D Art', value: '3d-art' },
	{ name: 'Fine Art', value: 'fine-art' },
	{ name: 'Default', value: 'default' },
]

function FileUploadInput(props) {
  const uploaderRef = useRef();
  const dispatch = useDispatch();

  const openEmptyCanvas = () => {
    dispatch(setPresetColor(DEFAULT_COLORS));
    dispatch(setBackgroundImage(emptyJPEG));
    dispatch(setIsEditImage(false));
    dispatch(setIsOpen(true));
  };

  const PanelAttributes = useMemo(() => {
    return {
      className: 'flex flex-col gap-4 py-2 ',
    };
  }, []);

  const { method } = useGenerationEndpoint();


  const styleStrength = useSelector((state) => state.form.payload.styleStrength);
  const useStyleStrength = useSelector((state) => state.form.payload.useStyleStrength);

	const setStyleStrength = (val) => {
		dispatch(partialUpdateStatePayload({
			styleStrength: val
		}));
	};
	const setUseStyleStrength = (val) => {
		dispatch(partialUpdateStatePayload({
			useStyleStrength: val
		}));
	};

	const style = useSelector((state) => state.form.payload.style);
	const setStyle = (val) => {
		dispatch(partialUpdateStatePayload({
			style: val
		}));
	};

	const image = useSelector((state) => state.form.payload.styleImage);
	const imageUrl = useSelector((state) => state.form.payload.styleImageUrl);

  return (
    <>
      <Disclosure
        className={`relative rounded-xl px-3 bg-style-bg py-4 p-2 flex flex-col`}
        as='div'
				defaultOpen={!!image || !!imageUrl}
				key={!!image || !!imageUrl}
      >
        <Disclosure.Button
          className={`outline-none text-sm
            text-white flex items-center justify-between font-sans-pro text-[15.13px] font-semibold`}
        >
          <span className='flex'>
            Style Image
            <Tooltip title='Add a style reference image to match its style, color and lighting' position='right'>
              <InfoIcon className='w-[20px] h-[20px] ml-1' />
            </Tooltip>
          </span>
          <CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
        </Disclosure.Button>
        <Disclosure.Panel {...PanelAttributes}>
          <div className='flex flex-row justify-around items-center gap-4 border-gray-800 py-2 px-1'>
            <div
              className={`flex flex-col w-[52%] aspect-square items-center justify-center ${
                props.imageToUpload ? 'outline' : 'outline-dashed'
              } outline-2 outline-offset-2 outline-icon-text-color rounded-[8px]`}
            >
              <ImagePreview
                imageToUpload={props.imageToUpload}
                onRemoveUploadedImage={props.onRemoveUploadedImage}
                handleImageUpload={props.handleImageUpload}
              />
            </div>
            <div className='flex flex-col w-[48%] gap-2'>
              <button
                className='flex justify-start gap-[3px] items-center bg-button-purple p-[6px] rounded-[4px]'
                onClick={(e) => uploaderRef.current.click()}
              >
                <CloudUploadImageIcon className='w-[17px] h-[17px]' />
                <p className='text-white text-center text-[11px] font-semibold'>
                  Upload image
                </p>
              </button>

              <div className='h-full w-[2px] bg-white bg-opacity-10 shrink-0'></div>
              {true || !props.imageToUpload ? (
                <button
                  className='flex justify-start gap-[3px] items-center bg-app-bg-gray py-[6px] px-[8px] rounded-[4px]'
                  onClick={() => {
                    props.handleBrowsingGallery();
                  }}
                >
                  <BrowseIcon className='w-[15px] h-[15px] [&_path]:fill-white' />
                  <p className='text-switch-label-white text-center text-[11px] font-semibold whitespace-nowrap'>
                    Browse Gallery
                  </p>
                </button>
              ) : (
                <button
                  className='flex justify-start gap-[3px] items-center bg-app-bg-gray p-[6px] rounded-[4px]'
                  onClick={(e) => dispatch(setIsOpen(true))}
                >
                  <EditCanvasIcon className='w-[15px] h-[15px] [&_path]:fill-switch-label-white' />
                  <p className='text-switch-label-white text-center text-[11px] font-semibold'>
                    Edit Image
                  </p>
                </button>
              )}
            </div>
          </div>

          <input
            ref={uploaderRef}
            type='file'
            id='imgUpload'
            name='img'
            accept='image/*'
            onChange={(e) => props.handleImageUpload(e.target.files)}
            className=' hidden absolute opacity-0 '
          />
          {props.imageToUpload ? (
            <div className={`flex flex-col gap-2`}>
							<ValuedSlider
								label="Style Strength"
								title="Adjust the strength of style matching"
								values={[...Array(5)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
								value={styleStrength}
								onChange={setStyleStrength}
								showValue

								disabled={!useStyleStrength}
								onDisabled={(e) => setUseStyleStrength(!e)}
								disablable
							/>
              <div className='flex items-center'>
                <p className={`text-[12px] font-inter text-switch-label-white`}>
                  <span className='flex'>
                    Style Category
                    <Tooltip title='style category' position='right'>
                      <InfoIcon className='w-[20px] h-[20px] ml-1' />
                    </Tooltip>
                  </span>
                </p>
              </div>
              <div className='flex flex-row w-full h-full gap-[7px] flex-wrap'>
                {STYLE_TRANSFER_STYLES.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`flex items-center justify-start bg-app-bg-gray max-w-[123px] h-[24px] p-[10px] rounded-[4px] text-[10px] font-inter ${
                        item.value === style
                          ? 'border border-1 border-app-green'
                          : ''
                      }`}
                      onClick={() => setStyle(item.value)}
                    >
                      <div
                        className={`${
                          item.value === style
                            ? 'text-white font-bold'
                            : 'text-icon-text-color'
                        }`}
                      >
                        {item.name}
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className='flex flex-col gap-2'>
              <span className='flex text-switch-label-white text-[12px]'>
                Browse Gallery
                <Tooltip title='browser gallery' position='right'>
                  <InfoIcon className='w-[20px] h-[20px] ml-2' />
                </Tooltip>
              </span>
              <div className='grid grid-cols-4 gap-x-[14px] gap-y-[10px]'>
                {props.gallery.filter((_, i) => i < 7).map((image) => {
                  return (
                    <button
                      key={image?._id}
                      className='relativ w-[48px] h-[48px] bg-app-bg-gray rounded-[8px]'
                      onClick={() => {
												dispatch(updateStyleImageUrl(image.imageUrl))
												if (image.style && STYLE_TRANSFER_STYLES.find(x => x.value === image.style)) {
													dispatch(partialUpdateStatePayload({
														style: image.style,
													}))
												}
											}}
                    >
                      <img src={image?.optimizedImageUrl ?? image.imageUrl}
												alt={image.name}
                        className={`w-[48px] h-[48px] relative rounded-[8px]`}
                      />
                    </button>
                  );
                })}
                <button
                  className='relativ flex items-center justify-center w-[48px] h-[48px] bg-app-bg-gray rounded-[8px]'
                  onClick={() => {
                    props.handleBrowsingGallery();
                  }}
                >
                  <BrowseIcon
                    className={`w-[24px] h-[24px] relative rounded-[8px]`}
                  />
                </button>
              </div>
            </div>
          )}
        </Disclosure.Panel>
      </Disclosure>
    </>
  );
}

function ImagePreview(props) {
  const uploaderRef = useRef();
  const dispatch = useDispatch();
  return (
    <>
      {props.imageToUpload ? (
        <div className='relative group overflow-hidden rounded-[8px]'>
          <>
            {' '}
            <button
              className='group/delete basis-[45%] flex-grow shrink-0 !outline-none absolute -top-3 right-[3px] gap-1 mt-4'
              onClick={(e) => props.onRemoveUploadedImage()}
            >
              <TrashIconV4 className='w-[20px] h-[20px] group-hover/delete:[&_path]:fill-app-green' />
            </button>
            <img
              className='min-h-full min-w-full rounded-[8px]'
              src={props.imageToUpload}
              alt='to upload'
            />
          </>
        </div>
      ) : (
        <button
          className='flex w-full h-full items-center justify-center'
          onClick={(e) => uploaderRef.current.click()}
        >
          <UploadImageIconV4 className='w-auto' />
          <input
            ref={uploaderRef}
            type='file'
            id='imgUpload'
            name='img'
            accept='image/*'
            onChange={(e) => props.handleImageUpload(e.target.files)}
            className=' hidden absolute opacity-0 '
          />
        </button>
      )}
    </>
  );
}

function StyleImage(props) {
  const { renderInput } = props;

  const [isCropping, setIsCropping] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [isBrowsingGallery, setIsBrowsingGallery] = useState(false);

  const InputComponent = useMemo(
    () => renderInput || FileUploadInput,
    [renderInput]
  );

	const dispatch = useDispatch()
	const image = useSelector((state) => state.form.payload.styleImage);
	const imageUrl = useSelector((state) => state.form.payload.styleImageUrl);

	const setStyleImage = (image) => {
		dispatch(updateStyleImage(image))
	}

  const handleImageSelection = (files) => {
    const [file] = files;

    if (file) {
      const url = URL.createObjectURL(file);
      const image = new Image();

      image.onload = () => {
        const { width, height } = image;

        if (width !== height) {
          setImageToCrop(url);
          setIsCropping(true);
        } else {
          toDataURL(url, (base64) => {
            setStyleImage(base64);
          });
        }
      };

      image.src = url;
    }
  };
  const handleBrowsingGallery = () => {
    setIsBrowsingGallery(true);
  };

  const handleImageCropped = (file, base64Image) => {
    const url = URL.createObjectURL(file);

    setStyleImage(base64Image);
    // setImageToUpload(url);

    setImageToCrop(null);
    setIsCropping(false);
    setIsBrowsingGallery(false);
  };

  const handleBrowsedGalley = (url) => {
		dispatch(updateStyleImageUrl(url))
	};

	const [gallery, setGallery] = useState([])

  return (
    <>
      <ImageCropper
        isCropping={isCropping}
        imageToCrop={imageToCrop}
        onConfirm={handleImageCropped}
        onCancel={() => {
          setIsCropping(false);
          setImageToCrop(null);
        }}
      />
      <BrowseGallery
        open={isBrowsingGallery}
        handleImageUpload={handleImageSelection}
        onConfirm={handleBrowsedGalley}
				gallery={gallery}
				setGallery={setGallery}
        onClose={() => {
          setIsBrowsingGallery(false);
        }}
      />
      <InputComponent
        handleImageUpload={handleImageSelection}
        imageToUpload={image || imageUrl}
				gallery={gallery}
				setGallery={setGallery}
        onRemoveUploadedImage={() => setStyleImage(undefined)}
        handleBrowsingGallery={handleBrowsingGallery}
      />
    </>
  );
}

export default StyleImage;
