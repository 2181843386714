import { Tooltip } from 'react-tippy';

function LdTooltip(props) {
  const { className, position, ...rest } = props
  return (
    <Tooltip className={`cursor-pointer ${className}`} position= {position} {...rest}>
      {props.children}
    </Tooltip>
  )
}

export default LdTooltip;