import { NavLink } from 'react-router-dom';

import AppLayout from './AppLayout';
import ProtectedRoute from '../components/ProtectedRoute';

import { ReactComponent as UserIcon } from '../assets/UserIcon.svg';
import { ReactComponent as HistoryIcon } from '../assets/HistoryIcon.svg';
import { ReactComponent as BillingIcon } from '../assets/BillingIcon.svg';
import { ReactComponent as DocumentationIcon } from '../assets/DocumentationIcon.svg';
import { ReactComponent as BookmarkIcon } from '../assets/BookmarkIconProfile.svg';
import { ReactComponent as CanvasIcon } from '../assets/BrushIcon.svg';
import { ReactComponent as BGRemoveIcon } from '../assets/BGRemoveIcon.svg';
import { ReactComponent as VectorizerIcon } from '../assets/VectorizerIcon.svg';
import { ReactComponent as IntegrationsIcon } from '../assets/IntegrationsIcon.svg';
import { ReactComponent as TextIcon } from '../assets/Text.svg';
import { ReactComponent as DashboardIcon } from '../assets/dashboard/dashboar-icon.svg';
import { ReactComponent as UpscaleIcon } from '../assets/UpscaleIcon.svg';
import Notification from '../components/base/Notification';

function UserLayout(props) {
  const activeLinkClasses =
    'bg-[#7C56EB] bg-opacity-20 text-white p-2 font-light flex gap-2 rounded-[8px] h-[48px] flex items-center';
  const inactiveLinkClasses =
    'p-2 font-light text-white flex gap-2 h-[48px] flex items-center';

  return (
    <AppLayout disableNotification>
      <ProtectedRoute>
        <div className='flex grow relative'>
          <div className='hidden md:flex w-2/12 bg-app-bg-gray flex-col gap-2 pt-[32px] px-[10px]'>
            <NavLink
              to='/'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <DashboardIcon className='mx-[4px]' />
              Dashboard
            </NavLink>

            <NavLink
              to='/gen/text-workspace'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <TextIcon className='mx-[3px]' />
              Text to Image
            </NavLink>

            <NavLink
              to='/gen/image-workspace'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <CanvasIcon />
              Image to Image
            </NavLink>

            <NavLink
              to='/upscale'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <UpscaleIcon className='mx-[3px]' />
              Image Upscaler
            </NavLink>

            <NavLink
              to='/bg-remover'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <BGRemoveIcon className='mx-[4px]' />
              Background Remover
            </NavLink>
            <NavLink
              to='/vectorizer'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <VectorizerIcon className='mx-[6px]' />
              Image to Vector
            </NavLink>

            <NavLink
              to='/profile'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <UserIcon />
              User profile
            </NavLink>

            <NavLink
              to='/history'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <HistoryIcon />
              History
            </NavLink>

            <NavLink
              to='/bookmarks'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <BookmarkIcon className='mx-[3px]' />
              Bookmarks
            </NavLink>

            {/* <NavLink to="/billing" className={(navData) => navData.isActive ? activeLinkClasses : inactiveLinkClasses}>
              <BillingIcon />
              Billing
            </NavLink> */}

            <NavLink
              to='/integrations'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <IntegrationsIcon className='h-[24px] w-[24px] mx-[3px] ' />
              Integrations
            </NavLink>

            <NavLink
              to='/documentation'
              className={(navData) =>
                navData.isActive ? activeLinkClasses : inactiveLinkClasses
              }
            >
              <DocumentationIcon className='mx-[3px]' />
              Help
            </NavLink>
          </div>

          <div className='w-full md:w-10/12'>
            <div className={props.containerClasses || 'max-w-[1500px] mx-auto'}>
              <Notification
                containerClasses={
                  props.notificationClasses || 'max-w-[1400px] mx-auto'
                }
              />
              {props.children}
            </div>
          </div>
        </div>
      </ProtectedRoute>
    </AppLayout>
  );
}

export default UserLayout;
