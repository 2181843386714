import { configureStore, applyMiddleware, combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk'
import userSlice, { INITIAL_STATE as userInitialState } from './reducers/userReducer';
import appSlice, { INITIAL_STATE as appInitialState } from './reducers/appReducer';
import formReducer, { INITIAL_STATE as formInitialState } from './reducers/formReducer';
import { imageEditorSlice, INITIAL_STATE as editorInitialState } from './reducers/imageEditorReducer';
import { FLUSH, PAUSE, PERSIST, REHYDRATE, PURGE, REGISTER, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import formListener from './reducers/formMiddleware';
import { v3listener } from './reducers/v3StyleMiddleware';

const composedEnhancer = applyMiddleware(thunkMiddleware);

const combinedReducers = combineReducers({
  userSlice: userSlice,
  appSlice: appSlice,
  // form: persistReducer({ key: 'form', storage, blacklist: [ 'generations', 'generatedMasks', 'payload' ] }, formReducer),
  form: formReducer,
  imageEditor: imageEditorSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'imageEditor',
    'form',
  ],
};

const rootReducer = (state, action) => {
  if (action.type === REHYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  }
  return combinedReducers(state, action);
};


const persistedReducer = persistReducer(
  persistConfig, 
  rootReducer
);

const store = configureStore({
  // composedEnhancer,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {},
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
		.prepend(formListener.middleware)
		.prepend(v3listener.middleware),
  preloadedState: {
    userSlice: userInitialState,
    appSlice: appInitialState,
    form: formInitialState,
    imageEditor: editorInitialState,
  },
});


export default store;
export const persistor = persistStore(store);
