import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Range, getTrackBackground } from 'react-range';
import { setStrokeSize } from '../../../reducers/imageEditorReducer';
import RangeInput from '../../RangeInput';

const StrokeSlider = (props) => {
	const { editor } = props;

	const dispatch = useDispatch();

	const strokeSize = useSelector((state) => state.imageEditor.strokeSize);
	const selectedElement = useSelector((state) => state.imageEditor.selectedElement);

  const updateStrokeSize = async (size) => {
    dispatch(setStrokeSize(size));

		await editor
			.current
			.getInstance()
			.setObjectProperties(selectedElement.id, {
				strokeWidth: size,
			});
  }

	return (
		<RangeInput step={1} min={0} max={16} values={[strokeSize]} onRangeChange={(values) => updateStrokeSize(values[0])} />
	);

	return (
    <Range
      step={1}
      min={0}
      max={16}
      values={[strokeSize]}
      onChange={(values) => updateStrokeSize(values[0])}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            display: 'flex',
            width: '100%',
						height: '24px',
						clipPath: 'polygon(0% 100%, 100% 0%, 100% 100%)',
          }}
					className="bg-white"
        >
          <div
            ref={props.ref}
            style={{
              height: '100%',
              width: '100%',
              alignSelf: 'center',
							position: 'relative',

              background: getTrackBackground({
                values: [strokeSize],
								colors: ['#3DD598', '#fff'],
                min: 1,
                max: 16
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
					className={`w-0 h-[0.875rem] outline-none rounded-sm bg-white absolute top-0`}
          style={{
            ...props.style,
            outline: 'none',
          }}
        />
      )}
    />
	);
};

export default StrokeSlider;
