import moment from "moment";

const calculateNextBillingPeriod = (date) => {
	const currentDay = moment()
	const renewalDay = moment(date)

	if (currentDay.date() > renewalDay.date()) {
		currentDay.add(1, 'months')
		currentDay.date(renewalDay.date())
	} else {
		const endOfMonth = currentDay.endOf('month').date()
		currentDay.date(Math.min(renewalDay.date(), endOfMonth))
	}

	return currentDay;

}

export default calculateNextBillingPeriod;
