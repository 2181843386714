import React, { useMemo, useState } from 'react';
import Radio from '../../base/Radio';
import { useDispatch, useSelector } from 'react-redux';
import {
  partialUpdateStatePayload,
  updateDetailLevel,
  updateEdgeThreshold,
  updateImageAsControlImage,
  updateImageStrength,
} from '../../../reducers/formReducer';
import {
  DenoisingAcceptedValues,
  ThresholdAcceptedValues,
  UseAsControlImageValues,
  detailLevelValues,
} from '../constants';
import Slider from '../../base/Slider';
import { Tooltip } from 'react-tippy';
import { Switch } from '@headlessui/react';
import { ReactComponent as CheckmarkIcon } from '../../../assets/CheckmarkIconGray.svg';
import useCurrentWorkspace from '../../../hooks/useCurrrentWorkspace';
import useGenerationEndpoint from '../../input/useGenerationEndpoint';
import { ReactComponent as InfoIcon } from "../../../assets/InfoIconGray.svg";

import { V4StyledSlider as ValuedSlider } from '../../base/ValuedSlider';
import Checkbox from '../../base/Checkbox';
import CustomSwitch from '../../base/Switch';


const WaterfallSettings = (props) => {
	const { wideLayout } = props;
	const dispatch = useDispatch()

  const { workspace } = useCurrentWorkspace();

	const imageStrength = useSelector((state) => state.form.payload.imageStrength);
	const setImageStrength = (value) => {
		dispatch(updateImageStrength(value))
	}

	const useImageColors = useSelector((state) => state.form.payload.useImageColors);
	const setUseImageColors = (value) => {
		dispatch(partialUpdateStatePayload({
			useImageColors: value
		}))
	}

	return (
		<div className="flex flex-col gap-2">
			<ValuedSlider 
				label="Image Strength"
				title="Adjust the strength of form and depth matching"
				values={[...Array(10)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
				value={imageStrength}
				onChange={setImageStrength}
				showValue

				wideLayout={wideLayout}
			/>
			{workspace === 'image-workspace' ? (
				<label className="flex items-center justify-start gap-2 text-xs text-gray-label font-poppins font-bold ">
					Use Image Colors

					<Switch 
						checked={useImageColors} 
						className={`group mb-1 ui-checked:bg-app-green ui-not-checked:bg-app-bg-gray relative inline-flex h-5 w-10 items-center rounded-full`}
						onChange={(e) => setUseImageColors(!useImageColors)}
					>
						<span
							className={`translate-x-1 ui-checked:translate-x-6 bg-white inline-block h-[14px] w-[14px] transform rounded-full transition`}
						/>
					</Switch>
				</label>
			) : (
				<div className="flex">
					<Checkbox
						label="Use Image Colors"
						value={useImageColors}
						checked={useImageColors}
						onChange={(e) => setUseImageColors(e.target.checked)}
						title="Use the image colors in generation"
					/>
				</div>
			)}
		</div>
	)
}

const ControlnetSettings = (props) => {
	const dispatch = useDispatch()
	const strengthEnabled = useSelector((state) => state.form.payload.multiCnSwitch)

	const depthEnabled = useSelector((state) => state.form.payload.depthSwitch)
	const cannyEnabled = useSelector((state) => state.form.payload.cannySwitch)

	const handleDepthEnabled = (val) => {
		dispatch(partialUpdateStatePayload({
			depthSwitch: val ? 'On' : 'Off',
			...(!val && cannyEnabled === 'Off' && {
				cannySwitch: 'On'
			})
		}))
	}

	const handleCannyEnabled = (val) => {
		dispatch(partialUpdateStatePayload({
			cannySwitch: val ? 'On' : 'Off',
			...(!val && depthEnabled === 'Off' && {
				depthSwitch: 'On'
			})
		}))
	}

	return (
		<div
			className={`flex flex-row gap-2 ${
				strengthEnabled === 'On' ? '' : 'opacity-5'
			}`}
		>
			<label
				className='bg-app-bg-gray rounded-[4px] py-2 px-3 flex items-center justify-center gap-1'
			>
				<input
					className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 '
					type='checkbox'
					checked={cannyEnabled === 'On'}
					onChange={(e) => handleCannyEnabled(e.target.checked)}
					disabled={strengthEnabled === 'Off'}
				/>
				<span className='text-xs font-inter text-white font-semibold select-none'>
					Details
				</span>
				<Tooltip title="Captures small elements and details. Best if you want to preserve detailed design elements">
					<InfoIcon className="" />
				</Tooltip>
			</label>
			<label
				className='bg-app-bg-gray rounded-[4px] py-2 px-3 flex items-center justify-center gap-1'
			>
				<input
					className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 '
					type='checkbox'
					checked={depthEnabled === 'On'}
					onChange={(e) => handleDepthEnabled(e.target.checked)}
					disabled={strengthEnabled === 'Off'}
				/>
				<span className='text-xs font-inter text-white font-semibold select-none'>
					Depth
				</span>
				<Tooltip title="Captures overall shape and structure of your input image. Ideal for logos with simple and bold shapes or when you want to add more depth to your input image.">
					<InfoIcon className="" />
				</Tooltip>
			</label>
		</div>
	)
}

const BoltSettings = (props) => {
	const { wideLayout } = props;
  const dispatch = useDispatch();

  const denoisingStrength = useSelector(
    (state) => state.form.payload.denoisingStrength
  );
  const edgeThreshold = useSelector(
    (state) => state.form.payload.edgeThreshold
  );
  const imageAsControlImage = useSelector(
    (state) => state.form.payload.image_as_control_image
  );


  const handleImageAsControlImage = (value) => {
    dispatch(updateImageAsControlImage(value));
  };

  const { workspace } = useCurrentWorkspace();

	const { generationStyle } = useGenerationEndpoint()

	// Image Strength
	const strengthEnabled = useSelector((state) => state.form.payload.multiCnSwitch)
	const imageStrength = useSelector((state) => state.form.payload.imageStrength)

	// Image colors
	const useImageColors = useSelector((state) => state.form.payload.useImageColors);
	const colorInfluence = useSelector((state) => state.form.payload.main_sampler_denoise_value);

	// Image Strength
	const setImageStrength = (val) => {
		dispatch(updateImageStrength(val))
	}

	const handleStrengthEnabled = (val) => {
		console.log("Updating switch")
		dispatch(partialUpdateStatePayload({
			multiCnSwitch: val ? 'On' : 'Off',
			...(!val && !useImageColors && {
				useImageColors: true,
			})
		}))
	}

	// Image colors
	const handleUseImageColors = (val) => {
		dispatch(partialUpdateStatePayload({
			useImageColors: val,
			...(!val && strengthEnabled === 'Off' && {
				multiCnSwitch: 'On',
			})
		}))
	}

	const handleColorInfluence = (val) => {
		dispatch(partialUpdateStatePayload({
			main_sampler_denoise_value: val
		}))
	}

	// Background Separation
	const useBackgroundSeparation = useSelector((state) => state.form.payload.useBackgroundSeparation);
	const backgroundSeparation = useSelector((state) => state.form.payload.backgroundSeparation);

	const handleBackgroundSeparation = (val) => {
		dispatch(partialUpdateStatePayload({
			backgroundSeparation: val
		}))
	}

	const handleUseBackgroundSeparation = (val) => {
		dispatch(partialUpdateStatePayload({
			useBackgroundSeparation: val
		}))
	}

  return (
    <div
      className={`flex flex-col gap-4 `}
    >
			<div className={` `}>
				<ValuedSlider 
					label="Image Strength"
					title="Adjust the strength of form and depth matching"
					values={[...Array(10)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
					value={imageStrength}
					onChange={setImageStrength}
					showValue

					disabled={strengthEnabled !== 'On'}
					// disablable={!wideLayout}
					disablable
					onDisabled={(e) => handleStrengthEnabled(!e)}

					wideLayout={wideLayout}

					otherControls={wideLayout && <ControlnetSettings /> }
				/>
			</div>
			{!wideLayout && (
				<ControlnetSettings /> 
			)}
			<ValuedSlider 
				label="Use Image Colors"
				title="Adjust the strength of original image color matching"
				values={[...Array(10)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
				value={colorInfluence}
				onChange={handleColorInfluence}
				showValue

				disablable
				disabled={!useImageColors}
				onDisabled={(e) => handleUseImageColors(!e)} 

				wideLayout={wideLayout}
			/>

			{ generationStyle === 'style-transfer' && (
				<ValuedSlider 
					label="Background Separation"
					values={[...Array(5)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
					value={backgroundSeparation}
					onChange={handleBackgroundSeparation}
					showValue

					disablable
					disabled={!useBackgroundSeparation}
					onDisabled={(e) => handleUseBackgroundSeparation(!e)} 

					wideLayout={wideLayout}
				/>
			)}
    </div>
  );
};

const XLControlnetSettings = (props) => {
	const dispatch = useDispatch()

	const cnSwitch = useSelector((state) => state.form.payload.cnSwitch)

	// Image colors
	const useImageColors = useSelector((state) => state.form.payload.useImageColors);

	const handleUseImageColors = (val) => {
		dispatch(partialUpdateStatePayload({
			useImageColors: val,
			...(!val && cnSwitch === 'Off' && {
				cnSwitch: 'On',
			})
		}))
	}

	const handleCannyEnabled = (val) => {
		dispatch(partialUpdateStatePayload({
			cnSwitch: val ? 'On' : 'Off',
			...(!val && !useImageColors && {
				useImageColors: true,
			})
		}))
	}

	return (
		<div
			className={`flex flex-row gap-2`}
		>
			<label
				className='bg-app-bg-gray rounded-[4px] py-2 px-3 flex items-center justify-center '
			>
				<input
					className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 mr-[10px]'
					type='checkbox'
					checked={useImageColors}
					onChange={(e) => handleUseImageColors(e.target.checked)}
				/>
				<span className='text-[10px] font-inter text-white font-semibold select-none capitalize'>
					Use Image Colors
				</span>
			</label>
			<label
				className='bg-app-bg-gray rounded-[4px] py-2 px-3 flex items-center justify-center'
			>
				<input
					className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 mr-[10px]'
					type='checkbox'
					checked={cnSwitch === 'On'}
					onChange={(e) => handleCannyEnabled(e.target.checked)}
				/>
				<span className='text-[10px] font-inter text-white font-semibold select-none'>
					Details
				</span>
			</label>
		</div>
	)
}

const XLSettings = (props) => {
	const { wideLayout } = props;
  const dispatch = useDispatch();

  const { workspace } = useCurrentWorkspace();

	const { generationStyle } = useGenerationEndpoint()

	// Image Strength
	const imageStrength = useSelector((state) => state.form.payload.imageStrength)

	const setImageStrength = (val) => {
		dispatch(updateImageStrength(val))
	}

	const handleStrengthEnabled = (val) => {
		console.log("Updating switch")
		dispatch(partialUpdateStatePayload({
			multiCnSwitch: val ? 'On' : 'Off'
		}))
	}

	const cnSwitch = useSelector((state) => state.form.payload.cnSwitch)

	// Image colors
	const useImageColors = useSelector((state) => state.form.payload.useImageColors);
	const colorInfluence = useSelector((state) => state.form.payload.main_sampler_denoise_value);

	const handleUseImageColors = (val) => {
		dispatch(partialUpdateStatePayload({
			useImageColors: val,
			...(!val && cnSwitch === 'Off' && {
				cnSwitch: 'On',
			})
		}))
	}

	const handleCannyEnabled = (val) => {
		dispatch(partialUpdateStatePayload({
			cnSwitch: val ? 'On' : 'Off',
			...(!val && !useImageColors && {
				useImageColors: true,
			})
		}))
	}

	const handleColorInfluence = (val) => {
		dispatch(partialUpdateStatePayload({
			main_sampler_denoise_value: val
		}))
	}

  return (
    <div
      className={`flex flex-col gap-4 `}
    >
			<ValuedSlider 
				label="Image Strength"
				title="Adjust the strength of form and depth matching"
				values={[...Array(10)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
				value={imageStrength}
				onChange={setImageStrength}
				showValue

				wideLayout={wideLayout}

				disabled={cnSwitch !== 'On'}
				disablable
				onDisabled={(e) => handleCannyEnabled(!e)}
			/>

			<ValuedSlider 
				label="Use Image Colors"
				title=""
				values={[...Array(10)].map((_, i) => ({ name: `${i + 1}`, value: i + 1}))}
				value={colorInfluence}
				onChange={handleColorInfluence}
				showValue

				wideLayout={wideLayout}

				disabled={!useImageColors}
				disablable
				onDisabled={(e) => handleUseImageColors(!e)}

			/>


    </div>
  );
};


const ImageSettings = (props) => {
	const { wideLayout } = props;
	const { generationStyle } = useGenerationEndpoint()

	return (
		<>
			{generationStyle === 'waterfall' && (
				<WaterfallSettings wideLayout={wideLayout} />
			)}
			{(
				generationStyle === 'bolt' ||
				generationStyle === 'style-transfer'
			) && (
				<BoltSettings wideLayout={wideLayout} />
			)}
			{generationStyle === 'xl' && (
				<XLSettings wideLayout={wideLayout} />
			)}
		</>
	)
}

export default ImageSettings;
