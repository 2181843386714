import React, { useState, useRef, useEffect } from 'react';
import Option from './Option';
import { ReactComponent as DropdownIcon } from '../../../assets/dashboard/dropdownicon.svg';

function Dropdown({ options, handleOptionChange, isSelectedFilter }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown
    }
  };

  useEffect(() => {
    // Attach the listeners to the document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the listeners when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='relative' ref={dropdownRef}>
      <div
        className='flex items-center justify-between border-[2px] rounded-[28px] border-[#2B2B35] pr-2 pl-5 py-2 gap-5 cursor-pointer '
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className='text-[#677788] text-[15.5px] font-semibold'>
          {!isSelectedFilter ? 'All styles' : 'Multi-styles Selected'}
        </div>
        <div className='bg-[#2B2B35] rounded-full p-[4px]'>
          <DropdownIcon />
        </div>
      </div>
      {isOpen && (
        <div className='absolute right-0 mt-2 w-48 bg-[#2B2B35] border-none rounded-[19px] p-[15px]'>
          {options.map((option) => (
            <Option
              key={option.value}
              option={option}
              handleChange={handleOptionChange}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
