import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "../../Tooltip"

import { ReactComponent as UploadIcon } from "../../../assets/UploadImageEditor.svg";
import { ReactComponent as SaveEditor } from "../../../assets/SaveEditor.svg";
import { ReactComponent as CloseIcon } from "../../../assets/CloseEditor.svg";
import { ReactComponent as CleanIcon } from "../../../assets/Clean.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/DownloadImageEditor.svg";
import { ReactComponent as VectorizeIcon } from '../../../assets/VectorizeIconLightGray.svg';

import { resizeBase64, resizeInputImage } from "../resizers";
import { EDITOR_SIZE, IMAGE_EXPORT_SIZE } from "../settings";
import { updateInputImage, updateMethod } from "../../../reducers/formReducer";

import { fabric } from 'fabric';
import { RenderedColorPickerIcon, RenderedSvgBgColorPicker, RenderedSvgStrokeColorPicker } from "../selection/selection_icons";
import useCurrentWorkspace from "../../../hooks/useCurrrentWorkspace";
import { setBackgroundImage, setIsOpen } from "../../../reducers/imageEditorReducer";

function extend(target, objects) { // eslint-disable-line no-unused-vars
  const hasOwnProp = Object.prototype.hasOwnProperty;
  let source;
  let prop;
  let i;
  let len;

  for (i = 1, len = arguments.length; i < len; i += 1) {
    source = arguments[i];
    for (prop in source) {
      if (hasOwnProp.call(source, prop)) {
        target[prop] = source[prop];
      }
    }
  }

  return target;
}

export const ActionBar = (props) => {
	const { 
		editor, setCurrentTool, onSave, 
		zoomCenterRef, selectionStyle, isModal,
		handleStrokeSelectionColorPicker, 
		handleSelectionColorPicker,
		isOpen,
	} = props;
  const uploaderRef = useRef();
  const dispatch = useDispatch();

	const { workspace } = useCurrentWorkspace();

  const { backgroundImage, zoom } = useSelector((state) => state.imageEditor);

	const uploadSvgRef = useRef();

  const resetCanvas = async () => {
    await editor.current?.getInstance().clearObjects();

		const { image } = await resizeBase64(backgroundImage, EDITOR_SIZE, EDITOR_SIZE);
    const resp = await fetch(image);
    const url = URL.createObjectURL(await resp.blob());

    await editor.current?.getInstance().loadImageFromURL(url, "Inspiration");
  }

  const insertImage = async (e) => {

    const { url, width, height } = await resizeInputImage(e.target.files[0], Math.floor(editor?.current.getInstance()._graphics._canvas.width * 0.8))

    const imageObj = await editor.current?.getInstance().addImageObject(url)

    
    await editor.current?.getInstance().setObjectPropertiesQuietly(imageObj.id, {
      left: 256,
      top: 256,
      width,
      height,
    });


    setCurrentTool();
    await editor.current?.getInstance().stopDrawingMode();
  }

  const save = async () => {
    await editor.current?.getInstance().zoom({zoomLevel: 1, x: EDITOR_SIZE / 2, y: EDITOR_SIZE / 2})
    const originalImage = editor.current?.getInstance().toDataURL({multiplier: 4});
    const { image } = await resizeBase64(originalImage, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE)
    dispatch(updateInputImage(image));

    if (onSave) onSave();
  };

  const downloadImage = async () => {
    await editor.current?.getInstance().zoom({zoomLevel: 1, x: EDITOR_SIZE / 2, y: EDITOR_SIZE / 2})
    const originalImage = editor.current?.getInstance().toDataURL({multiplier: 4});
    await editor.current?.getInstance().zoom({ zoomLevel: zoom, x: zoomCenterRef.current.x, y: zoomCenterRef.current.y })
    const { image } = await resizeBase64(originalImage, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE)

    const link = document.createElement("a");
    link.href = image;
    link.download = "Lodi drawing.png";
    document.body.appendChild(link);
    link.click();
    document.removeChild(link);
  }

	useEffect(() => {
		editor?.current?.getInstance()._graphics._canvas.on('selection:created', (e) => {
			console.log("New seldction ", e)
		});
	}, [editor]);

	const loadSVG = async (e) => {
		const url = URL.createObjectURL(e.target.files[0]);
		fabric.loadSVGFromURL(url, async (objects) => {
			for (let object of objects) {
				// console.log(object, editor?.current?.getInstance()._graphics._canvas)
				if (object.type === 'text' || object.type === 'i-text') {
					object.set(
						extend(
							{
								type: 'i-text',
								fill: object.fill,
								stroke: object.stroke,
							},
							{
								...selectionStyle,
								controls: {
									...selectionStyle.controls,

									strokeColorPicker: new fabric.Control({
										x: -0.5,
										y: 0.5,
										offsetY: 20,
										offsetX: 15,
										cursorStyle: 'pointer',
										mouseDownHandler: handleStrokeSelectionColorPicker,

										render: RenderedColorPickerIcon,
										cornerSize: 32
									}),
								},
							},
							editor?.current?.getInstance()._graphics.controlStyle
						)
					);

					await editor.current?.getInstance().addText(object.text, {
						styles: {
							...object,
						},
						position: {
							x: object.aCoords.tl.x,
							y: object.aCoords.tl.y,
						},
						autofocus: false,
					});

				} else {
					object.set(
						extend(
							{
								type: object.type === 'text' ? 'i-text' : 'icon',
								fill: object.fill,
								stroke: object.stroke,
							},
							selectionStyle,
							editor?.current?.getInstance()._graphics.controlStyle,
							{
								...selectionStyle,

								controls: {
									...selectionStyle.controls,

									colorPicker: new fabric.Control({
										x: -0.5,
										y: 0.5,
										offsetY: 25,
										offsetX: -20,
										cursorStyle: 'pointer',
										mouseDownHandler: handleSelectionColorPicker,

										render: RenderedSvgBgColorPicker,
										cornerSize: 28
									}),

									strokeColorPicker: new fabric.Control({
										x: -0.5,
										y: 0.5,
										offsetY: 25,
										offsetX: 8,
										cursorStyle: 'pointer',
										mouseDownHandler: handleStrokeSelectionColorPicker,

										render: RenderedSvgStrokeColorPicker,
										cornerSize: 28
									}),
								},
							},
						)
					);
					editor?.current?.getInstance()._graphics.getCanvas().add(object);
					editor?.current?.getInstance()._graphics._addFabricObject(object);

					object.set(
						extend(
							{
								originX: 'center',
								originY: 'center',
							},
						)
					);
					object.setPositionByOrigin(object.aCoords.tl, 'left', 'top');
				}
			}
		});
	}
  const method = useSelector((state) => state.form.method);

  const handleRemoveImage = () => {
		if (isOpen) {
			dispatch(setIsOpen(false));
			return;
		}
    dispatch(updateInputImage(null));
    dispatch(setBackgroundImage(null));
    if (method === 'ddd')
      dispatch(updateMethod('redesign'));
  }

  return (
    <div className={`flex flex-row gap-2 bg-app-black items-center ${isModal ? "justify-center": "justify-between"}`}>
      <Tooltip title="Add Image">
        <button
          className={`bg-divider w-[38px] h-[38px] p-2 lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
          onClick={() => uploaderRef.current.click()}
        >
          <UploadIcon className="max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[26px] lg:h-[26px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green" />
        </button>
        <input
          type="file"
          className="hidden"
          ref={uploaderRef}
          onChange={insertImage}
        />
      </Tooltip>

      <Tooltip title="Import SVG">
        <button
          className={`bg-divider w-[38px] h-[38px] p-2 lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
					type="button"
          onClick={() => uploadSvgRef.current.click()}
        >
          <VectorizeIcon className="max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[26px] lg:h-[26px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green" />
        </button>
        <input
          type="file"
          className="hidden"
          ref={uploadSvgRef}
          onChange={loadSVG}
					accept=".svg"
        />
      </Tooltip>
     
      <Tooltip title="Clear everything">
        <button
          className={`bg-divider p-2 w-[38px] h-[38px] lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group bg-app-black`}
          onClick={resetCanvas}
        >
          <CleanIcon
            className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[24px] lg:h-[24px]  [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green`}
          />
        </button>
      </Tooltip>
     
     <Tooltip title="Download">
       <button
         className={`bg-divider p-2 w-[38px] h-[38px] lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group bg-app-black`}
         onClick={downloadImage}
       >
         <DownloadIcon
           className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]  [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green`}
         />
       </button>
     </Tooltip>

     {workspace === 'image-workspace' &&(
			 <Tooltip title="Cancel">
				 <button
					 className={`bg-divider p-2 w-[38px] h-[38px] lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group bg-app-black`}
					 onClick={handleRemoveImage}
				 >
					 <CloseIcon
						 className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[16px] lg:h-[16px]  [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green`}
					 />
				 </button>
			 </Tooltip>
		 )}
     <Tooltip title="Save">
       <button
         className={`bg-divider p-2 w-[38px] h-[38px] lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group bg-app-black`}
         onClick={save}
       >
         <SaveEditor
           className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]  [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green`}
         />
       </button>
     </Tooltip>
    </div>
  )
}

export default ActionBar
