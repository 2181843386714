import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { ReactComponent as TextIcon } from '../../../assets/Text.svg';
import LdTooltip from '../../Tooltip';
import { FontSection } from './FontSection';
import { toolIconBtnCss } from '../toolbar/const';

const TextTool = (props) => {
  const { editor, updateDrawingMode } = props;
  const { tool: currentTool } = useSelector((state) => state.imageEditor);

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [arrowElement, setArrowElement] = useState();

  const { styles, attributes } = usePopper(anchorEl, popperEl, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: props.isImageWorkSpace
          ? { offset: [0, 20] }
          : { offset: [0, 10] },
      },
    ],
    placement: 'bottom',
  });

  return (
    <Popover className='relative overflow-visible'>
      {() => (
        <>
          <LdTooltip title='Text' position='right'>
            <Popover.Button
              className={`${
                currentTool === 'TEXT' ? 'bg-divider' : 'bg-app-black'
              } ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1`}
              ref={setAnchorEl}
              onClick={() => updateDrawingMode('TEXT')}
            >
              <TextIcon
                className={`max-lg:aspect-square [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green ${
                  currentTool === 'TEXT' ? '[&_path]:stroke-app-green' : ''
                }`}
              />
            </Popover.Button>
          </LdTooltip>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              // className="absolute left-1/2 -translate-x-1/2 z-20 w-[200px] px-4 sm:px-0 lg:max-w-3xl mb-2"
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
              className='my-2'
            >
              <div
                ref={setArrowElement}
                style={styles.arrow}
                {...attributes.arrow}
                className='w-3 h-3 overflow-hidden inline-block bottom-full'
              >
                <div className='h-full w-full bg-[#1c1c25ff] translate-x-1/2 translate-y-1/2 rotate-45 origin-top-left'></div>
              </div>

              <FontSection
                editor={editor}
                updateDrawingMode={updateDrawingMode}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default TextTool;
