import { Datagrid, DateField, List, TextField, ReferenceField } from 'react-admin';

export const QuestionnaireList = () => (
<List>
	<Datagrid rowClick="edit">
		<ReferenceField source="user" reference="users">
			<TextField source="email" />
		</ReferenceField>

		<TextField source="discovery" />
		<TextField source="occupation" />
		<TextField source="usageExplanation" label="Purpose" />
		<TextField source="futureRecommendations" label="Recommendations" />
		<DateField source="createdAt" label="Date" />
	</Datagrid>
</List>
);

export default QuestionnaireList;
