import { RadioGroup, } from '@headlessui/react';
import React from 'react';
import { Tooltip } from 'react-tippy';
import { SDXLDenoisingValues, UseAsControlImageValues } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { partialUpdateStatePayload, updateImageAsControlImage } from '../../reducers/formReducer';
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import Switch from '../base/Switch';
import SliderV2 from '../base/SliderV2';
import Radio from '../base/Radio';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';

const SDDenoising = (props) => {
  const imageAsControlImage = useSelector((state) => state.form.payload.image_as_control_image);
  const denoisingStrength = useSelector(
    (state) => state.form.payload.denoisingStrength
  );
	const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const method = useSelector((state) => state.form.method);

	const dispatch = useDispatch()

	const handleDenoising = (value) => {
    dispatch(partialUpdateStatePayload({ denoisingStrength: value }));
	}

  const setImageAsControlImage = (value) => {
		dispatch(updateImageAsControlImage(value));
	}

	const { workspace } = useCurrentWorkspace();

	if (isDevModeEnabled) {
		return (
			<div className={"flex justify-between "}>
				<label className={"text-gray-label font-poppins font-bold text-xs flex gap-1 field-label items-center"}>
					Use Image Colors
		
					<Tooltip title={"Defines the influence of the redesign method."} >
						<InfoIcon />
					</Tooltip>
				</label>
				<div className="flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]">
					<Switch value={imageAsControlImage} onChange={setImageAsControlImage} />
					<input 
						className={"py-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-1/2"}
						value={denoisingStrength}
						onChange={(e) => handleDenoising(e.target.value)}
						disabled={!imageAsControlImage}
					/>
				</div>
			</div>
		);
	}

	return (
		<Radio 
			values={UseAsControlImageValues}
			value={imageAsControlImage}
			onChange={setImageAsControlImage}
			label={"Use Image colors"}
			title={" Use this if you want to use your image's colors as well, (Low) uses the main colors in your image, (High)  tries to use as much colors as possible from your image"}
			appliedClasses={{
				optionContainer: `justify-start grow ${workspace === 'image-workspace' ? 'max-w-[150px]' : 'max-w-[41%]'}`,
				option: 'grow [&>p]:text-center [&>p]:grow',
			}}
		/>
	)
}

export default SDDenoising;
