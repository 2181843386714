import { BulkDeleteButton, Button, Datagrid, ImageField, List, ReferenceField, SaveButton, SaveContextProvider, SimpleForm, TextField, useListContext, useNotify, useRecordContext } from 'react-admin';
import authAxiosInstance from '../helpers/auth-axios';
import { useState } from 'react';

export const AddToGalleryAction = (props) => {
	const { draft } = props;
	const { selectedIds } = useListContext();

	const notify = useNotify();

	const save = async (e, b) => {
		try {
			const resp = await authAxiosInstance.post('/api/gallery', {
				generationIds: selectedIds,
				draft,
			});

			notify("Generations added to gallery", { type: 'success' })
		} catch(e) {
			notify("Failed to add to gallery", { type: 'error' })
		}
	}

	return (
		<Button onClick={save} label={`Add to Gallery ${draft ? 'As Draft' : ''}`} />
	)
}

export const GenerationList = () => {
	return (
    <List>
        <Datagrid rowClick="edit" bulkActionButtons={
					<>
						<AddToGalleryAction />
						<AddToGalleryAction draft />
						<BulkDeleteButton />
					</>
				}>
            <ImageField source="imageUrl" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }} />
            <ReferenceField source="userId" reference="users">
              <TextField source="email" />
            </ReferenceField>
            <ImageField source="inputImage" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }} />

            <TextField source="dimension" />

            <TextField source="prompt" />
            <TextField source="negativePrompt" />
            <TextField source="contentStyle" label="Style" />
            <TextField source="detailLevel" />
            <TextField source="imageQuality" />
            <TextField source="seed" />

        </Datagrid>
    </List>
	);
}

export default GenerationList;
