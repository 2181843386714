import { Create, NumberInput, SimpleForm, ReferenceInput, TextInput, AutocompleteInput, useRecordContext } from 'react-admin';

const PostTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};

export const UserEdit = () => (
  <Create title={<PostTitle />}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="name" />
      <TextInput source="password" />
      <NumberInput source="remainingCredits" />
      <ReferenceInput source="role" reference="userRoles">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="id" disabled />
    </SimpleForm>
  </Create>
);

export default UserEdit;