import environment from "../helpers/envProvider";

const ENVIRONMENT = environment("REACT_APP_ENVIRONMENT") || "production";

const dimensions = [
  '512 x 512',
  ...(ENVIRONMENT === 'staging' ? ['640 x 640'] : []),
  '768 x 768',
  '1024 x 1024',
];
export const UPSCALE_RESOLUTIONS = {
  '512 x 512': '1024 x 1024',
  ...(ENVIRONMENT === 'staging' && {'640 x 640': '1280 x 1280'}),
  '768 x 768': '1536 x 1536',
  '1024 x 1024': '2048 x 2048',
};

export const DIMENSOIN_COSTS = {
  '512 x 512': 1,
  ...(ENVIRONMENT === 'staging' && {'640 x 640': 1}),
  '768 x 768': 2,
  '1024 x 1024': 2,
}
export const APPSUMO_DIMENSOIN_COSTS = {
	...DIMENSOIN_COSTS,
  '1024 x 1024': 3,
}


export default dimensions;
