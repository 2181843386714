import { DateInput, Edit, SimpleForm, ReferenceField, TextField, TextInput, Image, ImageField, BooleanInput, Create, FileField, ImageInput, SelectInput } from 'react-admin';
import Grid from '@mui/material/Grid';
import { STYLE_TRANSFER_STYLES } from '../StyleImage';

export const StyleTransferGalleryCreate = () => (
    <Create>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
							
							<ImageInput source="image" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }}/>
            </Grid>

            <Grid item xs={6}>
              <TextInput source="order" fullWidth />
              <TextInput source="name" fullWidth />
              <BooleanInput source="draft" fullWidth />
              <SelectInput source="style" choices={STYLE_TRANSFER_STYLES.map(x => ({
								id: x.value,
								name: x.name
							}))}/>
            </Grid>

          </Grid>
        </SimpleForm>
    </Create>
);

export default StyleTransferGalleryCreate;
