import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as SlideIcon } from '../assets/slider-icon.svg';

import { ReactComponent as Download } from '../assets/DownloadIcon.svg';
import { ReactComponent as RefreshIcon } from '../assets/refresh.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';

import Background from '../assets/BGRmoveBackground.svg';
import useGenerationEndpoint from './input/useGenerationEndpoint';

const ImageComparisonV4 = (props) => {
  const { beforeImage, afterImage, handleDownload, handleRefresh } = props;
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const containerRef = useRef(null);

  const [sliderValue, setSliderValue] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const handleSliderChange = (event) => {
    if (!isDragging) return;
    const rect = event.currentTarget.getBoundingClientRect();
    const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
    const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));
    setSliderValue(percent);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className='flex flex-col w-full h-full' onMouseUp={handleMouseUp}>
      <div
        className='flex flex-row h-full self-center aspect-square relative rounded-lg overflow-hidden'
        ref={containerRef}
        onMouseMove={handleSliderChange}
      >
        <div className='absolute z-20 left-5 top-5 flex flex-row gap-3'>
          {handleDownload && (
            <button
              className='bg-gray-200 shadow-sm rounded-md p-2'
              onClick={handleDownload}
            >
              <Download className='w-4 h-4 [&>path]:fill-[#84808D]' />
            </button>
          )}
          {handleRefresh && (
            <button
              className='bg-gray-200 shadow-sm rounded-md p-2'
              onClick={handleRefresh}
            >
              <RefreshIcon className='w-4 h-4' />
            </button>
          )}
        </div>

        <img
          src={Background}
          alt='background'
          className='absolute w-full h-full object-cover '
        />
        <img
          ref={imageRef1}
          src={afterImage}
          alt='After'
          className='object-contain cursor-grabbing w-full h-full '
          draggable={false}
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          }}
        />
        <div className=' bg-gray-200 shadow-sm px-[9px] py-[4px] rounded-[6px] absolute right-[45px] top-1/2 -translate-y-1/2 text-gray-500 text-[14px] font-bold z-20 select-none'>
          Result
        </div>

        <div
          className='absolute top-0 left-0 right-0 bottom-0 w-full h-full select-none z-30'
          style={{ clipPath: `inset(0 ${100 - sliderValue}% 0 0)` }}
        >
          <img
            src={beforeImage}
            alt='before'
            className='object-contain cursor-grabbing w-full h-full select-none'
            ref={imageRef2}
            draggable={false}
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            }}
          />
          <div className='bg-gray-200 shadow-sm px-[9px] py-[4px] rounded-[6px] absolute left-[45px] top-1/2 -translate-y-1/2 text-gray-500 text-[14px] font-bold z-20 select-none'>
            Input
          </div>
        </div>
        <div
          className='absolute top-0 bottom-0 w-[2.6px] bg-white z-40 select-none'
          style={{ left: `calc(${sliderValue}% - 1px)` }}
        >
          {/* <div className='bg-[#30303E] absolute rounded-full h-[38px] w-[30px] -left-[13.5px] top-[calc(50%-5px)]'></div> */}
          <SlideIcon
            className='absolute h-[38px] w-[30px] -left-[13.5px] top-[calc(50%-5px)] cursor-grabbing hover:fill-blue-200 active:fill-blue-200'
            onMouseDown={handleMouseDown}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageComparisonV4;
