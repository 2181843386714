import React from 'react'
import { ReactComponent as ZoomPlusIcon } from "../../assets/zoom-plus.svg";
import { ReactComponent as ZoomMinusIcon } from "../../assets/zoom-minus.svg";
import { useDispatch, useSelector } from 'react-redux';
import { updateZoom } from '../../reducers/imageEditorReducer';
import { EDITOR_SIZE } from './settings';

const Zoom = (props) => {
  const { editor, zoomCenterRef } = props;

  const zoom = useSelector((state) => state.imageEditor.zoom);
  const dispatch = useDispatch();

  const changeZoom = async (e, direction) => {

    const newZoom = Math.max(Math.min(5, zoom + direction * 0.2), 1)

    dispatch(updateZoom(newZoom));

    await editor?.current.getInstance().zoom({zoomLevel: newZoom, x: zoomCenterRef.current.x, y: zoomCenterRef.current.y}, newZoom, 100, 100)

    zoomCenterRef.current = {
      x: EDITOR_SIZE / 2,
      y: EDITOR_SIZE / 2,
      zoomLevel: newZoom
    }

  }

  return (
    <span className='absolute left-4 bottom-4 flex flex-row [&>*]:border-solid [&>*]:border [&>*]:border-l-0 [&>*]:first-of-type:border-l [&>*]:border-zoom-border z-20'>

      <button className="bg-app-black flex items-center justify-center rounded-l-lg border border-solid border-zoom-border group p-2" onClick={(e) => changeZoom(e, -1)}>
        <ZoomMinusIcon
          className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[13px] lg:h-[13px] [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green`}
        />
      </button>

      <div className='bg-app-black px-4 border border-l-0 border-solid border-zoom-border text-active-icon flex items-center justify-center'>{Math.floor(zoom * 100)}%</div>

      <button className="bg-app-black flex items-center justify-center rounded-r-lg border border-l-0 border-solid border-zoom-border group p-2" onClick={(e) => changeZoom(e, 1)}>
        <ZoomPlusIcon
          className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[13px] lg:h-[13px] [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green `}
        />
      </button>

    </span>
  )
}

export default Zoom