import React from 'react';

function MultiSwitchToggle({ selected, handleSelect }) {
  const options = ['All generations', 'Paid', 'Free', 'Sumolings'];

  return (
    <div className='flex items-center w-fit gap-2 justify-start p-[2px] rounded-[6px] bg-gray-300'>
      {options.map((option) => (
        <button
          key={option}
          onClick={() => handleSelect(option)}
          className={`px-2 py-1 rounded ${
            selected === option ? 'bg-white' : 'bg-transparent'
          } text-black text-[12px] font-bold`}
        >
          {option}
        </button>
      ))}
    </div>
  );
}

export default MultiSwitchToggle;
