import React, { useState, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '../Tooltip';
import DownloadImage from '../../services/ImageDownloader';

import { convertToHtml, parentheseSplit } from '../../helpers/importanceHelpers';

import { ReactComponent as SparkleIcon } from '../../assets/Sparkle.svg';
import { ReactComponent as CopyIcon } from '../../assets/CopyIcon.svg';
import { ReactComponent as VectorizeIcon } from '../../assets/VectorizeIconLightGray.svg';
import { ReactComponent as BookmarkIcon } from '../../assets/BookmarkIconOutline.svg';
import { ReactComponent as BookmarkIconGreen } from '../../assets/BookmarkIconGreenOutline.svg';
import { ReactComponent as TrashIcon } from '../../assets/TrashIconLightGray.svg';
import { ReactComponent as DownloadIcon } from '../../assets/DownloadIcon.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/ThumbsUpOutline.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/ThumbsDownOutline.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as ChevronDown } from '../../assets/ChevronDownLightGray.svg';
import { ReactComponent as ImageIcon } from '../../assets/ImageIconGray.svg';
import { ReactComponent as ImageIconDisabled } from '../../assets/ImageIconDisabled.svg';
import { ReactComponent as DDDIcon } from '../../assets/3D-icon.svg';
import { ReactComponent as UpscaleIcon } from '../../assets/upscale-icon.svg';
import { ReactComponent as EditCanvasIcon } from "../../assets/EditCanvas.svg";
import { ReactComponent as RemoveBackgroundIcon } from "../../assets/RemoveBG.svg";
import { ReactComponent as Method2DIcon } from "../../assets/2d-icon-new.svg";
import { ReactComponent as Method3DIcon } from "../../assets/3d-icon-new.svg";
import { bgRemove, setImageAsInspiration, upscaleImage, vectorizeImage, updateContentStyle, GENERATION_STATUS } from '../../reducers/formReducer';

import { Dialog, Transition } from '@headlessui/react';
import SpinnerLoader from '../base/SpinnerLoader';
import { setIsEditImage, setIsOpen } from '../../reducers/imageEditorReducer';
import {
  DenoisingAcceptedValues,
  IterationsAcceptedValeus,
  PromptRigidnessAcceptedValues,
  ThresholdAcceptedValues,
  detailLevelValues,
  imageWeightAcceptedValues,
} from '../redesign/constants';
import redesignMethods from '../../data/redesignMethods';
import STYLES from '../input/content-styles';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import { ImageViewerContext } from './context';

function handleRasterDownload(image, dispatch, opts) {
  DownloadImage.download('raster', image, opts);
}

const DepImageViewer = (props) => {

	const dispatch = useDispatch();
  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const user = useSelector((state) => state.userSlice.user);

	const {
		viewingImage,
		actions: {
			handleNext,
			handlePrevious,
			onClose,

			handleOpenTool,
			copyToClipboard,
			handleBookmarkClicked,
			handleDeleteGeneration,
			handleReuse,
			handleFeedback,
			handleUseAsInputImage,
		},
		config: {
			isShowBookmark = true, isShowDelete = true, isShowEdit = true, isShowLike= true, isShowDislike= true, isShowUpscale= true, 
		}
	} = useContext(ImageViewerContext);

  const { mode: currentMode, method: currentMethod } = useGenerationEndpoint();
  const { mode, method: imageMethod, generationStyle } = useGenerationEndpoint({ 
		method: viewingImage?.method });

  const [showVectorizeSpinner, setShowVectorizeSpinner] = useState(false);
  const [showUpscalerSpinner, setShowUpscalerSpinner] = useState(false);
  const [showRemoveBgSpinner, setShowRemoveBgSpinner] = useState(false);

  const handleUseAsInspiration = async (e) => {
    try {
      await dispatch(setImageAsInspiration({
        imageUrl: viewingImage.imageUrl,
				method: currentMode === 'v3.0' ? undefined : 'sdxl'
      })).unwrap()

      props?.gotoTextWorkspace?.();

      onClose();
    } catch (e) {
      console.log(e);
      toast('Failed to load image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleUseAsDDDInspiration = async (e) => {
    try {
      await dispatch(setImageAsInspiration({
        imageUrl: viewingImage.imageUrl,
        method: currentMode === 'v3.0' ? undefined : 'sdxl-3d'
      })).unwrap()
			if (currentMode === 'v3.0') {
				dispatch(updateContentStyle('3d'));
			}
      
      props?.gotoTextWorkspace?.();

      onClose();
    } catch (e) {
      console.log(e);
      toast('Failed to load image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleVectorizeDownload = async (image, dispatch, opts) => {
    setShowVectorizeSpinner(true);
    try {
      await dispatch(vectorizeImage(viewingImage)).unwrap();
    } catch (e) {
      toast(e.error?.detail || 'Something went wrong during vectorization', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowVectorizeSpinner(false);
  };

  const handleUpscale = async (e) => {
    e.stopPropagation();
    setShowUpscalerSpinner(true);
    try {
      await dispatch(upscaleImage(viewingImage)).unwrap();
    } catch (e) {
      console.log(e);

      toast(e?.error?.detail || 'Failed to upscale image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowUpscalerSpinner(false);
  };


  const handleEditImage = async () => {
    await handleUseAsInspiration(viewingImage.imageUrl);
    dispatch(setIsEditImage(true));
    dispatch(setIsOpen(true));
  };

  const handleRemoveBg = async () => {
    setShowRemoveBgSpinner(true);
    try {
      await dispatch(
        bgRemove({
          ...viewingImage,
        })
      ).unwrap();
    } catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to remove background', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowRemoveBgSpinner(false);
  };

  // const vectorize = async e => {
  //   setShowVectorizeSpinner(true)
  //   await handleVectorize(viewingImage)
  //   setShowVectorizeSpinner(false)
  // }

  // function handleVectorizeDownload (e) {
  //   const dispatch = useDispatch();
  //   let downloaded = DownloadImage(props.image, { element: e }).vector();
  //   dispatch(setUserTokens({ tokens: downloaded?.user?.remainingCredits }));
  // };

  const promptValue = useMemo(
    () =>
      convertToHtml(parentheseSplit(viewingImage?.prompt || ''), {
        editable: false,
      }),
    [viewingImage]
  );

	return (
		<Transition.Child
			as={React.Fragment}
			enter='ease-out duration-300'
			enterFrom='opacity-0 scale-95'
			enterTo='opacity-100 scale-100'
			leave='ease-in duration-200'
			leaveFrom='opacity-100 scale-100'
			leaveTo='opacity-0 scale-95'
		>
			<Dialog.Panel
				className='max-h-[90vh] max-w-[90vw] bg-[#13131A] p-6 rounded-2xl flex flex-col  lg:flex-row gap-6 border border-[#1A1E2D] overflow-x-hidden overflow-y-auto relative custom-scroll'
				onClick={(e) => e.stopPropagation()}
			>
				<div className='rounded-lg relative flex items-center justify-center aspect-square w-11/12 grow lg:grow-0 lg:w-[700px] shrink-0 lg:shrink my-4 mx-6 '>

					{[GENERATION_STATUS.GENERATING, GENERATION_STATUS.IN_QUEUE].indexOf(viewingImage?.status) === -1 && (
						<>
							<img
								src={viewingImage?.imageUrl}
								alt={`Gradient background - ${viewingImage?.prompt}`}
								className='absolute inset-0 w-full h-full blur-xl opacity-40 rounded-2xl'
							/>
							<img
								src={viewingImage?.imageUrl}
								alt={`Image: ${viewingImage?.prompt}`}
								className='rounded-lg z-10 max-w-full '
							/>
						</>
					)}

					<button
						className='flex justify-center items-center rounded-md bg-divider absolute top-1/2 left-4 p-2 -translate-y-1/2 z-20'
						onClick={() => handlePrevious()}
					>
						<ChevronDown className='rotate-90 w-6 h-6' />
					</button>

					<button
						className='flex justify-center items-center rounded-md bg-divider absolute top-1/2 right-4 p-2 -translate-y-1/2 z-20'
						onClick={() => handleNext()}
					>
						<ChevronDown className='-rotate-90 w-6 h-6' />
					</button>

					<SpinnerLoader
						isLoading={
							showUpscalerSpinner ||
							showVectorizeSpinner ||
							showRemoveBgSpinner ||
							[GENERATION_STATUS.GENERATING, GENERATION_STATUS.IN_QUEUE].indexOf(viewingImage?.status) > -1
						}
						classNames={{
							backdrop: '!rounded-lg',
						}}
						icon={
							{
								...(showVectorizeSpinner && {
									icon: <VectorizeIcon className='w-8 h-8' />,
								}),
								...(showUpscalerSpinner && {
									icon: <UpscaleIcon className='w-8 h-8' />,
								}),
								...(showRemoveBgSpinner && {
									icon: <RemoveBackgroundIcon className='w-8 h-8' />,
								}),
							}.icon
						}
					/>
				</div>

				<div className='flex flex-row flex-wrap lg:flex-nowrap lg:flex-col lg:overflow-y-auto custom-scroll grow lg:w-[350px] gap-4 lg:justify-start '>
					<div className='grow basis-full lg:grow-0 lg:basis-auto'>
						<div className='flex justify-end absolute right-4 top-4 lg:relative z-30'>
							<Tooltip title='Close'>
								<button
									className='p-2 bg-divider rounded-md'
									onClick={(e) => onClose()}
								>
									<CloseIcon />
								</button>
							</Tooltip>
						</div>

						<div className=' flex flex-col gap-2'>
							<p className='font-semibold text-[20px] text-title-white flex items-center gap-2'>
								Prompt:
								<Tooltip title='Copy prompt'>
									<CopyIcon
										className='cursor-pointer'
										onClick={(e) =>
											copyToClipboard(viewingImage?.prompt)
										}
									/>
								</Tooltip>
							</p>

							<p
								className='text-editor-label text-start'
								dangerouslySetInnerHTML={{ __html: promptValue }}
							></p>

							{viewingImage?.negativePrompt && (
								<p className='text-xs text-text-secondary text-start'>
									Negative Prompt: {viewingImage?.negativePrompt}
								</p>
							)}

							{isDevModeEnabled && (
								<>
									<hr className='border-divider' />
									<p className='text-xs text-start text-white'>
										<span className='text-text-secondary'>
											Internal Positive Prompt:{' '}
										</span>
										{viewingImage?.internals?.injectedPrompt}
									</p>
									<p className='text-xs text-start text-white'>
										<span className='text-text-secondary '>
											Internal Negative Prompt:{' '}
										</span>
										{viewingImage?.internals?.injectedNegativePrompt}
									</p>
								</>
							)}

							<ul className='flex gap-[9px] flex-wrap '>
								{[
									STYLES[imageMethod]?.find(
										(item) => item.id === viewingImage?.contentStyle
									)?.name,
									...(viewingImage?.promptHelpers ||
										viewingImage?.styles ||
										[]),
								]
									.filter((item) => !!item)
									.map((style) => (
										<li
											key={`image-viewer-style-${style}`}
											className='bg-app-green text-[#08432B] text-[10px] leading-[24px] tracking-wide rounded-[17px] px-[8px] font-bold'
										>
											{style}
										</li>
									))}
							</ul>
						</div>
					</div>

					<button
						className='px-5 py-3 bg-button-blue rounded-xl text-sm text-title-white font-semibold'
						onClick={handleReuse}
					>
						<span className='flex items-center justify-center gap-2'>
							<SparkleIcon className='max-md:[inline-size:1.75em]' />
							Reuse Settings
						</span>
					</button>

					<div className='grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-1 lg:gap-2 [&>div]:lg:w-[45px] [&>div]:xl:w-[40px] [&>div]:lg:shrink-0 [&>div>button]:w-full'>
						<Tooltip
							title={
								handleUseAsInspiration
									? 'Use as inspiration'
									: 'Use as inspiration (available on app view)'
							}
						>
							<button
								className={`bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group ${
									handleUseAsInspiration
										? ''
										: 'cursor-not-allowed pointer-events-none'
								}`}
								onClick={(e) =>
									handleUseAsInspiration(viewingImage.imageUrl)
								}
							>
								{handleUseAsInspiration ? (
									<ImageIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
								) : (
									<ImageIconDisabled className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]' />
								)}
							</button>
						</Tooltip>

						<Tooltip title='Use as 3D inspiration'>
							<button
								className={`bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group ${
									handleUseAsInspiration
										? ''
										: 'cursor-not-allowed pointer-events-none'
								}`}
								onClick={(e) =>
									handleUseAsDDDInspiration(viewingImage.imageUrl)
								}
							>
								<DDDIcon className='max-lg:aspect-square max-lg:h-4 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>

						{isShowEdit && <Tooltip title='Edit Image'>
							<button
								className={`bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group ${
									handleUseAsInspiration
										? ''
										: 'cursor-not-allowed pointer-events-none'
								}`}
								onClick={handleEditImage}
							>
								<EditCanvasIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>}

						<Tooltip title='Remove Background (2 credits)'>
							<button
								className={`bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group `}
								onClick={handleRemoveBg}
							>
								<RemoveBackgroundIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>

						<Tooltip title='Vectorize (5 credits)'>
							<button
								className={`bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group `}
								onClick={(e) =>
									handleVectorizeDownload(viewingImage, dispatch, {
										element: e.user,
									})
								}
							>
								<VectorizeIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>

						{/* <Tooltip title="Edit (Coming soon)">
							<div className='bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group'>
								<EditIcon className="max-lg:aspect-square max-lg:h-6 max-lg:w-6 lg:w-[23px] lg:h-[23px]" />
							</div>
						</Tooltip> */}

						<Tooltip title='Download'>
							<button
								className='bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group'
								onClick={(e) =>
									handleRasterDownload(viewingImage, dispatch, {
										element: e,
										user,
										imageName: viewingImage.prompt,
									})
								}
							>
								<DownloadIcon className='max-lg:aspect-square max-lg:h-4 max-lg:w-4 lg:w-[18px] lg:h-[18px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>

						{isShowUpscale && <Tooltip title='Upscale (1 credit)'>
							<button
								className={`bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group `}
								onClick={handleUpscale}
							>
								<UpscaleIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>}


						{ isShowBookmark && <Tooltip
							title={
								viewingImage?.bookmarked
									? 'Remove bookmark'
									: 'Bookmark'
							}
						>
							<button
								className='bg-divider p-2 lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group'
								onClick={(e) => handleBookmarkClicked(viewingImage)}
							>
								{viewingImage?.bookmarked ? (
									<BookmarkIconGreen className='max-lg:aspect-square max-lg:h-4 max-lg:w-4 lg:w-[20px] lg:h-[20px]' />
								) : (
									<BookmarkIcon className='max-lg:aspect-square max-lg:h-4 max-lg:w-4 lg:w-[19px] lg:h-[19px] [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green' />
								)}
							</button>
						</Tooltip>}


						{ isShowDelete && <Tooltip title='Delete'>
							<button
								onClick={(e) => handleDeleteGeneration(viewingImage)}
								className='bg-divider p-2 lg:px-0 lg:p-2 flex items-center justify-center rounded-md cursor-pointer aspect-square group'
							>
								<TrashIcon className='max-lg:aspect-square max-lg:h-6 max-lg:w-6 lg:w-[23px] lg:h-[23px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
							</button>
						</Tooltip>}


						{isShowDislike && <Tooltip title='Dislike'>
							<button
								onClick={(e) => handleFeedback(-1)}
								className='bg-divider p-2 lg:px-0 lg:p-2 flex items-center justify-center rounded-md cursor-pointer aspect-square group w-full'
							>
								<ThumbsDownIcon
									className={`max-lg:aspect-square max-lg:h-6 max-lg:w-6 lg:w-[23px] lg:h-[23px] ${
										viewingImage?.feedback < 0
											? '[&_path]:fill-dislike group-hover:[&_path]:fill-dislike/50'
											: '[&_path]:fill-active-icon group-hover:[&_path]:fill-dislike'
									}`}
								/>
							</button>
						</Tooltip>}
						{isShowLike && <Tooltip title='Like'>
							<button
								onClick={(e) => handleFeedback(1)}
								className='bg-divider p-2 lg:px-0 lg:p-2 flex items-center justify-center rounded-md cursor-pointer aspect-square group'
							>
								<ThumbsUpIcon
									className={`max-lg:aspect-square max-lg:h-6 max-lg:w-6 lg:w-[23px] lg:h-[23px] ${
										viewingImage?.feedback > 0
											? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50'
											: '[&_path]:fill-active-icon group-hover:[&_path]:fill-app-green'
									}`}
								/>
							</button>
						</Tooltip>}
					</div>

					<div className='grid grid-cols-2 gap-2 lg:gap-3 grow lg:grow-0 text-start'>
						<p className='font-semibold text-[20px] text-title-white col-span-2 flex items-center gap-2'>
							Image Settings:

							<div className="flex">
								<div className={`bg-overlay-bg overflow-hidden rounded-l-md text-icon-text-color text-xs font-bold [line-height:1.25rem] text-center w-5`}>
									{/* {mode === 'v2.0' ? <ModeV20 className="w-5 h-5"/> : <ModeV11 className="w-5 h-5"/>} */}
									{mode === 'v2.0' ? "v2" : mode === "v3.0" ? "v3" : "v1"}
								</div>
								<div className={`[&_.bg]:fill-overlay-bg  [&_.text]:fill-icon-text-color overflow-hidden rounded-r-md [&_.bg]:[rx:0]`}>
									{generationStyle === '2d' ? <Method2DIcon className="w-5 h-5"/> : <Method3DIcon className="w-5 h-5"/>}
								</div>
							</div>
						</p>

						<div>
							<p className='text-editor-label text-sm flex items-center gap-1'>
								Resolution:{' '}
								<span className='text-item-value font-bold'>
									{viewingImage?.dimension || viewingImage?.dimensions}
								</span>
							</p>
						</div>

						<div>
							<p className='text-editor-label text-sm flex items-center gap-1'>
								Seed:{' '}
								<span className='text-item-value font-bold'>
									{viewingImage?.seed}
								</span>
							</p>
						</div>

						<div>
							<p className='text-editor-label text-sm flex items-center gap-1'>
								Image quality:{' '}
								<span className='text-item-value font-bold'>
									{
										IterationsAcceptedValeus[mode].find(
											(item) =>
												item.value ===
												(viewingImage?.imageQuality ||
													viewingImage?.steps)
										)?.name
									}
								</span>
							</p>
						</div>

						{mode !== 'v3.0' && (
							<div>
								<p className='text-editor-label text-sm flex items-center gap-1'>
									Prompt rigidness:{' '}
									<span className='text-item-value font-bold'>
										{
											PromptRigidnessAcceptedValues.find(
												(item) =>
													item.value === viewingImage?.promptRigidness
											)?.name
										}
									</span>
								</p>
							</div>
						)}

						{mode !== 'v3.0' && (
							<div>
								<p className='text-editor-label text-sm flex items-center gap-1'>
									Sampler:{' '}
									<span className='text-item-value font-bold'>
										{viewingImage?.sampler}
									</span>
								</p>
							</div>
						)}

						{mode !== 'v3.0' && (
							<div>
								<p className='text-editor-label text-sm flex items-center gap-1'>
									Logo Details:{' '}
									<span className='text-item-value font-bold'>
										{
											detailLevelValues.find(
												(item) => item.value == viewingImage?.detailLevel
											)?.name
										}
									</span>
								</p>
							</div>
						)}

						{/* <div>
							<p className='text-item-value text-sm flex items-center font-bold'>
								{viewingImage?.model}
							</p>
						</div> */}
					</div>

					{viewingImage?.inputImage && (<>
						<hr className='border-divider'/>
						<div className='flex flex-col gap-4'>
							<p className='font-semibold text-[20px] text-title-white col-span-2 text-start flex items-center gap-2 [&_.bg]:fill-overlay-bg  [&_.text]:fill-icon-text-color'>
								Redesign Settings:
							</p>
							<div className='flex flex-row gap-4'>
								<img src={viewingImage?.inputImage} alt="Inspiration" className='w-[45%] max-w-[140px] grow shrink-0 aspect-square rounded-xl self-center' />
								<div className='flex flex-col gap-2 justify-around'>

									{mode === 'v2.0' && (
										<p className='text-editor-label text-sm flex items-center gap-1'>
											Edge: <span className='text-item-value font-bold'>{ThresholdAcceptedValues.find((item) => item.value === viewingImage?.edgeThreshold)?.name} | {redesignMethods.find((item) => item.slug === viewingImage?.redesignMethod)?.name}</span>
										</p>
									)}
									{mode === 'v3.0' && (
										<p className='text-editor-label text-sm flex items-center gap-1'>
											Redesign Priority: <span className='text-item-value font-bold'>{viewingImage.controlnetMode}</span>
										</p>
									)}
									{mode === 'v2.0' || mode === 'v3.0' ? (
										<>
											<p className='text-editor-label text-sm flex items-center gap-1'>
												Image shape influence: <span className='text-item-value font-bold'>{
													imageWeightAcceptedValues[mode]?.[viewingImage?.controlnetMode || 'details']?.[viewingImage?.image_as_control_image]?.[viewingImage?.method]?.find((item) => item.value === viewingImage?.imageWeight)?.name
												}</span>
											</p>
										{/*	
											<p className='text-editor-label text-sm flex items-center gap-1'>
												Image color influence: <span className='text-item-value font-bold uppercase'>{
													viewingImage?.image_as_control_image ? 
													SDXLDenoisingValues[viewingImage?.method].find(item => item.value === 0)?.name :
													SDXLDenoisingValues[viewingImage?.method].find(item => item.value === viewingImage?.denoisingStrength)?.name
												}</span>
											</p>
											*/}
												<p className='text-editor-label text-sm flex items-center gap-1'>
													Use Image Colors:{' '}
													<span className='text-item-value font-bold uppercase'>
														{viewingImage?.image_as_control_image
															? 'yes'
															: 'no'}
													</span>
												</p>
											</>
										) : (
											<>
												<p className='text-editor-label text-sm flex items-center gap-1'>
													Image Influence:{' '}
													<span className='text-item-value font-bold'>
														{
															imageWeightAcceptedValues[mode].find(
																(item) =>
																	item.value ===
																	viewingImage?.imageWeight
															)?.name
														}
													</span>
												</p>
												<p className='text-editor-label text-sm flex items-center gap-1'>
													Use Image Colors:{' '}
													<span className='text-item-value font-bold uppercase'>
														{viewingImage?.image_as_control_image
															? 'yes'
															: 'no'}
													</span>
												</p>
												<p className='text-editor-label text-sm flex items-center gap-1'>
													Creative Freedom:{' '}
													<span className='text-item-value font-bold'>
														{viewingImage?.image_as_control_image
															? DenoisingAcceptedValues.find(
																	(item) =>
																		item.value ===
																		viewingImage?.denoisingStrength
																)?.name
															: 'N/A'}
													</span>
												</p>
											</>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</Dialog.Panel>
		</Transition.Child>
	)
}
export default DepImageViewer
