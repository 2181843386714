import React, {
  useState, useMemo, useRef, useEffect,
} from 'react';
import { animate, motion, useMotionValue } from 'framer-motion';

export const Page = (props) => {
  const {
    index, renderPage, x, onDragEnd, setIndex, itemIndex
  } = props;
  const child = useMemo(() => renderPage({ index, setIndex }), [renderPage, index]);

	const [left, setLeft] = useState('');

	useEffect(() => {
		const unsubStart = x.on('animationStart', () => setLeft(`${Math.round(index * 100)}%`))
		const unsubEnd = x.on('animationEnd', () => setLeft(`${Math.round(index * 100)}%`))
		
		return () => {
			unsubStart();
			unsubEnd();
		}
	}, [x, index])

  return (
    <motion.div
      style={{
        x,
        left,
      }}
      className="absolute w-full h-full "
      draggable
      drag="x"
      dragElastic={1}
      onDragEnd={onDragEnd}
    >
      {child}
    </motion.div>
  );
};

// dots
const Dots = (props) => {
  const { index, setIndex, count } = props;
  const dots = useMemo(() => [...Array(count).keys()], [count]);
  return (
    <div className="absolute bottom-0 right-0 left-0 flex items-center justify-center flex-row-reverse">
      {
        dots.map((ind) => (
          <div
            className={`w-4 h-4 m-1 rounded-full cursor-pointer transition-all  ${
              (index % count + count) % count !== ind
                ? 'bg-dot-pattern '
                : 'bg-disabled shadow-2xl scale-125'} `}
            key={ind}
            onClick={() => setIndex(ind)}
          />
        ))
      }
    </div>
  );
};

const range = [-1, 0, 1];
const transition = {
  type: 'spring',
  bounce: 0,
};
const Carousel = (props) => {
  const {
    children, count, DotsComponent, containerClass, innerContainerClassName, index, setIndex,
  } = props;
  const x = useMotionValue(0);
  const containerRef = useRef(null);
  const calculateNewX = (index) => -index * (containerRef.current?.clientWidth || 0);
  const handleEndDrag = (e, dragProps) => {
    const clientWidth = containerRef.current?.clientWidth || 0;
    const { offset, velocity } = dragProps;
    if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
      animate(x, calculateNewX(index), transition);
      return;
    }
    if (offset.x > clientWidth / 4) {
      setIndex(index - 1);
    } else if (offset.x < -clientWidth / 4) {
      setIndex(index + 1);
    } else {
      animate(x, calculateNewX(index), transition);
    }
  };

  useEffect(() => {
    const controls = animate(x, calculateNewX(index), transition);
    return controls.stop;
  }, [index]);

  return (
    <div className={`h-full w-full relative d-flex items-center justify-center ${containerClass}`}>
      <motion.div ref={containerRef} className={`absolute inset-0 overflow-x-hidden d-flex items-center justify-center ${innerContainerClassName}`}>
        {range.map((item, ind) => (
          <Page
            key={item + index}
            index={item + index}
						itemIndex={item}
            renderPage={children}
            x={x}
            onDragEnd={handleEndDrag}
            setIndex={setIndex}
          />
        ))}
      </motion.div>
      {DotsComponent ? <DotsComponent index={index} setIndex={setIndex} count={count} /> : <Dots index={index} setIndex={setIndex} count={count} />}
    </div>
  );
};
export default Carousel;
