import { useSelector } from 'react-redux';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import StyleImage from '../StyleImage';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import { useMemo, useState } from 'react';
import { STYLE_SETTINGS } from '../input/style-config/constants';
import AdvancedSettings from './AndvancedSettings';
import ImageUploadV4 from '../ImageUploadV4';
import StyleSettingsV4 from '../input/style-config/StyleSettingsV4';
import V4StyleSelector from '../input/V4StyleSelector';
import { Tooltip } from 'react-tippy';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up-v4.svg';
import { shortenFields } from '../../helpers/shortenFields';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import DevSettings from './v4/DevSettings';


const RedesignV4 = () => {
  const { workspace } = useCurrentWorkspace();
  const { mode, method } = useGenerationEndpoint();

  const style = useSelector((state) => state.form.payload.style);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const config = useMemo(
    () => STYLE_SETTINGS[method]?.[style] || [],
    [style, method]
  );

  const filteredConfig = config.filter(
    (config) => config.section_id !== 'colors'
  );

  return (
    <div
      className={`flex flex-col gap-4  ${
        workspace === 'image-workspace'
          ? '[&_.field-label]:font-bold [&_.field-label]:text-base overflow-auto custom-scroll'
          : 'px-2'
      }`}
    >
      <V4StyleSelector />
      {filteredConfig.length > 0 && <StyleSettingsV4 />}
      {workspace === 'text-workspace' && <ImageUploadV4 />}
      <StyleImage />
      <AdvancedSettings />

      {isDevModeEnabled && (
        <DevSettings />
      )}
    </div>
  );
};
export default RedesignV4;
