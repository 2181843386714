import { useState } from "react";

import { ReactComponent as TrashIcon } from '../assets/TrashIconGray.svg';
import environment from "../helpers/envProvider";
import authAxiosInstance from "../helpers/auth-axios";

const API_URL = environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';

function AdminInvites() {
  const [file, setFile] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [emails, setEmails] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  }

  const handleReadFile = () => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        setEmails(text.split('\r\n'));
        setHasSubmitted(true);
      };

      fileReader.readAsText(file);
    }
  }

  const removeEmail = (email) => {
    const newEmails = emails.filter(e => e !== email);

    setEmails(newEmails);
  }

  const showNextPage = () => {
    if(page + 1 < Math.ceil(emails.length / perPage)) {
      setPage(page + 1);
    }
  }

  const showPrevPage = () => {
    if(page !== 0) {
      setPage(page - 1);
    }
  }

  const handleSubmit = async () => {
    try {
      await authAxiosInstance.post(`/api/admin/early-access-invite-many`, {
        emails,
        startingTokens: 200
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="py-10 px-10">
      {
        !hasSubmitted
          ? (
            <div>
              <input type="file" accept=".csv" onChange={handleOnChange} />
              <button onClick={handleReadFile}>Read file</button>
            </div>
          )
          : (
            <div>
              <table className="w-full border-separate mb-4">
                <thead>
                  <tr>
                    <th className="text-left py-1">Email</th>
                    <th className="text-right py-1">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {emails.slice(page * perPage, (page + 1) * perPage).map((email, i) => (
                    <tr className={i % 2 === 0 ? 'bg-gray-200' : 'bg-transparent'}>
                      <td className="py-2 px-2 align-middle">{email}</td>
                      <td className="text-right py-2 px-2 align-middle">
                        <button>
                          <TrashIcon
                            onClick={e => removeEmail(email)}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-between items-center">
                <div className="flex gap-4 items-center">
                  <button onClick={e => showPrevPage()}>Prev</button>
                  <span>{page + 1} / {Math.ceil(emails.length / perPage)}</span>
                  <button onClick={e => showNextPage()}>Next</button>
                </div>

                <button
                  className="bg-blue-600 text-white p-2 font-bold px-4"
                  onClick={e => handleSubmit()}
                >
                  Send invites
                </button>
              </div>
            </div>
          )
      }
      
    </div>
  );
}

export default AdminInvites;
