import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react'
import { usePopper } from 'react-popper';
import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';
import { ReactComponent as FacebookIcon } from '../../assets/fb-group.svg';

const Community = (props) => {
  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: "bottom",
  });

  return (
    <Popover className="relative overflow-visible flex self-stretch mr-4">
      <Popover.Button
        className="bg-app-bg-gray rounded-lg text-title-white font-semibold font-poppins text-sm px-4 flex flex-row items-center justify-center flex-grow !outline-none"
        ref={setAnchorEl}
      >
        Community
        {/* <CaretUp className="rotate-180 stroke-icon-text-color" /> */}
      </Popover.Button>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        {...props}
      >
        <Popover.Panel 
          className="border border-solid border-app-search-gray rounded-xl bg-app-bg-gray px-3 py-3 mt-2 flex flex-col gap-2 w-full" 
          ref={setPopperEl}
          style={styles.popper}
          {...attributes.popper}
        >

          <a className="text-community-text no-underline font-poppins text-xs font-semibold flex flex-row items-center gap-2" href="https://discord.com/invite/jE3pC2YHtg">
            <DiscordIcon className='w-5 h-5 shrink-0'/>
            Discord
          </a>
          <a className="text-community-text no-underline font-poppins text-xs font-semibold flex flex-row items-center gap-2" href="https://www.facebook.com/groups/logodiffusion">
            <FacebookIcon className='w-5 h-5 shrink-0'/>
            FB Group
          </a>

        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Community