import { ReactComponent as PictorialStyle } from '../../assets/styles/pictorial.svg';
import { ReactComponent as MascotStyle } from '../../assets/styles/mascot.svg';
import { ReactComponent as BadgeStyle } from '../../assets/styles/badge.svg';
import { ReactComponent as CartoonStyle } from '../../assets/styles/cartoon.svg';
import { ReactComponent as IconStyle } from '../../assets/styles/icon.svg';
import { ReactComponent as AbstractStyle } from '../../assets/styles/abstract.svg';
import { ReactComponent as CancelIcon } from '../../assets/CancelIcon.svg';
import { ReactComponent as ThSDXLLineArt } from '../../assets/styles/sdxl/line-art.svg';
import ThSDXLLogoType from '../../assets/styles/sdxl/logotype.png';
import { ReactComponent as ThSDXLMonogram } from '../../assets/styles/sdxl/monogram.svg';

import { ReactComponent as LogoDesign } from '../../assets/styles/v3/logo-design.svg';
import { ReactComponent as TwoDArt } from '../../assets/styles/v3/2d-art.svg';
import { ReactComponent as ThreeDArt } from '../../assets/styles/v3/3d-art.svg';
import { ReactComponent as Realistic } from '../../assets/styles/v3/realistic.svg';
import { ReactComponent as FineArt } from '../../assets/styles/v3/fine-art.svg';
import { ReactComponent as None } from '../../assets/styles/v3/none.svg';

import ThStylized3D from '../../assets/styles/v3/stylized-3d.jpg';
import ThRealistic3D from '../../assets/styles/v3/realistic-3d.jpg';
import Th3DText from '../../assets/styles/v3/3d-text.jpg';
import ThInflated from '../../assets/styles/v3/inflated.jpg';
import ThEmbroidered from '../../assets/styles/v3/embroidered.jpg';
import ThIsometric from '../../assets/styles/v3/isometric.jpg';
import ThLowPoly from '../../assets/styles/v3/Low-poly.jpg';

import ThStylizedCartoon from '../../assets/styles/v3/stylized-cartoon.jpg';
import ThPixelArt from '../../assets/styles/v3/pixel-art.jpg';
import ThFlatGraphics from '../../assets/styles/v3/flat-graphic.jpg';
import ThColoringBook from '../../assets/styles/v3/coloring-book.jpg';
import ThCyberPunk from '../../assets/styles/v3/cyberpunk.jpg';
import ThSticker from '../../assets/styles/v3/sticker.jpg';
import ThMarker from '../../assets/styles/v3/marker.jpg';
import ThColorSketchNote from '../../assets/styles/v3/color-sketch-note.jpg';
import ThSketchNote from '../../assets/styles/v3/sketchnote.jpg';
import ThDigitalArt from '../../assets/styles/v3/digital-art.jpeg';
import ThFantasyArt from '../../assets/styles/v3/fantasy.jpg';
import ThPopArt from '../../assets/styles/v3/popart.jpg';
import ThComicArt from '../../assets/styles/v3/comic-art.jpg';
import ThManga from '../../assets/styles/v3/manga.jpg';
import ThKawaii from '../../assets/styles/v3/kawaii.jpg';
import ThPunkCollage from '../../assets/styles/v3/punk.jpg';
import ThDarkArts from '../../assets/styles/v3/dark-art.jpg';
import ThCharcoalSketch from '../../assets/styles/v3/charcoal.jpg';
import ThHalftone from '../../assets/styles/v3/halftone.jpg';

import ThCinematic from '../../assets/styles/v3/cinematic.jpg';
import ThHyperrealism from '../../assets/styles/v3/hyperreal.jpg';
import ThCraftClay from '../../assets/styles/v3/craft-clay.jpg';
import ThFuturistic from '../../assets/styles/v3/futuristic.jpg';
import ThOrigami from '../../assets/styles/v3/origami.jpg';
import ThPaperCraft from '../../assets/styles/v3/papercraft.jpg';

import ThOilPainting from '../../assets/styles/v3/oil-painting.jpg';
import ThFantasyIllustration from '../../assets/styles/v3/fantasy-illustration.jpg';
import ThWaterColor from '../../assets/styles/v3/watercolor.jpg';
import ThArtDeco from '../../assets/styles/v3/art-deco.jpg';
import ThBauhaus from '../../assets/styles/v3/bauhaus.jpg';

const ImageThumbnail = (image, props) => () => <img src={image} {...props} />;

export const V3_GROUPS = [
  {
    id: 'logo-design',
    name: 'Logo Design',
    icon: LogoDesign,
  },
  {
    id: '2d-art',
    name: '2D Art',
    icon: TwoDArt,
  },
  {
    id: '3d-art',
    name: '3D Art',
    icon: ThreeDArt,
  },
  {
    id: 'realistic',
    name: 'Realistic',
    icon: Realistic,
  },
  {
    id: 'fine-art',
    name: 'Fine Art',
    icon: FineArt,
  },
  {
    id: 'none',
    name: 'None',
    icon: None,
  },
];

export const V3_STYLES = [
	//logo design
	{
		id: 'pictorial',
			name: 'Pictorial',
			icon: PictorialStyle,
			group: 'logo-design',
	},
	{
		id: 'mascot',
		name: 'Mascot',
		icon: MascotStyle,
		group: 'logo-design',
	},
	{
		id: 'badge',
		name: 'Badge/Crest',
		icon: BadgeStyle,
		group: 'logo-design',
	},
	{
		id: 'cartoon',
		name: 'Cartoon',
		icon: CartoonStyle,
		group: 'logo-design',
	},
	/*
	{
		id: 'adcartoon',
		name: 'AdCartoon',
		icon: CartoonStyle,
	},
	*/
	{
		id: 'icon',
		name: 'Icon/Emoji',
		icon: IconStyle,
		group: 'logo-design',
	},
	{
		id: 'abstract',
		name: 'Abstract',
		icon: AbstractStyle,
		group: 'logo-design',
	},
	{
		id: 'line-art',
		name: 'Line Art',
		icon: ThSDXLLineArt,
		icon_classes: 'p-2',
		icon_preview_classes: 'p-1',
		group: 'logo-design',
	},
	{
		id: 'logo-type',
		name: 'Logo Type',
		icon: ImageThumbnail(ThSDXLLogoType, { className: 'my-auto pb-2 px-2' }),
		icon_preview: ImageThumbnail(ThSDXLLogoType, {
			className: 'my-auto pt-1',
		}),
		icon_preview_classes: '!p-1',
		beta: true,
		group: 'logo-design',
	},
	{
		id: 'monogram',
		name: 'Monogram',
		icon: ThSDXLMonogram,
		icon_classes: 'p-3',
		icon_preview_classes: 'p-1',
		beta: true,
		group: 'logo-design',
	},

	// 2D Art

	{
		id: 'stylized-cartoon',
		name: 'Stylized Cartoon',
		icon: ImageThumbnail(ThStylizedCartoon),
		group: '2d-art',
		new: true,
	},
	{
		id: 'pixel-art',
		name: 'Pixel Art',
		icon: ImageThumbnail(ThPixelArt),
		group: '2d-art',
	},
	{
		id: 'flat-graphics',
		name: 'Flat Graphics',
		icon: ImageThumbnail(ThFlatGraphics),
		group: '2d-art',
	},
	{
		id: 'coloring-book',
		name: 'Coloring book',
		icon: ImageThumbnail(ThColoringBook),
		group: '2d-art',
		new: true,
	},
	{
		id: 'cyberpunk',
		name: 'CyberPunk',
		icon: ImageThumbnail(ThCyberPunk),
		group: '2d-art',
	},
	{
		id: 'sticker',
		name: 'Sticker',
		icon: ImageThumbnail(ThSticker),
		group: '2d-art',
		new: true,
	},
	{
		id: 'marker-drawing',
		name: 'Marker Drawing',
		icon: ImageThumbnail(ThMarker),
		group: '2d-art',
		new: true,
	},
	{
		id: 'sketch-note',
		name: 'Sketch Note',
		icon: ImageThumbnail(ThSketchNote),
		group: '2d-art',
		new: true,
	},
	{
		id: 'color-sketch-note',
		name: 'Color Sketch Note',
		icon: ImageThumbnail(ThColorSketchNote),
		group: '2d-art',
		new: true,
	},
	{
		id: 'digital-art',
		name: 'Digital art',
		icon: ImageThumbnail(ThDigitalArt),
		group: '2d-art',
	},
	{
		id: 'fantasy-art',
		name: 'Fantasy art',
		icon: ImageThumbnail(ThFantasyArt),
		group: '2d-art',
	},
	{
		id: 'pop-art',
		name: 'Pop Art',
		icon: ImageThumbnail(ThPopArt),
		group: '2d-art',
	},
	{
		id: 'comic-art',
		name: 'Comic Art',
		icon: ImageThumbnail(ThComicArt),
		group: '2d-art',
	},
	{
		id: 'manga',
		name: 'Manga',
		icon: ImageThumbnail(ThManga),
		group: '2d-art',
	},
	{
		id: 'kawaii',
		name: 'Kawaii',
		icon: ImageThumbnail(ThKawaii),
		group: '2d-art',
	},
	{
		id: 'punk-collage',
		name: 'Punk Collage',
		icon: ImageThumbnail(ThPunkCollage),
		group: '2d-art',
		new: true,
	},
	{
		id: 'dark-arts',
		name: 'Dark Arts',
		icon: ImageThumbnail(ThDarkArts),
		group: '2d-art',
	},
	{
		id: 'charcoal-sketch',
		name: 'Charcoal Sketch',
		icon: ImageThumbnail(ThCharcoalSketch),
		group: '2d-art',
		new: true,
	},
	{
		id: 'halftone',
		name: 'Halftone',
		icon: ImageThumbnail(ThHalftone),
		group: '2d-art',
		new: true,
	},

	//3d art

	{
		id: 'stylized-3d',
		name: 'Stylized 3D',
		icon: ImageThumbnail(ThStylized3D),
		group: '3d-art',
	},
	{
		id: '3d',
		name: 'Realistic 3D',
		icon: ImageThumbnail(ThRealistic3D),
		group: '3d-art',
	},
	{
		id: '3d-text',
		name: '3D Text',
		icon: ImageThumbnail(Th3DText),
		group: '3d-art',
		beta: true,
	},
	{
		id: 'inflated',
		name: 'Inflated',
		icon: ImageThumbnail(ThInflated),
		group: '3d-art',
	},
	{
		id: 'embroidered',
		name: 'Embroidered',
		icon: ImageThumbnail(ThEmbroidered),
		group: '3d-art',
	},
	{
		id: 'isometric',
		name: 'Isometric',
		icon: ImageThumbnail(ThIsometric),
		group: '3d-art',
	},
	{
		id: 'low-poly',
		name: 'Low Poly',
		icon: ImageThumbnail(ThLowPoly),
		group: '3d-art',
	},

	// realistic

	{
		id: 'cinematic',
		name: 'Cinematic',
		icon: ImageThumbnail(ThCinematic),
		group: 'realistic',
	},
	{
		id: 'hyperrealism',
		name: 'Hyperrealism',
		icon: ImageThumbnail(ThHyperrealism),
		group: 'realistic',
		nea: true,
	},
	{
		id: 'craft-clay',
		name: 'Craft Clay',
		icon: ImageThumbnail(ThCraftClay),
		group: 'realistic',
	},
	{
		id: 'futuristic',
		name: 'Futuristic',
		icon: ImageThumbnail(ThFuturistic),
		group: 'realistic',
	},
	{
		id: 'origami',
		name: 'Origami',
		icon: ImageThumbnail(ThOrigami),
		group: 'realistic',
	},
	{
		id: 'paper-craft',
		name: 'Papercraft',
		icon: ImageThumbnail(ThPaperCraft),
		group: '2d-art',
	},

	// fine art

	{
		id: 'oil-painting',
		name: 'Oil Painting',
		icon: ImageThumbnail(ThOilPainting),
		group: 'fine-art',
		new: true,
	},
	{
		id: 'fantasy-illustration',
		name: 'Fantasy Illustration',
		icon: ImageThumbnail(ThFantasyIllustration),
		group: 'fine-art',
		new: true,
	},
	{
		id: 'watercolor',
		name: 'Water Color',
		icon: ImageThumbnail(ThWaterColor),
		group: 'fine-art',
	},
	{
		id: 'art-deco',
		name: 'Art Deco',
		icon: ImageThumbnail(ThArtDeco),
		group: 'fine-art',
		new: true,
	},
	{
		id: 'bauhaus',
		name: 'Bauhaus',
		icon: ImageThumbnail(ThBauhaus),
		group: 'fine-art',
		new: true,
	},

	// none

	{
		id: 'none',
		name: 'None',
		icon: CancelIcon,
		icon_classes: 'p-3',
		icon_preview_classes: 'p-1',
		group: 'none',
	},
]
