import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import GeneratedImage from '../GeneratedImage';
import { ReactComponent as LoadingDots } from '../../assets/LoadingDots.svg';
import { toast } from 'react-toastify';
import { loadGenerationHistory } from '../../reducers/formReducer';
import throttle from 'lodash/throttle'

const GenerationHistory = forwardRef((props, ref) => {
  const store = useStore();

  const toGenerate = useSelector(state => state.form.payload.imagesToGenerate);
  const showPlaceholders = useSelector(state => state.form.showPlaceholders);
  const _generations = useSelector(state => state.form.generations);

  const generations = useMemo(() => showPlaceholders ? _generations  : _generations.slice(toGenerate, _generations.length),
  [_generations, showPlaceholders, toGenerate]);

  const hasMoreToLoad = useSelector((state) => state.form.hasMoreGenerationsToLoad);

  const dispatch = useDispatch();
 
	const loadingAnchor = useRef();

  const loadMore = throttle(async () => {
    try {
      await dispatch(loadGenerationHistory()).unwrap()

    } catch(e) {
      console.log(e)
      toast('Failed to load generation history', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }
  }, 5000);

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				console.log("Intersection changed");
				if (store.getState().userSlice.user && entries[0].isIntersecting && store.getState().form.hasMoreGenerationsToLoad && !store.getState().form.isLoadingGenerations) {
          loadMore();
				}
			},
			{ threshold: 1 }
		);

		if (loadingAnchor.current) {
			observer.observe(loadingAnchor.current);
		}

		return () => {
			if (loadingAnchor.current) {
				observer.unobserve(loadingAnchor.current);
			}
		};
	}, [loadingAnchor, store, loadMore]);

  return (
    <div ref={ref} className='h-full w-full lg:overflow-hidden bg-app-bg-gray rounded-[20px] pl-4 pr-1 pt-4 pb-16'>
      <div className='text-sm text-white pb-4'>Generation History</div>
      <div className='grow h-max lg:h-full grid grid-cols-4 lg:grid-cols-2 xl:grid-cols-2 gap-4 overflow-x-hidden overflow-y-auto custom-scroll pr-2'>
        {generations.map((generatedImage, i) => (
          <GeneratedImage
            image={generatedImage}
						variant="small"
            key={`generated-${generatedImage.imageUrl}`}
          />
        ))}

        <div ref={loadingAnchor} className='p-2 px-3 flex items-center justify-center bg-w col-span-full'>
          {/* {page >= totalPages ? '' : 'Loading...'} */}
          {hasMoreToLoad && (
            <span
              className="flex items-center justify-center"
              id="button-loading"
            >
              <LoadingDots className='h-2'/>
            </span>
          )}
        </div>
      </div>
    </div>
  )
})

export default GenerationHistory
