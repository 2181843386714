import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/logo.svg';
import authAxiosInstance from '../helpers/auth-axios';

function ChangePassword() {
  const [password, setPassword] = useState('');
	const [error, setError] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if(!password) return toast('Password is required.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

    if(!confirmedPassword) return toast('Please confirm your password.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

    if(password !== confirmedPassword) return toast('Passwords must match', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark',
      autoClose: 2000
    });

    try {
      const token = searchParams.get('token');
      await authAxiosInstance.post('/auth/change-password', { token, newPassword: password })
      navigate('/login');
    } catch (error) {
			const err = error.response?.data?.detail || 'Something went wrong, please try again.'
			setError(err)
      toast(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

      dispatch(setIsLoading(false));

      console.log('error: ', error);
    }
  }
  
  return (
    <div>
      <div className='max-w-[360px] w-[90%] mx-auto rounded-2xl flex flex-col justify-center min-h-screen'>
        <Helmet>
          <title>Change password | Logo Diffusion</title>
        </Helmet>

        <div className='font-bold text-xl text-white flex gap-2 items-center justify-center mb-8'>
            <Logo />
            Logo Diffusion
            <span className='text-xs text-black bg-app-green font-normal p-1 px-2 rounded-md ml-2'>
              v4.0
            </span>
          </div>

        <div className='bg-app-bg-gray text-white text-center rounded-2xl'>
          <div className='p-4'>
            <h1 className='font-bold text-white text-lg mb-6'>Change your password</h1>

            <form onSubmit={handleResetPassword} className='flex flex-col'>
              <input
                type="password"
                placeholder='New password'
                className='bg-app-search-gray mb-3 p-2 rounded-md'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <input
                type="password"
                placeholder='Confirm password'
                className='bg-app-search-gray mb-3 p-2 rounded-md'
                value={confirmedPassword}
                onChange={e => setConfirmedPassword(e.target.value)}
              />

							{error && (
								<div className="text-dislike">{error}</div>
							)}

              <button
                className='bg-blue-600 text-white font-bold w-full rounded-md p-2 mb-2 text-sm'
                type="submit"
              >
              {isLoading ? 'Changing your pass' : 'Change' }
            </button>
            </form>
          </div>

          <div className='bg-app-search-gray rounded-b-2xl text-sm p-4'>
            Already know your creds? <Link to="/login" className='text-blue-600'>Login</Link>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ChangePassword;
