import SportsLogoGenerator from '../assets/SportsLogoGenerator.png';
import AbstractLogoGenerator from '../assets/AbstractLogoGenerator.png';
import MinimalistLogoGenerator from '../assets/MinimalistLogoGenerator.png';

import ModelThumbnail from '../assets/ModelThumbnail.png';

const models = [
  {
    id: 1,
    name: 'Sports Logo Diffusion v1.0',
    slug: 'sports-logo-diffusion',
    shortName: 'LoDi_Sports',
    thumbnail: SportsLogoGenerator,
    smallThumbnail: ModelThumbnail,
    lastUpdated: 'January 2023',
    tag: 'v 1.0'
  },
  {
    id: 2,
    name: 'Minimalist Diffusion (coming soon)',
    slug: 'minimalist-diffusion',
    shortName: 'LoDi_Minimalist',
    thumbnail: AbstractLogoGenerator,
    smallThumbnail: ModelThumbnail,
    lastUpdated: 'November 2022',
    tag: 'SD 1.5'
  },
  {
    id: 3,
    name: 'Monogram Diffusion (coming soon)',
    slug: 'monogram-diffusion',
    shortName: 'LoDi_Monogram',
    thumbnail: MinimalistLogoGenerator,
    smallThumbnail: ModelThumbnail,
    lastUpdated: 'November 2022',
    tag: 'SD 1.5'
  },
  {
    id: 4,
    name: 'General Diffusion (coming soon)',
    slug: 'general-diffusion',
    shortName: 'LoDi_General',
    thumbnail: AbstractLogoGenerator,
    smallThumbnail: ModelThumbnail,
    lastUpdated: 'November 2022',
    tag: 'SD 1.5'
  }
];

export default models;
