import { DateInput, Edit, SimpleForm, ReferenceField, TextField, TextInput, Image, ImageField, BooleanInput } from 'react-admin';
import Grid from '@mui/material/Grid';

export const GalleryEdit = () => (
    <Edit>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
							<TextField source="generation.prompt" fullWidth aria-readonly="false"/>
							
							<ImageField source="generation.imageUrl" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }}/>
							<ImageField source="generation.inputImage" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }}/>
            </Grid>

            <Grid item xs={6}>
              <TextInput source="order" fullWidth />
              <BooleanInput source="draft" fullWidth />
            </Grid>

          </Grid>
        </SimpleForm>
    </Edit>
);

export default GalleryEdit;
