import UserLayout from '../layouts/UserLayout';
import { Helmet } from 'react-helmet';

import GenerateButton from '../components/input/GenerateButton';

function Home() {
  return (
    <UserLayout>
      <Helmet>
        <title>Billing | Logo Diffusion</title>
      </Helmet>

      <div className='flex min-w-[600px] min-h-[400px] items-center justify-center'>
        <GenerateButton />
      </div>
    </UserLayout>
  );
}

export default Home;
