import AdminProtectedRoute from '../components/AdminProtectedRoute';

function AdminLayout(props) {
  return (
    <div>
      <AdminProtectedRoute>
        {props.children}
      </AdminProtectedRoute>
    </div>
  )
}

export default AdminLayout;
