import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from "react-tippy";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";

const Checkbox = (props) => {
	const {
		value,
		checked,
		onChange,
		label,
		title,
		className: {
			box: boxClass,
			label: labelClass,
			input: inputClass,
			info: infoClass,
		} = {}
	} = props

	return (
		<label className={twMerge(
			'bg-app-bg-gray rounded-[4px] py-2 px-3 flex items-center justify-center gap-2', 
			boxClass
		)}>
			<input 
				type="checkbox"
				value={value} onChange={onChange}
				checked={checked}
				class={twMerge(
					'form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 ',
					inputClass,
				)}
			/>
			<span className={twMerge(
				'text-[10px] font-inter text-white font-semibold select-none',
				labelClass,
			)}>{label}</span>

			{title && (
				<Tooltip title={title} >
					<InfoIcon className={`${infoClass}`}/>
				</Tooltip>
			)}
		</label>
	)

}

export default Checkbox;
