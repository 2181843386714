import { SDColors } from '../../redesign/constants';
import ConfigLetterSelector from './LetterSelector';
import ConfigTextInput from './TextInput';

import { ReactComponent as BadgeShieldIcon } from '../../../assets/styles/v3/badge-shapes/shield.svg';
import { ReactComponent as BadgeOvalIcon } from '../../../assets/styles/v3/badge-shapes/oval.svg';
import { ReactComponent as BadgeCircular } from '../../../assets/styles/v3/badge-shapes/circular.svg';
import { ReactComponent as BadgeTriangular } from '../../../assets/styles/v3/badge-shapes/triangular.svg';
import { ReactComponent as BadgeHexagonal } from '../../../assets/styles/v3/badge-shapes/hexagonal.svg';
import { ReactComponent as BadgeRectangular } from '../../../assets/styles/v3/badge-shapes/rectangular.svg';
import { ReactComponent as BadgeDiamond } from '../../../assets/styles/v3/badge-shapes/diamond.svg';
import { ReactComponent as BadgeSquare } from '../../../assets/styles/v3/badge-shapes/square.svg';

export const GENERAL_PROPERTIES = {
  line_thickness: {
    id: 'line_thickness',
    values: [
      {
        id: 'thin',
        name: 'Thin',
      },
      {
        id: 'bold',
        name: 'Bold',
      },
    ],
    colors: 1,
  },
  v3_line_thickness: {
    id: 'line_thickness',
    default_enabled: true,
    values: [
      {
        id: 'thin',
        name: 'Thin',
      },
      {
        id: 'thick',
        name: 'Thick',
      },
    ],
  },
  outline_stroke: {
    id: 'outline_stroke',
    values: [
      {
        id: 'thin',
        name: 'Thin',
      },
      {
        id: 'thick',
        name: 'Thick',
      },
    ],
    colors: 1,
  },
  v3_detail: {
    id: 'detail',
    values: [
      {
        id: 'low',
        name: 'Minimalist',
      },
      {
        id: 'high',
        name: 'Detailed',
      },
    ],
  },
  details: {
    id: 'details',
    values: [
      {
        id: 'low',
        name: 'Minimalist',
      },
      {
        id: 'high',
        name: 'Detailed',
      },
    ],
  },
  background_color: {
    id: 'background',
    default_enabled: false,
    colors: 1,
  },
};

const V3_SETTINGS = {
  pictorial: [
    {
      section_id: 'style',
      name: 'Logo Style',
      elements: [
        {
          id: 'style',
          default_enabled: false,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [GENERAL_PROPERTIES.v3_detail],
    },
    {
      section_id: 'special',
      name: 'Specialized',
      elements: [
        {
          id: 'special',
          default_enabled: false,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },

    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  mascot: [
    {
      section_id: 'style-mode',
      name: 'Logo Style',
      elements: [
        {
          id: 'style',
          default_enabled: false,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
            {
              id: 'sports',
              name: 'Sports',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: true }],
    },
    {
      section_id: 'specialized',
      name: 'Specialized',
      elements: [
        {
          id: 'special',
          default_enabled: true,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  badge: [
    {
      section_id: 'special',
      name: 'Badge Type',
      elements: [
        {
          id: 'special',
          default_enabled: true,
          values: [
            {
              id: 'shield',
              name: 'Sheild',
            },
            {
              id: 'emblem',
              name: 'Emblem',
            },
            {
              id: 'crest',
              name: 'Crest',
            },
          ],
        },
      ],
    },
    {
      section_id: 'shape',
      name: 'Badge Shape',
      elements: [
        {
          id: 'shape',
          default_enabled: true,
          default: 'shield',
          values: [
            {
              id: 'shield',
              name: (
                <BadgeShieldIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'oval',
              name: (
                <BadgeOvalIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'circular',
              name: (
                <BadgeCircular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'triangular',
              name: (
                <BadgeTriangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'hexagonal',
              name: (
                <BadgeHexagonal className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'rectangular',
              name: (
                <BadgeRectangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'diamond',
              name: (
                <BadgeDiamond className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
            {
              id: 'square',
              name: (
                <BadgeSquare className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
              ),
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Badge Style',
      elements: [
        {
          id: 'detail',
          default_enabled: true,
          values: [
            {
              id: 'low',
              name: 'Modern, Minimalist',
            },
            {
              id: 'high',
              name: 'Illustrative',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  cartoon: [
    {
      section_id: 'style-mode',
      name: 'Cartoon style',
      elements: [
        {
          id: 'style',
          default_enabled: false,
          values: [
            {
              id: 'cute',
              name: 'Cute',
            },
            {
              id: 'adcartoon',
              name: 'Cool',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: false }],
    },
    {
      section_id: 'special',
      name: 'Specialized',
      elements: [
        {
          id: 'special',
          default_enabled: true,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  icon: [
    {
      section_id: 'special',
      name: 'Specialized',
      elements: [
        {
          id: 'special',
          values: [
            {
              id: 'three-quarters',
              name: 'Three Quarters View',
            },
            {
              id: 'front-view',
              name: 'Front View',
            },
            {
              id: 'high-angle',
              name: 'High angle view',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, always_enabled: true }],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  abstract: [
    {
      section_id: 'line-thickness',
      name: 'Line Thickness',
      elements: [GENERAL_PROPERTIES.v3_line_thickness],
    },
    {
      section_id: 'style-mode',
      name: 'Style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'curved',
              name: 'Curved',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: true }],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'line-art': [
    {
      section_id: 'line-thickness',
      name: 'Line Thickness',
      elements: [GENERAL_PROPERTIES.v3_line_thickness],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: false }],
    },
    {
      section_id: 'style-mode',
      name: 'Style',
      elements: [
        {
          id: 'style',
          default_enabled: false,
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'curved',
              name: 'Curved',
            },
          ],
        },
      ],
    },
    {
      section_id: 'special',
      name: 'Specialized',
      elements: [
        {
          id: 'special',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  '3d-text': [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'text-style',
      name: 'Text type',
      elements: [
        {
          id: 'text_style',
          values: [
            {
              id: 'cursive',
              name: 'Cursive',
            },
            {
              id: 'block',
              name: 'Block letters',
            },
          ],
        },
      ],
    },
    {
      section_id: 'style',
      name: 'Logo style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'logo-type': [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'text-style',
      name: 'Text type',
      elements: [
        {
          id: 'text_style',
          values: [
            {
              id: 'cursive',
              name: 'Cursive',
            },
            {
              id: 'block',
              name: 'Block letters',
            },
          ],
        },
      ],
    },
    {
      section_id: 'style',
      name: 'Logo style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  monogram: [
    {
      section_id: 'text',
      name: 'Your Main Letter',
      elements: [
        {
          id: 'letter',
          default_enabled: true,
          default: '',
          component: ConfigLetterSelector,
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [GENERAL_PROPERTIES.v3_detail],
    },
    {
      section_id: 'style-look',
      name: 'Monogram look',
      elements: [
        {
          id: 'style_1',
          default_enabled: false,
          values: [
            {
              id: 'stylized',
              name: 'Stylized',
            },
            {
              id: 'flat',
              name: 'Flat',
            },
          ],
        },
      ],
    },
    {
      section_id: 'style-mode',
      name: 'Monogram style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'curved',
              name: 'Curved',
            },
            {
              id: 'geometric',
              name: 'Geometric',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  'stylized-cartoon': [
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: true }],
    },
    {
      section_id: 'special',
      name: 'Specialized',
      elements: [
        {
          id: 'special',
          default_enabled: true,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'pixel-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'flat-graphic': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'coloring-book': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  cyberpunk: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  sticker: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'marker-drawing': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'sketch-note': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'color-sketch-note': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'digital-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'fantasy-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'pop-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'comic-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  manga: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  kawaii: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'punk-collage': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'dark-arts': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'charcoal-sketch': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  halftone: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  'stylized-3d': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  '3d': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  inflated: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  embroidered: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  isometric: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'low-poly': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  cinematic: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  hyperrealism: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'craft-clay': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  futuristic: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  origami: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'paper-craft': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  'oil-painting': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'fantasy-illustration': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  watercolor: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'art-deco': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  bauhaus: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
};
const V4_SETTINGS = {
  pictorial: [
    {
      section_id: 'structure',
      name: 'Structure',
      elements: [
        {
          id: 'structure',
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'organic',
              name: 'Organic',
            },
          ],
        },
      ],
    },
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					default_enabled: false,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [GENERAL_PROPERTIES.v3_detail],
    },
    {
      section_id: 'style',
      name: 'Logo Style',
      elements: [
        {
          id: 'style',
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },

    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  mascot: [
    {
      section_id: 'details',
      name: 'Details',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: true }],
    },
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'composition',
      name: 'Composition',
      elements: [
        {
          id: 'composition',
					default_enabled: false,
          values: [
            {
              id: 'symmetrical',
              name: 'Symmetrical',
            },
            {
              id: 'side-view',
              name: 'Side view',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'retro-mascot': [
    {
      section_id: 'character_item',
      name: 'Character Items',
      elements: [
        {
          id: 'character_item',
					default_enabled: false,
          values: [
            {
              id: 'shoes',
              name: 'Wearing Shoes',
            },
            {
              id: 'gloves',
              name: 'Wearing Gloves',
            },
          ],
        },
      ],
    },
    {
      section_id: 'character_action',
      name: 'Character Actions',
      elements: [
        {
          id: 'character_action',
					default_enabled: false,
          values: [
            {
              id: 'standing',
              name: 'Standing',
            },
            {
              id: 'running',
              name: 'Running',
            },
            {
              id: 'walking',
              name: 'Walking',
            },
            {
              id: 'jumping',
              name: 'Jumping',
            },
          ],
        },
      ],
    },
    {
      section_id: 'character_emotion',
      name: 'Character Emotion',
      elements: [
        {
          id: 'character_emotion',
					default_enabled: false,
          values: [
            {
              id: 'open-mouth',
              name: 'Open Mouth',
            },
            {
              id: 'smirking',
              name: 'Smirking',
            },
            {
              id: 'smiling',
              name: 'smiling',
            },
            {
              id: 'whistling',
              name: 'Whistling',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  badge: [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'special',
      name: 'Badge Type',
      elements: [
        {
          id: 'special',
          default_enabled: true,
          values: [
            {
              id: 'shield',
              name: 'Sheild',
            },
            {
              id: 'emblem',
              name: 'Emblem',
            },
            {
              id: 'crest',
              name: 'Crest',
            },
          ],
        },
      ],
    },
    {
      section_id: 'shape',
      name: 'Badge Shape',
      elements: [
        {
          id: 'shape',
          default_enabled: true,
          default: 'shield',
          values: [
            {
              id: 'shield',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeShieldIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Shield</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeShieldIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Shield</p>
                </div>
              ),
            },
            {
              id: 'oval',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeOvalIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Oval</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeOvalIcon className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Oval</p>
                </div>
              ),
            },
            {
              id: 'circular',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeCircular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Circular</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeCircular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Circular</p>
                </div>
              ),
            },
            {
              id: 'triangular',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px]rounded-[5px] mr-2'>
                    <BadgeTriangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Triangular</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeTriangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Triangular</p>
                </div>
              ),
            },
            {
              id: 'hexagonal',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeHexagonal className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Hexagonal</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeHexagonal className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Hexagonal</p>
                </div>
              ),
            },
            {
              id: 'rectangular',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeRectangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>
                    Rectangular
                  </p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeRectangular className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>
                    Rectangular
                  </p>
                </div>
              ),
            },
            {
              id: 'diamond',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeDiamond className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Diamond</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeDiamond className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Diamond</p>
                </div>
              ),
            },
            {
              id: 'square',
              name: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-search-gray w-[24px] h-[24px] rounded-[5px] mr-2'>
                    <BadgeSquare className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 my-1' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Square</p>
                </div>
              ),
              selectedName: (
                <div className='flex items-center'>
                  <div className=' flex items-center justify-center bg-app-green w-[24px] h-[24px]  rounded-[5px] mr-2'>
                    <BadgeSquare className='[&_*]:stroke-deactive ui-checked:[&_*]:stroke-tag-text w-4 h-4 ' />
                  </div>
                  <p className='text-icon-text-color text-[12px]'>Square</p>
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      section_id: 'detail',
      name: 'Detail',
      elements: [
        {
          id: 'detail',
          default_enabled: true,
          values: [
            {
              id: 'low',
              name: 'Modern, Minimalist',
            },
            {
              id: 'high',
              name: 'Illustrative',
            },
          ],
        },
      ],
    },
    {
      section_id: 'style',
      name: 'Style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'sports',
              name: 'Sports',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  cartoon: [
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					default_enabled: false,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: false }],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  icon: [
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, always_enabled: true }],
    },
    {
      section_id: 'view',
      name: 'View',
      elements: [
        {
          id: 'view',
          values: [
            {
              id: 'three-quarters',
              name: 'Three-Quarter View',
            },
            {
              id: 'front-view',
              name: 'Front View',
            },
            {
              id: 'high-angle',
              name: 'High angle view',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  abstract: [
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: true }],
    },
    {
      section_id: 'style-mode',
      name: 'Style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'organic',
              name: 'organic',
            },
            {
              id: 'symmetrical',
              name: 'Symmetrical',
            },
            {
              id: 'circular',
              name: 'Circular',
            },
            {
              id: 'wavy',
              name: 'Wavy',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'line-art': [
		{
			section_id: 'line-thickness',
			name: 'Line Thickness',
			elements: [
				{
					id: 'line_thickness',
					values: [
						{
							id: 'thin',
							name: 'Thin',
						},
						{
							id: 'thick',
							name: 'Thick',
						},
					],
				},
			],
		},
    {
      section_id: 'structure',
      name: 'Structure',
      elements: [
        {
          id: 'structure',
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'curved',
              name: 'Curved',
            },
          ],
        },
      ],
    },
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					default_enabled: false,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail }],
    },
    {
      section_id: 'style',
      name: 'Style',
      elements: [
        {
          id: 'style',
          default_enabled: false,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 1,
          colorNames: ['Primary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  '3d-text': [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'text-style',
      name: 'Text type',
      elements: [
        {
          id: 'text_style',
          values: [
            {
              id: 'cursive',
              name: 'Cursive',
            },
            {
              id: 'block',
              name: 'Block letters',
            },
          ],
        },
      ],
    },
    {
      section_id: 'style',
      name: 'Logo style',
      elements: [
        {
          id: 'style',
          default_enabled: true,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'logo-type': [
    {
      section_id: 'text',
      name: 'Your Main Text',
      elements: [
        {
          id: 'text',
          default_enabled: true,
          default: '',
          component: ConfigTextInput,
        },
      ],
    },
    {
      section_id: 'letter_type',
      name: 'Letter type',
      elements: [
        {
          id: 'letter_type',
          values: [
            {
              id: 'cursive',
              name: 'Cursive',
            },
            {
              id: 'block',
              name: 'Block letters',
            },
          ],
        },
      ],
    },
    {
      section_id: 'type',
      name: 'Type',
      elements: [
        {
          id: 'type',
          default_enabled: true,
          values: [
            {
              id: 'modern',
              name: 'Modern',
            },
            {
              id: 'vintage',
              name: 'Vintage',
            },
          ],
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [GENERAL_PROPERTIES.v3_detail],
    },
    {
      section_id: 'style',
      name: 'Style',
      elements: [
        {
          id: 'style',
          values: [
            // {
            //   id: 'minimalist',
            //   name: 'Minimalist',
            // },
            {
              id: 'traditional',
              name: 'Traditional',
            },
            {
              id: 'brush-strokes',
              name: 'Brush Strokes',
            },
            // {
            //   id: '3d',
            //   name: '3D',
            // },
            {
              id: 'modern',
              name: 'Modern',
            },
          ],
        },
      ],
    },
    {
      section_id: 'font_style',
      name: 'Font Style',
      elements: [
        {
          id: 'font_style',
          values: [
            {
              id: 'geometric',
              name: 'Geometric',
            },
            {
              id: 'handwritten',
              name: 'Handwritten',
            },
            {
              id: 'curved',
              name: 'Curved',
            },
            {
              id: 'chunky',
              name: 'Chunky',
            },
            {
              id: 'thin',
              name: 'Thin',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  monogram: [
    {
      section_id: 'text',
      name: 'Your Main Letter',
      elements: [
        {
          id: 'letter',
          default_enabled: true,
          default: '',
          component: ConfigLetterSelector,
        },
      ],
    },
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [GENERAL_PROPERTIES.v3_detail],
    },
    {
      section_id: 'font_style',
      name: 'Font Style',
      elements: [
        {
          id: 'font_style',
          default_enabled: true,
          values: [
            {
              id: 'organic',
              name: 'Organic',
            },
            {
              id: 'geometric',
              name: 'Geometric',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  'stylized-cartoon': [
    {
      section_id: 'details',
      name: 'Detail Level',
      elements: [{ ...GENERAL_PROPERTIES.v3_detail, default_enabled: true }],
    },
    {
      section_id: 'character_composition',
      name: 'Character Composition',
      elements: [
        {
          id: 'character_composition',
					default_enabled: false,
          values: [
            {
              id: 'full-body',
              name: 'Full Body',
            },
            {
              id: 'portrait',
              name: 'Portrait',
            },
          ],
        },
      ],
    },
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'outline_stroke',
          colors: 1,
          colorNames: ['Outline Color'],
          default_enabled: false,
          values: [
            {
              id: 'thin',
              name: 'Thin Outline',
            },
            {
              id: 'thick',
              name: 'Thick Outline',
            },
          ],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'pixel-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'flat-graphic': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'coloring-book': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
        },
      ],
    },
  ],
  cyberpunk: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
          default: 'solid',
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  sticker: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'marker-drawing': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'sketch-note': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'color-sketch-note': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'digital-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'fantasy-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'pop-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'comic-art': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  manga: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  kawaii: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'punk-collage': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'dark-arts': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'charcoal-sketch': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
        },
      ],
    },
  ],
  halftone: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  'stylized-3d': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  '3d': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  inflated: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
        },
      ],
    },
  ],
  embroidered: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  isometric: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'low-poly': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  cinematic: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  hyperrealism: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'craft-clay': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  futuristic: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  origami: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'paper-craft': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],

  'oil-painting': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'fantasy-illustration': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  watercolor: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  'art-deco': [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
  bauhaus: [
    {
      section_id: 'colors',
      elements: [
        {
          id: 'palette',
          colors: 2,
          colorNames: ['Primary Color', 'Secondary Color'],
        },
        {
          id: 'background',
          colors: 1,
          colorNames: ['Background Color'],
          default: 'solid',
          values: [
            {
              id: 'solid',
              name: 'Solid Color',
            },
            {
              id: 'gradient',
              name: 'Gradient',
            },
          ],
        },
      ],
    },
  ],
};

export const STYLE_SETTINGS = {
  sdxl: {
    pictorial: [
      {
        section_id: 'style-mode',
        name: 'Pictorial Logo Style',
        elements: [
          {
            id: 'style-mode',
            default_enabled: false,
            values: [
              {
                id: 'modern',
                name: 'Modern',
              },
              {
                id: 'vintage',
                name: 'Vintage',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [GENERAL_PROPERTIES.details],
      },
      {
        section_id: 'extra',
        name: 'Extra Features',
        elements: [
          {
            id: 'extra',
            default_enabled: false,
            values: [
              {
                id: 'full-body',
                name: 'Full Body',
              },
              {
                id: 'portrait',
                name: 'Portrait',
              },
            ],
          },
        ],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    mascot: [
      {
        section_id: 'outline-stroke',
        name: 'Logo Outline Stroke',
        elements: [GENERAL_PROPERTIES.outline_stroke],
      },
      {
        section_id: 'style-mode',
        name: 'Mascot Logo Style',
        elements: [
          {
            id: 'style-mode',
            default_enabled: false,
            values: [
              {
                id: 'modern',
                name: 'Modern',
              },
              {
                id: 'vintage',
                name: 'Vintage',
              },
              {
                id: 'sports',
                name: 'Sports',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [GENERAL_PROPERTIES.details],
      },
      {
        section_id: 'extra',
        name: 'Extra Features',
        elements: [
          {
            id: 'extra',
            default_enabled: false,
            values: [
              {
                id: 'full-body',
                name: 'Full Body',
              },
              {
                id: 'portrait',
                name: 'Portrait',
              },
            ],
          },
        ],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    badge: [
      {
        section_id: 'outline-stroke',
        name: 'Logo Outline Stroke',
        elements: [GENERAL_PROPERTIES.outline_stroke],
      },
      {
        section_id: 'extra',
        name: 'Badge Styles',
        elements: [
          {
            id: 'extra',
            default_enabled: true,
            values: [
              {
                id: 'shield',
                name: 'Sheild',
              },
              {
                id: 'emblem',
                name: 'Emblem',
              },
              {
                id: 'crest',
                name: 'Crest',
              },
            ],
          },
        ],
      },
      {
        section_id: 'style-mode',
        name: 'Line Art Logo Style',
        elements: [
          {
            id: 'style-mode',
            default_enabled: false,
            values: [
              {
                id: 'modern',
                name: 'modern',
              },
              {
                id: 'vintage',
                name: 'vintage',
              },
              {
                id: 'sports',
                name: 'sports',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [GENERAL_PROPERTIES.details],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    cartoon: [
      {
        section_id: 'outline-stroke',
        name: 'Logo Outline Stroke',
        elements: [
          { ...GENERAL_PROPERTIES.outline_stroke, default_enabled: true },
        ],
      },
      {
        section_id: 'style-mode',
        name: 'Cartoon Logo Style',
        elements: [
          {
            id: 'style-mode',
            default_enabled: false,
            values: [
              {
                id: 'cute',
                name: 'Cute',
              },
              {
                id: 'cool',
                name: 'Cool',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [{ ...GENERAL_PROPERTIES.details, default_enabled: true }],
      },
      {
        section_id: 'extra',
        name: 'Extra Features',
        elements: [
          {
            id: 'extra',
            default_enabled: false,
            values: [
              {
                id: 'full-body',
                name: 'Full Body',
              },
              {
                id: 'portrait',
                name: 'Portrait',
              },
            ],
          },
        ],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    icon: [
      {
        section_id: 'style-mode',
        name: 'Icon/Emoji Logo Style',
        elements: [
          {
            id: 'style-mode',
            values: [
              {
                id: 'three-quarters',
                name: 'Three Quarters View',
              },
              {
                id: 'front-view',
                name: 'Front View',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [{ ...GENERAL_PROPERTIES.details, always_enabled: false }],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    abstract: [
      {
        section_id: 'line-thickness',
        name: 'Logo Line Thickness',
        elements: [
          { ...GENERAL_PROPERTIES.line_thickness, default_enabled: false },
        ],
      },
      {
        section_id: 'style-mode',
        name: 'Mascot Logo Style',
        elements: [
          {
            id: 'style-mode',
            values: [
              {
                id: 'geometric',
                name: 'Geometric',
              },
              {
                id: 'organic',
                name: 'Organic',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [GENERAL_PROPERTIES.details],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    'line-art': [
      {
        section_id: 'line-thickness',
        name: 'Logo Line Thickness',
        elements: [GENERAL_PROPERTIES.line_thickness],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [{ ...GENERAL_PROPERTIES.details, default_enabled: false }],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    'logo-type': [
      {
        section_id: 'style-mode-style',
        name: 'LogoType Logo Style',
        elements: [
          {
            id: 'style-mode-style',
            default_enabled: false,
            values: [
              {
                id: 'modern',
                name: 'Modern',
              },
              {
                id: 'vintage',
                name: 'Vintage',
              },
            ],
          },
        ],
      },
      {
        section_id: 'style-mode-swoosh',
        name: 'LogoType Style',
        elements: [
          {
            id: 'style-mode-swoosh',
            default_enabled: false,
            values: [
              {
                id: 'swoosh',
                name: 'Swoosh',
              },
              {
                id: 'no-swoosh',
                name: 'No Swoosh',
              },
            ],
          },
          {
            id: 'style-mode-slatend',
            default_enabled: false,
            values: [
              {
                id: 'level',
                name: 'Level',
              },
              {
                id: 'slanted',
                name: 'Slanted',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [GENERAL_PROPERTIES.details],
      },
      {
        section_id: 'extra',
        name: 'Extra Features',
        elements: [
          {
            id: 'extra-font',
            default_enabled: false,
            values: [
              {
                id: 'serif',
                name: 'Serif',
              },
              {
                id: 'sans-serif',
                name: 'Sans Serif',
              },
            ],
          },
          {
            id: 'extra-font-thickness',
            values: [
              {
                id: 'bold',
                name: 'Bold',
              },
              {
                id: 'thin',
                name: 'Thin',
              },
            ],
          },
          {
            id: 'extra-style',
            values: [
              {
                id: 'cursive',
                name: 'Cursive',
              },
              {
                id: 'block',
                name: 'Block letters',
              },
            ],
          },
        ],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
    monogram: [
      {
        section_id: 'style-mode',
        name: 'Monogram Logo Style',
        elements: [
          {
            id: 'style-mode-style',
            values: [
              {
                id: 'modern',
                name: 'Modern',
              },
              {
                id: 'vintage',
                name: 'Vintage',
              },
            ],
          },
          {
            id: 'style-mode-lines',
            default_enabled: false,
            values: [
              {
                id: 'curved',
                name: 'Curved Lines',
              },
              {
                id: 'straight',
                name: 'Straight Lines',
              },
            ],
          },
        ],
      },
      {
        section_id: 'details',
        name: 'Detail Level',
        elements: [GENERAL_PROPERTIES.details],
      },
      {
        section_id: 'extra',
        name: 'Extra Features',
        elements: [
          {
            id: 'extra-style-form',
            values: [
              {
                id: 'geometric',
                name: 'Geometric',
              },
              {
                id: 'organic',
                name: 'Organic',
              },
            ],
          },
          {
            id: 'extra-font-thickness',
            values: [
              {
                id: 'bold',
                name: 'Bold',
              },
              {
                id: 'thin',
                name: 'Thin',
              },
            ],
          },
          {
            id: 'extra-monogram-style',
            values: [
              {
                id: 'lower',
                name: 'Lowercase',
              },
              {
                id: 'upper',
                name: 'Uppercase',
              },
            ],
          },
          {
            id: 'extra-font',
            default_enabled: false,
            values: [
              {
                id: 'serif',
                name: 'Serif',
              },
              {
                id: 'sans-serif',
                name: 'Sans Serif',
              },
            ],
          },
        ],
      },
      {
        section_id: 'background',
        name: 'Background Color',
        elements: [GENERAL_PROPERTIES.background_color],
      },
    ],
  },

  'v3/2d': V3_SETTINGS,

  'v4/waterfall': V4_SETTINGS,
  'v4/bolt': V4_SETTINGS,
  'v4/xl': V4_SETTINGS,
  'v4/style-transfer': V4_SETTINGS,
};

export const CONFIG_PROPS = Object.entries(STYLE_SETTINGS).reduce(
  (body, [method_id, method_config]) => {
    return {
      ...body,
      [method_id]: Object.entries(method_config).reduce(
        (config, [style_id, style_config]) => {
          return {
            ...config,
            [style_id]: style_config.reduce(
              (config_metadata, section) => ({
                ...config_metadata,
                ...section.elements.reduce(
                  (section_metadata, field) => ({
                    ...section_metadata,
                    [field.id]: field,
                  }),
                  {}
                ),
              }),
              {}
            ),
          };
        },
        {}
      ),
    };
  },
  {}
);

export const randomize_config_item = (
  method,
  style,
  id,
  item,
  config = { randomize_colors: false, use_defaults: false },
) => {
  const conf = CONFIG_PROPS[method][style]?.[id];
	if (!conf) return item
	console.log(conf, method, style, id)
  const conf_values = conf.values || [];
  const conf_checkboxes = conf.checkboxes || [];
  return {
    enabled: item.enabled ?? false,
    __randomized: item.enabled,
    // colors: [...Array(conf.colors || 0)].map(() => SDColors[Math.floor(Math.random() * SDColors.length)].id),
    colors: Array.isArray(item.colors) ? item.colors.slice(0, conf.colors) : [],
    value:
      conf.default ??
			item.value ?? 
      conf_values[Math.floor(Math.random() * (conf.values?.length || 0))]?.id,
    values:
      conf.default ??
			item.values ?? 
      conf_checkboxes.map((item) => item.id).filter(() => Math.random() > 0.5),
  };
};

export const removeEmpty = (obj) => {
	Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
  return obj;
};

export const generate_style_config = (
  method,
  style,
  current_config = {},
  config = { randomize_colors: false, use_defaults: false }
) => {
  return removeEmpty(Object.entries(CONFIG_PROPS[method][style] ?? {}).reduce(
    (conf, [id, item]) => ({
      ...conf,
      [id]: randomize_config_item(
        method,
        style,
        id,
        {
					...(Array.isArray(current_config[id]?.colors) && current_config[id]),
          enabled:
            current_config?.[id]?.enabled ??
            item.default_enabled ??
            item.always_enabled ??
            true,
        },
        config,
      ),
    }),
    current_config
  ));
};
