import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Modal from '../components/Modal';
import TutorialModal from '../components/TutorialModal';
import OutOfCredits from '../components/modals/OutOfCredits';
import Appsumo1024LimitDialog from '../components/modals/Appsumo1024LimitDialog';
import Notification from '../components/base/Notification';
import { useEffect } from 'react';
import { updateOutOfCreditText, updateShowOutOfCreditModal } from '../reducers/formReducer';

function Home(props) {
	const { disableNotification } = props
  const isTutorialModalOpen = useSelector(state => state.appSlice.isTutorialModalOpen);

	const user = useSelector(state => state.userSlice.user);

	const dispatch = useDispatch()

	useEffect(() => {
		const showUpgradeOnLogin = localStorage.getItem('showUpgradeOnLogin')
		if (showUpgradeOnLogin === 'true') {
			dispatch(updateShowOutOfCreditModal(true));
			dispatch(updateOutOfCreditText({
				title: <>Upgrade for more credits<br />and features</>,
				subtitle: null,
			}));
		}
		localStorage.removeItem('showUpgradeOnLogin')
	}, [dispatch])
	
  return (
    <div className="flex flex-col grow h-full overflow-auto">
      <Navbar />
      <MobileNav />

      {/* <Modal isOpen={isTutorialModalOpen}>
        <TutorialModal isModal={true} />
      </Modal> */}

			{!disableNotification && <Notification containerClasses="max-w-[1400px]" />}
      {props.children}


			<OutOfCredits />
			<Appsumo1024LimitDialog />
      <ToastContainer />
    </div>
  );
}

export default Home;
