import { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

function RangeInput(parentProps) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(function () {
    window.addEventListener('resize', function (e) {
      setWindowWidth(windowWidth);
    });
  });

  const dynamicThumbStyles = () => {
    return {
      height: windowWidth < 768 ? '26px' : '12px',
      width: windowWidth < 768 ? '26px' : '12px',
    };
  };

  const dynamicRailStyles = () => {
    return {
      height: windowWidth < 768 ? '8px' : '4px',
    };
  };

  return (
    <Range
      step={parentProps.step || 1}
      min={parentProps.min}
      max={parentProps.max}
			disabled={parentProps.disabled}
      values={parentProps.values}
      onChange={(values) => parentProps.onRangeChange(values)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: '30px',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '4px',
              width: '100%',
              borderRadius: '4px',
              ...dynamicRailStyles(),
							...(parentProps.importedRailStyle ?? {}),
              background: getTrackBackground({
                values: parentProps.values,
                colors: [
                  parentProps.bgColor || '#3DD598',
                  parentProps.bgInactiveColor || parentProps.barColor || '#fff',
                ],
                min: parentProps.min,
                max: parentProps.max,
              }),
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            borderRadius: '50%',
            backgroundColor: '#fff',
            outline: 'none',
            ...dynamicThumbStyles(),
						...(parentProps.importedThumbStyle ?? {}),
          }}
        />
      )}
    />
  );
}

export default RangeInput;
