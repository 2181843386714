import { defaults } from "lodash"
import { useDispatch, useSelector } from "react-redux";
import { partialUpdateStatePayload } from "../../../../reducers/formReducer";
import DevInput from "./DevInput";

const DynamicSettings = (props) => {
	const dispatch = useDispatch()

	const payload = useSelector((state) => state.form.payload);

	const devFields = useSelector((state) => state.form.devModeFields);

	const handleFieldUpdate = (key) => (val) => {
		dispatch(partialUpdateStatePayload({
			[key]: val
		}))
	}
	return (
		<>
			<div className="text-gray-label font-poppins font-bold">
				<span className="font-bold text-base">Dynamic settings</span>
			</div>
			{devFields.map(item => (
				<DevInput
					key={item}
					fieldName={item}
					value={payload[item]}
					onChange={handleFieldUpdate(item)}
					label={item}
					title={item}
				/>
			))}
		</>
	)
}

export default DynamicSettings;
