import React, { useMemo } from 'react';
import { Range, getTrackBackground } from 'react-range';

const SliderV2 = (props) => {
	const {
		acceptedValues = [],
		value,
		onChange,
		disabled,
	}= props;
	const values = useMemo(() => [Math.max(acceptedValues.findIndex((item) => item.value === value), 0)], [value, acceptedValues])
  return (
    <Range
      step={1}
      min={0}
      max={acceptedValues.length - 1}
      values={values}
      onChange={(values) => onChange(acceptedValues[values[0]]?.value)}
			disabled={disabled}
			renderMark={({ props, index }) => {
				if (index === 0 || index === acceptedValues.length - 1) {
					return (
						<div 
							className={`bottom-[250%] text-[0.65rem] ${index === 0 ? '!left-0' : '!left-full -translate-x-full'} ${disabled ? 'text-app-search-gray' : 'text-deactive'}`}
							{...props}
							style={{
								...props.style,
								marginTop: 0,
							}}
						>
							{acceptedValues[index]?.name}
						</div>
						)
				}
				return (
					<div
						{...props}
						className="w-[7px] bg-app-black h-2"
						style={{
							...props.style,
							marginTop: 0,
							top: '-0.125rem',

						}}
					>
						<div
							className={`h-full w-[1px] rounded-full mx-auto ${disabled ? 'bg-overlay-bg' : index < values[0] ?  'bg-app-green' : 'bg-white'}`}
						/>
					</div>
				)
			}}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            display: 'flex',
            width: '100%',
						height: '2rem'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '0.25rem',
              width: '100%',
              borderRadius: '45px',
              alignSelf: 'center',
							position: 'relative',

              background: getTrackBackground({
                values: values,
								...(disabled ? {
									colors: ['#292932', '#292932'],
								} : {
									colors: ['#3DD598', '#fff'],
								}),
                min: 0,
                max: acceptedValues.length -1
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
					className={`w-[0.625rem] h-[0.875rem] outline-none rounded-sm ${disabled ? 'bg-slider-thumb-disabled' : 'bg-white'} absolute top-0`}
          style={{
            ...props.style,
            outline: 'none',
          }}
        />
      )}
    />
  )
}

export default SliderV2;
