import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import ProtectedRoute from '../../components/ProtectedRoute';
import AppLayout from '../../layouts/AppLayout';

import ImageViewer from '../../components/ImageViewer';

import { EditorProvider } from '../../context';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import V3ImagePipeline from '../../components/image-to-image/V3';
import V4ImagePipeline from '../../components/image-to-image/V4';
import SketchToLogo from '../../assets/tutorials/sketch-to-logo.jpg'

import { driver } from "driver.js";


const driverObj = driver({
  showProgress: true,
	popoverClass: 'driverjs-theme',
	prevBtnText: `
		<span class="text" onclick="event.currentTarget.parentNode.click()"> 
			<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g opacity="0.5">
					<path d="M1.24609 5.58984C1.24609 6.33152 1.46603 7.05655 1.87808 7.67323C2.29014 8.28992 2.87581 8.77056 3.56103 9.05439C4.24625 9.33822 5.00025 9.41248 5.72768 9.26779C6.45511 9.12309 7.1233 8.76594 7.64774 8.24149C8.17219 7.71705 8.52934 7.04886 8.67404 6.32143C8.81873 5.594 8.74447 4.84 8.46064 4.15478C8.17681 3.46956 7.69617 2.88389 7.07948 2.47183C6.4628 2.05978 5.73777 1.83984 4.99609 1.83984C3.94774 1.84379 2.9415 2.25285 2.18776 2.98151L1.24609 3.92318" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M1.24609 1.83984V3.92318H3.32943" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
			</svg>
			Back
		</span>`,
	nextBtnText: `
		<span class="text" onclick="event.currentTarget.parentNode.click()">
			Next
			<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.996094 1L4.99609 5.5L0.996094 10" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</span>`,
  steps: [
    { 
			element: '#prompt', 
			popover: { 
				title: 'Animated Tour Example', 
				image: SketchToLogo,
				description: 'Here is the code example showing animated tour. Let\'s walk you through it.', 
				side: "top",
				align: 'center' 
			}
		},
    { 
			element: '#style-selector', 
			popover: { 
				video: "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4",
				title: 'Import the Library', 
				description: 'It works the same in vanilla JavaScript as well as frameworks.', 
				side: "right", 
				align: 'start' 
			}
		},
    { 
			popover: { 
				title: 'Happy Coding', 
				image: "",
				description: 'And that is all, go ahead and start adding tours to your applications.' 
			} 
		}
  ],
	onNextClick:() => {
		driverObj.moveNext();
	},
  onPrevClick:() => {
    driverObj.movePrevious();
  },
  onCloseClick:() => {
    driverObj.destroy();
  },
	onDestroyStarted: () => {
    if (!driverObj.hasNextStep()) {
			// localStorage.setItem('hasDoneImageWorkspaceTour', 'true')
      driverObj.destroy();
    }
  },
	onPopoverRender: (popover, { config, state }) => {
		popover.progress.innerHTML = ""
		for (let i = 0; i < config.steps.length; i++) {
			const btn = document.createElement("button");
			btn.innerText = '';
			btn.classList.add('driver-step');
			if (state.activeIndex === i)
				btn.classList.add('active');
			popover.progress.appendChild(btn);

			btn.addEventListener("click", () => {
				driverObj.drive(i);
			});
		}

		if (config.steps[state.activeIndex].popover.image) {
			const media = document.createElement("img");
			media.classList.add('driver-media')
			media.src = config.steps[state.activeIndex].popover.image
			media.alt = config.steps[state.activeIndex].popover.title
			popover.wrapper.prepend(media)
		}
		if (config.steps[state.activeIndex].popover.video) {
			const media = document.createElement("video");
			media.classList.add('driver-media')
			media.src = config.steps[state.activeIndex].popover.video
			media.autoplay = true
			media.controls = true

			popover.wrapper.prepend(media)
		}
	},
});


const ImagePipeline = (props) => {
  const { mode: version } = useGenerationEndpoint();

	useEffect(() => {
		const startTour = () => {
			// let done = localStorage.getItem('hasDoneImageWorkspaceTour')
			// if (done === 'true') return;
			// driverObj.drive()
		}
		startTour()
	}, [])

  return (
    <AppLayout>
      <ProtectedRoute>
        <Helmet>
          <title>Generator | Logo Diffusion</title>
        </Helmet>
        <EditorProvider>
					{version === 'v4.0' ? (
						<V4ImagePipeline />
					) : (
						<V3ImagePipeline />
					)}

          <ImageViewer />

          {/* <ImageEditor /> */}

        </EditorProvider>
      </ProtectedRoute>
    </AppLayout>
  );
};

export default ImagePipeline;
