import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react'
import { flushSync } from "react-dom";
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PenIcon } from "../../../assets/Pen.svg";
import LdTooltip from '../../Tooltip';
import { setBrushSize} from '../../../reducers/imageEditorReducer';
import { BRUSH_SIZES } from '../brush/brush_sizes';
import { toolIconBtnCss } from '../toolbar/const';

const PenSelector = (props) => {
  const { editor, updateDrawingMode } = props;
  const dispatch = useDispatch();
  const {
    activeColor,
    brushSize,
    tool: currentTool,
  } = useSelector((state) => state.imageEditor);

  const [anchorEl, setAnchorEl] = useState()
  const [popperEl, setPopperEl] = useState()
  const [arrowElement, setArrowElement] = useState()
  const { styles, attributes } = usePopper(anchorEl, popperEl, {
    modifiers: [
      { 
        name: 'arrow', 
        options: { element: arrowElement } 
      },
      {
        name: 'offset',
        options: props.isImageWorkSpace
        ? { offset: [0, 20] }
        : { offset: [0, 10] },
      },
    ],
    placement: props.isImageWorkSpace ? 'right' : 'bottom',
  })

  const clickBrushSize = async (size) => {
    flushSync(() => {
      dispatch(setBrushSize(size));
    })

    if (currentTool !== "FREE_DRAWING") updateDrawingMode("FREE_DRAWING"); 

    await editor.current?.getInstance().setBrush({
      width: size,
      color: activeColor,
    });

  }

  return (
    <Popover className="relative overflow-visible" >
      {({ close }) => (
        <>
          <LdTooltip title="Pen" position='right'>
            <Popover.Button
              className={`${currentTool === "FREE_DRAWING" ? 'bg-divider' : 'bg-app-black'} ${toolIconBtnCss}`}
              ref={setAnchorEl}
						onClick={() => clickBrushSize(brushSize)}
            >
            <PenIcon
              className={`max-lg:aspect-square [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green ${
                currentTool === "FREE_DRAWING"
                  ? "[&_path]:stroke-app-green"
                  : ""
              }`}
            />
            </Popover.Button>
          </LdTooltip>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel 
              // className="absolute left-1/2 -translate-x-1/2 z-20 w-[200px] px-4 sm:px-0 lg:max-w-3xl mb-2"
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
              className="my-2"
            >
              <div ref={setArrowElement} style={styles.arrow} {...attributes.arrow} className="w-3 h-3 overflow-hidden inline-block bottom-full">
                <div className="h-full w-full bg-[#1c1c25ff] translate-x-1/2 translate-y-1/2 rotate-45 origin-top-left"></div>
              </div>
              <div className='rounded-lg bg-[#1c1c25ff] shadow-2xl p-3 z-50'>
                <div className="flex items-end justify-between gap-4">
                  {BRUSH_SIZES.map(({ size, borderRadius }) => (
                    <button
                      className={`aspect-square transition-all duration-150 ${
                        size === brushSize ? "bg-app-green" : "bg-editor-label"
                      }`}
                      style={{ height: `${size}px`, borderRadius: `${borderRadius}px` }}
                      onClick={() => {
                        close();
                        clickBrushSize(size);
                      }}
                      key={`brush-${size}`}
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default PenSelector
