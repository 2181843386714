import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { ReactComponent as PickColor } from '../../../assets/Eyedropper.svg';
import LdTooltip from '../../Tooltip';
import { ColorPanel } from './ColorPanel';
import { useSelector } from 'react-redux';
import { toolIconBtnCss } from '../toolbar/const';

const ColorPicker = (props) => {
  const { editor } = props;
  const { activeColor } = useSelector((state) => state.imageEditor);

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [arrowElement, setArrowElement] = useState();
  const { styles, attributes } = usePopper(anchorEl, popperEl, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: props.isImageWorkSpace
          ? { offset: [0, 20] }
          : { offset: [0, 10] },
      },
    ],
    placement: 'bottom',
  });

  return (
    <Popover className='relative overflow-visible'>
      {({ open }) => (
        <>
          <LdTooltip title='Color' position='right'>
            <Popover.Button
              className={`bg-transparent ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1
              `}
              style={{ background: activeColor }}
              ref={setAnchorEl}
            >
              <PickColor
                className={`max-lg:aspect-square [&>*]:invert [&>*]:grayscale `}
                style={{ fill: activeColor }}
              />
            </Popover.Button>
          </LdTooltip>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
              className='my-2'
            >
              <div
                ref={setArrowElement}
                style={styles.arrow}
                {...attributes.arrow}
                className='w-3 h-3 overflow-hidden inline-block bottom-full'
              >
                <div className='h-full w-full bg-[#1c1c25ff] translate-x-1/2 translate-y-1/2 rotate-45 origin-top-left'></div>
              </div>
              <div className='rounded-lg bg-[#1c1c25ff] shadow-2xl p-3 z-50'>
                <ColorPanel editor={editor} isOpen={open} />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ColorPicker;
