import React, { useState, useEffect } from 'react';
import { ReactComponent as DropdownDownIcon } from '../../../assets/dashboard/dropdown-down.svg';

function Option({ option, handleChange, className = '' }) {
  const { name, id, checked, subItems } = option;
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);
  useEffect(() => {
    if (checked) {
      setIsShowSubMenu(true);
    }
  }, [checked]);

  const handleLocalChange = (e) => {
    handleChange(id, e.target.checked);
  };
  return (
    <div
      className={`rounded-[16px] ${
        checked ? 'border-[#7351D9]' : ''
      } border-[2px] ${
        subItems ? 'border-[#4A525B]' : 'border-none'
      } my-[10px] max-h-[150px] overflow-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-[#4B4B59] scrollbar-track-transparent scrollbar scrollbar-w-[8px] ${className}`}
    >
      <div
        className={`${
          checked ? 'text-white' : 'text-[#848A90]'
        } font-light cursor-pointer ${subItems ? 'py-2 px-0' : 'px-2'}`}
      >
        <div className='flex items-center justify-between px-2'>
          <div className='flex items-center justify-between pl-1'>
            <input
              id={id}
              className='form-checkbox h-[13px] w-[13px] rounded-[4px] border-[#4A525B] border-[1px] bg-transparent text-custom-green focus:ring-transparent focus:ring-offset-0 mr-[10px]'
              type='checkbox'
              checked={checked}
              onChange={handleLocalChange}
            />
            <label
              className='text-[14px] font-semibold select-none'
              htmlFor={`${id}`}
            >
              {name}
            </label>
          </div>
          {subItems && subItems.length > 0 && (
            <div
              className='w-5 h-5 flex items-center justify-center'
              onClick={() => {
                setIsShowSubMenu(!isShowSubMenu);
              }}
            >
              {isShowSubMenu ? (
                <DropdownDownIcon className=' rotate-180' />
              ) : (
                <DropdownDownIcon />
              )}
            </div>
          )}
        </div>
        {subItems &&
          subItems.map((sub) => (
            <Option
              key={sub.id}
              option={sub}
              handleChange={handleChange}
              className={`${isShowSubMenu ? 'block' : 'hidden'}`}
            />
          ))}
      </div>
    </div>
  );
}

export default Option;
