import { Switch } from '@headlessui/react';
import React from 'react';

const CustomSwitch = (props) => {
	const { value, onChange, disabled } = props;
	return (
		<Switch
			checked={value}
			onChange={onChange}
			disabled={disabled}
			className="relative inline-flex h-[1.125rem] w-8 items-center rounded-[0.5rem] ui-checked:bg-app-green ui-not-checked:bg-overlay-bg shrink-0"
		>
			<span className="inline-block h-3 w-3 transform rounded-full bg-white transition ui-checked:translate-x-[1.125rem] ui-not-checked:translate-x-[0.125rem] uppercase ">
			</span>
		</Switch>
	);
}


export default CustomSwitch;
