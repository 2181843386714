import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { twMerge } from 'tailwind-merge'

const portalElementId = 'custom-tooltip'
if (!document.getElementById(portalElementId)) {
	const portalElement = document.createElement('div') 
	portalElement.setAttribute('id', portalElementId);
	document.getElementsByTagName('body')[0].appendChild(portalElement);
}

const BaseTooltip = (props) => {
	const { 
		children,
		anchorEl,
		handleTouch,
		boxClasses = "",
		placement = 'top',
		placementArrowClasses = 'top-full',
		placementArrowElClasses = 'w-full h-full bg-style-method-enabled border-select-border border-solid border-0 border-b border-l -rotate-45 -translate-y-1.5',
	} = props;

	const [open, setOpen] = useState(false);

  const [popperEl, setPopperEl] = useState();
  const [arrowEl, setArrowEl] = useState();
  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowEl,
        },
      },
    ],
		strategy: 'fixed',
  });

	useEffect(() => {
		const handleOpen = (e) => {
			setOpen(true);
		}
		const handleClose = (e) => {
			setOpen(false);
		}
		if (anchorEl) {
			anchorEl?.addEventListener('mouseenter', handleOpen)
			anchorEl?.addEventListener('mouseleave', handleClose)
			if (handleTouch) {
				anchorEl?.addEventListener('touchend', handleClose)
			}
		}

		return () => {
			anchorEl?.removeEventListener('mouseenter', handleOpen)
			anchorEl?.removeEventListener('mouseleave', handleClose)
			if (handleTouch) {
				anchorEl?.removeEventListener('touchend', handleClose)
			}
		}
	}, [anchorEl, handleTouch]);

	return createPortal(
		open && (<div
			className={twMerge(`fixed flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 transition-all duration-300 w-60 rounded-md z-50 ${open ? 'opacity-100': 'opacity-0'} transition-all duration-300`, boxClasses)}
			ref={setPopperEl}
			style={styles.popper}
			{...attributes.popper}
		>
			<div
				ref={setArrowEl}
				style={styles.arrow}
				{...attributes.arrow}
				className={`h-3 w-3 inline-block ${placementArrowClasses} `}
			>
				<div className={placementArrowElClasses} />
			</div>
			{children}
		</div>),
		document.getElementById(portalElementId)
	)
}

export default BaseTooltip;
