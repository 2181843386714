import { Popover, Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { HuePicker } from 'react-color';
import AlphaPicker from "react-color/lib/components/common/Alpha";
import tinycolor from 'tinycolor2';
import SaturationPicker from "react-color/lib/components/common/Saturation";
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TextColorIcon } from "../../../assets/TextColor.svg";
import { selectColor } from '../../../reducers/imageEditorReducer';
import { ColorPanel } from '../color/ColorPanel';

export const SaturationPointer = (props) => {
  const { hsl } = props;
  return (
    <div className="h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1/2 mt-[0.125rem]" style={{ backgroundColor: `#${tinycolor(hsl).toHex8()}` }}/>
  );
}
export const AlphaPointer = (props) => {
  const { activeColor } = props
  return (
    <div className="h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1 mt-[0.125rem]" style={{ backgroundColor: activeColor }}/>
  );
}

export const Color = (props) => {
  const { activeColor, color, onClick } = props;

  return <div className='rounded-full aspect-square h-6 p-[0.125rem]' style={{
    backgroundColor: color,
  }} onClick={onClick}>
    <div className={`rounded-full h-full w-full bg-transparent trnasition-all duration-150 border-solid border-app-black ${activeColor === color ? 'border-[3px]' : 'border-0' }`}></div>

  </div>

}

const TextColorPicker = (props) => {
  const { editor } = props;
  const dispatch = useDispatch();

  const [newColorAnchor, setNewColorAnchor] = useState()
  const [newColorElement, setNewColorElement] = useState()
  let { styles: newColorStyles, attributes: newColorAttributes } = usePopper(newColorAnchor, newColorElement, {
		placement: 'bottom',
	})

  const [newColor, setNewColor] = useState();

  const activeColor = useSelector((state) => state.imageEditor.activeColor);

  const selectedElement = useSelector(
    (state) => state.imageEditor.selectedElement
  );

  const onChangeColor = (color) => {
    setNewColor(color)
    if (selectedElement && selectedElement?.id) {
      editor
      .current
      .getInstance()
      .changeTextStyle(selectedElement.id, { fill: color.toHex8String() });
    }
  }

  const getInitialObjectColor = (obj, editor) => {
    if (!obj?.id) return
    const curObj = editor.current?.getInstance()._graphics._canvas._activeObject
    return tinycolor(curObj.fill)
  }

  useEffect(() => {
    setNewColor(getInitialObjectColor(selectedElement, editor))
  }, [editor, selectedElement])

  return (
    <Popover className="relative overflow-visible" >
      {({ close }) => (
        <>
          <Popover.Button
            className={`rounded-md aspect-square transition-all duration-150 bg-picker-button hover:bg-opacity-70 px-2 py-1 !outline-none`}
            ref={setNewColorAnchor}
          >

            <TextColorIcon
              className={`rounded-md w-4 h-4 transition-all duration-150 [&>path]:fill-white `}
            />
          </Popover.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel 
              className="z-30 fixed"
            >
              <div 
								className='overflow-hidden rounded-lg bg-app-bg-gray shadow-2xl p-3 z-50 ml-20 my-3 border border-solid border-overlay-border'
								ref={setNewColorElement}
								style={newColorStyles.popper}
								{...newColorAttributes.popper}
							>

								<ColorPanel editor={editor}/>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default TextColorPicker
