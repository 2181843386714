import React from 'react';
import { Tooltip } from "react-tippy";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { Switch } from '@headlessui/react';
import SDColorSelector from '../base/SDColorSelector';
import { useDispatch, useSelector } from 'react-redux';
import { updateInjectColorsAt, updateShouldInjectColros } from '../../reducers/formReducer';

const ColorInjection = (props) => {
  const { 
		label="Specify Colors",
		title="Use this to force a color palette if the setting above is not producing accurate colors.",
		appliedClasses={}, 
	} = props;

  const enabled = useSelector((state) => state.form.payload.shouldInjectColors);
  const colors = useSelector((state) => state.form.payload.injectColors);

	const dispatch = useDispatch();

  const handleDisable = (value) => {
    dispatch(updateShouldInjectColros(value));
  };
	
  const handleColor = (index, value) => {
    dispatch(updateInjectColorsAt({ index, value }));
  };

	return (
    <div className={`flex justify-between ${appliedClasses.main}`} disabled={!enabled}>
      <label className={`text-gray-label font-poppins font-bold text-xs flex items-center gap-1 ${!enabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
        {label}
  
        <Tooltip title={title} >
          <InfoIcon className={`${appliedClasses.infoTooltip}`}/>
        </Tooltip>
      </label>

			<div className="flex items-center justify-between gap-2 basis-[40%] max-w-[120px] grow-0">
				<Switch
					checked={enabled}
					onChange={handleDisable}
					className="relative inline-flex h-[1.125rem] w-8 items-center rounded-[0.5rem] ui-checked:bg-app-green ui-not-checked:bg-overlay-bg"
				>
					<span className="inline-block h-3 w-3 transform rounded-full bg-white transition ui-checked:translate-x-[1.125rem] ui-not-checked:translate-x-[0.125rem] uppercase ">
					</span>
				</Switch>
				<div className="flex gap-2">
					<SDColorSelector 
						disabled={!enabled}
						setColor={(val) => handleColor(0, val)}
						color={colors[0]}
					/>
					<SDColorSelector 
						disabled={!enabled}
						setColor={(val) => handleColor(1, val)}
						color={colors[1]}
					/>
				</div>
			</div>


		</div>
	);

}

export default ColorInjection;
