import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import store, { persistor } from './store'
import { Provider, useDispatch } from 'react-redux'

import "driver.js/dist/driver.css";
import 'react-image-crop/dist/ReactCrop.css'
import './index.css';
import 'react-tippy/dist/tippy.css';
import 'react-toastify/dist/ReactToastify.css';

import Home from './routes/Home';
import Register from './routes/Register';
import CompleteRegistration from './routes/CompleteRegistration';
import Login from './routes/Login';
import Profile from './routes/Profile';
import History from './routes/History';
import Plans from './routes/Plans';
import Billing from './routes/Billing';
import Documentation from './routes/Documentation';
import Bookmarks from './routes/Bookmarks';
import Admin from './routes/Admin';
import Integrations from './routes/Integrations';
import ChangePassword from './routes/ChangePassword';
import ResetPassword from './routes/ResetPassword';
import Upscale from './routes/Upscale';
import UpdatedUpscale from './routes/UpdatedUpscale';
import BgRemover from './routes/BgRemover';


import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import InterceptorHelperComponent from './helpers/axiosInterceptors';
import ConfirmEmail from './routes/ConfirmEmail';
import AppSumo from './routes/AppSumo';
import ImagePipeline from './routes/generation/ImagePipeline';
import TextPipeline from './routes/generation/TextPipeline';

import Tap from "@tapfiliate/tapfiliate-js";
import environment from './helpers/envProvider';
import Payment from './routes/Payment';

import * as Sentry from "@sentry/react";
import { init as initApm } from '@elastic/apm-rum'
import { ApmRoutes } from '@elastic/apm-rum-react'

import Vectorize from './routes/Vectorize';

import { app as FirebaseApp } from './firebase';
import SocketHandler from './helpers/socketHandler';
import useHasGenerationOccured from './hooks/useHasGenerationOccured';
import { resetForRouteChange } from './reducers/formReducer';

Sentry.init({
  dsn: "https://e8b28672ff8a92bca0b770d635147804@o4506209471430656.ingest.sentry.io/4506238623416320",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
	sampleRate: Number(environment("REACT_APP_SENTRY_SAMPLE_RATE") || 1),
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	environment: environment("REACT_APP_ENVIRONMENT") || "local",
	release: environment("REACT_APP_VERSION_ID"),
});

const apm = initApm({
  serviceName: 'app',

  serverUrl: 'https://apm.logodiffusion.com',


	breakdownMetrics: true,
	environment: environment("REACT_APP_ENVIRONMENT") || "local",
  serviceVersion: environment("REACT_APP_VERSION_ID"),

	transactionSampleRate: Number(environment("REACT_APP_APM_SAMPLE_RATE") || 1),
})

Tap.init(environment('REACT_APP_TAPFILIATE_ACCOUNT_ID'), { integration: 'stripe' });

if ("Notification" in window) {
	Notification.requestPermission().then((permission) => {
		console.log("Notification permission", permission)
	})
}

const RouteResetHandler = () => {
	const hasGenerationOccured = useHasGenerationOccured();
  const location = useLocation();
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(resetForRouteChange());
	}, [location, dispatch]);
	return (<></>)
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <InterceptorHelperComponent />
			<SocketHandler />
      <BrowserRouter>
				<RouteResetHandler />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/gen/image-workspace" element={<ImagePipeline />} />
					<Route path="/gen/text-workspace" element={<TextPipeline />} />
					<Route path="/appsumo" element={<AppSumo />} />
					<Route path="/login" element={<Login />} />
					<Route path="/register" element={<Register />} />
					<Route path="/change-password" element={<ChangePassword />} />
					<Route path="/email-confirmation" element={<ConfirmEmail />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/complete-registration/:userId" element={<CompleteRegistration />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/admin/*" element={<Admin />} />
					<Route path="/history" element={<History />} />
					<Route path="/plans" element={<Plans />} />
					<Route path="/payment/:status/:sessionId" element={<Payment />} />
					<Route path="/payment/:status/" element={<Payment />} />
					<Route path="/bookmarks" element={<Bookmarks />} />
					<Route path="/integrations" element={<Integrations />} />
					<Route path="/billing" element={<Billing />} />
					<Route path="/documentation" element={<Documentation />} />
					<Route path="/upscale" element={<Upscale />} />
					<Route path="/upscale-updated" element={<UpdatedUpscale />} />
					<Route path="/bg-remover" element={<BgRemover />} />
					<Route path="/vectorizer" element={<Vectorize />} />
				</Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
