import { useSelector } from "react-redux";

import Tooltip from "../../Tooltip"

import { ReactComponent as CursorIcon } from "../../../assets/cursor.svg";
import { ReactComponent as PanIcon } from "../../../assets/Pan.svg";
import { ReactComponent as EraserIcon } from "../../../assets/Eraser.svg";
import { ReactComponent as CropIcon } from "../../../assets/Crop.svg";

import ShapeSelector from "../shapes/ShapeSelector";
import { EDITOR_SIZE } from "../settings";
import PenSelector from "../pen";
import TextTool from "../text";
import ColorPicker from "../color";
import BgColorPicker from "../background";
import { toolIconBtnCss } from "./const";

export const Toolbar = (props) => {
  const { editor, updateDrawingMode, setCurrentTool } = props;

  const { isEditImage, tool: currentTool } = useSelector((state) => state.imageEditor);

  const setSelectTool = async () => {
    setCurrentTool(null);
    await editor.current?.getInstance().stopDrawingMode();
  }

  const setEraserTool = async () => {

    await editor.current?.getInstance().stopDrawingMode();
    await editor.current?.getInstance().deactivateAll();
    // editor.current?.getInstance().changeCursor('grab');

    setCurrentTool("ERASER")
  }

  const setCropTool = async () => {
    if (currentTool === 'CROPPER') {
      await editor.current?.getInstance().crop(editor.current?.getInstance().getCropzoneRect());
      await editor.current?.getInstance().resize({ width: EDITOR_SIZE, height: EDITOR_SIZE});
      await editor.current?.getInstance().stopDrawingMode();

      updateDrawingMode("FREE_DRAWING")
      return;
    }
    await editor.current?.getInstance().stopDrawingMode();
    await editor.current?.getInstance().startDrawingMode('CROPPER');
    await editor.current?.getInstance().setCropzoneRect(1);

    setCurrentTool("CROPPER")

  }

  const setPanTool = async () => {

    await editor.current?.getInstance().stopDrawingMode();
    await editor.current?.getInstance()._graphics.startHandMode();
    // await editor.current?.getInstance().startDrawingMode('RESIZE');

    setCurrentTool("PAN")
  }

  return (
    <div className="absolute top-3.5 left-[50%] -translate-x-[50%] !h-fit !w-fit max-w-[95%] flex flex-row gap-3 bg-app-black py-2 px-3 rounded-[18px]">
      <Tooltip title="Select">
        <button
          className={`${currentTool ? 'bg-app-black' : 'bg-divider'} ${toolIconBtnCss}`}
          onClick={setSelectTool}
        >
          <CursorIcon
            className={`max-lg:aspect-square [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green ${
              !currentTool ? "[&_path]:stroke-app-green" : ""
            }`}
          />
        </button>
      </Tooltip>
      <ColorPicker editor={editor} />
      <div className="h-[24px] w-[1px] bg-[#92929D]" />
      <PenSelector updateDrawingMode={updateDrawingMode} editor={editor} />
      <Tooltip title="Move around">
        <button
          className={`${currentTool === "PAN" ? 'bg-divider' : 'bg-app-black'} ${toolIconBtnCss}`}
          onClick={setPanTool}
        >
          <PanIcon
            className={`max-lg:aspect-square [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green ${
              currentTool === "PAN" ? "[&_path]:stroke-app-green" : ""
            }`}
          />
        </button>
      </Tooltip>
      <ShapeSelector setSelectTool={setSelectTool} editor={editor}/>
      <Tooltip title="Crop">
        <button
          className={`${currentTool === "CROPPER" ? 'bg-divider' : 'bg-app-black'} ${toolIconBtnCss}`}
          onClick={setCropTool}
        >
          <CropIcon
            className={`max-lg:aspect-square [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green ${
              currentTool === "CROPPER" ? "[&_path]:fill-app-green group-hover:[&_path]:fill-app-green" : ""
            }`}
          />
        </button>
      </Tooltip>
      <TextTool editor={editor} updateDrawingMode={updateDrawingMode} />
      <Tooltip title="Delete elements">
        <button
          className={`${currentTool === "ERASER" ? 'bg-divider' : 'bg-app-black'} ${toolIconBtnCss}`}
          onClick={setEraserTool}
        >
          <EraserIcon
            className={`max-lg:aspect-square [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green ${
              currentTool === "ERASER" ? "[&_path]:fill-app-green" : ""
            }`}
          />
        </button>
      </Tooltip>
      {!isEditImage && (
        <Tooltip title="Change Background">
          <BgColorPicker setCurrentTool={setCurrentTool} editor={editor} />
        </Tooltip>
      )}
    </div>
  )
}

export default Toolbar
