import axios from "axios";
import { toast } from "react-toastify";

const convertUrlToBase64 = (url) => new Promise((resolve, reject) => {
    axios.get(url.startsWith('/') ? `${window.location.origin}${url}` : url, {
        headers: {
            "Content-Type": "image/png"
        },
        responseType: "blob",
    }).then((res) => {
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onload = () => resolve(reader.result.replace('text/plain', 'image/png'));
        reader.onerror = error => reject(error);
    }).catch((r) => reject(r))
})

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export const convertImageToBase64 = async (imgLocation, imageWidth=512, imageHeight=512) => {
    return new Promise(async (resolve, reject) => {
        var img = new Image();
        var c = document.createElement("canvas");
        var ctx = c.getContext("2d");
        // img.crossOrigin = 'anonymous';
        // img.setAttribute('crossorigin', 'anonymous')
        // img.setAttribute('referrerpolicy', 'strict-origin')
        // img.crossOrigin = 'use-credentials';

        img.alt = "Inspiration"

        img.onload = function() {
            c.width = imageWidth || img.width;
            c.height = imageHeight || img.height;

            ctx.drawImage(this, 0, 0, imageWidth || img.width, imageHeight || img.height);

            c.toBlob(function(blob) {
                var reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onloadend = function() {
                    var base64data = reader.result;
                    resolve(base64data)
                }
            }, "image/png", 1);
        };

        try {
            const resp = await axios.get(imgLocation, { responseType: 'blob', headers: {'Cache-Control': 'no-cache'}})
    
            img.src = URL.createObjectURL(resp.data);
            // img.src = imgLocation;
        } catch(e) {
            console.log(e);
            toast('Failed to load inspiration', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark',
                autoClose: 2000
            });
        }

    });
}

export const getImageSize = async (base64) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
    
        image.onload = function() {
            resolve({
                width: image.width,
                height: image.height
            });
        };

        image.onerror = function() {
            reject(new Error('Failed to load image'));
        };

        image.src = base64;
    })
    
}

export default convertUrlToBase64;