import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '../../Tooltip';

import { ReactComponent as DownloadIcon } from '../../../assets/DownloadImageEditorNew.svg';

import { resizeBase64 } from '../resizers';
import { EDITOR_SIZE, IMAGE_EXPORT_SIZE } from '../settings';

export const DownloadAction = (props) => {
  const { editor, zoomCenterRef } = props;

  const { backgroundImage, zoom } = useSelector((state) => state.imageEditor);

  const downloadImage = async () => {
    await editor.current
      ?.getInstance()
      .zoom({ zoomLevel: 1, x: EDITOR_SIZE / 2, y: EDITOR_SIZE / 2 });
    const originalImage = editor.current
      ?.getInstance()
      .toDataURL({ multiplier: 4 });
    await editor.current?.getInstance().zoom({
      zoomLevel: zoom,
      x: zoomCenterRef.current.x,
      y: zoomCenterRef.current.y,
    });
    const { image } = await resizeBase64(
      originalImage,
      IMAGE_EXPORT_SIZE,
      IMAGE_EXPORT_SIZE
    );

    const link = document.createElement('a');
    link.href = image;
    link.download = 'Lodi drawing.png';
    document.body.appendChild(link);
    link.click();
    document.removeChild(link);
  };

  useEffect(() => {
    editor?.current
      ?.getInstance()
      ._graphics._canvas.on('selection:created', (e) => {
        console.log('New seldction ', e);
      });
  }, [editor]);

  return (
    <span className={`absolute right-4 top-4 flex flex-row z-20`}>
      <Tooltip title='Download'>
        <button
          className={`bg-[#7E7E7E] bg-opacity-50 p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
          onClick={downloadImage}
        >
          <DownloadIcon
            className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]  [&_path]:fill-bg-app-gray group-hover:[&_path]:fill-app-green`}
          />
        </button>
      </Tooltip>
    </span>
  );
};

export default DownloadAction;
