import React from "react";

import { ReactComponent as UndoIcon } from "../../assets/Undo.svg";
import { ReactComponent as RedoIcon } from "../../assets/Redo.svg";
import { ReactComponent as SaveEditor } from "../../assets/SaveEditor.svg";
import Tooltip from "../Tooltip";
import { useDispatch } from "react-redux";
import { resizeBase64 } from "./resizers";
import { updateInputImage } from "../../reducers/formReducer";
import { IMAGE_EXPORT_SIZE } from "./settings";
import useCurrentWorkspace from "../../hooks/useCurrrentWorkspace";

const RedoUndo = (props) => {
  const { editor, toolRef, zoomCenterRef, onSave } = props;

	const dispatch = useDispatch();

  const handleRedoUndo = () => {
    editor.current?.getInstance().undo()
  }

  const handleRedoRedo = () => {
    editor.current?.getInstance().redo()
  }

  const save = async () => {
    await editor?.current.getInstance().zoom(zoomCenterRef.current)

    toolRef.current = null
    await editor.current?.getInstance().stopDrawingMode();

		setTimeout(async() => {
			const originalImage = editor?.current.getInstance().toDataURL({multiplier: 4});
			const { image } = await resizeBase64(originalImage, IMAGE_EXPORT_SIZE, IMAGE_EXPORT_SIZE)
			dispatch(updateInputImage(image));
		})

		if (onSave) onSave()
  };

	const { workspace } = useCurrentWorkspace();

  return (
    <span className="absolute right-4 bottom-4 flex flex-row gap-2 z-20">
      <Tooltip title="Undo" className="">
        <button
          className="bg-[#7E7E7E]/50 bg-opacity-50 p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group"
          onClick={handleRedoUndo}
        >
          <UndoIcon
            className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]  [&_path]:stroke-[#636162] group-hover:[&_path]:stroke-app-green`}
          />
        </button>
      </Tooltip>
      <Tooltip title="Redo" className="">
        <button
          className="bg-[#7E7E7E]/50 bg-opacity-50 p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group"
          onClick={handleRedoRedo}
        >
          <RedoIcon
            className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]  [&_path]:stroke-[#636162] group-hover:[&_path]:stroke-app-green`}
          />
        </button>
      </Tooltip>
			{workspace === 'text-workspace' && (
			 <Tooltip title="Save">
				 <button
          className="bg-[#7E7E7E]/50 bg-opacity-50 p-2 w-[32px] h-[32px] lg:p-0 flex items-center justify-center rounded-md cursor-pointer aspect-square group"
					 onClick={save}
				 >
					 <SaveEditor
							className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px]  [&_path]:stroke-[#636162] group-hover:[&_path]:stroke-app-green`}
					 />
				 </button>
			 </Tooltip>
			)}
    </span>
  );
};

export default RedoUndo;
