import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  setUserTokens,
  setUserTotalGenerations,
} from '../reducers/userReducer';

import Tooltip from './Tooltip';
import DownloadImage from '../services/ImageDownloader';

import { ReactComponent as ImageIcon } from '../assets/ImageIconGray.svg';
import { ReactComponent as EditIcon } from '../assets/EditIconGray.svg';
import { ReactComponent as DownloadIcon } from '../assets/DownloadIcon.svg';
import { ReactComponent as BookmarkIcon } from '../assets/BookmarkIconOutline.svg';
import { ReactComponent as TrashIcon } from '../assets/TrashIconLightGray.svg';
import { ReactComponent as CopyIcon } from '../assets/CopyIcon.svg';
import { ReactComponent as VectorizeIcon } from '../assets/VectorizeIcon.svg';
import { ReactComponent as ThumbsDownIcon } from '../assets/ThumbsDownOutline.svg';
import { ReactComponent as ThumbsUpIcon } from '../assets/ThumbsUpOutline.svg';
import { ReactComponent as MoreIcon } from '../assets/MoreIcon.svg';
import { ReactComponent as DDDIcon } from '../assets/3D-icon.svg';
import { ReactComponent as UpscaleIcon } from '../assets/upscale-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/CloseEditor.svg';
import {
  GENERATION_STATUS,
  bookmarkImage,
  deleteImage,
  setImageAsInspiration,
  showImageModal,
  submitFeedback,
  upscaleImage,
  vectorizeImage,
} from '../reducers/formReducer';
import SpinnerLoader from './base/SpinnerLoader';
import GeneratedImagePlaceholder from './GeneratedImagePlaceholder';

function GeneratedImage(props) {
  const { variant } = props;
  const [isDropdownDisplayed, setIsDropdownDisplayed] = useState(false);
  const [showVectorizeSpinner, setShowVectorizeSpinner] = useState(false);
  const [showUpscalerSpinner, setShowUpscalerSpinner] = useState(false);
  const dropdownAnchor = useRef();

  const user = useSelector((state) => state.userSlice.user);

  const dispatch = useDispatch();

  async function handleVectorizeDownload(e) {
    console.log(`[GeneratedImage] handleVectorizeDownload()`, {
      e,
      props,
    });
    setShowVectorizeSpinner(true);
    try {
      await dispatch(vectorizeImage(props.image)).unwrap();
    } catch (e) {
      toast(e.error?.detail || 'Something went wrong during vectorization', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowVectorizeSpinner(false);
  }
  function handleRasterDownload(e) {
    console.log(`[GeneratedImage] handleRasterDownload()`, {
      e,
      props,
    });
    DownloadImage.download('raster', props.image, {
      element: e,
      imageName: props.image.prompt,
    });
  }

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast('Prompt copied.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  // const downloadImage = (image) => {
  //   const a = document.createElement('a');
  //   a.href = image;
  //   a.download = `ld-download-${new Date().toString()}.png`;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //       'event': 'download',
  //       'attributes': {
  //           user: '{ user id here }',
  //           // method: '{ Can we grab the method from img meta here? gen/redesign/ddd }',
  //           // lodi_request: '{ preferably the lodi request attached to it (will filter this down later during optimisation) }',
  //           // a1_request: '{ same Q as above if avail}',
  //           // a1_response: '{ same Q as above if avail}',
  //       },
  //   });
  // }

  const handleCopyPrompt = (e) => {
    e.stopPropagation();
    copyToClipboard(props.image.prompt);
  };

  const handleUseAsInspiration = async (e) => {
    e.stopPropagation();
    try {
      await dispatch(
        setImageAsInspiration({
          imageUrl: props.image.imageUrl,
          method: 'sdxl',
        })
      ).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUseAsDDDInspiration = async (e) => {
    e.stopPropagation();
    try {
      await dispatch(
        setImageAsInspiration({
          imageUrl: props.image.imageUrl,
          method: 'sdxl-3d',
        })
      ).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpscale = async (e) => {
    e.stopPropagation();
    setShowUpscalerSpinner(true);
    try {
      await dispatch(upscaleImage(props.image)).unwrap();
    } catch (e) {
      console.log(e);

      toast('Failed to upscale image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowUpscalerSpinner(false);
  };

  const handleViewImage = () => {
    dispatch(showImageModal(props.image.imageUrl));
  };

  const handleBookmarkClicked = async () => {
    try {
      const resp = await dispatch(
        bookmarkImage({
          _id: props.image._id,
          bookmarked: !props.image.bookmarked,
        })
      ).unwrap();

      if (props.updateItemInList) props.updateItemInList(resp);
      toast('Image bookmarked', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    } catch (e) {
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleDeleteGeneration = async () => {
    try {
      await dispatch(deleteImage(props.image._id)).unwrap();
      if (props.deleteItemInList) props.deleteItemInList(props.image._id);
    } catch (e) {
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleFeedback = async (feedback) => {
    try {
      await dispatch(
        submitFeedback({
          id: props.image._id,
          feedback: feedback === props.image.feedback ? 0 : feedback,
        })
      ).unwrap();
    } catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to submit feedback', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  if (
    [GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(
      props.image.status
    ) > -1
  ) {
    return <GeneratedImagePlaceholder image={props.image} />;
  }
  if ([GENERATION_STATUS.FAILED].indexOf(props.image.status) > -1) {
    return (
      <div className='overflow-hidden  border border-dashed border-gray-600 bg-app-bg-gray flex items-center justify-center aspect-square rounded-2xl relative'>
        <div className='flex flex-col items-center justify-center z-10 gap-4'>
          <CloseIcon className='w-12 h-12' />

          <span className='text-xs text-center text-[#8A8A92]'>Failed!</span>
        </div>
      </div>
    );
  }

  return (
    <div className='relative group rounded-2xl transition-2 bg-app-bg-gray'>
      <div
        className='relative cursor-pointer'
        onClick={(e) => handleViewImage(props.image)}
      >
        <SpinnerLoader
          isLoading={showUpscalerSpinner || showVectorizeSpinner}
          icon={
            showVectorizeSpinner ? (
              <VectorizeIcon className='w-8 h-8' />
            ) : (
              <UpscaleIcon className='w-8 h-8' />
            )
          }
        />

        {variant !== 'small' && (
          <div className='hidden lg:flex absolute top-0 left-0 right-0 bottom-0 flex-col justify-between p-4 custom-overlay-gradient opacity-0 rounded-2xl group-hover:opacity-100 transition-opacity ease-in-out duration-400'>
            <div
              className='bg-app-bg-gray text-white font-semibold text-[10px] 2xl:text-[13px] flex justify-evenly mx-6 p-3 items-center rounded-2xl pointer-events-auto'
              onClick={(e) => e.stopPropagation()}
            >
              <span>How would you rate this result?</span>
              <button
                className='group/thumbsdown flex items-center justify-center group/thumbsdown'
                onClick={() => handleFeedback(-1)}
              >
                <ThumbsDownIcon
                  className={`${
                    props.image?.feedback < 0
                      ? '[&_path]:fill-dislike group-hover/thumbsdown:[&_path]:fill-dislike/50'
                      : '[&_path]:fill-active-icon group-hover/thumbsdown:[&_path]:fill-dislike'
                  }`}
                />
              </button>

              <button
                className='group/thumbsup flex items-center justify-center group/thumbsup'
                onClick={() => handleFeedback(1)}
              >
                <ThumbsUpIcon
                  className={`${
                    props.image?.feedback > 0
                      ? '[&_path]:fill-app-green group-hover/thumbsup:[&_path]:fill-app-green/50'
                      : '[&_path]:fill-active-icon group-hover/thumbsup:[&_path]:fill-app-green'
                  }`}
                />
              </button>
            </div>

            <div>
              <div className='flex mb-2 gap-2 pointer-events-auto'>
                <p className='text-white font-bold'>Prompt: </p>

                <Tooltip title='Copy prompt'>
                  <CopyIcon onClick={handleCopyPrompt} />
                </Tooltip>
              </div>

              <p className='text-gray-300 leading-6 text-xs'>
                {props.image.prompt}
              </p>
            </div>
          </div>
        )}

        <img
          src={props.image?.imageUrl}
          alt={props.image?.prompt}
          className='w-full aspect-square rounded-2xl'
        />
      </div>

      {variant !== 'small' && (
        <div className='py-2 px-4 lg:px-6 flex items-center justify-between relative'>
          <div className='flex items-center gap-4 lg:gap-4 xl:gap-6'>
            <Tooltip title='Use as inspiration'>
              <ImageIcon
                className='w-[1rem] h-[1rem] cursor-pointer [&>*]:fill-active-icon'
                onClick={handleUseAsInspiration}
              />
            </Tooltip>

            <Tooltip title='Use as 3D inspiration'>
              <DDDIcon
                className='w-[0.9rem] h-[0.9rem] cursor-pointer [&>*]:fill-active-icon'
                onClick={handleUseAsDDDInspiration}
              />
            </Tooltip>

            <Tooltip title='Download image' className='!hidden lg:!inline'>
              <DownloadIcon
                className='w-[0.8rem] h-[0.8rem] cursor-pointer [&>*]:fill-active-icon hidden lg:block'
                onClick={handleRasterDownload}
              />
            </Tooltip>

            <Tooltip
              title='Vectorize (5 credits)'
              className='!hidden lg:!inline'
            >
              <VectorizeIcon
                className='w-[0.9rem] h-[0.9rem] cursor-pointer [&>*]:fill-active-icon hidden lg:block'
                onClick={handleVectorizeDownload}
              />
            </Tooltip>

            <Tooltip title='Upscale (1 credit)' className='!hidden lg:!inline'>
              <UpscaleIcon
                className='w-[0.9rem] h-[0.9rem] cursor-pointer [&>*]:fill-active-icon hidden lg:block'
                onClick={handleUpscale}
              />
            </Tooltip>
          </div>

          <div className='flex items-center gap-3'>
            <Tooltip title='View more'>
              <MoreIcon
                onClick={(e) => setIsDropdownDisplayed(!isDropdownDisplayed)}
                ref={dropdownAnchor}
              />
            </Tooltip>
          </div>

          {isDropdownDisplayed ? (
            <div
              className='z-50 fixed inset-0'
              onClick={() => setIsDropdownDisplayed(false)}
            >
              <div
                className='absolute border border-[#44444F] bg-[#292932] pr-2 lg:pr-10 pl-4 py-3 rounded-2xl z-20 -translate-x-full'
                style={{
                  left:
                    dropdownAnchor.current?.getBoundingClientRect().right + 10,
                  top:
                    dropdownAnchor.current?.getBoundingClientRect().bottom + 14,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <ul className='text-white flex flex-col gap-1'>
                  <li className='flex gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75'>
                    <EditIcon className='w-4 h-4 cursor-pointer' /> Edit Image
                  </li>

                  <li
                    onClick={(e) => handleRasterDownload()}
                    className='flex lg:hidden gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75'
                  >
                    <DownloadIcon className='w-4 h-4 [&>*]:fill-active-icon' />{' '}
                    Download
                  </li>
                  <li
                    onClick={(e) => handleVectorizeDownload()}
                    className='flex lg:hidden gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75'
                  >
                    <VectorizeIcon className='w-4 h-4 [&>*]:fill-active-icon' />{' '}
                    Vectorize
                  </li>
                  <li
                    onClick={handleUpscale}
                    className='flex lg:hidden gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75'
                  >
                    <UpscaleIcon className='w-4 h-4 [&>*]:fill-active-icon' />{' '}
                    Upscale
                  </li>

                  <li
                    onClick={(e) => handleBookmarkClicked()}
                    className='flex gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75'
                  >
                    <BookmarkIcon className='w-4 h-4' /> Bookmark image
                  </li>
                  <li
                    onClick={(e) => handleDeleteGeneration()}
                    className='flex gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75'
                  >
                    <TrashIcon className='w-4 h-4' /> Delete image
                  </li>
                  <li
                    className='flex gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75 group/thumbsup'
                    role='botton'
                    onClick={(e) => handleFeedback(1)}
                  >
                    <ThumbsUpIcon
                      className={`h-4 w-4 ${
                        props.image?.feedback > 0
                          ? '[&_path]:fill-app-green group-hover/thumbsdown:[&_path]:fill-app-green/50'
                          : '[&_path]:fill-active-icon group-hover/thumbsdown:[&_path]:fill-app-green'
                      }`}
                    />{' '}
                    Good result
                  </li>
                  <li
                    className='flex gap-2 text-sm text-[#E2E2EA] items-center cursor-pointer hover:brightness-75 group/thumbsdown'
                    role='botton'
                    onClick={(e) => handleFeedback(-1)}
                  >
                    <ThumbsDownIcon
                      className={`h-4 w-4 ${
                        props.image?.feedback < 0
                          ? '[&_path]:fill-dislike group-hover/thumbsup:[&_path]:fill-dislike/50'
                          : '[&_path]:fill-active-icon group-hover/thumbsup:[&_path]:fill-dislike'
                      }`}
                    />{' '}
                    Bad result
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default GeneratedImage;
