import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser, registerUser } from '../reducers/userReducer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { ReactComponent as Logo } from '../assets/logo.svg';

const apiHost = 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';

function Register() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(function() {
    axios.get(`${apiHost}/api/users/${params.userId}`)
      .then(res => res.data)
      .then(userData => {
        setEmail(userData.email);
      })
      .catch(err => {
        console.log(err)
      });
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();

  if(!name) return toast('Name is required.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark',
      autoClose: 2000
    });

  if(!password) return toast('Password is required.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark',
      autoClose: 2000
    });

    try {
      await axios.post(`${apiHost}/auth/finish-registration`, {
        id: params.userId,
        name,
        password
      });

      const loginRes = await dispatch(loginUser({ email, password })).unwrap();

      localStorage.setItem('userInfo', JSON.stringify({ id: loginRes.id, refreshToken: loginRes.refreshToken }));

      navigate('/');
    } catch (error) {
      console.log('error: ', error);
      toast('Something went wrong, please try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }
  };
  
  return (
    <div>
      <div className='max-w-[360px] w-[90%] mx-auto rounded-2xl flex flex-col justify-center min-h-screen'>
        <Helmet>
          <title>Complete registration | Logo Diffusion</title>
        </Helmet>

        <div className='font-bold text-xl text-white flex gap-2 items-center justify-center mb-8'>
            <Logo />
            Logo Diffusion
            <span className='text-xs text-black bg-app-green font-normal p-1 px-2 rounded-md ml-2'>
              v4.0
            </span>
          </div>

        <div className='bg-app-bg-gray text-white text-center rounded-2xl'>
          <div className='p-4'>
            <h1 className='font-bold text-white text-lg mb-6'>Finish setting up your account</h1>

            <form className='flex flex-col'>
              <input
                type="email"
                placeholder='Your email'
                className='bg-app-search-gray mb-3 p-2 rounded-md'
                value={email}
                disabled
                onChange={e => setEmail(e.target.value)}
              />

              <input
                type="text"
                placeholder='Your name'
                className='bg-app-search-gray mb-3 p-2 rounded-md'
                value={name}
                onChange={e => setName(e.target.value)}
              />

              <input
                type="password"
                placeholder='Create password'
                className='bg-app-search-gray mb-3 p-2 rounded-md'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <p className='text-sm mt-2 mb-4'>
                By signing up, you confirm that you’ve read and accepted our User Notice and Privacy Policy.
              </p>

              <button
                type="submit"
                className='bg-blue-600 text-white font-bold w-full rounded-md p-2 mb-2 text-sm'
                onClick={handleRegister}
              >
                Finish setup
              </button>
            </form>
          </div>

          <div className='bg-app-search-gray rounded-b-2xl text-sm p-4'>
            Already have an account? <Link to="/login" className="text-blue-600">Login</Link>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Register;
