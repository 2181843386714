import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import GeneratedImage from '../GeneratedImage';
import { ReactComponent as LoadingDots } from '../../assets/LoadingDots.svg';
import { toast } from 'react-toastify';
import {
    GENERATION_STATUS,
  loadGenerationHistory,
  selectActiveHistoryGeneration,
  updateLastGenerationId,
} from '../../reducers/formReducer';
import throttle from 'lodash/throttle';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import GeneratedImagePlaceholderV4 from '../GeneratedImagePlaceholderV4';
import { FixedSizeContainerWRapper } from '../image-to-image/V4ContainerContext';


const LoadingPlaceholder = (props) => {
	const { onClick, generatedImage } = props;

  const [timeElapsed, setTimeElapsed] = useState(() => {
		const x = (Date.now() - generatedImage?._startTime) / 1000;

		if (isNaN(x)) return 0

		return Math.floor(x)
	});

  const generationStatus = useSelector((state) => state.form.generationStatus);
  const isLoading = useMemo(() => {
    if (
      generatedImage?.status &&
      [GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(
        generatedImage.status
      ) > -1
    ) {
      return true;
    }
    return (
      [GENERATION_STATUS.IN_QUEUE, GENERATION_STATUS.GENERATING].indexOf(
        generationStatus
      ) > -1
    );
  }, [generationStatus, generatedImage]);

  const timer = useRef(null);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setTimeElapsed((t) => {
					const x = (Date.now() - generatedImage?._startTime) / 1000;

					if (isNaN(x)) return t

					return Math.floor(x)
				})
      }, 1000);

      timer.current = interval;
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
      setTimeElapsed(0);
    };
  }, [isLoading, generatedImage?._startTime]);

  const calculateLoaderPercentage = (timeElapsed) => {
    if (timeElapsed < 1) return 100;

    const calculateVal = (n) =>
      [...Array(n)].reduce((a, b, ind) => a + 1 / (ind + 1), 0);

    const rate =
      1 -
      (calculateVal(timeElapsed + 5) - calculateVal(3)) /
        (calculateVal(65) - calculateVal(3));

    return rate * 100;
  };

	return (
		<div
			className={`overflow-hidden flex-shrink-0 !w-[62px] !h-[62px] flex items-center justify-center aspect-square rounded-md 2xl:rounded-2xl relative bg-[#222229] border border-app-search-gray`}
			role="button"
			onClick={onClick}
		>
			{generatedImage?.generationId && !generatedImage?._id &&
				(<div
					className='absolute top-0 bottom-0 right-20 left-0 bg-app-search-gray transition-all duration-300 roundedd-l-md 2xl:rounded-l-2xl'
					style={{ right: `${calculateLoaderPercentage(timeElapsed)}%` }}
				></div>
			)}

			<div className={`m-2 v4loader`}></div>
		</div>
	);
}

const V4GenerationHistory = forwardRef((props, ref) => {
  const store = useStore();

  const toGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );
  const showPlaceholders = useSelector((state) => state.form.showPlaceholders);
  const _generations = useSelector((state) => state.form.generations);

  const generations = useMemo(
    () => _generations,
    [_generations, showPlaceholders, toGenerate]
  );

  const hasMoreToLoad = useSelector(
    (state) => state.form.hasMoreGenerationsToLoad
  );

  const dispatch = useDispatch();

  const loadingAnchor = useRef();

  const loadMore = throttle(async () => {
    try {
      await dispatch(loadGenerationHistory()).unwrap();
    } catch (e) {
      console.log(e);
      toast('Failed to load generation history', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  }, 5000);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log('Intersection changed');
        if (
          store.getState().userSlice.user &&
          entries[0].isIntersecting &&
          store.getState().form.hasMoreGenerationsToLoad &&
          !store.getState().form.isLoadingGenerations
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (loadingAnchor.current) {
      observer.observe(loadingAnchor.current);
    }

    return () => {
      if (loadingAnchor.current) {
        observer.unobserve(loadingAnchor.current);
      }
    };
  }, [loadingAnchor, store, loadMore]);
  const activeHistoryGeneration = useSelector(
    (state) => state.form?.activeHistoryGeneration
  );

  const handleSelectHistoryGeneration = (value) => {
    if (value._id) dispatch(selectActiveHistoryGeneration(value._id));
    dispatch(updateLastGenerationId(value.generationId));
  };

  const { workspace } = useCurrentWorkspace();

  return (
    <div
      className={`h-full w-full bg-app-bg-gray max-2xl:rounded-t-2xl 2xl:rounded-l-2xl ${
        workspace === 'image-workspace'
          ? 'flex-row 2xl:flex-col overflow-x-auto overflow-y-hidden 2xl:overflow-y-auto 2xl:overflow-x-hidden'
          : 'flex-col  overflow-x-hidden overflow-y-auto'
      } flex gap-4  custom-scroll p-3`}
      ref={ref}
    >
      <div
        className={`text-sm text-white font-semibold font-sans-pro  ${
          workspace === 'image-workspace'
            ? 'rotate-180 2xl:rotate-0 max-2xl:pt-3 max-2xl:[writing-mode:vertical-lr]'
            : ''
        } `}
      >
        History
      </div>
      {generations.map((generatedImage, index) => (
        generatedImage.imageUrl ? (
					<img
						className={`relative group rounded-2xl transition-2 bg-app-bg-gray w-[62px] h-[62px] ${
							activeHistoryGeneration === generatedImage._id
								? 'border-app-green border-[2px]'
								: ''
						}`}
						src={generatedImage.imageUrl}
						alt={generatedImage.imageUrl}
						key={generatedImage.imageUrl}
						role="button"
						onClick={() => handleSelectHistoryGeneration(generatedImage)}
					/>
				) : (
					<LoadingPlaceholder 
						onClick={() => handleSelectHistoryGeneration(generatedImage)}
						generatedImage={generatedImage}
					/>
				)
      ))}

      <div
        ref={loadingAnchor}
        className='p-2 px-3 flex items-center justify-center 2xl:max-w-full '
      >
        {/* {page >= totalPages ? '' : 'Loading...'} */}
        {hasMoreToLoad && (
          <span
            className='flex items-center justify-center'
            id='button-loading'
          >
            <LoadingDots className='h-2' />
          </span>
        )}
      </div>
    </div>
  );
});

export default V4GenerationHistory;
