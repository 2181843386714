import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const BrushSizePreview = (props) => {
  const { editor, editorContainer } = props;

  const currentTool = useSelector((state) => state.imageEditor.tool);
  const activeColor = useSelector((state) => state.imageEditor.activeColor);
  const brushSize = useSelector((state) => state.imageEditor.brushSize);
  const zoom = useSelector((state) => state.imageEditor.zoom);


  const [position, setPosition] = useState({ x: 0, y: 0 });


  useEffect(() => {
		const handleBrushSizePreview = (event) => {
			const container = editorContainer.current.getBoundingClientRect()
			setPosition({
				x: Math.min(container.width, Math.floor(Math.max(container.left, event.clientX) - container.left)),
				y: Math.min(container.height, Math.floor(Math.max(container.top, event.clientY) - container.top)),
			})
		}
    if (editorContainer.current && currentTool === 'FREE_DRAWING') {
      editorContainer.current.addEventListener('mousemove', handleBrushSizePreview)
    }

    return () => {
      if (editorContainer.current && currentTool === 'FREE_DRAWING') {
        editorContainer.current.removeEventListener('mousemove', handleBrushSizePreview)
      }
    }
  }, [currentTool, editorContainer])

  if (currentTool !== "FREE_DRAWING") return <></>

  // console.log(editorContainer.current.getBoundingClientRect().width, editor?.current.getInstance().getCanvasSize().width, editorContainer.current.getBoundingClientRect().width / editor?.current.getInstance().getCanvasSize().width)

  return (
    <div className='absolute aspect-square rounded-full border-dashed border-2 -translate-x-1/2 -translate-y-1/2 pointer-events-none' style={{
      width: `${brushSize * zoom}px`,
      height: `${brushSize * zoom}px`,
      borderColor: activeColor,
      left: `${position.x}px`,
      top: `${position.y}px`,
    }}></div>
  )
}

export default BrushSizePreview
