import { Helmet } from 'react-helmet';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Tracking from '../services/TrackingService';
import UserLayout from '../layouts/UserLayout';
import Modal from '../components/Modal';
import ImageViewer from '../components/ImageViewer';
// import { setUserTokens, setUserTotalGenerations } from '../reducers/userReducer';

import { setUserTokens } from '../reducers/userReducer';
import environment from '../helpers/envProvider';
import { ReactComponent as LoadingDots } from '../assets/LoadingDots.svg';
import { showImageModal } from '../reducers/formReducer';
import authAxiosInstance from '../helpers/auth-axios';
import { useMemo } from 'react';

const TempVar_API = environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';
// ${TempVar_API}
function History() {
  const [history, setHistory] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [viewingImage, setViewingImage] = useState(null);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(50)
	const [totalPages, setTotalPages] = useState(1);
	const [viewingImageIndex, setViewingImageIndex] = useState(null);
	const [loading, setLoading] = useState(false)
	const loadingAnchor = useRef();

  const user = useSelector(state => state.userSlice.user);

  const dispatch = useDispatch();

	useEffect(() => {
		async function fetchData() {
			if(user) {
				const res = await authAxiosInstance.get(`/api/generation/by-user?userId=${user._id}&range=[${(page - 1) * perPage},${page * perPage}]`);

        const generationsByDay = {};

        setGeneratedImages(res.data.docs);
				setTotalPages(res.data.totalPages);

        res.data.docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).forEach(doc => {
          const docDate = new Date(doc.createdAt);
          const dateKey = `${docDate.getFullYear()}.${docDate.getMonth() + 1}.${docDate.getDate()}`;

          generationsByDay[dateKey] = generationsByDay[dateKey] || [];
          generationsByDay[dateKey].push(doc);
        });

				setHistory(generationsByDay);
			}
		}
		
		fetchData();
	}, [user]);

  const handleViewImage = (image) => {
		dispatch(showImageModal(image.imageUrl))
    // setViewingImage(image);
    // setIsImageViewerOpen(true);
		// setViewingImageIndex(generatedImages.findIndex(gen => gen._id === image._id));
  }

	const loadPage = async (page) => {
		setLoading(true)
		try {
			const res = await authAxiosInstance.get(`/api/generation/by-user?userId=${user._id}&range=[${(page - 1) * perPage},${page * perPage}]`);	

			const generationsByDay = {};

			setPage(page);
			setGeneratedImages([...generatedImages, ...res.data.docs]);
			setTotalPages(res.data.totalPages);

			[...generatedImages, ...res.data.docs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).forEach(doc => {
				const docDate = new Date(doc.createdAt);
				const dateKey = `${docDate.getFullYear()}.${docDate.getMonth() + 1}.${docDate.getDate()}`;

				generationsByDay[dateKey] = generationsByDay[dateKey] || [];
				generationsByDay[dateKey].push(doc);
			});

			setHistory(generationsByDay);
		} catch (error) {
			toast('Something went wrong, please try again', {
				position: toast.POSITION.BOTTOM_RIGHT,
				theme: 'dark',
				autoClose: 2000
			});
		}
		setLoading(false)
	}

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				console.log("Intersection changed");
				if (entries[0].isIntersecting && page < totalPages && !loading) {
					loadPage(page + 1)
				}
			},
			{ threshold: 1 }
		);

		if (loadingAnchor.current) {
			observer.observe(loadingAnchor.current);
		}

		return () => {
			if (loadingAnchor.current) {
				observer.unobserve(loadingAnchor.current);
			}
		};
	}, [loadingAnchor, totalPages, page, loading]);

	const handleUpdateItem = (updatedItem) => {
		setGeneratedImages(generatedImages.map(item => item._id === updatedItem._id ? {...item, ...updatedItem} : item))
	}
	const handleDeleteItem = (deletedItem) => {
		setGeneratedImages(g => g.filter(item => item._id !== deletedItem._id))

		setHistory((h) => {

			const docDate = new Date(deletedItem.createdAt);
			const dateKey = `${docDate.getFullYear()}.${docDate.getMonth() + 1}.${docDate.getDate()}`;
			return {
				...h,
				[dateKey]: (h[dateKey] || []).filter(item => item._id !== deletedItem._id),
			};
		})
	}

	const historyDays = useMemo(() => {
		console.log(Object.keys(history).map(x => new Date(x)));
		return Object.keys(history).sort((a, b) => new Date(a) - new Date(b)).reverse().filter(d => history[d].length > 0)
	}, [history])


  return (
    <UserLayout>
      <Helmet>
        <title>History | Logo Diffusion</title>
      </Helmet>

			<ImageViewer
				generatedImages={generatedImages}
				updateItemInList={handleUpdateItem}
				deleteItemInList={handleDeleteItem}
			/>

      <div className='px-12 py-6 overflow-auto'>
        <h1 className='text-white text-xl font-bold mb-6'>
          Your Image generation history
        </h1>

        <div className='bg-app-bg-gray rounded-lg p-6'>
          {historyDays.map(dayKey => (
            <div key={dayKey}>
              <h2 className='text-gray-300 font-bold my-6'>{dayKey.split('.').reverse().join('.')}</h2>

              <div className='flex gap-4 flex-wrap '>
                {history[dayKey].map(image => (
                  <img className="cursor-pointer rounded-[20px] aspect-square w-[70px] md:w-[100px] xl:w-[150px] xxl:w-[225px]" src={image.imageUrl} alt={image.prompt} onClick={e => handleViewImage(image)} key={image.imageUrl} />
                ))}
              </div>
            </div>
          ))}

					<div ref={loadingAnchor} className='p-2 px-3 flex items-center justify-center bg-w'>
						{/* {page >= totalPages ? '' : 'Loading...'} */}
						{page < totalPages && (
							<span
								className="flex items-center justify-center"
								id="button-loading"
							>
								<LoadingDots className='h-2'/>
							</span>
						)}
					</div>

          {/* <div className="flex justify-center gap-[20px] items-center">
            <button
							className={`${page === 1 ? 'bg-gray-800 text-gray-500 cursor-not-allowed' : 'bg-ld-purple text-white' } p-3 px-24 rounded-md font-bold`}
							onClick={e => handleSetPage(page - 1)}
						>
              Previous
            </button>

						<span className="text-white font-bold">{page}/{totalPages}</span>

            <button
							className={`${page === totalPages ? 'bg-gray-800 text-gray-500 cursor-not-allowed' : 'bg-ld-purple text-white' } p-3 px-24 rounded-md font-bold`}
							onClick={e => handleSetPage(page + 1)}
						>
              Next
            </button>
          </div> */}
        </div>
      </div>
    </UserLayout>
  );
}

export default History;
