import React from 'react';
import { Tooltip } from 'react-tippy';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import RangeInput from '../RangeInput';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import CustomSwitch from './Switch';
import { Switch } from '@headlessui/react';

const Slider = (props) => {
  const {
    value,
		displayValue,
    onChange,
    title,
    disabled,
    label,
    rangeInputProps = {},
    appliedClasses = {},
    showValue,

		disablable,
		onDisabled,

		otherControls,
  } = props;
  const { mode } = useGenerationEndpoint();
  return (
    <div className={`flex justify-between items-center ${appliedClasses.main}`}>
      {/* {mode !== 'v4.0' && ( */}
      <label
        className={`text-gray-label font-poppins items-center font-bold text-xs flex gap-1 ${
          disabled ? 'text-select-label-disabled' : ''
        } ${appliedClasses.label}`}
      >
        {label}
        <Tooltip title={title}>
          <InfoIcon />
        </Tooltip>
				{disablable && (
					<>
						<div className="grow" />
						<Switch
							checked={!disabled}
							className={`group mb-1 ui-checked:bg-app-green ui-not-checked:bg-app-bg-gray relative inline-flex h-5 w-10 items-center rounded-full`}
							onChange={() => onDisabled(!disabled)}
						>
							<span
								className={`translate-x-1 ui-checked:translate-x-6 bg-white inline-block h-[14px] w-[14px] transform rounded-full transition`}
							/>
						</Switch>
					</>
				)}
				{otherControls}
      </label>
      {/* )} */}
      <div
        className={`w-1/2 flex flex-row shrink-0 grow-0 gap-2 ${
          disabled ? 'opacity-5' : ''
        } ${appliedClasses.rangeinput}`}
      >
        <RangeInput
          {...rangeInputProps}
					disabled={disabled}
          values={value}
          onRangeChange={onChange}
        />
        {showValue && (
          <div
            className={`text-deactive text-xs px-1 rounded-sm bg-app-bg-gray self-center ${appliedClasses.value}`}
          >
            {displayValue ?? value}
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;
