import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import UserLayout from "../layouts/UserLayout";
import Modal from "../components/Modal";
import PricingPlan from "../components/PricingPlan";
import GetMoreCreditsForm from "../components/GetMoreCreditsForm";
import ProfileInfoForm from "../components/ProfileInfoForm";
import ResetPasswordForm from "../components/ResetPasswordForm";

import ProfileImage from "../assets/ProfileImage.png";

import { ReactComponent as ProfileCircleIcon } from "../assets/ProfileCircleIcon.svg";
import { ReactComponent as LockIcon } from "../assets/LockIcon.svg";
import { ReactComponent as TrashIcon } from "../assets/TrashIconGray.svg";
import { ReactComponent as ActiveIcon } from "../assets/profile/active-icon.svg";

import plans from "../data/plans";
import { cancelSubscription, getStripeManagementUrl, getSumolingLicense } from "../reducers/userReducer";
import moment from "moment";
import { toast } from "react-toastify";
import SumolingPlans from "../data/sumoling-plans";
import environment from "../helpers/envProvider";
import { useSearchParams } from "react-router-dom";
import calculateNextBillingPeriod from "../helpers/calculateNextBillingPeriod";
import DeleteAccountModal from "../components/DeleteAccountModal";

function Profile() {
  const user = useSelector((state) => state.userSlice.user);
  const sumolingLicense = useSelector((state) => state.userSlice.sumolingLicense);
  const [sumolingLicenseLoaded, setSumolingLicenseLoaded] = useState(false);
  const dispatch = useDispatch();

  const [isProfileInfoOpen, setIsProfileInfoOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isGetMoreCreditsModalOpen, setIsGetMoreCreditsModalOpen] =
    useState(false);
  const [billingPeriod, setBillingPeriod] = useState("Monthly");
  const [showCancelationModal, setShowCancelationModal] = useState(false);

  const [searchParams] = useSearchParams();

  const getUsername = () => {
    if (user?.name) return user.name;
    if (user?.googleNames?.length > 0) return user.googleNames[0];
    if (user?.discordUsername)
      return `${user.discordUsername}#${user.discordDiscriminator}`;
  };

  const getProfileImage = () => {
    if (user?.googlePhoto) return user?.googlePhoto;
    if (user?.discordId && user?.discordAvatar)
      return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.discordAvatar}.png`;
    return ProfileImage;
  };

  const getSumolingData = async () => {
    try {
      await dispatch(getSumolingLicense()).unwrap();
      setSumolingLicenseLoaded(true)
    } catch(e) {
      console.log(e);
      
      toast(e.error?.message || e.error || "Something went wrong while activating Sumoling's license", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }
  }

	const userPlan = useMemo(() => {
    if (user?.isSumoling) 
				return user?.appsumoLicense;

		return user?.plan
	}, [user, sumolingLicense])


  const currentPlan = useMemo(() => {
    if (user?.isSumoling) {

      return SumolingPlans.find(
        (val) =>
          user?.appsumoLicense?.productId === val.plan_id ||
          sumolingLicense?.productId === val.plan_id
      );
    }
    if (!user?.plan) {
      return plans[0]
    }
    return plans.find(
      (val) =>
        user?.plan?.productId === val.monthlyPriceId ||
        user?.plan?.productId === val.yearlyPriceId
    );
  }, [user, sumolingLicense]);

	const planStatus = useMemo(() => {
		if (user?.plan?.cancelAtEndOfPeriod) {
			return { title: 'Canceling', style: 'text-amber-400 bg-amber-400/20' }
		}

		return { title: (<>
				<ActiveIcon /> active
			</>), style: 'text-tag-green bg-tag-green/20' 
		}
	}, [user]);

  const handleCancelSubscription = async () => {
    try {
      await dispatch(cancelSubscription()).unwrap();
      toast("Subscription canceled.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
      setShowCancelationModal(false);
    } catch (e) {
      toast(e.response?.data?.details || "Failed to cancel subscription", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

	const getSubscriptionManagementLink = async () => {
		try {
			const resp = await dispatch(getStripeManagementUrl()).unwrap();
			
      window.open(resp.redirect_url, '_self');
		} catch(e) {
			console.log(e);
      toast(e.response?.data?.details || "Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
		}
	}

  useEffect(() => {
    if (user?.isSumoling && !sumolingLicenseLoaded) {
      getSumolingData();
    }
  }, [user, sumolingLicenseLoaded]);


	const [showBuyCreditHelper, setShowBuyCreditHelper] = useState(false);
	const [buyCreditHighlighterStyles, setBuyCreditHighlighterStyles] = useState({})
	const buyMoreCreditRef = useRef(null);
	useEffect(() => {
		let addListeners = false;
		const onResize = (e) => {
			setBuyCreditHighlighterStyles(() => {
				const boundingRect = buyMoreCreditRef.current?.getBoundingClientRect();
				const padding = 8;
				console.log(boundingRect);
				return {
					width: boundingRect.width + padding * 2,
					height: boundingRect.height + padding * 2,

					top: boundingRect.top - padding,
					left: boundingRect.left - padding,
				}
			})
		}


		if (searchParams.get('showBuyCredits')) {
			setShowBuyCreditHelper(true);
			addListeners = true;
			onResize();
			window.addEventListener('resize', onResize);
		}

		return () => {
			if (addListeners) {
				window.removeEventListener('resize', onResize);
			}
		}
	}, [searchParams, buyMoreCreditRef]);

	const [deleteAccountStage, setDeleteAccountStage] = useState(0);

  return (
    <UserLayout>
      <Helmet>
        <title>Profile | Logo Diffusion</title>
      </Helmet>

      <Modal isOpen={isProfileInfoOpen}>
        <ProfileInfoForm handleClose={(e) => setIsProfileInfoOpen(false)} />
      </Modal>

      <Modal isOpen={isChangePasswordOpen}>
        <ResetPasswordForm
          handleClose={(e) => setIsChangePasswordOpen(false)}
        />
      </Modal>

			<DeleteAccountModal
				stage={deleteAccountStage}
				setStage={setDeleteAccountStage}
			/>

      <Modal
        isOpen={isGetMoreCreditsModalOpen}
        onClose={(e) => setIsGetMoreCreditsModalOpen(false)}
      >
        <GetMoreCreditsForm
          initialCredits={160}
          costPerCredit={0.025}
          onClose={(e) => setIsGetMoreCreditsModalOpen(false)}
        />
      </Modal>

      <Modal
        isOpen={showCancelationModal}
        onClose={(e) => setShowCancelationModal(false)}
      >
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          className="bg-app-bg-gray rounded-lg p-4 px-12 w-5/12 text-center"
          onClick={(e) => e.stopPropagation()}
        >
          <p className="font-bold text-xl text-white mb-8">
            Are you sure you want to cancel your subscription?
          </p>

          <div className="flex items-center justify-center gap-3">
            <button
							type="reset"
              className="text-gray-400 text-xs cursor-pointer "
              onClick={() => setShowCancelationModal(false)}
            >
              Go back
            </button>
            {user?.isSumoling ? sumolingLicense?.invoices && (
              <a
                className="text-ld-purple border-ld-purple border border-solid p-1 px-4 text-xs rounded-md "
                href={`https://appsumo.com/account/redemption/${sumolingLicense?.invoices[sumolingLicense?.invoices.length - 1]}#refund-instructions`}
              >
                Cancel Subscription
              </a>
            ) : (
							<>
								<button
									type="submit"
									className="text-ld-purple border-ld-purple border border-solid p-1 px-4 text-xs rounded-md "
									onClick={handleCancelSubscription}
								>
									Cancel Subscription
								</button>
							</>
            )}
          </div>
        </div>
      </Modal>

      <div className="flex gap-4 p-6 px-12 items-center">
        <img
          src={getProfileImage()}
          referrerPolicy="no-referrer"
          className="rounded-full h-24 border-4 border-white"
          alt="user profile"
        />

        <div className="flex flex-col gap-1">
          <h1 className="text-white font-bold text-xl">{getUsername()}</h1>
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={(e) => setIsProfileInfoOpen(!isProfileInfoOpen)}
          >
            <ProfileCircleIcon className="h-4" />
            <p className="text-gray-500 text-sm">Update profile info</p>
          </div>

					<div className="flex gap-2">
						<button
							className="flex gap-2 items-center cursor-pointer"
							onClick={(e) => setIsChangePasswordOpen(!isChangePasswordOpen)}
						>
							<LockIcon className="h-4" />
							<p className="text-gray-500 text-sm">Change Password</p>
						</button>
						|
						<button
							className="flex gap-2 items-center cursor-pointer text-fade-text"
							onClick={(e) => setDeleteAccountStage(1)}
						>
							<TrashIcon className="h-4 [&>*]:fill-fade-text" />
							<p className="text-sm">Delete Account</p>
						</button>

					</div>
        </div>
      </div>

      <div className="flex flex-col gap-6 px-12">
				
				<div className="flex flex-col gap-4">
					<div className="font-bold font-sans text-xl text-white">
						Your Plan: 
						<span className="text-ld-purple text-base mx-1" >
							{currentPlan?.name || "Free"}
						</span>
						<span className={`mx-1 px-3 py-1 uppercase text-xs rounded-full inline-flex items-center gap-1 ${planStatus.style}`}>
							{planStatus.title}
						</span>
					</div>

					<div className="flex gap-8 ">
						<div className="flex flex-col gap-4 bg-app-bg-gray border border-solid border-profile-box-border [box-shadow:0px_2px_4px_-2px_rgba(0,0,0,0.30),0px_4px_6px_-1px_rgba(0,0,0,0.30)] p-6 rounded-xl basis-5/12 grow">
							<div className="text-profile-box-title text-lg font-bold font-sans-pro capitalize">
								Usage Details
							</div>

							<div className="flex flex-col text-profile-box-title font-normal gap-1">
								<div className="flex justify-between font-bold">
									<span>Credits:</span>
									<span className="font-bold">{user?.remainingCredits} / {currentPlan?.credits}</span>
								</div>
								{user?.individualCredits > 0 && (user?.plan?.active || user?.isSumoling) && (
									<div className="flex justify-between font-bold capitalize">
										<span>Non-subscription credits:</span>
										<span className="font-bold">{user?.individualCredits}</span>
									</div>
								)}
								<div className="flex justify-between">
									<span>Vectorization Jobs:</span>
									<span className="">{user?.paidFeatureUsage?.vectorize || 0} / {currentPlan?.paidFeatureUsage?.vectorize}</span>
								</div>
								<div className="flex justify-between">
									<span>Background Removal:</span>
									<span className="">{user?.paidFeatureUsage?.['remove-bg'] || 0} / {currentPlan?.paidFeatureUsage?.['remove-bg']}</span>
								</div>

								<div className="flex justify-between">
									<span>Upscale 2x:</span>
									<span className="">{user?.paidFeatureUsage?.['upscale-2x'] || 0} / {currentPlan?.paidFeatureUsage?.['upscale-2x']}</span>
								</div>

								<div className="flex justify-between">
									<span>Upscale 4x:</span>
									<span className="">{user?.paidFeatureUsage?.['upscale-4x'] || 0} / {currentPlan?.paidFeatureUsage?.['upscale-4x']}</span>
								</div>
								{user?.isSumoling && (
									<div className="flex justify-between">
										<span>1K images:</span>
										<span className="">{user?.paidFeatureUsage?.['high-res'] || 0} / {currentPlan?.paidFeatureUsage?.['high-res']}</span>
									</div>
								)}
								{userPlan?.renewsOn && (
									<div className="font-bold text-tag-green ">
										Credits reset on {' '}
										{calculateNextBillingPeriod(userPlan?.renewsOn).format("MMMM Do")}
									</div>
								)}
							</div>

							<div className="flex gap-6 pt-1">
								<button
									type="submit"
									ref={buyMoreCreditRef}
									className="disabled:cursor-not-allowed basis-5/12 grow bg-profile-box-btn p-2 text-white font-normal rounded-md"
									onClick={e => setIsGetMoreCreditsModalOpen(true)}
								>
									Buy More Credits
								</button>

							</div>
						</div>

						{showBuyCreditHelper && (
							<div 
								className="rounded-lg fixed pointer-events-none border-4 p-4 text-xl border-solid highlighter-values highlighter "
								style={buyCreditHighlighterStyles}
							>
							</div>
						)}

						<div className="flex flex-col gap-4 bg-app-bg-gray border border-solid border-profile-box-border [box-shadow:0px_2px_4px_-2px_rgba(0,0,0,0.30),0px_4px_6px_-1px_rgba(0,0,0,0.30)] p-6 rounded-xl basis-5/12 grow">
							<div className="text-profile-box-title text-lg font-bold font-sans-pro capitalize">
								Usage Details
							</div>

							<div className="flex flex-col text-profile-box-title font-normal">
								<div className="flex justify-between">
									<span>Price:</span>
									<span className="font-bold">
										{ user?.isSumoling ? null : (
											user?.plan?.isYearly ? 
												`${currentPlan?.priceYearly}$ / yr` :
												`${currentPlan?.priceMonthly}$ / mo`
										)}
									</span>
								</div>
								<div className="flex justify-between">
									<span>Billing Period:</span>
									<span className="">{
										user?.isSumoling ? "Lifetime" : (
											user?.plan?.isYearly ? "Yearly" : "Monthly"
										)
									}</span>
								</div>
								{!user?.isSumoling && (
									<div className="flex justify-between">
										<span>{user?.plan?.cancelAtEndOfPeriod ? 'Canceling on' : 'Next Invoice'}</span>
										<span className="">
											{moment(user?.plan?.renewsOn).format("MMMM Do")}
										</span>
									</div>
								)}
							</div>
							
							<div className="flex items-end gap-6 pt-6 grow">
								{!user?.isSumoling ? (
									<button
										type="submit"
										className="disabled:cursor-not-allowed basis-5/12 grow bg-profile-box-btn p-2 text-white font-normal rounded-md capitalize"
										onClick={getSubscriptionManagementLink}
									>
										Subscription management and invoices
									</button>
								) : (
									<a
										className="disabled:cursor-not-allowed basis-5/12 grow bg-profile-box-btn p-2 text-white font-normal rounded-md text-center capitalize"
										href={`https://appsumo.com/account/redemption/${sumolingLicense?.invoices[sumolingLicense?.invoices.length - 1]}`}
									>
										Subscription management and invoices
									</a>
								)}
							</div>
						</div>
					</div>
				</div>

        <div className="rounded-lg p-6 pt-15 mb-12">
          {!user?.isSumoling && (
            <div className="flex justify-center mb-[32px]">
              <div className="flex p-[2px] bg-[#0B0B0E] w-[512px] h-[44px] rounded-[20px]">
                <button
									type="button"
                  className={`${
                    billingPeriod === "Yearly"
                      ? "bg-ld-purple text-white"
                      : "text-[#A0AEC0]"
                  } rounded-[20px]  w-1/2 xs:w-[264px] flex items-center justify-center cursor-pointer font-bold`}
                  id="pay-yearly-period"
                  onClick={(e) => setBillingPeriod("Yearly")}
                >
                  <p className="font-inter text-[14px] leading-[20px] flex items-center gap-[4px]">
                    Pay Yearly
                    <span
                      className="px-[8px] py-[2px] rounded-[20px] text-[12px] leading-[20px] bg-white bg-opacity-[12%] hidden xs:inline"
                      id="pay-yearly-sub"
                    >
                      GET 2 MONTHS FREE
                    </span>
                  </p>
                </button>

                <button
									type="button"
                  className={`${
                    billingPeriod === "Monthly"
                      ? "bg-ld-purple text-white"
                      : "text-[#A0AEC0]"
                  } flex items-center justify-center grow cursor-pointer rounded-[20px]`}
                  onClick={(e) => setBillingPeriod("Monthly")}
                >
                  <p className="font-inter">Pay Monthly</p>
                </button>
              </div>
            </div>
          )}

          {!user?.isSumoling && (
            <div className="grid grid-cols-2 2xl:grid-cols-4 gap-8 mb-8">
              {plans.map((plan, ind) => (
                <PricingPlan
                  plan={plan}
									planInd={ind}
                  billingPeriod={billingPeriod}
									onCancel={() => setShowCancelationModal(true)}
                  key={`pricing-plan-${plan.name}`}
                />
              ))}
            </div>
          )}

          <div className="bg-[#1C1C24] rounded-lg p-6">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <p className="text-lg font-bold text-white">Enterprise</p>
                <p className="text-gray-400 text-sm">
                  Custom trained models, Dedicated 24/7 GPU servers, tailored
                  specifically to your business and workflow.
                </p>
              </div>

              <a
                href="https://logodiffusion.com/contact.html"
                rel="noreferrer"
                target="_blank"
                className="bg-ld-purple rounded-md p-2 px-6 text-white"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}

export default Profile;
