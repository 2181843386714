import React, { useEffect, useMemo, useRef, useState } from "react";
import redesignMethods from "../../data/redesignMethods";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { generateImageMasks, updateRedesignMethod } from "../../reducers/formReducer";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";

const RedesignMethod = (props) => {
  const {redesignMethod} = props;

  const edgeThreshold = useSelector((state) => state.form.payload.edgeThreshold);
  const inputImage = useSelector((state) => state.form.payload.inputImage)
  const generatedMasks = useSelector((state) => state.form.generatedMasks)

  const isLoading = useSelector((state) => state.form.isGeneratingMasks)

  const [showPreview, setShowPreview] = useState(false)
  const [previewPosition, setPreviewPosition] = useState({})

  const maskList = useMemo(() => generatedMasks[edgeThreshold] || {}, [edgeThreshold, generatedMasks]);

  return (
    <RadioGroup.Option
      key={redesignMethod.name}
      value={redesignMethod.slug}
      className={({ active, checked }) =>
        `relative cursor-pointer rounded-sm focus:outline-none text-[9px] font-roboto group`
      }
      onMouseEnter={() => setShowPreview(true)} 
      onMouseLeave={() => setShowPreview(false)}
      onMouseMove={(e) => {
        // console.log(e)
        const x = e.clientX
        const y = e.clientY
        setPreviewPosition({
          top: `${y}px`,
          left: `${x}px`,
        })
      }}
    >
      {({ active, checked }) => (
        <>
          <div className="group-hover:block hidden absolute top-0 right-0 bottom-0 left-0 bg-white bg-opacity-10 rounded-2xl"></div>

          {isLoading && (<div className="absolute inset-0 bg-black bg-opacity-60 rounded-2xl flex items-center justify-center">
            <div className="absolute bottom-3 right-3 w-5 h-5 animate-spin rounded-full bg-gradient-to-r from-spinner-blue via-spinner-cyan to-spinner-lime opacity-70">
              {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[calc(100%-0.5rem)] h-[calc(100%-0.5rem)] shadow-[0_0_0_99999px_rgba(0,0,0,.8)]">
              </div> */}
            </div>
          </div>)}

          <img
            src={maskList[redesignMethod.maskKey] || redesignMethod.thumbnail}
            alt={`${redesignMethod.name} redesign method`}
            className={`${
              checked ? "border-app-green" : "border-transparent"
            } w-full border-2 rounded-2xl`}
          />
          {showPreview && createPortal(<img
            src={maskList[redesignMethod.maskKey] || redesignMethod.thumbnail}
            alt={`${redesignMethod.name} redesign method`}
            key={`redesign-hover-${redesignMethod.name}`}
            className={`w-[180px] h-[180px] aspect-square position fixed z-50 max-w-none -translate-y-full rounded-2xl`}
            style={previewPosition}
          />, document.body)}

          <RadioGroup.Label
            className="text-gray-label text-center"
            as="div"
          >
            {redesignMethod.name}
          </RadioGroup.Label>
        </>
      )}
    </RadioGroup.Option>
  )
}

const RedesignMethods = (props) => {
  const dispatch = useDispatch();
  const selectedMethod = useSelector((state) => state.form.payload.redesignMethod);
  const edgeThreshold = useSelector((state) => state.form.payload.edgeThreshold);
  const inputImage = useSelector((state) => state.form.payload.inputImage)
  const generatedMasks = useSelector((state) => state.form.generatedMasks)

  const updateSelection = (value) => {
    dispatch(updateRedesignMethod(value))
  }

  const reloadMasks = async () => {
    try {
      await dispatch(generateImageMasks()).unwrap();
    } catch(e) {
      console.log(e)

      toast('Failed to generate image masks', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }

  }

  useEffect(() => {
    if (inputImage && !generatedMasks[edgeThreshold])
      reloadMasks()

  }, [edgeThreshold, inputImage, generatedMasks]);


  return (
    <RadioGroup
      value={selectedMethod}
      onChange={updateSelection}
      className="grid grid-cols-2 grid-rows-2 gap-4 col overflow-x-auto"
    >

      {/* <RadioGroup.Label className="text-gray-label font-poppins font-bold text-xs">{label}</RadioGroup.Label> */}
      {redesignMethods.map((redesignMethod, index) => (
        <RedesignMethod redesignMethod={redesignMethod} key={index} />
      ))}
    </RadioGroup>
  );
};
export default RedesignMethods;
