import Tracking from './TrackingService';

const generateEvent = ({ type = 'custom', eventData = {}, extraData = {}, opts = {} } = {}) => {
  const trackingValue = opts.value || extraData.value || eventData.value || 0;

  return {
    event: type,
    value: trackingValue,
    ecommerce: {
      ...eventData,
      currency: eventData.currency,
      value: trackingValue,
    },
    ...extraData,
  };
};

const Commerce = {
  track: (payload = {}) => {
    const { type, ...props } = payload;
    Commerce.reset();
    const dataLayerPayload = generateEvent({ type, ...props });
    Tracking.send(type, dataLayerPayload, payload.opts);
    return dataLayerPayload;
  },

  view: (_, opts) => generateEvent({ type: 'view', opts }),
  spend: (_, opts) => generateEvent({ type: 'spend', opts }),

  reset: (dataLayer = window.dataLayer || []) => {
    dataLayer.push({ ecommerce: null });
  },
};

export default Commerce;



/* PRE-DEFINED TYPES
https://support.google.com/analytics/answer/9267735#:~:text=completes%20a%20tutorial-,For%20online%20sales,-We%20recommend%20these

For online sales
We recommend these events when you want to measure sales on your site or app. They're useful for retail, ecommerce, education, real estate, and travel. Sending the events populates the Ecommerce purchases report. To learn more about these events, see Measure ecommerce for your website. View Measure ecommerce (app) for events in your app project.
Event	Trigger when
add_payment_info	a user submits their payment information
add_shipping_info	a user submits their shipping information
add_to_cart	a user adds items to cart
add_to_wishlist	a user adds items to a wishlist
begin_checkout	a user begins checkout
generate_lead	a user submits a form or a request for information
purchase	a user completes a purchase
refund	a user receives a refund
remove_from_cart	a user removes items from a cart
select_item	a user selects an item from a list
select_promotion	a user selects a promotion
view_cart	a user views their cart
view_item	a user views an item
view_item_list	a user sees a list of items/offerings
view_promotion	a user sees a promotion

gTag Event Name: add_payment_info|add_shipping_info|add_to_cart|add_to_wishlist|begin_checkout|generate_lead|purchase|refund|remove_from_cart|select_item|select_promotion|view_cart|view_item|view_item_list|view_promotion
*/

/*
? idk, spend_virtual_currency tho?
For games
We recommend these events for games properties. Sending these events populates the games reports.

Event	Trigger when
earn_virtual_currency	a user earns virtual currency (coins, gems, tokens, etc.)
join_group	a user joins a group to measure the popularity of each group
level_end	a user completes a level in the game
level_start	a user starts a new level in the game
level_up	a user levels-up in the game
post_score	a user posts their score
select_content	a user selects content
spend_virtual_currency	a user spends virtual currency (coins, gems, tokens, etc.)
tutorial_begin	a user begins a tutorial
tutorial_complete	a user completes a tutorial
unlock_achievement	a user unlocks an achievement

selected: spend_virtual_currency
gTag Event Name: earn_virtual_currency|join_group|level_end|level_start|level_up|post_score|select_content|spend_virtual_currency|tutorial_begin|tutorial_complete|unlock_achievement
*/



/*
https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#implementation
Implementation
dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
dataLayer.push({
  event: "view_item_list",
  ecommerce: {
    item_list_id: "related_products",
    item_list_name: "Related products",
    items: [
     {
      item_id: "SKU_12345",
      item_name: "Stan and Friends Tee",
      affiliation: "Google Merchandise Store",
      coupon: "SUMMER_FUN",
      discount: 2.22,
      index: 0,
      item_brand: "Google",
      item_category: "Apparel",
      item_category2: "Adult",
      item_category3: "Shirts",
      item_category4: "Crew",
      item_category5: "Short sleeve",
      item_list_id: "related_products",
      item_list_name: "Related Products",
      item_variant: "green",
      location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: 9.99,
      quantity: 1
    },
    {
      item_id: "SKU_12346",
      item_name: "Google Grey Women's Tee",
      affiliation: "Google Merchandise Store",
      coupon: "SUMMER_FUN",
      discount: 3.33,
      index: 1,
      item_brand: "Google",
      item_category: "Apparel",
      item_category2: "Adult",
      item_category3: "Shirts",
      item_category4: "Crew",
      item_category5: "Short sleeve",
      item_list_id: "related_products",
      item_list_name: "Related Products",
      item_variant: "gray",
      location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: 20.99,
      promotion_id: "P_12345",
      promotion_name: "Summer Sale",
      quantity: 1
    }]
  }
});

*/


