import useGenerationEndpoint from "../components/input/useGenerationEndpoint";
import tutorials from "../data/tutorials";

const useTutorials = () => {
	const { mode } = useGenerationEndpoint();	

	return tutorials.filter(t => t.v === mode)
};

export default useTutorials;
