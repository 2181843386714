import { useState, useEffect } from 'react';
import { getWindowDimensions } from '../helpers/dimen';

export function useWindowDimensions() {
  const [windowDimens, setWindowDimens] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimens(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimens;
}
