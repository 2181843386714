function Modal(props) {
  const containerClasses = 'fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 z-50 flex items-center justify-center';
  
  return (
    <div
      className={`${props.isOpen ? 'flex' : 'hidden'} ${containerClasses}`}
      onClick={(e)=> props.onClose && props.onClose()}
    >
      {props.children}
    </div>
  )
}

export default Modal;