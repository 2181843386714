import { Disclosure } from "@headlessui/react";
import Tooltip from '../Tooltip';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up-v4.svg';

const Accordion = (props) => {
	const { children, title, description, defaultOpen } = props;

	return (
		<Disclosure
			className={`relative rounded-xl px-3 bg-style-bg py-4 p-2 flex flex-col`}
			as='div'
			defaultOpen={defaultOpen}
		>
			<Disclosure.Button
				className={`outline-none text-sm
					text-white flex items-center justify-between font-sans-pro text-[15.13px] font-semibold`}
			>
				<span className='flex'>
					{ title }
					{description && (
						<Tooltip title={description} position='right'>
							<InfoIcon className='w-[20px] h-[20px] ml-1' />
						</Tooltip>
					)}
				</span>
				<CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
			</Disclosure.Button>
			
			<Disclosure.Panel className={`flex flex-col gap-4 py-2`}>
				{children}
			</Disclosure.Panel>
		</Disclosure>
	)
}

export default Accordion;
