import WebFont from "webfontloader"

export const FONT_FAMILIES = {
  "Open Sans": {
    family: "'Open Sans', sans-serif",
    normal: 500,
    bold: 800
  },
  "Oswald": {
    family: "'Oswald', sans-serif",
    normal: 400, 
    bold: 700,
  },
  "Montserrat": {
    family: "'Montserrat', sans-serif",
    normal: 700,
    bold: 900,
  },
  "Raleway": {
    family: "'Raleway', sans-serif",
    normal: 500,
    bold: 900
  },
  "Pacifico": {
    family: "'Pacifico', cursive",
    normal: 400,
  },
  "Permanent Marker": {
    family: "'Permanent Marker', cursive",
    normal: 400,
  },
  "Ultra": {
    family: "'Ultra', serif",
    normal: 400,
  },
  "Caprasimo": {
    family: "'Caprasimo', cursive",
    normal: 400,
  },
}

WebFont.load({
  google: {
    families: Object.keys(FONT_FAMILIES),
  }
})