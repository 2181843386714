import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import GeneratedImagePlaceholder from '../GeneratedImagePlaceholder';
import {
  GENERATION_STATUS,
  loadGenerationHistory,
  showImageModal,
} from '../../reducers/formReducer';
import { throttle } from 'lodash';
import { toast } from 'react-toastify';
import GeneratedImage from '../GeneratedImage';
import { ReactComponent as LoadingDots } from '../../assets/LoadingDots.svg';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import useHasGenerationOccured from '../../hooks/useHasGenerationOccured';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';

const Generations = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const store = useStore();

  const hasGenerationOccured = useHasGenerationOccured();

  const toGenerate = useSelector(
    (state) => state.form.payload.imagesToGenerate
  );
  const generations = useSelector((state) => state.form.generations);
  const lastGenerationId = useSelector((state) => state.form.lastGenerationId);
  const newGenerations = useMemo(() => {
    // if (!hasGenerationOccured) {
    // 	return []
    // }
    return generations.filter((x) => x.generationId === lastGenerationId);
  }, [generations, lastGenerationId]);
  const showImagePlaceholders = useSelector(
    (state) => state.form.showPlaceholders
  );
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClickMainImage = useCallback(() => {
    if (!showImagePlaceholders) {
      dispatch(showImageModal(newGenerations[activeIndex].imageUrl));
    }
  }, [activeIndex, dispatch, newGenerations, showImagePlaceholders]);

  const { workspace } = useCurrentWorkspace();

  const hasMoreToLoad = useSelector(
    (state) => state.form.hasMoreGenerationsToLoad
  );

  const loadingAnchor = useRef();

  const loadMore = useCallback(
    throttle(async () => {
      try {
        await dispatch(loadGenerationHistory()).unwrap();
      } catch (e) {
        console.log(e);
        toast('Failed to load generation history', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000,
        });
      }
    }, 5000),
    [dispatch]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log('Intersection changed');
        if (
          store.getState().userSlice.user &&
          entries[0].isIntersecting &&
          store.getState().form.hasMoreGenerationsToLoad &&
          !store.getState().form.isLoadingGenerations
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (loadingAnchor.current) {
      observer.observe(loadingAnchor.current);
    }

    return () => {
      if (loadingAnchor.current) {
        observer.unobserve(loadingAnchor.current);
      }
    };
  }, [loadingAnchor, store, loadMore]);

  const { mode } = useGenerationEndpoint();
  if (workspace === 'image-workspace') {
    return (
      <div
        className='grid grid-cols-4 grid-rows-4 aspect-[3/4] h-full max-w-full gap-4'
        ref={ref}
      >
        <GeneratedImagePlaceholder
          isMain
          image={newGenerations[activeIndex]}
          key={`placeholder-main`}
          onClick={handleClickMainImage}
          appliedClasses={{
            container: 'row-start-1 col-start-1 col-span-3 row-span-3',
          }}
        />
        {[...Array(toGenerate)].map((img, i) => (
          <GeneratedImagePlaceholder
            image={newGenerations[i]}
            key={`placeholder-${i}`}
            onClick={() => setActiveIndex(i)}
            appliedClasses={{
              container: 'row-start-4',
            }}
          />
        ))}
      </div>
    );
  }

  return (
    <div
      className='h-full overflow-y-auto overflow-x-hidden custom-scroll custom-scroll-padding py-6'
      ref={ref}
    >
      <div className='rounded-md lg:px-4 grid grid-cols-2 3xl:grid-cols-3 4xl:grid-cols-4 5xl:grid-cols-5 6xl:grid-cols-6 gap-8 pb-32'>
        {showImagePlaceholders &&
          [...Array(toGenerate)].map((img, i) => (
            <GeneratedImagePlaceholder key={`placeholder-${i}`} />
          ))}
        {generations.map((generatedImage, i) => (
          <GeneratedImage
            image={generatedImage}
            key={`generated-${generatedImage.generationId}-${generatedImage.imageUrl}-${generatedImage.ind}`}
            // onUseAsInspiration={onUseAsInspiration}
            // handleUseAsDDDInspiration={handleUseAsDDDInspiration}
            // handleViewImage={handleViewImage}
            // handleDeleteGeneration={handleDeleteGeneration}
          />
        ))}

        <div
          ref={loadingAnchor}
          className='p-2 px-3 flex items-center justify-center bg-w col-span-full'
        >
          {/* {page >= totalPages ? '' : 'Loading...'} */}
          {hasMoreToLoad && (
            <span
              className='flex items-center justify-center'
              id='button-loading'
            >
              <LoadingDots className='h-2' />
            </span>
          )}
        </div>
      </div>
    </div>
  );
});

export default Generations;
