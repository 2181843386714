import { Datagrid, DateField, EmailField, List, ReferenceField, TextField, EditButton, TextInput } from 'react-admin';

export const UserList = () => (
	<List filters={[
			<TextInput label="Search" source="email" alwaysOn />,
	]}>
		<Datagrid rowClick="edit">
			<EmailField source="email" />
			<TextField source="name" />
			<TextField source="remainingCredits" />
			<TextField source="totalGenerations" />

			<ReferenceField
				source="role"
				reference="userRoles"
			>
				<TextField source="name" />
			</ReferenceField>

			<DateField source="createdAt" />
			<EditButton />
		</Datagrid>
	</List>
);

export default UserList;
