
import { twMerge } from "tailwind-merge";
import { ReactComponent as InfoIcon } from "../../../../assets/InfoIconGray.svg";
import { ReactComponent as CloseIcon } from '../../../../assets/CloseEditor.svg';
import { Tooltip } from "react-tippy";
import { useDispatch } from "react-redux";
import { removeDevModeField } from "../../../../reducers/formReducer";

const DevInput = (props) => {
  const { label, fieldName, title, value, onChange, disabled, appliedClasses={}, } = props;

	const dispatch = useDispatch()
	
	return (
		<div className={twMerge(`flex justify-between `, appliedClasses.main)} disabled={disabled}>
			<label className={twMerge(`font-bold font-poppins text-xs flex gap-1 ${disabled ? 'text-select-label-disabled' : 'text-gray-label '} inline-flex items-center`, appliedClasses.label)}>
				{label}

				{fieldName && (
					<button onClick={e => {
						e.stopPropagation();
						dispatch(removeDevModeField(fieldName))
						onChange(undefined)
					}}>
						<CloseIcon />
					</button>
				)}
	
				<Tooltip title={`${title} - ${fieldName}`} >
					<InfoIcon className={twMerge(``, appliedClasses.infoTooltip)}/>
				</Tooltip>
			</label>
			<div className="flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]">
				<input 
					className={twMerge(` p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-full `, appliedClasses.optionContainer)}
					value={value}
					onChange={(e) => onChange(e.target.value)}
					disabled={disabled}
				/>
			</div>
		</div>
	);
}

export default DevInput;
