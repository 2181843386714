import { Popover, Transition } from '@headlessui/react';
import { ReactComponent as PlusIcon } from "../../../assets/Plus.svg";
import { ReactComponent as NoStrokeIcon } from "../../../assets/NoStroke.svg";
import SaturationPicker from "react-color/lib/components/common/Saturation";
import React, { useState } from 'react'
import tinycolor from 'tinycolor2';
import { HuePicker } from 'react-color';
import AlphaPicker from "react-color/lib/components/common/Alpha";
import { usePopper } from 'react-popper';
import { Color } from './Color';

const SaturationPointer = (props) => {
  const { hsl } = props;
  return (
    <div className="h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1/2 mt-[0.125rem]" style={{ backgroundColor: `#${tinycolor(hsl).toHex8()}` }}/>
  );
}
const AlphaPointer = (props) => {
  const { activeColor } = props
  return (
    <div className="h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1 mt-[0.125rem]" style={{ backgroundColor: activeColor }}/>
  );
}

const CirclePicker = (props) => {
  const { colors = [], onChange, activeColor, onColorAdded, showBlank} = props;

  const [newColorAnchor, setNewColorAnchor] = useState()
  const [newColorElement, setNewColorElement] = useState()
  let { styles: newColorStyles, attributes: newColorAttributes } = usePopper(newColorAnchor, newColorElement)


  const [newColor, setNewColor] = useState();

  return (
    <div className={`grid grid-cols-7 self-stretch gap-x-5 gap-y-2`}>

			{showBlank && (
				<button type="button" onClick={(e) => onChange("", e)} className="flex items-center justify-center" >
					<NoStrokeIcon />
				</button>
			)}

      {['#FFF', '#000', ...colors].map((color) => (
        <Color color={color} activeColor={activeColor} onClick={(e) => onChange(color, e)} key={color}/>
      ))}

      {onColorAdded && (
        <Popover className="relative overflow-visible aspect-square z-30" >
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`bg-white bg-opacity-10 ui-open:bg-opacity-20 transition-all duration-150 aspect-square !outline-none text-gray-label h-6 w-6 rounded-full group flex items-center justify-center`}
                ref={setNewColorAnchor}
              >

                <PlusIcon className="max-lg:aspect-square max-lg:h-5 lg:h-[12px] [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green" />
              </Popover.Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel 
                  // className="absolute left-1/2 -translate-x-1/2 z-20 w-[200px] px-4 sm:px-0 lg:max-w-3xl mb-2"
                  ref={setNewColorElement}
                  style={newColorStyles.popper}
                  {...newColorAttributes.popper}
                >
                  <div className='overflow-hidden rounded-lg bg-app-bg-gray shadow-2xl flex flex-col gap-2 py-1 w-[200px] z-50'>
                    <button className='px-2 hover:bg-app-search-gray flex gap-2 items-center text-editor-label' onClick={() => {
                      onColorAdded(newColor ? newColor.toHex8String() : activeColor)
                      setNewColor()
                      close()
                    }}>
                      <span className='w-3 h-3 rounded-sm inline-flex' style={{ backgroundColor: newColor ? newColor.toHex8String() : activeColor}}></span>
                      Add current color
                    </button>


                    <div className='flex flex-col gap-2 items-center'>
                      <div className='relative aspect-square w-[150px] mb-2'>
                        <SaturationPicker
                          hsl={newColor?.toHsl() || tinycolor(activeColor).toHsl()}
                          hsv={newColor?.toHsv() || tinycolor(activeColor).toHsv()}
                          onChange={(color) => {
                            setNewColor(tinycolor(color));
                          }}
                          pointer={SaturationPointer}

                          style={{ 
                            color: { borderRadius: '0.75rem' },
                            white: { borderRadius: '0.75rem' },
                            black: { borderRadius: '0.75rem' },
                          }}
                        />
                      </div>

                      <div className='[&_.hue-horizontal]:!rounded-full'>
                        <HuePicker
                          color={newColor?.toHex8String() || activeColor}
                          onChange={(color) => {
                            setNewColor(tinycolor(color.rgb));
                          }}
                          pointer={(props) => <AlphaPointer {...props} activeColor={newColor?.toHex8String() || activeColor}/>}
                          width='150px'
                          styles={{
                            default: {
                              "hue": {
                                borderRadius: '1000px'
                              }
                            }
                          }}
                        />
                      </div>
                      <div className="rounded-full relative bg-white bg-opacity-20 h-4 w-[150px]">
                        <AlphaPicker
                          pointer={AlphaPointer}
                          rgb={newColor?.toRgb() || tinycolor(activeColor).toRgb()}
                          hsl={newColor?.toHsl() || tinycolor(activeColor).toHsl()}
                          activeColor={newColor?.toHex8String() || activeColor}
                          // color={activeColorObj}
                          width={150}
                          style={{
                            // picker: { borderRadius: "999px" },
                            picker: { borderRadius: "999px" },
                            alpha: { borderRadius: "999px" },
                            gradient: { borderRadius: "999px" },
                            checkboard: { borderRadius: "999px" },
                            container: {
                              margin: '0',
                            }
                          }}
                          onChange={(color) => {
                            setNewColor(tinycolor(color));
                            // pickColor(tinycolor(color).toHex8String());
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}

    </div>
  )
}

export default CirclePicker
