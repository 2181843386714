import { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../base/Dropdown";
import { FONT_FAMILIES } from "../text/fonts";
import { updateFontFamily, updateFontWeight } from "../../../reducers/imageEditorReducer";
import TextColorPicker from "../text/TextColorPicker";
import { ReactComponent as BoldIcon } from "../../../assets/Bold.svg";

export const FontSection = ({
  editor,
}) => {
  const dispatch = useDispatch();
  const {
    fontFamily: currentFont,
    fontWeight: currentFontWeight,
    selectedElement,
  } = useSelector((state) => state.imageEditor);


  const isBold = useMemo(
    () =>
      currentFont &&
      Object.values(FONT_FAMILIES).find((item) => item.family === currentFont)
        ?.bold === currentFontWeight,
    [currentFont, currentFontWeight]
  );

  const setCurrentFont = (val) => {
    dispatch(updateFontFamily(val));
  };

  const setCurrentFontWeight = (val) => {
    dispatch(updateFontWeight(val));
  };

  const updateTextFontFamily = async (val) => {
    const fontWeight = Object.values(FONT_FAMILIES).find(
      (item) => item.family === val
    )?.normal;

    if (selectedElement && selectedElement.type === 'i-text') {
      await editor.current?.getInstance().changeTextStyle(selectedElement.id, {
        fontFamily: val,
        fontWeight: fontWeight,
      });
    }

    setCurrentFont(val);
    setCurrentFontWeight(fontWeight);

  };

  const toggleCurrentFontWeight = async (e) => {
    const fontFamily = Object.values(FONT_FAMILIES).find(
      (item) => item.family === currentFont
    );
    const newFontWeight =
      fontFamily.normal === currentFontWeight
        ? fontFamily.bold || fontFamily.normal
        : fontFamily.normal;

    if (selectedElement.id && newFontWeight !== currentFontWeight) {
      await editor.current?.getInstance().changeTextStyle(selectedElement.id, {
        fontWeight: newFontWeight,
      });
    }

    setCurrentFontWeight(newFontWeight);
  };
  return (
    <div className="rounded-lg bg-[#1c1c25ff] shadow-2xl p-3 z-50">
      <div className="flex flex-row gap-2 self-stretch">
        <div className=" flex grow">
          <Dropdown
            values={Object.entries(FONT_FAMILIES).map(([item, val]) => ({
              name: item,
              value: val.family,
            }))}
            value={currentFont}
						valueFallback={currentFont}
            onChange={updateTextFontFamily}
            appliedClasses={{
              root: "w-full flex-grow",
              button: "w-full !rounded-lg py-1 !bg-divider !border-0",
              buttonCnt: "w-full !max-w-none",
            }}
          />
        </div>
        <TextColorPicker editor={editor} />
        <button
          className={`rounded-md aspect-square transition-all duration-150 ${
            isBold ? "bg-picker-button hover:bg-opacity-70" : "bg-divider"
          } px-2 py-1`}
          onClick={toggleCurrentFontWeight}
        >
          <BoldIcon
            className={`rounded-md w-4 h-4 transition-all duration-150 ${
              isBold
                ? "[&>path]:fill-white "
                : "[&>path]:fill-editor-label [&>path]:hover:fill-app-green"
            }`}
          />
        </button>
      </div>
    </div>
  );
};
