import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { checkoutCredits } from '../reducers/userReducer';

import RangeInput from './RangeInput';
import environment from '../helpers/envProvider';

const PAYMENT_ENVIRONMENT = environment("REACT_APP_PAYMENT_ENVIRONMENT") || "production";

const CreditCheckoutPriceId = PAYMENT_ENVIRONMENT === 'test' ? 'price_1OLC3IHPReHZNX9KDhNzILnV' : 'price_1OLC25HPReHZNX9KMRJsrBH8';

const GetMoreCreditsForm = function(props) {
  const [credits, setCredits] = useState([props.initialCredits]);
  const dispatch = useDispatch();


  const getCredits = async () => {
    try {
      // const response = await dispatch(checkoutCredits({ price: 'price_1N3KQwHZuEcshHGajO58Newa', quantity: credits[0]})).unwrap() // Produciton
      const response = await dispatch(checkoutCredits({ price: CreditCheckoutPriceId, quantity: credits[0]})).unwrap() // Testing

      window.open(response.callback, '_self');
    } catch(e) {
			toast(e.response?.data?.details || e.response?.data || 'An error occured.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				theme: 'dark',
				autoClose: 2000
			});
    }
  }

  return (
    <div className='bg-app-bg-gray rounded-lg p-4 px-12 w-5/12 text-center' onClick={e => e.stopPropagation()}>
      <p className='font-bold text-xl text-white mb-8'>
        Get more credits
      </p>

      <p className='text-xl flex items-center justify-center gap-1 mb-2'>
        <span className='text-ld-purple font-bold'>{credits}</span>
        <span className='text-base text-gray-400'>Credits =</span>
        <span className='text-white font-bold'>${Math.ceil(credits * props.costPerCredit)}</span>
      </p>

      <div className="mb-6">
        <RangeInput
          min={40}
          max={1000}
          step={40}
          values={credits}
          onRangeChange={setCredits}
          bgColor="#8058F3"
        />
      </div>

      <div className='flex items-center justify-center gap-3'>
        <p
          className='text-gray-400 text-xs cursor-pointer'
          onClick={props.onClose}
        >
          Cancel
        </p>
        <button className='bg-ld-purple p-1 px-4 text-white text-xs rounded-md' onClick={getCredits}>Checkout</button>
      </div>
    </div>
  )
}

export default GetMoreCreditsForm;
