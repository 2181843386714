import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isMobileNavOpen: false,
  isTutorialModalOpen: false,
	currentTutorial: 0,
	loadVideo: -1,
}

export const appSlice = createSlice({
  name: 'appSlice',

  initialState: INITIAL_STATE,

  reducers: {
    setIsMobileNavOpen(state, action) {
      state.isMobileNavOpen = action.payload;
    },

    setIsTutorialModalOpen(state, action) {
      state.isTutorialModalOpen = action.payload;
    },

    setCurrentTutorial(state, action) {
      state.currentTutorial = action.payload;
    },

    setLoadVideo(state, action) {
      state.loadVideo = action.payload;
    },
  }
});

export const {
	setIsMobileNavOpen,
	setIsTutorialModalOpen,
	setCurrentTutorial,
	setLoadVideo,
} = appSlice.actions;

export default appSlice.reducer;
