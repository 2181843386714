/**
 ** Tracking Module
 *
 * This module provides a simple and flexible interface for tracking events in Google Tag Services.
 * It exposes methods for tracking events, updating events, and finding values within objects.
 * This module leverages the Data Layer feature of Google Tag Manager (GTM) to push events and data to GTM.
 *
 * @module Tracking
 * 
 * @example
 * Import the module and use the `track` method to send an event to the GTM Data Layer:
 * import Tracking from './path/to/trackingModule';
 * Tracking.send("event", eventData, extraData, opts);
 *
 ** Functions and Objects
 *
 * @function findValueFromLocations - Searches for a key in an array of objects and returns the corresponding value or null.
 * @param {string} key - The key to search for.
 * @param {Array} locations - An array of objects to search within.
 * @returns {any|null} The found value or null if not found.
 *
 * @function buildDataLayerPayload - Builds a payload object for pushing to the GTM Data Layer.
 * @param {string} type - The event type.
 * @param {Object} eventData - The event data object.
 * @param {Object} extraData - Extra data to be added to the payload.
 * @param {Object} opts - Optional overrides for the payload properties.
 * @returns {Object} The prepared Data Layer payload.
 *
 * @function trackDataLayer - Sends event data to the GTM Data Layer.
 * @param {string} type - The event type.
 * @param {Object} eventData - The event data object.
 * @param {Object} extraData - Extra data to be added to the payload.
 * @param {Object} opts - Optional overrides for the payload properties.
 * @returns {Object} The pushed Data Layer payload.
 *
 * @object Tracking - The main tracking object with track() and update() methods.
 * @property {Function} track - Sends event data to the GTM Data Layer.
 * @property {Function} update - Updates events in the GTM Data Layer with new data.
 */
/**
 * Tracking module that provides an interface for tracking events with Google Tag Manager.
 * Includes functions for creating tracking payloads and interacting with GTM Data Layer.
 */
const eventNames = new Map([
  ["ad_impression", "a user sees an ad impression, for app only"],
  ["earn_virtual_currency", "a user earns virtual currency (coins, gems, tokens, etc.)"],
  ["join_group", "a user joins a group to measure the popularity of each group"],
  ["login", "a user logs in"],
  ["search", "a user searches your content"],
  ["select_content", "a user selects content"],
  ["share", "a user shares content"],
  ["sign_up", "a user signs up to measure the popularity of each sign-up method"],
  ["spend_virtual_currency", "a user spends virtual currency (coins, gems, tokens, etc.)"],
  ["tutorial_begin", "a user begins a tutorial"],
  ["tutorial_complete", "a user completes a tutorial"]
]);

const getValue = (key, sources) => sources.reduce((val, src) => val || src[key], undefined);

const createPayload = (type, { event = {}, extra = {}, options = {} } = {}) => {
  const value = getValue("value", [options, event, extra]);
  const user = getValue("user", [options, event, extra]);
  const attributes = { ...event, value, user };

  const payload = {
    success: false,
    attributes,
    extraInfo: { ...extra },
    eventType: type ?? "",
  };

  if (attributes.user) {
    payload.userId = attributes.user?._id || attributes.user;
    payload.userIdentifier = attributes.user?.email || attributes.user?.name;
  }

  payload.success =
    [
      "generation",
      "logout",
      "register",
      "relog",
      "authenticate",
      "login",
    ].includes(type) && Boolean(attributes.user?._id);

  return payload;
};

const sendDataLayer = (type, event = {}, extra = {}, options = {}) => {
  try {
    const dataLayerPayload = createPayload(type, { event, extra, options });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerPayload);
    return dataLayerPayload;
  } catch(e) {
    console.log("Tracking error", e);
    return null;
  }
};

const Tracking = {
  send: sendDataLayer,
  update: (update = {}, extra = {}, eventType = "", options = {}) => {
    return sendDataLayer(eventType, update, extra, options);
  },
};

export default Tracking;

