import { TabbedForm, Edit, NumberInput, TextField, ReferenceInput, ReferenceField, TextInput, AutocompleteInput, useRecordContext, ReferenceManyField, ImageField, Datagrid, Pagination, BooleanInput, DeleteButton, BulkDeleteButton } from 'react-admin';
import Grid from '@mui/material/Grid';
import { AddToGalleryAction } from './AdminGenerationList';

const PostTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};

export const UserEdit = () => (
  <Edit title={<PostTitle />}>
    <TabbedForm>
      <TabbedForm.Tab label="Overview">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextInput source="email" fullWidth />
          </Grid>

          <Grid item xs={4}>
            <TextInput source="name" fullWidth />
          </Grid>

          <Grid item xs={4}>
            <TextInput source="id" disabled fullWidth />
          </Grid>

          <Grid item xs={4}>
            <ReferenceInput source="role" reference="userRoles">
              <AutocompleteInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={4}>
            <NumberInput source="remainingCredits"  fullWidth />
          </Grid>

          <Grid item xs={4}>
            <NumberInput source="individualCredits"  fullWidth />
          </Grid>

          <Grid item xs={4}>
            <NumberInput source="totalGenerations"  fullWidth />
          </Grid>

          <Grid item xs={4}>
            <NumberInput source="paidFeatureUsage.vectorize"  fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="paidFeatureUsage.remove-bg"  fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="paidFeatureUsage.high-res"  fullWidth />
          </Grid>

          <Grid item xs={2}>
            <BooleanInput source="emailVerified"  fullWidth />
          </Grid>

          <Grid item xs={2}>
            <BooleanInput source="isAdmin"  fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="canAccessStaging"  fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="isPartner"  fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="isDev"  fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="isBlocked"  fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="isSumoling"  fullWidth disabled />
          </Grid>
        </Grid>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Generations">
        <ReferenceManyField label="Generations" reference="generation" target="userId" pagination={<Pagination />}>
          <Datagrid bulkActionButtons={
						<>
							<AddToGalleryAction />
							<AddToGalleryAction draft />
							<BulkDeleteButton />
						</>
					}>
            <TextField source="createdAt" />

            <ImageField source="imageUrl" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }} />
            <ImageField source="inputImage" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }} />

            <ReferenceField source="userId" reference="users">
              <TextField source="email" />
            </ReferenceField>

            <TextField source="prompt" />
            <TextField source="negativePrompt" />
            <TextField source="styles" />
            <TextField source="seed" />
          </Datagrid>
        </ReferenceManyField>
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
);

export default UserEdit;
