import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/InfoIcon.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import { twMerge } from "tailwind-merge";

const Notification = (props) => {
	const { containerClasses } = props;
  const user = useSelector((state) => state.userSlice.user);

	const [showNotification, _setShowNotification] = useState(() => {
		return false
		return localStorage.getItem('hide-appsumo-sale-notif') !== 'true'
	});

	const setShowNotification = (v) => {
		_setShowNotification(v)
		localStorage.setItem('hide-appsumo-sale-notif', !v ? 'true' : 'false')
	}

	useEffect(() => {
		if (
			// user?.isSumoling && 
			moment('2023-12-01').isAfter(moment()) &&
			localStorage.getItem('hide-appsumo-sale-notif') !== 'true'
		) {
			_setShowNotification(true)
		}
	}, [user])


	if (!showNotification) return <></>

	return (
		<div className={twMerge(
			"self-center rounded-lg bg-notification-purple-bg p-3 flex items-center gap-2 text-notification-purple-text mt-4 border border-solid border-notification-purple-border",
			containerClasses,
		)}>
			<InfoIcon className="[&_path]:!fill-notification-purple-text w-6 h-6 shrink-0"/>
			<div className="flex-grow font-bold text-sm font-roboto">
				{user?.isSumoling ? (
					<>
						Due to high usage from our Black Friday deal, you may experience some service delays. We're a small team working to fix this. If you’re getting failed generations, please try again later. Your lifetime subscription means there's plenty of time to use our service!
					</>
				) : (
					<>
						Due to high traffic you may experience some service delays. We're a small team working to fix this. If you're getting failed generations, please try again later.
					</>
				)}
			</div>
			<button onClick={() => setShowNotification(false)} type="button">
				<CloseIcon className="[&_path]:!stroke-notification-purple-text [&_path]:!stroke-2"/>
			</button>

		</div>
	)
};

export default Notification;
