import React, { useEffect, useMemo, useState } from 'react';

import { CONFIG_PROPS } from './style-config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Listbox, Popover, RadioGroup, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { SDColors } from '../redesign/constants';
import { ReactComponent as EyeDropperIcon } from "../../assets/Eyedropper.svg";
import { setStyleConfig } from '../../reducers/formReducer';
import { ReactComponent as CheckmarkIcon } from "../../assets/green_checkmark.svg";
import { ReactComponent as CaretDownIcon } from "../../assets/caret-up.svg";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { Tooltip } from 'react-tippy';
import useGenerationEndpoint from './useGenerationEndpoint';
import useStyle from '../../hooks/useStyle';

const COLOR_KEYS = [
	'palette',
	'outline_stroke',
	'background',
]

const ValueSelector = (props) => {
	const { config_key,} = props;

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

	const dispatch = useDispatch();

	const style_config = useSelector((state) => state.form.payload.styleConfig);
	const config = style_config[config_key];

	const style = useStyle()

  const method = useSelector((state) => state.form.method);
	const config_metadata = useMemo(() => CONFIG_PROPS[method]?.[style]?.[config_key] || [], [style, method, config_key]);


	const handleChange = (value) => {
		dispatch(setStyleConfig({
			config_id: config_key,
			value: {
				...config,
				enabled: true,
				value,
				__randomized: false,
			}
		}))
	}

	return (
		<Listbox
			as={React.Fragment}
			value={config?.value}
			onChange={handleChange}
		>
			{({ open }) => (
				<>
					<Listbox.Button className="p-[0.125rem] relative font-poppins text-xs text-gray-label flex items-center justify-between rounded-md px-2 grow bg-divider" ref={setAnchorEl}>
						<span className="block truncate">
							{config_metadata?.values?.find((item) => item.id === config?.value)?.name}
						</span>
						<CaretDownIcon className="transition-all duration-300 ui-not-open:rotate-180 [&_path]:fill-chevron-icon " />
					</Listbox.Button>
					<Transition
						show={open}
						as={React.Fragment}
						leave="transition ease-in duration-100 "
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options
							className="origin-bottom my-1 w-auto overflow-auto rounded-md bg-divider text-gray-label font-poppins shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-xs z-50"
							ref={setPopperEl}
							style={styles.popper}
							{...attributes.popper}
						>
							{config_metadata?.values?.map((item) => (
								<Listbox.Option
									key={item.id}
									value={item.id}
									className="relative cursor-default select-none ui-active:bg-app-green ui-active:bg-opacity-40 ui-active:text-white py-1 px-2 pl-7 ui-selected:font-semibold ui-selected:text-white ui-disabled:text-app-search-gray"
								>
									{({ selected }) => (
										<>
											<span className="truncate">{item.name}</span>
											{selected && (
												<span className="absolute inset-y-0 left-0 pl-2 flex items-center">
													<CheckmarkIcon className="w-3 h-3" />
												</span>
											)}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</>
			)}
		</Listbox>
	)
}


const Colors = (props) => {
	const { config_key, ind, } = props;

	const dispatch = useDispatch();

	const style_config = useSelector((state) => state.form.payload.styleConfig);
	const config = style_config[config_key];

	const handleChange = (value) => {
		let colors = [...(config?.colors || [])]
		if (colors.length < ind) {
			colors = [...colors, ...Array(ind + 1 - colors.length).fill(null)];
		}
		colors[ind] = value
		dispatch(setStyleConfig({
			config_id: config_key,
			value: {
				...config,
				enabled: true,
				colors,
				__randomized: false,
			}
		}))
	}


	return (
		<RadioGroup value={config?.colors?.[ind] ?? null} onChange={handleChange} className="flex flex-wrap items-center gap-2">
			{SDColors.map((item) => (
				<RadioGroup.Option
					key={item.id}
					value={item.id}
					className={({ active, checked }) =>
						`
								${
									checked
										? "ui-disabled:bg-gray-label  "
										: "text-deactive "
								}
								ui-disabled:text-select-label-disabled  relative flex cursor-pointer rounded-sm max-md:px-1 md:px-1 focus:outline-none text-sm font-roboto font-bold`
					}
					style={{
						...(config?.value === 'gradient' ? {
							background: item.gradient,
						} : {
							backgroundColor: item.hex,
							borderColor: item.hex,
						})
					}}
				>
					<RadioGroup.Label as="p" className="text-black/60" style={{ color: item.textHex }}>{item.name}</RadioGroup.Label>
				</RadioGroup.Option>
			))}
			<RadioGroup.Option
				value={null}
				className={({ active, checked }) =>
					`
							${
								checked
									? "ui-disabled:bg-gray-label border-app-green "
									: "text-deactive border-app-bg-gray"
							}
							text-white border-2 border-solid 
							ui-disabled:text-select-label-disabled  relative flex cursor-pointer rounded-sm max-md:px-2 md:px-[0.25rem] focus:outline-none text-sm font-roboto font-bold`
				}
				style={{
				}}
			>
				<RadioGroup.Label as="p" >
					None
				</RadioGroup.Label>
			</RadioGroup.Option>
		</RadioGroup>
	)
}

const ColorSelector = (props) => {
	const { method, } = useGenerationEndpoint()

	const style = useStyle()

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();

	const dispatch = useDispatch();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'top',
    strategy: 'fixed',
  });

	const config = useMemo(() => CONFIG_PROPS[method]?.[style] || [], [style, method]);
	const style_config = useSelector((state) => state.form.payload.styleConfig);

	const color_keys = useMemo(() => {
		return COLOR_KEYS.filter(key => config[key] && config[key].colors > 0);
	}, [config, style])

	const fields = useMemo(() => {
		const arr = []
		for (const key of color_keys) {
			if (config[key]) {
				for (let i = 0; i < config[key].colors; i++) {
					arr.push({
						key,
						name: config[key].colorNames?.[i],
						ind: i
					})
				}
			}
		}
		return arr
	}, [config, style, method])

	const [currentColor, setCurrentColor] = useState()

	const hasColorFields = useMemo(() => {
		for (const key of color_keys) {
			if (config[key]) return true
		}
		return false
	}, [config])

	const handleClear = () => {
		for (const key of color_keys) {
			if (config[key]) {
				dispatch(setStyleConfig({
					config_id: key,
					value: {
						value: config[key].default ?? style_config[key].value,
						enabled: config[key].default_enabled ?? config[key].always_enabled ?? true,
						colors: [],
						__randomized: true,
					}
				}))
			}
		}
	}

	useEffect(() => {
		setCurrentColor(fields[0])
	}, [fields, style])

	return (
		<>
      <Popover as={React.Fragment}>
        {({ open, close }) => (
          <>
            <Popover.Button
              className='relative flex justify-start items-center cursor-pointer focus:outline-none overflow-hidden rounded-lg bg-overlay-bg grow h-7 gap-1 pr-2 disabled:cursor-not-allowed disabled:text-config-disabled text-xs'
              ref={setAnchorEl}
			  disabled={!hasColorFields}
            >
				<div className="flex flex-col w-3 h-full">
					{color_keys.map((key, index) => {
						return <React.Fragment key={index}>
							{style_config[key]?.colors?.filter(c => !!c).map((item, innerIndex) => (
								<div className="grow w-full"
									key={innerIndex}
									style={{
										...(style_config[key]?.value === 'gradient' ? {
											background: SDColors.find(i => i.id === item)?.gradient || '#292932'
										} : {
											backgroundColor: SDColors.find(i => i.id === item)?.hex || '#292932'
										})
									}}
								>
								</div>
							))}
						</React.Fragment>
					})}
				</div>
				Colors
            </Popover.Button>

            <Transition
              as={React.Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
              {...props}
            >
              <Popover.Panel
                className='border border-solid border-app-search-gray rounded-xl bg-app-bg-gray flex gap-5 max-w-[95%] w-[450px] overflow-hidden relative p-4 mb-12'
                ref={setPopperEl}
                style={styles.popper}
                {...attributes.popper}
              >
								<div className="flex flex-col gap-4 shrink-0">
									<h5 className="text-title-white font-semibold font-poppins text-lg flex flex-row gap-1 items-center">
										Color Palette
										<Tooltip title={"You can select the colors used in the image"}>
											<InfoIcon />
										</Tooltip>
									</h5>
									<RadioGroup className="flex flex-col gap-1 grow " value={currentColor} onChange={setCurrentColor}>
										{fields.map((item, ind) => (
											<RadioGroup.Option
												className="flex items-center justify-start gap-2 pl-2 pr-4 py-3 cursor-pointer rounded-lg hover:bg-button-purple/10 ui-checked:bg-button-purple/30 text-white"
												key={`${item.key}-${item.ind}`}
												value={item}
											>
												<span
													className="aspect-square rounded-sm bg-divider p-[0.125rem]"
													style={{
														backgroundColor: SDColors.find(i => i.id === style_config?.[item.key]?.colors?.[item.ind])?.hex
													}}
												>
													<EyeDropperIcon
														className="[&>*]:invert [&>*]:contrast-[9] [&>*]:grayscale fill-black w-3 h-3"
														style={{
															fill: SDColors.find(i => i.id === style_config?.[item.key]?.colors?.[item.ind])?.hex
														}}
													/>
												</span>
												{item.name}
											</RadioGroup.Option>
										))}
										
									</RadioGroup>
									
									<button
										className="border border-config-clear-border border-solid rounded-lg text-config-clear-text py-2 text-sm font-bold font-montserrat "
										type="reset"
										onClick={handleClear}
									>
										Clear
									</button>
								</div>
								<div className="flex flex-col gap-2">
									<div className="basis-7">
										{config[currentColor?.key]?.values && (<ValueSelector config_key={currentColor?.key} />)}
									</div>
									<Colors config_key={currentColor?.key} ind={currentColor?.ind} />
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</>
	)
}

export default ColorSelector;
