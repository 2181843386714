import React from 'react';
import { ReactComponent as ZoomPlusIcon } from '../../assets/zoom-plus-new.svg';
import { ReactComponent as ZoomMinusIcon } from '../../assets/zoom-minus-new.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateZoom } from '../../reducers/imageEditorReducer';
import { EDITOR_SIZE } from './settings';

const V4Zoom = (props) => {
  const { editor, zoomCenterRef } = props;

  const zoom = useSelector((state) => state.imageEditor.zoom);
  const dispatch = useDispatch();

  const changeZoom = async (e, direction) => {
    const newZoom = Math.max(Math.min(5, zoom + direction * 0.2), 1);

    dispatch(updateZoom(newZoom));

    await editor?.current.getInstance().zoom(
      {
        zoomLevel: newZoom,
        x: zoomCenterRef.current.x,
        y: zoomCenterRef.current.y,
      },
      newZoom,
      100,
      100
    );

    zoomCenterRef.current = {
      x: EDITOR_SIZE / 2,
      y: EDITOR_SIZE / 2,
      zoomLevel: newZoom,
    };
  };

  return (
    <span className='absolute left-4 bottom-4 flex flex-row '>
      <button
        className='bg-[#7E7E7E] bg-opacity-50 flex items-center justify-center rounded-l-lg group p-[6px]'
        onClick={(e) => changeZoom(e, -1)}
      >
        <ZoomMinusIcon
          className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[18px] lg:h-[18px] [&_path]:fill-bg-app-gray group-hover:[&_path]:stroke-app-green`}
        />
      </button>

      <div className='bg-[#7E7E7E] bg-opacity-50 px-1 flex items-center justify-center'>
        {Math.floor(zoom * 100)}%
      </div>

      <button
        className='bg-[#7E7E7E] bg-opacity-50 flex items-center justify-center rounded-r-lg group p-[6px]'
        onClick={(e) => changeZoom(e, 1)}
      >
        <ZoomPlusIcon
          className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[18px] lg:h-[18px] [&_path]:fill-bg-app-gray group-hover:[&_path]:stroke-app-green `}
        />
      </button>
    </span>
  );
};

export default V4Zoom;
