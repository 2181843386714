import { Popover, RadioGroup, Transition } from '@headlessui/react';
import { ReactComponent as EyeDropperIcon } from "../../assets/Eyedropper.svg";
import React, { useMemo, useState } from 'react';
import { SDColors } from '../redesign/constants';
import { usePopper } from 'react-popper';


const SDColorSelector = (props) => {
	const { color, setColor, disabled } = props;

	const sd_color = useMemo(() => SDColors.find(item => item.id === color), [color])

	const [anchorEl, setAnchorEl] = useState();
	const [popperEl, setPopperEl] = useState();
	const [arrowEl, setArrowEl] = useState();

  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: "top",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowEl,
        },
      },
    ],
		strategy: 'fixed',
  });

	return (
		<Popover >
			<Popover.Button
				className={`aspect-square rounded-md focus:outline-none p-[.375rem] ${disabled ? 'bg-select-label-disabled cursor-not-allowed' : 'bg-app-bg-gray'} `}
				style={{
					...(!disabled && {
						backgroundColor: sd_color?.hex
					})
				}}
				disabled={disabled}
				ref={setAnchorEl}
			>
				<EyeDropperIcon
					className="[&>*]:invert [&>*]:contrast-[9] [&>*]:grayscale fill-black w-4 h-4"
					style={{
						...(!disabled && {
							fill: sd_color?.hex
						})
					}}
				/>
			</Popover.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        {...props}
      >
        <Popover.Panel 
          className="bg-overlay-bg rounded-xl z-50 max-w-[250px] p-4 mb-4" 
          ref={setPopperEl}
          style={styles.popper}
          {...attributes.popper}
        >

					<div style={styles.arrow} {...attributes.arrow} ref={setArrowEl} className="-bottom-2">
						<div className="bg-overlay-bg rotate-45 z-40 w-4 h-4" />
					</div>

					<RadioGroup 
						value={color}
						onChange={setColor}
						className={`flex justify-between `}
						disabled={disabled} 
					>
						<div className={`flex gap-2 items-center flex-wrap`}>
							<RadioGroup.Option
									value={null}
									className={({ active, checked }) =>
										`
												${
													checked
														? "ui-disabled:bg-gray-label border-app-green "
														: "text-deactive border-app-bg-gray"
												}
												text-white border-2 border-solid 
												ui-disabled:text-select-label-disabled  relative flex cursor-pointer rounded-sm max-md:px-2 md:px-[0.25rem] focus:outline-none text-sm font-roboto font-bold`
									}
									style={{
									}}
								>
									<RadioGroup.Label as="p" >
										Not specified
									</RadioGroup.Label>
								</RadioGroup.Option>
							{SDColors.map((item) => (
								<RadioGroup.Option
									key={item.id}
									value={item.id}
									className={({ active, checked }) =>
										`
												${
													checked
														? "ui-disabled:bg-gray-label  "
														: "text-deactive "
												}
												ui-disabled:text-select-label-disabled  relative flex cursor-pointer rounded-sm max-md:px-1 md:px-1 focus:outline-none text-sm font-roboto font-bold`
									}
									style={{
										backgroundColor: item.hex,
										borderColor: item.hex,
									}}
								>
									<RadioGroup.Label as="p" className="text-black/60" style={{ color: item.textHex }}>{item.name}</RadioGroup.Label>
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>

        </Popover.Panel>
      </Transition>
		</Popover>
	);
}

export default SDColorSelector;
