import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { activateSumolingLicense } from '../reducers/userReducer';
import { toast } from 'react-toastify';

const AppSumo = (props) => {
  const { mode="login" } = props
  const user = useSelector(state => state.userSlice.user);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  // const activate = async () => {
  //   try {
  //     await dispatch(activateSumolingLicense()).unwrap();
  //     localStorage.removeItem("sumoling_instance")
  //   } catch(e) {
  //     console.log(e);
      
  //     return toast(e.error?.message || e.error || "Something went wrong while activating Sumoling's license", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       theme: 'dark',
  //       autoClose: 2000
  //     });
  //   }
  // }

  useEffect(() => {
    const email = searchParams.get("email");
    if (mode === "login") {
      if (email) {
        if (email === user?.email) {
          navigate(`/`)
        } else {
          navigate(`/register?preset_email=${email}`)
        }
      }
      localStorage.setItem("sumoling_instance", "true")
    } 
    // else if(mode === "check" && localStorage.getItem("sumoling_instance") === "true") {
    //   if (!user?.isSumoling) {
    //     activate();
    //   }
    // }
  }, [mode, user, searchParams])

  return (
    <></>
  )
}

export default AppSumo