
export const shortenFields = (obj) => {
	if (obj === null || obj === undefined) return obj;
	if (typeof obj === 'string') {
		if (obj.length > 100) {
			return obj.slice(100) + '...'
		}
		return obj
	}
	if (Array.isArray(obj)) {
		return obj.map(x => shortenFields(x))
	}
	if (typeof obj === 'object') {
		return Object.entries(obj).reduce((prev, [key, val]) => ({
			...prev,
			[key]: shortenFields(val),
		}), {})
	}
	return obj
}
