import ThinOutlineThumbnail from '../assets/ThinOutlineThumbnail.png';
import RoughEdgesThumbnail from '../assets/RoughEdgesThumbnail.png';
import ThickOutlineThumbnail from '../assets/ThickOutlineThumbnail.png';
import ShapeBlocksThumbnail from '../assets/ShapeBlocksThumbnail.png';

const redesignMethods = [
  {
    name: 'Thin outline',
    slug: 'thin-outline',
    thumbnail: ThinOutlineThumbnail,
    maskKey: 'canny',
  },
  {
    name: 'Rough edges',
    slug: 'rough-edges',
    thumbnail: RoughEdgesThumbnail,
    maskKey: 'xdog',
  },
  {
    name: 'Thick outline',
    slug: 'thick-outline',
    thumbnail: ThickOutlineThumbnail,
    maskKey: 'hed',
  },
  {
    name: 'Shape blocks',
    slug: 'shape-blocks',
    thumbnail: ShapeBlocksThumbnail,
    maskKey: 'threshold',
  }
];

export default redesignMethods;