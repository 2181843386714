import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '../../Tooltip';

import { ReactComponent as CursorIcon } from '../../../assets/cursornew.svg';
import { ReactComponent as PanIcon } from '../../../assets/Pan.svg';
import { ReactComponent as EraserIcon } from '../../../assets/EraserNew.svg';
import { ReactComponent as CropIcon } from '../../../assets/Crop.svg';
import { ReactComponent as UploadIcon } from '../../../assets/UploadImageEditorNew.svg';
import { ReactComponent as ImportSvgIcon } from '../../../assets/ImportSvgIcon.svg';
import { ReactComponent as UndoIcon } from '../../../assets/Undo.svg';
import { ReactComponent as RedoIcon } from '../../../assets/Redo.svg';
import { ReactComponent as CleanIcon } from '../../../assets/CleanNew.svg';
import { ReactComponent as CloseIcon } from '../../../assets/CloseEditor.svg';

import { resizeBase64, resizeInputImage } from '../resizers';
import ShapeSelector from '../shapes/ShapeSelector';
import { EDITOR_SIZE } from '../settings';
import PenSelector from '../pen';
import V4PenSelector from '../pen/V4PenSelector';
import TextTool from '../text';
import ColorPicker from '../color';
import BgColorPicker from '../background';
import { toolIconBtnCss } from './const';

import { fabric } from 'fabric';
import {
  RenderedColorPickerIcon,
  RenderedSvgBgColorPicker,
  RenderedSvgStrokeColorPicker,
} from '../selection/selection_icons';
import {
  setBackgroundImage,
  setIsOpen,
} from '../../../reducers/imageEditorReducer';
import { updateInputImage, updateMethod } from '../../../reducers/formReducer';
import AdvancedSettingPopup from '../../input/AdvancedSettingPopup';

function extend(target, objects) {
  // eslint-disable-line no-unused-vars
  const hasOwnProp = Object.prototype.hasOwnProperty;
  let source;
  let prop;
  let i;
  let len;

  for (i = 1, len = arguments.length; i < len; i += 1) {
    source = arguments[i];
    for (prop in source) {
      if (hasOwnProp.call(source, prop)) {
        target[prop] = source[prop];
      }
    }
  }

  return target;
}

export const V4Toolbar = (props) => {
  const {
    editor,
    onSave,
    zoomCenterRef,
    isModal,
    isOpen,
    updateDrawingMode,
    setCurrentTool,
    selectionStyle,
    handleSelectionColorPicker,
    handleStrokeSelectionColorPicker,
  } = props;

  const uploaderRef = useRef();
  const uploadSvgRef = useRef();
  const dispatch = useDispatch();

  const { isEditImage, tool: currentTool } = useSelector(
    (state) => state.imageEditor
  );

  const setSelectTool = async () => {
    setCurrentTool(null);
    await editor.current?.getInstance().stopDrawingMode();
  };

  const setEraserTool = async () => {
    await editor.current?.getInstance().stopDrawingMode();
    await editor.current?.getInstance().deactivateAll();
    // editor.current?.getInstance().changeCursor('grab');

    setCurrentTool('ERASER');
  };

  const setCropTool = async () => {
    if (currentTool === 'CROPPER') {
      await editor.current
        ?.getInstance()
        .crop(editor.current?.getInstance().getCropzoneRect());
      await editor.current
        ?.getInstance()
        .resize({ width: EDITOR_SIZE, height: EDITOR_SIZE });
      await editor.current?.getInstance().stopDrawingMode();

      updateDrawingMode('FREE_DRAWING');
      return;
    }
    await editor.current?.getInstance().stopDrawingMode();
    await editor.current?.getInstance().startDrawingMode('CROPPER');
    await editor.current?.getInstance().setCropzoneRect(1);

    setCurrentTool('CROPPER');
  };

  const setPanTool = async () => {
    await editor.current?.getInstance().stopDrawingMode();
    await editor.current?.getInstance()._graphics.startHandMode();
    // await editor.current?.getInstance().startDrawingMode('RESIZE');

    setCurrentTool('PAN');
  };

  const insertImage = async (e) => {
    const { url, width, height } = await resizeInputImage(
      e.target.files[0],
      Math.floor(editor?.current.getInstance()._graphics._canvas.width * 0.8)
    );

    const imageObj = await editor.current?.getInstance().addImageObject(url);

    await editor.current
      ?.getInstance()
      .setObjectPropertiesQuietly(imageObj.id, {
        left: 256,
        top: 256,
        width,
        height,
      });

    setCurrentTool();
    await editor.current?.getInstance().stopDrawingMode();
  };

  const loadSVG = async (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    fabric.loadSVGFromURL(url, async (objects) => {
      for (let object of objects) {
        // console.log(object, editor?.current?.getInstance()._graphics._canvas)
        if (object.type === 'text' || object.type === 'i-text') {
          object.set(
            extend(
              {
                type: 'i-text',
                fill: object.fill,
                stroke: object.stroke,
              },
              {
                ...selectionStyle,
                controls: {
                  ...selectionStyle.controls,

                  strokeColorPicker: new fabric.Control({
                    x: -0.5,
                    y: 0.5,
                    offsetY: 20,
                    offsetX: 15,
                    cursorStyle: 'pointer',
                    mouseDownHandler: handleStrokeSelectionColorPicker,

                    render: RenderedColorPickerIcon,
                    cornerSize: 32,
                  }),
                },
              },
              editor?.current?.getInstance()._graphics.controlStyle
            )
          );

          await editor.current?.getInstance().addText(object.text, {
            styles: {
              ...object,
            },
            position: {
              x: object.aCoords.tl.x,
              y: object.aCoords.tl.y,
            },
            autofocus: false,
          });
        } else {
          object.set(
            extend(
              {
                type: object.type === 'text' ? 'i-text' : 'icon',
                fill: object.fill,
                stroke: object.stroke,
              },
              selectionStyle,
              editor?.current?.getInstance()._graphics.controlStyle,
              {
                ...selectionStyle,

                controls: {
                  ...selectionStyle.controls,

                  colorPicker: new fabric.Control({
                    x: -0.5,
                    y: 0.5,
                    offsetY: 25,
                    offsetX: -20,
                    cursorStyle: 'pointer',
                    mouseDownHandler: handleSelectionColorPicker,

                    render: RenderedSvgBgColorPicker,
                    cornerSize: 28,
                  }),

                  strokeColorPicker: new fabric.Control({
                    x: -0.5,
                    y: 0.5,
                    offsetY: 25,
                    offsetX: 8,
                    cursorStyle: 'pointer',
                    mouseDownHandler: handleStrokeSelectionColorPicker,

                    render: RenderedSvgStrokeColorPicker,
                    cornerSize: 28,
                  }),
                },
              }
            )
          );
          editor?.current?.getInstance()._graphics.getCanvas().add(object);
          editor?.current?.getInstance()._graphics._addFabricObject(object);

          object.set(
            extend({
              originX: 'center',
              originY: 'center',
            })
          );
          object.setPositionByOrigin(object.aCoords.tl, 'left', 'top');
        }
      }
    });
  };

  const { backgroundImage, zoom } = useSelector((state) => state.imageEditor);

  const resetCanvas = async () => {
    await editor.current?.getInstance().clearObjects();

    const { image } = await resizeBase64(
      backgroundImage,
      EDITOR_SIZE,
      EDITOR_SIZE
    );
    const resp = await fetch(image);
    const url = URL.createObjectURL(await resp.blob());

    await editor.current?.getInstance().loadImageFromURL(url, 'Inspiration');
  };

  const handleRedoUndo = () => {
    editor.current?.getInstance().undo();
  };

  const handleRedoRedo = () => {
    editor.current?.getInstance().redo();
  };

  const method = useSelector((state) => state.form.method);

  const handleRemoveImage = () => {
    if (isOpen) {
      dispatch(setIsOpen(false));
      return;
    }
    dispatch(updateInputImage(null));
    dispatch(setBackgroundImage(null));
    if (method === 'ddd') dispatch(updateMethod('redesign'));
  };

  return (
    <>
      <div className='absolute -top-[50px] left-1/2 -translate-x-1/2 flex flex-row gap-[7px] 3xl:gap-3 border border-app-search-gray  bg-app-bg-gray py-3 px-2 rounded-[6px] group-hover/editor:top-1 group-focus/editor:top-1 transition-all duration-300' >
        <Tooltip title='Add Image' position='bottom'>
          <button
            className={`bg-app-black w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1 flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
            onClick={() => uploaderRef.current.click()}
          >
            <UploadIcon className='aspect-square w-full [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
          </button>
          <input
            type='file'
            className='hidden'
            ref={uploaderRef}
            onChange={insertImage}
          />
        </Tooltip>
        <Tooltip title='Import SVG' position='bottom'>
          <button
            className={`bg-app-black w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1 flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
            type='button'
            onClick={() => uploadSvgRef.current.click()}
          >
            <ImportSvgIcon className='aspect-square w-full [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
          </button>
          <input
            type='file'
            className='hidden'
            ref={uploadSvgRef}
            onChange={loadSVG}
            accept='.svg'
          />
        </Tooltip>
        <Tooltip title='Select' position='bottom'>
          <button
            className={`${
              currentTool ? 'bg-app-black' : 'bg-divider'
            } ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1 `}
            onClick={setSelectTool}
          >
            <CursorIcon
              className={`aspect-square w-full [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green group-hover:[&_path]:fill-app-green ${
                !currentTool ? '[&_path]:stroke-app-green [&_path]:fill-app-green' : ''
              }`}
            />
          </button>
        </Tooltip>
        <Tooltip title='Delete elements' position='bottom'>
          <button
            className={`${
              currentTool === 'ERASER' ? 'bg-divider' : 'bg-app-black '
            } ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px]  p-1 `}
            onClick={setEraserTool}Double click to edit
          >
            <EraserIcon
              className={`aspect-square w-full [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green ${
                currentTool === 'ERASER' ? '[&_path]:fill-app-green' : ''
              }`}
            />
          </button>
        </Tooltip>
        <ShapeSelector
          isImageWorkSpace={true}
          setSelectTool={setSelectTool}
          editor={editor}
        />
        <TextTool
          isImageWorkSpace={true}
          editor={editor}
          updateDrawingMode={updateDrawingMode}
        />
        <ColorPicker isImageWorkSpace={true} editor={editor} />
        <V4PenSelector
          isImageWorkSpace={true}
          updateDrawingMode={updateDrawingMode}
          editor={editor}
        />
        <Tooltip title='Move around' position='bottom'>
          <button
            className={`${
              currentTool === 'PAN' ? 'bg-divider' : 'bg-app-black '
            } ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1 `}
            onClick={setPanTool}
          >
            <PanIcon
              className={`aspect-square w-full [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green ${
                currentTool === 'PAN' ? '[&_path]:stroke-app-green' : ''
              }`}
            />
          </button>
        </Tooltip>

        <Tooltip title='Crop' position='bottom'>
          <button
            className={`${
              currentTool === 'CROPPER' ? 'bg-divider' : 'bg-app-black '
            } ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1 `}
            onClick={setCropTool}
          >
            <CropIcon
              className={`aspect-square w-full [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green ${
                currentTool === 'CROPPER'
                  ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green'
                  : ''
              }`}
            />
          </button>
        </Tooltip>

        {!isEditImage && (
          <BgColorPicker
            isImageWorkSpace={true}
            setCurrentTool={setCurrentTool}
            editor={editor}
          />
        )}
        <div>
          <Tooltip title='Clear everything' position='bottom'>
            <button
              className={`bg-app-black p-1 w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
              onClick={resetCanvas}
            >
              <CleanIcon
                className={`aspect-square w-full [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green`}
              />
            </button>
          </Tooltip>
        </div>
        <Tooltip title='Cancel' position='bottom'>
          <button
            className={`bg-app-black p-[0.375rem] 3xl:p-2 w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] flex items-center justify-center rounded-lg cursor-pointer aspect-square group`}
            onClick={handleRemoveImage}
          >
            <CloseIcon
              className={`aspect-square w-full  [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green`}
            />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default V4Toolbar;
