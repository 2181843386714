import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import SaturationPicker from "react-color/lib/components/common/Saturation";
import AlphaPicker from "react-color/lib/components/common/Alpha";
import { HuePicker } from 'react-color';

import LdTooltip from "../../Tooltip";
import { addPresetColor, selectColor, setBrushSize, setStrokeSize } from "../../../reducers/imageEditorReducer";
import { ReactComponent as EyeDropperIcon } from "../../../assets/Eyedropper.svg";
import CirclePicker from "./CirclePicker";
import { BRUSH_SIZES } from "../brush/brush_sizes";
import { STROKE_SIZES } from "../stroke/stroke_widths";
import StrokeSlider from "../stroke/StrokeSlider";

const SaturationPointer = (props) => {
  const { hsl } = props;
  return (
    <div className="h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1/2 mt-[0.125rem]" style={{ backgroundColor: `#${tinycolor(hsl).toHex8()}` }}/>
  );
}

const AlphaPointer = (props) => {
	const { activeColor: currentColor } = props
  const activeColor = useSelector((state) => state.imageEditor.activeColor);
  return (
    <div className="h-5 w-5 rounded-full border-[2px] border-solid border-white -translate-x-1/2 -translate-y-1 mt-[0.125rem]" style={{ backgroundColor: currentColor || activeColor }}/>
  );
}

const eyeDropperActive = window.EyeDropper !== undefined;

export const ColorPanel = ({
  editor,
	localColorSelect,
	value,
	onChange,
	isOpen,
}) => {
  const dispatch = useDispatch();
  const [_newColor, _setNewColor] = useState();

	const newColor = useMemo(() => value === undefined ? _newColor : value, [value, _newColor]);
	const setNewColor = useMemo(() => value === undefined ? _setNewColor : onChange, [value, onChange, _setNewColor]);

  const pickerType = useSelector((state) => state.imageEditor.selection.pickerType);
  const pickerOpen = useSelector((state) => state.imageEditor.selection.pickerOpen);

  const {
    activeColor,
    presetColors,
    brushSize,
    strokeSize,
    selectedElement,
  } = useSelector((state) => state.imageEditor);

  const activeColorObj = useMemo(() => tinycolor(newColor || activeColor), [newColor, activeColor]);

  const pickColor = async (color) => {
		if (color)
			setNewColor(color);
		if (localColorSelect) return;
		if (color) {
			dispatch(selectColor(color));
			await editor.current?.getInstance().setBrush({
				width: brushSize,
				color: color,
			});
		}

    if (selectedElement?.id) {
      switch (selectedElement.type) {
        case "i-text":
          await editor
            .current
            .getInstance()
            .changeTextStyle(selectedElement.id, { fill: color });
          break;
        case "icon":
					if (pickerOpen && pickerType === 'stroke') {
						await editor
							.current
							.getInstance()
							.setObjectProperties(selectedElement.id, {
								stroke: color,
							});
					} else {
						await editor
							.current
							.getInstance()
							.changeIconColor(selectedElement.id, color);
					}
          break;
        case "path":
          await editor
            .current
            .getInstance()
            .changeShape(selectedElement.id, { stroke: color });
          break;

        default:
      }
    }
  };

  const selectFromEyedropper = async () => {
    try {
      let eyeDropper = new window.EyeDropper();
      let pickedColor = await eyeDropper.open();
      pickColor(tinycolor(pickedColor.sRGBHex).toHex8String());
    } catch (e) {
      console.log(e);
    }
  };

  const loadInitialObjectColor = useCallback((obj, editor) => {
    if (!obj?.id) return ''
    const curObj = editor.current?.getInstance()._graphics._canvas._activeObject
  	if (pickerType === 'fill') {
			setNewColor(tinycolor(curObj.fill))
		} else {
			if (curObj.stroke)
				setNewColor(tinycolor(curObj.stroke))
			dispatch(setBrushSize(curObj.strokeWidth));
		}
  }, [dispatch, pickerType, setNewColor]);

  useEffect(() => {
		loadInitialObjectColor(selectedElement, editor)
  }, [editor, selectedElement, pickerType, isOpen, loadInitialObjectColor])

	// useEffect(() => {
		// if (isOpen) selectFromEyedropper();
	// }, [isOpen]);


  const clickStrokeSize = async (size) => {
    dispatch(setStrokeSize(size));

		await editor
			.current
			.getInstance()
			.setObjectProperties(selectedElement.id, {
				strokeWidth: size,
			});
  }


  return (
    <>
      <div className="flex flex-row justify-between gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <LdTooltip
              title={
                eyeDropperActive
                  ? "Grab a color from the window"
                  : "Your browser doesn't support this feature"
              }
            >
              <button
                className="aspect-square rounded-xl bg-picker-button focus:bg-ld-purple disabled:bg-select-label-disabled focus:outline-none p-3"
                style={{
                  background: newColor || activeColor,
                }}
                onClick={selectFromEyedropper}
                disabled={!eyeDropperActive}
              >
                <EyeDropperIcon
                  className="[&>*]:invert [&>*]:contrast-[9] [&>*]:grayscale fill-black"
                  style={{
                    fill: newColor || activeColor,
                  }}
                />
              </button>
            </LdTooltip>

            <div className="flex flex-col justify-between gap-0">
              <span className="text-editor-label text-sm">Color Picker</span>
              <input
                value={newColor || activeColor}
                onChange={(e) => pickColor(e.target.value)}
                className="!outline-none text-center bg-divider rounded-md w-20 text-xs text-input-color py-1 px-2"
              />
            </div>
          </div>

          <div className="rounded-full relative bg-white bg-opacity-20 h-4 w-[calc(110px+1.25rem)]">
            <AlphaPicker
              pointer={(props) => <AlphaPointer {...props} activeColor={newColor || activeColor}/>}
              rgb={activeColorObj.toRgb()}
              hsl={activeColorObj.toHsl()}
              // color={activeColorObj}
              width={110}
              style={{
                // picker: { borderRadius: "999px" },
                picker: { borderRadius: "999px" },
                alpha: { borderRadius: "999px" },
                gradient: { borderRadius: "999px" },
                checkboard: { borderRadius: "999px" },
                container: {
                  margin: '0 auto',
                  scale: '0.9',
                }
              }}
              onChange={(color) => {
                pickColor(tinycolor(color).toHex8String());
              }}
            />
          </div>
          <div className="rounded-full relative bg-white bg-opacity-20 h-4 w-[calc(110px+1.25rem)] [&_.hue-horizontal]:!rounded-full">
            <HuePicker
              color={newColor || activeColor}
              onChange={(color) => {
                pickColor(tinycolor(color.rgb).toHex8String());
              }}
              pointer={(props) => <AlphaPointer {...props} activeColor={newColor || activeColor}/>}
              width={'130px'}
              styles={{
                default: {
                  "hue": {
                    borderRadius: '1000px'
                  }
                }
              }}
            />
          </div>

					{pickerType === 'stroke' && (
						<div className="relative h-4 w-[calc(110px+1.25rem)] ">
							<StrokeSlider editor={editor} />
						</div>
					)}
        </div>

        <div className="aspect-square relative w-[130px]">
          <SaturationPicker
            hsl={activeColorObj.toHsl()}
            hsv={activeColorObj.toHsv()}
            onChange={(color) => {
              pickColor(tinycolor(color).toHex8String());
            }}
            pointer={SaturationPointer}

            style={{ 
              color: { borderRadius: '0.25rem' },
              white: { borderRadius: '0.25rem' },
              black: { borderRadius: '0.25rem' },
            }}
          />
        </div>

      </div>

      <div className="flex flex-col items-start gap-2 pt-2">
        <CirclePicker
					showBlank={pickerType === 'stroke'}
          colors={presetColors}
          activeColor={newColor || activeColor}
          onChange={pickColor}
          onColorAdded={(color) => dispatch(addPresetColor(color))}
        />
      </div>
    </>
  )
}
