import { useState } from "react";
import axios from 'axios';

import { ReactComponent as TrashIcon } from '../assets/TrashIconGray.svg';
import environment from "../helpers/envProvider";

const API_URL = environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';

const userFields = {
  0: 'email',
  1: 'firstName',
  15: 'createdAt',
  16: 'updatedAt',
  18: 'whitelisted'
}

function AdminInvites() {
  const [file, setFile] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [headers, setHeaders] = useState(null);
  const [allRows, setAllRows] = useState(null);
  const [rows, setRows] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  }

  const handleReadFile = () => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;

        const allRowData = text.split('\n');
        const headersData = allRowData[0];

        const newHeaders = [];

        Object.keys(userFields).forEach(fieldIndex => {
          newHeaders.push(headersData.split(',')[fieldIndex].replace(/"/g, ''))
        });

        setHeaders(newHeaders);

        const formattedRowData = [];

        allRowData.slice(1, allRowData.length).forEach(row => {
          let user = {};
          const splitRow = row.split(',');

          Object.keys(userFields).forEach(fieldIndex => {
            if(splitRow[fieldIndex]) {
              user[userFields[fieldIndex]] = splitRow[fieldIndex].replace(/"/g, '');
            }
          });

          if(user.email && user.email !== '') {
            formattedRowData.push(user);
          }
        })

        console.log(formattedRowData);

        setAllRows(formattedRowData);
        setHasSubmitted(true);
      };

      fileReader.readAsText(file);
    }
  }

  const handleCheckboxChange = (row) => {
    const newRowData = {
      ...row,
      whitelisted: row.whitelisted === 'true' ? 'false' : 'true'
    }

    const rowIndex = allRows.findIndex(r => r.email === row.email);
    
    const newRows = [...allRows];

    newRows[rowIndex] = newRowData;

    setAllRows(newRows);
  };

  const removeRow = (row) => {
    const newRows = allRows.filter(r => r.email !== row.email);

    setAllRows(newRows);
  }

  const showNextPage = () => {
    if(page + 1 < Math.ceil(allRows.length / perPage)) {
      setPage(page + 1);
    }
  }

  const showPrevPage = () => {
    if(page !== 0) {
      setPage(page - 1);
    }
  }

  const handleSubmit = async () => {
    const body = allRows.map(row => ({
        email: row.email,
        custom_fields: {
          'w1_T': row.whitelisted
        }
    }));

    console.log(body);

    try {
      await axios.post(`/api/emails/update-contacts`, {
        contacts: body
      });
    } catch (error) {
      console.log(error);
      console.log(error.response.body);
    }
  }

  return (
    <div className="py-10 px-10">
      {
        !hasSubmitted
          ? (
            <div>
              <input type="file" accept=".csv" onChange={handleOnChange} />
              <button onClick={handleReadFile}>Read file</button>
            </div>
          )
          : (
            <div>
              <table className="w-full border-separate mb-4">
                <thead>
                  <tr>
                    {headers.map(row => (
                      <th className="text-left py-1">{row}</th>
                    ))}
                    <th className="text-right py-1">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {allRows.map((row, i) => (
                    <tr className={i % 2 === 0 ? 'bg-gray-200' : 'bg-transparent'}>
                      <td className="py-2 px-2 align-middle">{row.email}</td>
                      <td className="py-2 px-2 align-middle">{row.firstName}</td>
                      <td className="py-2 px-2 align-middle">{row.createdAt}</td>
                      <td className="py-2 px-2 align-middle">{row.updatedAt}</td>
                      <td className="py-2 px-2 align-middle text-center">
                        <input type="checkbox" checked={row.whitelisted === 'true'} onChange={e => handleCheckboxChange(row)} />
                      </td>
                      <td className="py-2 px-2 align-middle text-center">
                        <button onClick={e => removeRow(row)}>
                          <TrashIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-between items-center">
                <div className="flex gap-4 items-center">
                  <button onClick={e => showPrevPage()}>Prev</button>
                  <span>{page + 1} / {Math.ceil(allRows.length / perPage)}</span>
                  <button onClick={e => showNextPage()}>Next</button>
                </div>

                <button
                  className="bg-blue-600 text-white p-2 font-bold px-4"
                  onClick={e => handleSubmit()}
                >
                  Add to whitelist
                </button>
              </div>
            </div>
          )
      }
    </div>
  );
}

export default AdminInvites;
