import { Helmet } from 'react-helmet';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

import UserLayout from '../layouts/UserLayout';
import CustomToogleSwitch from '../components/base/ToogleSwitch';
import ImageComparison from '../components/ImageComparison';
import SpinnerLoader from '../components/base/SpinnerLoader';
import Tooltip from '../components/Tooltip';
import {
  upscaleImageTool,
  updateAfterUpscaleImage,
} from '../reducers/formReducer';

import { ReactComponent as UpscaleIcon } from '../assets/upscale-icon-white.svg';
import { ReactComponent as UpscaleUploadImageIcon } from '../assets/UpscaleUploadImageIcon.svg';
import { ReactComponent as UpscaleLoadingIcon } from '../assets/upscale-icon.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';
import { ReactComponent as Download } from '../assets/DownloadIcon.svg';
import { ReactComponent as PenIcon } from '../assets/MagicPen.svg';
import Radio from '../components/base/Radio';

function mapToFloat(value) {
  switch (value) {
    case 1:
      return 0.01;
    case 2:
      return 0.05;
    case 3:
      return 0.1;
    case 4:
      return 0.15;
    case 5:
      return 0.2;
    case 6:
      return 0.25;
    case 7:
      return 0.3;
    case 8:
      return 0.35;
    case 9:
      return 0.4;
    case 10:
      return 0.45;
    default:
      return 0; // Default value when input is not 1-10
  }
}

const UpdatedUpscale = (props) => {
  const user = useSelector((state) => state.userSlice.user);
  const afterImage = useSelector((state) =>
    state.form.afterUpscaleImage
      ? `data:image/png;base64,${state.form.afterUpscaleImage}`
      : null
  );
  const isUpscalingTool = useSelector((state) => state.form.isUpscalingTool);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const dispatch = useDispatch();
  const uploadRef = useRef();

  const [beforeImage, setBeforeImage] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showUpscalerSpinner, setShowUpscalerSpinner] = useState(false);
  // const [isScaleChecked, setIsScaleChecked] = useState(false);
  const isImageUploaded = !!beforeImage;

	const [config, setConfig] = useState({
    detailer_steps: 6,
    detailer_denoise: 0.25,
    detailer_cfg: 2,

    steps: 4,
    denoise: 0.4,
    cfg: 2,

    saturation: 1.1,
    sharpness: 1.7,

    scale_by: 0.5,

		seed: undefined,

		prompt: '',
		negative_prompt: '',
	})

  const [sliderValue, setSlideValue] = useState(2);

  const doneUpscale = useMemo(() => {
    return afterImage && beforeImage;
  }, [afterImage, beforeImage]);

  const handleImageUpload = useCallback(
    (event) => {
      const file = event.target.files[0];

      if (file && file.type.startsWith('image/')) {
        Resizer.imageFileResizer(
          file,
          1344,
          1344,
          'PNG',
          100,
          0,
          (uri) => {
            setBeforeImage(uri);
            setFileName(file.name);
          },
          'base64',
          512
        );
      }
    },
    [setBeforeImage, setFileName]
  );

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('image/')) {
        Resizer.imageFileResizer(
          file,
          1344,
          1344,
          'PNG',
          100,
          0,
          (uri) => {
            setBeforeImage(uri);
            setFileName(file.name);
          },
          'base64',
          512
        );
      }
    },
    [setBeforeImage, setFileName]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleRemoveImage = useCallback(() => {
    setBeforeImage(null);
    setFileName(null);
  }, [setBeforeImage, setFileName]);

  const handleUpscale = async (e) => {
    e.stopPropagation();
    setShowUpscalerSpinner(true);
    try {
      await dispatch(
        upscaleImageTool({
					...config,
					endpoint: 'v3/upscale-updated',
					inputImage: beforeImage,
        })
      ).unwrap();
    } catch (e) {
      console.log(e);

      toast(e?.error?.detail || 'Failed to upscale image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowUpscalerSpinner(false);
  };
  const handleUpscaleDev = async (e) => {
    e.stopPropagation();
    setShowUpscalerSpinner(true);
    try {
      await dispatch(
        upscaleImageTool({
					...config,
					endpoint: 'v3/upscale-updated',
					inputImage: beforeImage,
        })
      ).unwrap();
    } catch (e) {
      console.log(e);

      toast(e?.error?.detail || 'Failed to upscale image', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
    setShowUpscalerSpinner(false);
  };

  const handleBack = useCallback(() => {
    dispatch(updateAfterUpscaleImage());
    // setBeforeImage(null);
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateAfterUpscaleImage());
  }, []);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = afterImage;
    link.download = `LoDi-${fileName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const onChangeScaleToggleSwitch = () => {
  //   setIsScaleChecked(!isScaleChecked);
  // };

  const handleInputChange = (value, key) => {
    setConfig({
			...config,
			[key]: value
		});
  };

  const handleZoomChange = (e) => {
    setSlideValue(e.target.value);
  };

  return (
    <UserLayout containerClasses='flex flex-col items-center justify-center h-full'>
      <Helmet>
        <title>Upscale | Logo Diffusion</title>
      </Helmet>
      {!isUpscalingTool && !doneUpscale ? (
        <div className='flex flex-col items-center w-full'>
          <UpscaleIcon className='w-12 h-12' />
          <h1 className='text-white text-[52px] text-center font-bold mt-4 uppercase'>
            Upscale images
          </h1>
          <p className='text-base text-app-green mt-[12px]'>
            Upscale your images in seconds with logo Diffusion
          </p>
          {!beforeImage ? (
            <div className='flex items-center justify-center bg-app-bg-gray h-[240px] w-[80%] mt-[42.19px] rounded-[8px] p-4'>
              <input
                type='file'
                accept='image/*'
                className='hidden'
                ref={uploadRef}
                onChange={handleImageUpload}
              />
              <div
                className='w-full h-full border-2 border-[#30303E] border-dashed rounded flex items-center justify-center'
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <button
                  type='button'
                  className='w-full flex flex-col items-center'
                  onClick={() => uploadRef.current.click()}
                >
                  <UpscaleUploadImageIcon className='w-8 h-8 mb-4' />
                  <p className='text-white text-base'>
                    Click to upload your image or drag and drop it here
                  </p>
                </button>
              </div>
            </div>
          ) : (
            <div className='flex items-center justify-center bg-app-bg-gray w-[250px] h-[auto] mt-[42.19px] rounded-[36px] p-4'>
              <div className='w-full h-full border-2 border-[#30303E] border-dashed rounded-[28px] flex items-center justify-center'>
                <div className='flex flex-col w-full h-full p-4 rounded-[20px]'>
                  <img
                    src={beforeImage}
                    alt='uploaded'
                    className='max-w-full h-auto rounded-t-[14px]'
                  />
                  <div className='flex items-center justify-between whitespace-nowrap w-full bg-[#E7E7E7] rounded-b-[14px] px-3 py-2'>
                    <p className='text-ellipsis overflow-hidden'>{fileName}</p>
                    <button
                      onClick={handleRemoveImage}
                      className='text-red-500 rounded text-2xl'
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isDevModeEnabled ? (
            <>
              <div className='flex flex-col w-auto gap-[17px] px-4 bg-app-bg-gray rounded-[24px] mt-[38px] py-4'>
								<Radio
									label={"Detailer Steps"}
									values={[]}
									value={config.detailer_steps}
									onChange={(e) => handleInputChange(e, 'detailer_steps')}
								/>
								<Radio
									label={"Detailer Denoise"}
									values={[]}
									value={config.detailer_denoise}
									onChange={(e) => handleInputChange(e, 'detailer_denoise')}
								/>
								<Radio
									label={"Detailer CFG"}
									values={[]}
									value={config.detailer_cfg}
									onChange={(e) => handleInputChange(e, 'detailer_cfg')}
								/>

								<Radio
									label={"Steps"}
									values={[]}
									value={config.steps}
									onChange={(e) => handleInputChange(e, 'steps')}
								/>
								<Radio
									label={"Denoise"}
									values={[]}
									value={config.denoise}
									onChange={(e) => handleInputChange(e, 'denoise')}
								/>
								<Radio
									label={"Cfg"}
									values={[]}
									value={config.cfg}
									onChange={(e) => handleInputChange(e, 'cfg')}
								/>

								<Radio
									label={"Saturation"}
									values={[]}
									value={config.saturation}
									onChange={(e) => handleInputChange(e, 'saturation')}
								/>
								<Radio
									label={"Sharpness"}
									values={[]}
									value={config.sharpness}
									onChange={(e) => handleInputChange(e, 'sharpness')}
								/>

								<Radio
									label={"Scale By"}
									values={[]}
									value={config.scale_by}
									onChange={(e) => handleInputChange(e, 'scale_by')}
								/>
								<Radio
									label={"Seed"}
									values={[]}
									value={config.seed}
									onChange={(e) => handleInputChange(e, 'scale')}
								/>

								<Radio
									label={"Prompt"}
									values={[]}
									value={config.prompt}
									onChange={(e) => handleInputChange(e, 'prompt')}
								/>
								<Radio
									label={"Negative Prompt"}
									values={[]}
									value={config.negative_prompt}
									onChange={(e) =>
										handleInputChange(e, 'negative_prompt')
									}
								/>
                <button
                  className={`flex justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px] cursor-pointer ${
                    !isImageUploaded
                      ? 'pointer-events-none opacity-50'
                      : ''
                  }`}
                  onClick={handleUpscaleDev}
                  disabled={!isImageUploaded}
                >
                  Upscale{' '}
                  <span className='text-[10px] text-[#B7A3F1]'>
                    {' '}
                    (2) credits
                  </span>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className='flex items-center justify-evenly max-w-[530px] w-full gap-[17px] px-4 h-[72px] bg-app-bg-gray rounded-[24px] mt-[38px]'>
                <p className='text-[#ACACAC] text-sm text-center'>
                  A.I Strength
                </p>
                <div className='flex gap-1'>
                </div>
                {/* <CustomToogleSwitch
                  onValue='x2'
                  offValue='x4'
                  isChecked={isScaleChecked}
                  handleCheckboxChange={onChangeScaleToggleSwitch}
                ></CustomToogleSwitch> */}
                <div className='bg-switch-bg-gray cursor-pointer h-[40px] rounded-[12px] p-[2px]'>
                  <p className='text-switch-label-green bg-app-green px-4 flex items-center h-[36px] rounded-[10px]'>
                    2x
                  </p>
                </div>

                <button
                  className={`flex whitespace-nowrap justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px] cursor-pointer ${
                    !isImageUploaded ? 'pointer-events-none opacity-50' : ''
                  }`}
                  onClick={handleUpscale}
                  disabled={!isImageUploaded}
                >
                  Upscale{' '}
                  <span className='text-[10px] text-[#B7A3F1]'>
                    {' '}
                    (2) credits
                  </span>
                </button>
              </div>
              <div className='flex items-center justify-center text-switch-bg-gray px-4 max-w-[530px] w-full py-[11px]'>
                ---Optional---
              </div>
              <div className='flex items-center justify-between gap-[17px] px-4 h-[72px] bg-app-bg-gray rounded-[24px] max-w-[530px] w-full'>
                <div className='w-[22%] flex items-center gap-[17px]'>
                  <p className='text-[#ACACAC] text-sm text-center whitespace-nowrap'>
                    Upscale Prompt
                  </p>
                  {/* <Tooltip title='Auto prompt'>
                    <div className='bg-[#3DD598] p-[2px] flex items-center justify-center rounded-[6px]'>
                      <PenIcon />
                    </div>
                  </Tooltip> */}
                </div>
                <div className='w-[78%]'>
                  <input
                    className='rounded-[13px] h-[48px] w-full text-white bg-switch-bg-gray p-2'
                    type='text'
                    placeholder='Describe your Image'
                    value={config.prompt}
                    onChange={(e) => handleInputChange(e.target.value, 'prompt')}
                  />
                </div>
              </div>
              <div className='flex items-center justify-between gap-[17px] px-4 h-[72px] bg-app-bg-gray rounded-[24px] mt-[18px] max-w-[530px] w-full'>
                <div className='w-[22%] flex items-start'>
                  <p className='text-[#ACACAC] text-sm text-center whitespace-nowrap'>
                    Negative prompt
                  </p>
                </div>
                <div className='w-[78%]'>
                  <input
                    className='rounded-[13px] h-[48px] w-full text-white bg-switch-bg-gray p-2'
                    type='text'
                    placeholder='Describe What to Avoid'
                    value={config.negative_prompt}
                    onChange={(e) =>
                      handleInputChange(e.target.value, 'negative_prompt')
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className='flex flex-col items-center px-5 py-2'>
          {!doneUpscale ? (
            <div className='relative flex items-center justify-center bg-app-bg-gray h-auto max-h-[77vh] w-auto max-w-[90vw] mt-[21px] rounded-[20px] border-[4.3px] border-[#30303E] border-dashed'>
              <img
                src={beforeImage}
                alt='uploaded'
                className='w-full h-full object-cover rounded-[20px]'
              />
              <SpinnerLoader
                classNames={{
                  backdrop: 'z-30 !rounded-[20px]',
                }}
                isLoading={showUpscalerSpinner}
                icon={<UpscaleLoadingIcon />}
              />
            </div>
          ) : (
            <div className='relative h-auto max-h-[77vh] w-auto max-w-[90vw]'>
              <ImageComparison
                beforeImage={beforeImage}
                afterImage={afterImage}
              />
            </div>
          )}
          <div className='flex items-center justify-evenly w-auto gap-[17px] px-4 h-[72px] bg-app-bg-gray rounded-[24px] mt-[38px]'>
            {!doneUpscale && (
              <button className='flex justify-center items-center h-[40px] px-[10px] text-[14px] font-semibold text-white bg-button-purple rounded-[12px]'>
                Upscaling{' '}
                <span className='text-[10px] text-[#B7A3F1]'>...</span>
              </button>
            )}
            {doneUpscale && (
              <>
                <button
                  className='flex justify-center items-center h-[40px] px-[12px] gap-1 text-[14px] font-semibold text-[#ACACAC] bg-[#30303E] rounded-[12px]'
                  onClick={handleBack}
                >
                  <ArrowLeft />
                  Back
                </button>

                <button
                  className='flex justify-center items-center h-[40px] px-[12px] gap-1 text-[14px] font-semibold text-white bg-button-purple rounded-[12px]'
                  onClick={handleDownload}
                >
                  Download
                  <Download />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </UserLayout>
  );
};

export default UpdatedUpscale;
