import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconSelectorIcon } from '../../../assets/IconSelector.svg';
import LdTooltip from '../../Tooltip';
import { SHAPES, SHAPE_METADATA, SVG_RESIZE_MULTIPLIER } from './shapes';
import {
  updateSelectedElement,
  updateSelectedShape,
} from '../../../reducers/imageEditorReducer';
import { toolIconBtnCss } from '../toolbar/const';
import { EDITOR_SIZE } from '../settings';

const Icon = (props) => {
  const { editor, name, icon, setSelectTool } = props;
  const selectedShape = useSelector((state) => state.imageEditor.selectedShape);
  const activeColor = useSelector((state) => state.imageEditor.activeColor);
  // const canvasSize = useSelector((state) => state.imageEditor.canvasSize);

  const dispatch = useDispatch();

  const select = async () => {
    dispatch(updateSelectedShape(name));
    const newEl = await editor.current?.getInstance().addIcon(name, {
      fill: activeColor,
      left: EDITOR_SIZE / 2,
      top: EDITOR_SIZE / 2,
    });

    dispatch(updateSelectedElement(newEl));

    setSelectTool('');
  };

  return (
    <button
      onClick={select}
      className={`flex items-center justify-center hover:[&>path]:stroke-app-green ${
        selectedShape === name
          ? '[&_path]:fill-app-green'
          : '[&_path]:fill-icon-fill'
      }`}
    >
      <svg
        width='30'
        height='30'
        viewBox={`0 0 ${
          SHAPE_METADATA[name]?.canvasWidth || 30 * SVG_RESIZE_MULTIPLIER
        } ${SHAPE_METADATA[name]?.canvasHeight || 30 * SVG_RESIZE_MULTIPLIER}`}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d={icon} />
      </svg>
    </button>
  );
};

const ShapeSelector = (props) => {
  const { editor, setSelectTool } = props;

  const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [arrowElement, setArrowElement] = useState();
  let { styles, attributes } = usePopper(anchorEl, popperEl, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: props.isImageWorkSpace
          ? { offset: [0, 20] }
          : { offset: [0, 10] },
      },
    ],
    placement: 'bottom',
  });

  return (
    <Popover className='relative overflow-visible'>
      {({ open, close }) => (
        <>
          <LdTooltip title='Add Icons' position='bottom'>
            <Popover.Button
              className={`bg-app-black ${toolIconBtnCss} !outline-none w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px]  p-1 `}
              ref={setAnchorEl}
            >
              <IconSelectorIcon
                className={`max-lg:aspect-square [&_path]:stroke-active-icon group-hover:[&_path]:stroke-app-green `}
              />
            </Popover.Button>
          </LdTooltip>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              // className="absolute left-1/2 -translate-x-1/2 z-20 w-[200px] px-4 sm:px-0 lg:max-w-3xl mb-2"
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
              className='my-2'
            >
              <div
                ref={setArrowElement}
                style={styles.arrow}
                {...attributes.arrow}
                className='w-3 h-3 overflow-hidden inline-block bottom-full'
              >
                <div className='h-full w-full bg-[#1c1c25ff] translate-x-1/2 translate-y-1/2 rotate-45 origin-top-left'></div>
              </div>
              <div className='overflow-hidden rounded-lg bg-[#1c1c25ff] shadow-2xl py-2 px-2 w-[270px] z-50 grid grid-cols-7 gap-2 '>
                {Object.entries(SHAPES).map(([name, icon]) => (
                  <Icon
                    editor={editor}
                    setSelectTool={setSelectTool}
                    name={name}
                    icon={icon}
                    key={name}
                  />
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ShapeSelector;
