import { createContext } from "react";

export const ImageViewerContext = createContext({
	open: false,

	viewingImageUrl: "",
	viewingImageIndex: -1,
	viewingImage: null,

	generations: [],

	config: {},

	actions: {},

})

