import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { HuePicker } from 'react-color';
import AlphaPicker from 'react-color/lib/components/common/Alpha';
import tinycolor from 'tinycolor2';
import SaturationPicker from 'react-color/lib/components/common/Saturation';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { ReactComponent as BackgroundTool } from '../../../assets/BackgroundTool.svg';
import { AlphaPointer, SaturationPointer } from '../text/TextColorPicker';
import { toolIconBtnCss } from '../toolbar/const';
import { ColorPanel } from '../color/ColorPanel';
import LdTooltip from '../../Tooltip';

const BgColorPicker = (props) => {
  const { editor } = props;

  const [newColorAnchor, setNewColorAnchor] = useState();
  const [newColorElement, setNewColorElement] = useState();
  const [arrowElement, setArrowElement] = useState();
  let { styles: newColorStyles, attributes: newColorAttributes } = usePopper(
    newColorAnchor,
    newColorElement,
    {
      modifiers: [
        {
          name: 'arrow',
          options: { element: arrowElement },
        },
        {
          name: 'offset',
          options: props.isImageWorkSpace
            ? { offset: [0, 20] }
            : { offset: [0, 10] },
        },
      ],
      placement: 'bottom',
    }
  );

  const { activeColor, tool: currentTool } = useSelector(
    (state) => state.imageEditor
  );

  const [newColor, setNewColor] = useState(activeColor);

  const changeBackground = async (color) => {
    const imageEditor = editor.current?.getInstance();
    imageEditor.applyFilter('blendColor', {
      mode: 'tint',
      color: color,
    });
  };

  return (
    <Popover className='relative overflow-visible'>
      {({ open, close }) => (
        <>
          <LdTooltip title='Change Background' position='right'>
            <Popover.Button
              className={`${
                currentTool === 'PAINTER' ? 'bg-divider' : 'bg-app-black'
              } ${toolIconBtnCss} w-[25px] h-[25px] 3xl:w-[30px] 3xl:h-[30px] p-1`}
              ref={setNewColorAnchor}
            >
              <BackgroundTool
                className={`max-lg:aspect-square [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green
                  ${
                    currentTool === 'PAINTER' ? '[&_path]:fill-app-green' : ''
                  }`}
              />
            </Popover.Button>
          </LdTooltip>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              className='z-30'
              ref={setNewColorElement}
              style={newColorStyles.popper}
              {...newColorAttributes.popper}
            >
              <div
                ref={setArrowElement}
                style={newColorStyles.arrow}
                {...newColorAttributes.arrow}
                className='w-3 h-3 overflow-hidden inline-block bottom-full'
              >
                <div className='h-full w-full bg-[#1c1c25ff] translate-x-1/2 translate-y-1/2 rotate-45 origin-top-left'></div>
              </div>
              <div className='rounded-lg bg-[#1c1c25ff] shadow-2xl p-3 z-50 flex flex-col gap-2'>
                <ColorPanel
                  editor={editor}
                  localColorSelect
                  value={newColor}
                  onChange={setNewColor}
                />
                <button
                  className='px-2 py-1 text-white bg-button-purple rounded-md'
                  type='submit'
                  onClick={() => {
                    const color = newColor ? newColor : activeColor;
                    changeBackground(color);
                    close();
                  }}
                >
                  Switch Background Color
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default BgColorPicker;
