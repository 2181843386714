import React, { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { ReferenceField, TextField, useGetOne } from 'react-admin';

const AdminGenerationCard = ({
  _id,
  userId,
	method,
  imageUrl,
  inputImage,
	styleImage,
  subscriptionProvider,
  createdAt,
  promptText,
  negativePromptText,
  contentStyle,
  dimension,
  imageQuality,
  onClick,
  onClickCheckBox,
  isChecked = false,
}) => {
  const [checkStatus, setCheckStatus] = useState(isChecked);
  useEffect(() => {
    setCheckStatus(isChecked);
  }, [isChecked]);
  const handleChange = (e) => {
    e.stopPropagation();
    setCheckStatus(!checkStatus);
    onClickCheckBox(_id, !checkStatus);
  };

  return (
    <Card className='w-[201px] h-[332px]' onClick={onClick}>
      <div className='relative w-full h-[57%]'>
        <div
          className='absolute top-[6px] w-[24px] h-[24px] bg-white left-[6px]  flex justify-center items-center rounded-[4px] cursor-pointer'
          onClick={handleChange}
        >
          <div className='flex items-center justify-center w-[18px] h-[18px] bg-white border-[2px] border-gray-500 rounded-[2px]'>
            {checkStatus && (
              <svg
                width='11'
                height='8'
                viewBox='0 0 11 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.08229 1.2774L3.53646 6.82324L1.01562 4.3024'
                  stroke='#3DD598'
                  stroke-width='1.99451'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            )}
          </div>
        </div>
        <img
          key={imageUrl}
          className='w-full h-full object-cover'
          src={imageUrl}
          alt=''
        />
				{(inputImage || styleImage) && (
					<div className="absolute right-[6px] -bottom-[6px] flex flex-row gap-2">
						{styleImage && (
							<div className="relative">
								<div className="absolute bottom-0 left-0 right-0 bg-black/40 text-white text-center text-xs rounded-b-[5px]">
									Style
								</div>
								<img
									key={styleImage}
									className=' w-[74px] h-[74px] object-cover rounded-[5px] shadow-xl'
									src={styleImage}
									alt='Style'
								/>
							</div>
						)}
						{inputImage && (
							<div className="relative">
								<div className="absolute bottom-0 left-0 right-0 bg-black/40 text-white text-center text-xs rounded-b-[5px]">
									Input
								</div>
								<img
									key={inputImage}
									className=' w-[74px] h-[74px] object-cover rounded-[5px] shadow-xl'
									src={inputImage}
									alt='Input'
								/>
							</div>
						)}
					</div>
				)}
        {subscriptionProvider && (
          <p className='absolute right-[6px] top-[6px] px-[6px] py-[2px] bg-[#979797] text-white text-[10px] rounded-[7px]'>
            {subscriptionProvider === 'stripe'
              ? 'Paid'
              : subscriptionProvider === 'free'
              ? 'Free'
              : subscriptionProvider === 'appsumo'
              ? 'Sumo'
              : null}
          </p>
        )}
      </div>
      <div className='w-full h-[43%] px-2 py-2 flex flex-col items-start justify-evenly'>
				<ReferenceField source="userId" reference="users">
					{/* <TextField className='text-[#1976D2] text-[12px] font-bold'>{user.email}</p> */}
          <TextField source="email" className='text-[#1976D2] text-[12px] font-bold'/>
				</ReferenceField>
        <p className='text-[#BABABA] text-[10px]'>{createdAt}</p>
        <p className='text-[12px] line-clamp-2'>{promptText}</p>
        {negativePromptText && (
          <p className='text-[#940000] line-clamp-2 text-[10px]'>
            {negativePromptText}
          </p>
        )}
        <div className='flex items-center justify-start gap-1 flex-wrap'>
          <p className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
            {method}
          </p>
          <p className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
            {contentStyle}
          </p>
          <p className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
            {dimension}
          </p>
          <p className='bg-app-green rounded-[17px] text-[#08432B] px-[6px] py-[2px] text-[10px] font-bold'>
            Q:{imageQuality}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default AdminGenerationCard;
