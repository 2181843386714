import { BooleanField,Datagrid, List,TextField, } from 'react-admin';

export const BlockedDomainList = () => (
    <List>
        <Datagrid
					rowClick="edit"
				>
            <TextField source="domain" />
            <BooleanField source="blockRegistration" />
        </Datagrid>
    </List>
);

export default BlockedDomainList;
