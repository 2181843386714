import React from 'react';
import { Tooltip } from "react-tippy";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { useSelector } from 'react-redux';
import SliderV2 from './SliderV2';

const FormSlider = (props) => {
  const { values, label, title, value, onChange, disabled, appliedClasses={}, fixedValues=false } = props;

	const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

	if (isDevModeEnabled && !fixedValues) {
		return (
			<div className={`flex justify-between ${appliedClasses.main}`} disabled={disabled}>
				<label className={`text-gray-label font-poppins font-bold text-xs flex gap-1 items-center ${disabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
					{label}
		
					<Tooltip title={title} >
						<InfoIcon className={`${appliedClasses.infoTooltip}`}/>
					</Tooltip>
				</label>
				<div className="flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]">
					<input 
						className={` p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-full ${appliedClasses.optionContainer}`}
						value={value}
						onChange={(e) => onChange(e.target.value)}
						disabled={disabled}
					/>
				</div>
			</div>
		);
	}

  return (
		<div className={`flex justify-between items-center ${appliedClasses.main}`} disabled={disabled}>
			<label className={`text-gray-label font-poppins font-bold text-xs flex gap-1 items-center ${disabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
				{label}
	
				<Tooltip title={title} >
					<InfoIcon className={`${appliedClasses.infoTooltip}`}/>
				</Tooltip>
			</label>
			<div className={`flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%] ${appliedClasses.optionContainer}`}>
				<SliderV2
					acceptedValues={values}
					value={value}
					onChange={onChange}
					disabled={disabled} 
				/>
			</div>
		</div>
  );

}

export default FormSlider;
