import { Dialog, Transition } from '@headlessui/react'
import React, { useEffect, useMemo, useState } from 'react'
import Carousel from '../base/Carousel'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as TutorialPlayIcon } from '../../assets/TutorialPlayIcon.svg';
import { ReactComponent as ChevronDown } from '../../assets/ChevronDownLightGray.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/CheckmarkIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTutorial, setIsTutorialModalOpen, setLoadVideo } from '../../reducers/appReducer';
import useTutorials from '../../hooks/useTutorials';


const TutorialItem = (props) => {
  const { index, selectedIndex } = props;
	const tutorials = useTutorials();
	const calculateRealIndex = (ind) => (ind % tutorials.length + tutorials.length) % tutorials.length;
  const t = tutorials[calculateRealIndex(index)]

	const loadVideo = useSelector((state) => state.appSlice.loadVideo);

  const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (loadVideo === index) {
			setIsPlaying(true);
			setLoadVideo(-1);
		}
	}, [loadVideo, index]);

	if (!t) {
    <div className='w-full h-full overflow-hidden text-lg text-app-search-gray font-bold'>
			No Tutorial Available yet
    </div>
	}

  return (
    <div className='w-full h-full overflow-hidden' key={`car-${t?.ytUrl}`}>
      {(selectedIndex === index && isPlaying) ? (<>
        <iframe
          className="w-full h-full aspect-video rounded-[15px] mb-[30px]"
          src={`${t.ytUrl}?autoplay=1`}
          title={t.name}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
      </iframe>
      </>) : (<>
        <img className='h-full w-full pointer-events-none' src={t?.thumbnail} alt={t?.name}/>
        <button onClick={() => setIsPlaying(true)} className='w-16 h-8 absolute top-[50%] translate-x-[-50%] left-[50%] translate-y-[-50%]'>
          <TutorialPlayIcon className='w-full h-full'/>
        </button>
      </>)}
    </div>
  )
}

const TutorialModal = (props) => {
  const openState = useSelector(state => state.appSlice.isTutorialModalOpen)
  const questionnaireOpen = useSelector(state => state.userSlice.showQuestionnaireModal)
  const questionnaireLoading = useSelector(state => state.userSlice.isLoading)
  const [hideModal, setHideModal] = useState(localStorage.getItem('dont_show_tutorial_modal') === "true")

	const tutorials = useTutorials();
	const calculateRealIndex = (ind) => (ind % tutorials.length + tutorials.length) % tutorials.length;

  // const [index, setIndex] = useState(0);
	const index = useSelector((state) => state.appSlice.currentTutorial);

	const setIndex = (i) => {
		dispatch(setCurrentTutorial(i));
	}

  const open = useMemo(() => openState && !questionnaireOpen && !questionnaireLoading, [questionnaireLoading, questionnaireOpen, openState]);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setIsTutorialModalOpen(false));
    setIndex(0)

    if (hideModal) {
      localStorage.setItem('dont_show_tutorial_modal', "true")
    }
  }

  useEffect(() => {
    if(openState) {
      dispatch(setIsTutorialModalOpen(localStorage.getItem('dont_show_tutorial_modal') !== "true"));
    }
  }, [openState])

	useEffect(() => {
		dispatch(setCurrentTutorial(0));
	}, []);

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog 
        open={open} 
        onClose={onClose}
        className="relative z-50"
      >

        <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >

          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto custom-scroll">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >

              <Dialog.Panel className='w-[600px] max-w-11/12 flex flex-col gap-6 justify-between items-center overflow-hidden relative bg-app-black rounded-md' onClick={(e)=>e.stopPropagation()}>

                <div className='w-full aspect-video'>
                  <Carousel index={index} setIndex={setIndex}>
                    {({ index: curIndex }) => <TutorialItem index={curIndex} selectedIndex={index} key={curIndex} />}
                  </Carousel>
                </div>

                <button className='w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center' onClick={onClose}>
                  <CloseIcon className='w-6 h-6' />
                </button>

                <h3 className='text-modal-title text-sm text-center'>
                  Logo Diffusion video tutorials
                </h3>
                <h1 className='text-white text-2xl font-bold text-center md:mx-16'>
                  {tutorials[calculateRealIndex(index)]?.name}
                </h1>

                <p className='text-modal-description text-sm text-center w-9/12 '>
                  We've created a series of short videos to introduce you to Logo Diffusion. Each video focuses on a specific feature or workflow within the tool, providing step-by-step guidance on how to use it to create your own designs.
                </p>


                <div className='flex flex-row gap-8'>

                  <button className='h-12 p-4 border border-solid border-carousel-button-border bg-app-bg-gray rounded-md group flex items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300'  onClick={() => setIndex(index - 1)}>
                    <ChevronDown className='rotate-90 [&>path]:stroke-white group-hover:[&>path]:stroke-app-green relative right-0' />
                  </button>

                  <div className='flex flex-row gap-2 items-center'>
                    {tutorials.map((item, ind) => (
                      <button className={`${calculateRealIndex(index) === ind ? 'w-4 bg-gradient-to-l from-spinner-blue via-spinner-cyan to-spinner-lime ' : 'w-2 bg-carousel-dot'} h-2 rounded-full transition-all duration-300`} key={`btn-${item.ytUrl}`} onClick={() => setIndex(ind)}></button>
                    ))}
                  </div>

                  <button className='h-12 py-4 pl-3 pr-1 border border-solid border-carousel-button-border bg-app-bg-gray rounded-md group flex gap-1 items-center justify-center transition-all duration-300 [&_path]:transition-all [&_path]:duration-300' onClick={() => setIndex(index + 1)}>
                    <span className='text-carousel-next-count mr-1'>
                      {isNaN(calculateRealIndex(index)) ? 0 : calculateRealIndex(index) + 1}/{tutorials.length}
                    </span>

                    <span className='text-white'>Next</span>

                    <ChevronDown className='-rotate-90 [&>path]:stroke-white group-hover:[&>path]:stroke-app-green relative ml-0 mr-1 group-hover:ml-1 group-hover:mr-0 transition-all duration-300' />
                  </button>


                </div>

                <div className='bg-app-bg-gray w-full p-4 flex items-center justify-center '>
                  <div className='flex flex-row'>
                    <input type="checkbox" id="dont-show" checked={hideModal} onChange={(e) => setHideModal(e.target.checked)}
                        className='appearance-none h-[17px] w-[17px]  rounded-[4px] border-[1.5px] border-solid border-checkmark-border transition-all duration-200 peer'
                    />
                    <CheckmarkIcon className='opacity-0 peer-checked:opacity-100 [&>path]:stroke-checkmark-check absolute rounded-full pointer-events-none my-1 mx-1 transition-all duration-200 w-[9px] h-[9px]'/>
                    <label htmlFor='dont-show' className='flex flex-col justify-center px-2 select-none text-xs text-title-white'>Don't show this again</label>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>

          </div>
        </div>
              
      </Dialog>
    </Transition>
  )
}

export default TutorialModal
