import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { ReactComponent as BrowseIcon } from '../assets/BrowseIcon.svg';
import { ReactComponent as CloudUploadImageIcon } from '../assets/CloudUploadImageIconNew.svg';
import { throttle } from 'lodash';
import authAxiosInstance from '../helpers/auth-axios';
import { Dialog, Transition } from '@headlessui/react';

import { ReactComponent as LoadingDots } from '../assets/LoadingDots.svg';
import { STYLE_TRANSFER_STYLES } from './StyleImage';
import { useDispatch } from 'react-redux';
import { partialUpdateStatePayload } from '../reducers/formReducer';

function BrowseGallery(props) {
	const {
		open, onClose,
		onConfirm, handleImageUpload,
		gallery: extGallery, setGallery: setExtGallery
	} = props

	const dispatch = useDispatch()

	const loadingAnchor = useRef(null);

	const [_gallery, _setGallery] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [loading, setLoading] = useState(false);

	const gallery = useMemo(() => extGallery ?? _gallery, [extGallery, _gallery])

	const setGallery = (v) => {
		if (extGallery) {
			setExtGallery && setExtGallery(v)
		} else {
			_setGallery(v)
		}
	}

  const handleSelect = (v) => {
		if (v && v.imageUrl) {
			onConfirm(v.imageUrl)
			if (v.style && STYLE_TRANSFER_STYLES.find(x => x.value === v.style)) {
				dispatch(partialUpdateStatePayload({
					style: v.style,
				}))
			}
		}
		onClose()
	};

  const handleCancel = () => {
    props.onCancel();
  };

  const uploaderRef = useRef();

	const loadMore = async () => {
		if (loading) return;
		setLoading(true)
		try {
			const response = await authAxiosInstance.get(
				`/api/style-transfer-gallery?range=[${gallery.length},${gallery.length + 60}]`
			);

			const g = [
				...gallery,
				...response.data.docs
			].filter((x, i, a) => a.findIndex(y => y._id === x._id) === i)

			setGallery(g)

			setHasMore(g.length < response.data.totalDocs)
		} catch(e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		loadMore()
	}, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log('Intersection changed');
        if (
          entries[0].isIntersecting
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (loadingAnchor.current) {
      observer.observe(loadingAnchor.current);
    }

    return () => {
      if (loadingAnchor.current) {
        observer.unobserve(loadingAnchor.current);
      }
    };
  }, [loadingAnchor]);

  return (

    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        open={open}
        onClose={onClose}
        className='relative z-40'
      >
        <Transition.Child
          as={React.Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/50' aria-hidden='true' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto custom-scroll'>
          <div className='flex min-h-full items-center justify-center p-4 text-center ' onClick={onClose}>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >

							<div className='p-8 bg-app-bg-gray flex flex-col gap-4 border border-gray-700 max-w-[85%] h-[80vh] xl:h-[90vh] rounded-lg' onClick={(e) => e.stopPropagation()}>
								<h1 className='text-white font-bold text-sm'>
									Select a style image, or upload an image
								</h1>
								<div className='rounded-[20px] bg-[#2A2A36] py-7 pl-7 pr-4 grow max-h-[90%]'>
									<div className='bg-[#2A2A36] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3 overflow-auto pr-4 h-full browse-gallery-custom-scroll custom-scroll'>
										<button
											className='relativ flex flex-col items-center justify-center w-full aspect-square bg-transparent border-[#9C9CAA] border-dashed border-[2px] rounded-[5px]'
											onClick={() => uploaderRef.current.click()}
										>
											<CloudUploadImageIcon
												className={`w-[50px] h-[50px] relative rounded-[8px]`}
											/>
											<p className='text-[#9C9CAA] text-[11px] w-[130px]'>
												Upload image
											</p>
											<input
												ref={uploaderRef}
												type='file'
												id='imgUpload'
												name='img'
												accept='image/*'
												onChange={(e) => {
													if (e.target.files.length > 0) {
														onClose()
													}
													props.handleImageUpload(e.target.files)
												}}
												className=' hidden absolute opacity-0 '
											/>
										</button>
										{gallery.map((image) => {
											return (
												<button onClick={() => handleSelect(image)} className={`aspect-square w-full`} key={image._id}>
													<img
														src={image.optimizedImageUrl || image.imageUrl}
														alt={image.name}
														className={`w-full h-full relative rounded-[5px] `}
													/>
												</button>
											);
										})}

										{hasMore ? (
											<span
												className='flex items-center justify-center'
												id='button-loading'
											>
												<LoadingDots className='h-2' />
											</span>
										) : (
											<div className='relativ flex flex-col items-center justify-center gap-1 w-full aspect-square bg-[#3A3A4C] rounded-[5px]'>
												<BrowseIcon
													className={`w-[50px] h-[50px] relative rounded-[8px]`}
												/>
												<p className='text-[#9C9CAA] text-[11px] w-[130px]'>
													More style images will be added soon...
												</p>
											</div>
										)}
									</div>
								</div>

								<div className='flex gap-2 items-center justify-end w-full'>
									<button
										className='font-bold text-white text-[16px]rounded-md px-[13px] py-[9px] bg-divider rounded-[10px]'
										onClick={onClose}
									>
										Cancel
									</button>
									<button
										className='font-bold text-white text-[16px]rounded-md px-[13px] py-[9px] bg-[#6904E9] rounded-[10px]'
										onClick={handleSelect}
									>
										Select
									</button>
								</div>
							</div>

						</Transition.Child>
					</div>
				</div>
			</Dialog>

		</Transition>
  );
}

export default BrowseGallery;
