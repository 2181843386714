import { Create, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const PostTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.title}` : ''}</span>;
};

export const UserEdit = () => (
  <Create title={<PostTitle />}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="text" />
      <TextInput source="category" />
    </SimpleForm>
  </Create>
);

export default UserEdit;