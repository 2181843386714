
import { Create, SimpleForm, TextInput, BooleanInput, } from 'react-admin';
import Grid from '@mui/material/Grid';

export const BlockedDomainCreate = () => (
    <Create>
        <SimpleForm>
          <Grid container spacing={2}>
						<TextInput source="domain" fullWidth />
						<BooleanInput source="blockRegistration" fullWidth />

          </Grid>
        </SimpleForm>
    </Create>
);

export default BlockedDomainCreate;
