import React from "react";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Tooltip } from "react-tippy";
import { ReactComponent as InfoIcon } from "../../assets/InfoIconGray.svg";
import { useSelector } from "react-redux";

const Radio = (props) => {
  const { values, label, title, value, onChange, disabled, appliedClasses={}, fixedValues=false } = props;

	const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

	if (isDevModeEnabled && !fixedValues) {
		return (
			<div className={`flex justify-between ${appliedClasses.main}`} disabled={disabled}>
				<label className={`text-gray-label font-poppins font-bold text-xs flex gap-1 ${disabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
					{label}
		
					<Tooltip title={title} >
						<InfoIcon className={`${appliedClasses.infoTooltip}`}/>
					</Tooltip>
				</label>
				<div className="flex gap-2 basis-5/12 items-center justify-end grow-0 w-[41%]">
					<input 
						className={` p-1 text-sm text-center bg-transparent font-poppins text-white border border-solid border-select-border rounded-xl disabled:text-select-label-disabled w-full ${appliedClasses.optionContainer}`}
						value={value}
						onChange={(e) => onChange(e.target.value)}
						disabled={disabled}
					/>
				</div>
			</div>
		);
	}

  return (
    <RadioGroup value={value} onChange={onChange} className={`flex justify-between ${appliedClasses.main}`} disabled={disabled}>
      <RadioGroup.Label className={`text-gray-label font-poppins font-bold text-xs flex gap-1 items-center ${disabled ? 'text-select-label-disabled' : ''} ${appliedClasses.label} field-label`}>
        {label}
  
        <Tooltip title={title} >
          <InfoIcon className={`${appliedClasses.infoTooltip}`}/>
        </Tooltip>
      </RadioGroup.Label>
      <div className={`flex gap-2 items-center ${appliedClasses.optionContainer}`}>
        {values.map((item) => (
          <RadioGroup.Option
            key={item.name}
            value={item.value}
            className={({ active, checked }) =>
              `
                  ${
                    checked
                      ? "bg-button-green ui-disabled:bg-gray-label  text-tag-text font-bold"
                      : "text-deactive"
                  }
                  ui-disabled:text-select-label-disabled  relative flex cursor-pointer rounded-sm max-md:px-2 md:px-[0.25rem] focus:outline-none text-sm md:text-[10px] font-roboto ${appliedClasses.option}`
            }
          >
            <RadioGroup.Label as="p">{item.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default Radio;
