import { useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";


const LoadingButton = (_props) => {
	const { children, className, onClick, errorMessage = 'Something went wrong', ...props } = _props;

	const [loading, setLoading] = useState(false)

	const handleClick = async (e) => {
		try {
			if (loading) return ;
			setLoading(true)
			await onClick(e)
		} catch(e) {
			console.log(e);
      toast(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
		} finally {
			setLoading(false)
		}
	}

	return (
		<button 
			className={twMerge(loading && 'relative overflow-hidden', className, loading && 'cursor-wait')}
			onClick={handleClick}
			{...props}
		>
			{children}
			{loading && (
				<div className="button-loading-bg absolute inset-0">
					<div className="absolute inset-0 bg-gradient-to-r from-spinner-blue/30 via-spinner-cyan/30 to-spinner-lime/30 " />
					<div className="absolute top-0 bottom-0 w-full right-full bg-gradient-to-r from-spinner-blue/30 via-spinner-cyan/30 to-spinner-lime/30 " />
				</div>
			)}
		</button>
	)
}

export default LoadingButton;
