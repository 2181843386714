

// const environment = typeof window === 'undefined' ? { ...process.env } : window.__env__
const environment = (name) => {
  if (typeof window == 'undefined' || !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return process.env[name]
  } else {
    return window.__env__[name]
  }
}

export default environment
