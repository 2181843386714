import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGenerationCost from '../../../helpers/useGenerationCost';
import useGenerationEndpoint from '../useGenerationEndpoint';
import { ReactComponent as InfoIcon } from '../../../assets/InfoIconGray.svg';
import { updateConfigTutorial } from '../../../reducers/formReducer';
import { usePopper } from 'react-popper';
import { useState } from 'react';
import useStyle from '../../../hooks/useStyle';
import { createPortal } from 'react-dom';

const ConfigTextInput = (props) => {
  const { config_metadata, value, onChange, label } = props;

  const input_value = useMemo(() => value?.value || '', [value]);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const handleValueChange = (v) => {
    onChange({
      ...value,
      __randomized: false,
      enabled: true,
      value: v,
    });
  };

  const { mode } = useGenerationEndpoint();

	const dispatch = useDispatch();

	const style = useStyle();

	const [anchorEl, setAnchorEl] = useState();

  const [configTutorialEl, setConfigTutorialEl] = useState();
  const [configTutorialArrowEl, setConfigTutorialArrowEl] = useState();
  let configTutorialPopper = usePopper(anchorEl, configTutorialEl, {
    placement: 'right',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: configTutorialArrowEl,
          // padding: -100,
        },
      },
    ],
  });

  const configTutorial = useSelector((state) => state.form.configTutorial);
  const handleCloseConfigTutorial = () => {
    dispatch(updateConfigTutorial({ ...configTutorial, style: null }));
  };

  return (
		<>
			<fieldset className='flex flex-col gap-2' ref={setAnchorEl}>
				{label && (
					<label className={`text-gray-label font-poppins font-bold text-xs flex gap-1 `}>
			
						{label}
					</label>
				)}
				<input
					className={`${
						mode === 'v4.0'
							? 'bg-app-bg-gray border-none py-2 text-icon-text-color w-full rounded-[4px]'
							: 'bg-transparent border border-dashed border-style-config-text-border w-4/5 rounded-md'
					}  placeholder:text-style-config-text-border placeholder:text-sm focus-within:outline-none focus-within:border-style-text px-2 py-1 `}
					placeholder='Write your main word here...'
					value={input_value}
					onChange={(e) => handleValueChange(e.target.value)}
					maxLength='25'
				/>
			</fieldset>
			{configTutorial.style === style && 
				createPortal(
					<div
						className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 ml-4 w-60 rounded-md'
						ref={setConfigTutorialEl}
						style={configTutorialPopper.styles.popper}
						{...configTutorialPopper.attributes.popper}
					>
						<div
							ref={setConfigTutorialArrowEl}
							style={configTutorialPopper.styles.arrow}
							{...configTutorialPopper.attributes.arrow}
							className='h-3 w-3 inline-block right-full top-1/2 '
						>
							<div className='w-full h-full bg-style-method-enabled translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-b border-l' />
						</div>
						<div className='flex items-center justify-start gap-2'>
							<span>
								<InfoIcon />
							</span>
							<span className='text-title-white text-sm font-roboto'>
								{configTutorial.title}
							</span>
						</div>
						<div className='font-roboto text-input-color text-xs '>
							{configTutorial.body}
						</div>
						<div className='flex items-center justify-end mt-1'>
							<button
								className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
								onClick={handleCloseConfigTutorial}
								type='button'
							>
								Got It
							</button>
						</div>
					</div>,
					document.getElementById('popup-root')
				)
			}
		</>
  );
};

export default ConfigTextInput;
