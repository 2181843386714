import axios from 'axios';
import environment from './envProvider';
import { getAccessToken } from './auth-axios';

const axiosInstance = axios.create({
  // baseURL: 'http://logodiffusion.com:1111',
  baseURL: environment('REACT_APP_API_URL') || "https://app.logodiffusion.com",
});

axiosInstance.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return request;
});

export default axiosInstance;
